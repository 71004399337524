import { Jexl } from 'jexl';
import moment from 'moment';
import { STANDARD_DATE_FORMAT } from '../utils/dates/dateHelpers';

export const jexl = new Jexl();

function toTime(input: unknown): number {
    if (input instanceof Date) {
        return input.getTime();
    } else if (typeof input === 'number') {
        return input;
    } else if (typeof input === 'string') {
        return new Date(input).getTime();
    }
    throw new TypeError(`Cannot convert "${input}" to time`);
}

const FACTOR_MILLI = 1;
const FACTOR_SECOND = FACTOR_MILLI * 1000;
const FACTOR_MINUTE = FACTOR_SECOND * 60;
const FACTOR_HOUR = FACTOR_MINUTE * 60;
const FACTOR_DAY = FACTOR_HOUR * 24;
const FACTOR_WEEK = FACTOR_DAY * 7;

function getTimeFactor(unit: string) {
    switch (unit) {
        case 'milli': return FACTOR_MILLI;
        case 'second': return FACTOR_SECOND;
        case 'minute': return FACTOR_MINUTE;
        case 'hour': return FACTOR_HOUR;
        case 'day': return FACTOR_DAY;
        case 'week': return FACTOR_WEEK;
        default: throw new TypeError('Unrecognized time-unit: ' + unit);
    }
}

jexl.addTransform('dateAdd', (x, unit, amount) => {
    const time = toTime(x);
    unit = unit.toLowerCase();
    const factor = getTimeFactor(unit);
    return time + factor * amount;
});

jexl.addTransform('dateFormat', (a, formatString) => {
    formatString = formatString || STANDARD_DATE_FORMAT;
    return moment(a).format(formatString);
});

jexl.addTransform('parseJSON', (x) => {
    try {
        return JSON.parse(x);
    } catch(e) {
        return x;
    }
});
