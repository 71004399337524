import React, { useMemo, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import { useCachedFetcher, useFetcher } from '@pearlchain/component-lib-common';
import { Button, Modal } from 'react-bootstrap';
import { error, success } from 'react-notification-system-redux';

import { listWebComponentUserGroups, RequestId, listUserGroups, saveWebComponentUserGroups } from './requestHandlers';
import UserGroupItemComponent from './UserGroupItem';
import UserGroupChooser from './UserGroupChooser';
import { StoreState } from '../../../types/storeTypes';
import { getDashboardVersion } from '../../../utils/selector/selectors';

type Props = {
    dashboardUuid: string;
    dashboardVersion: number;
    onSubmitSuccess: () => void;
}

type FormValues = {
    userGroups: string[];
}

function SharingForm(props: Props) {
    const dashboardUuid = props.dashboardUuid;
    const [isSubmitting, setSubmitting] = useState(false);

    const { data: dashboardUserGroups } = useFetcher(useCallback(() => listWebComponentUserGroups(dashboardUuid), [dashboardUuid]));
    const { data: userGroups } = useCachedFetcher(listUserGroups, RequestId.AVAILABLE_USER_GROUPS);
    
    const initialValues: FormValues = useMemo(() => ({
        userGroups: dashboardUserGroups || []
    }), [dashboardUserGroups]);
    

    const dispatch = useDispatch();
    function onSubmit(values: FormValues) {
        setSubmitting(true);
        saveWebComponentUserGroups(props.dashboardUuid, props.dashboardVersion, values.userGroups)
            .then(
                () => dispatch(success({ message: 'User-Groups Updated' })),
                (e: any) => dispatch(error({ message: 'Error updating user-groups' }))
            )
            .finally(() => {
                // hide the modal
                props.onSubmitSuccess();
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            render={({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <FieldArray
                            name="userGroups"
                            render={(arrayHelpers) => (
                                <div>
                                    <div className="mb-2">
                                        { values.userGroups.map((userGroup, index) => (
                                            <UserGroupItemComponent
                                                userGroup={userGroup}
                                                onRemoved={arrayHelpers.handleRemove(index)}
                                                userGroups={userGroups || []}/>)
                                        )}
                                    </div>
                                    <UserGroupChooser
                                        onChooseUserGroup={arrayHelpers.push}
                                        dashboardUserGroups={values.userGroups}
                                        userGroups={userGroups || []}/>
                                </div>
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={isSubmitting} type="submit">Save</Button>
                    </Modal.Footer>
                </form>
            )}
        />
    )
}

export default connect(
    (state: StoreState, ownProps: { dashboardUuid: string }) => ({
        dashboardVersion: getDashboardVersion(state, ownProps.dashboardUuid)
    }),
    { error, success }
)(SharingForm);
