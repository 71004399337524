import React from 'react';
import { FieldProps } from 'formik';
import { Form } from 'react-bootstrap';

type Props = {
    label?: string;
};

export default function CheckboxInput(props: Props & FieldProps) {
    const field = props.field;
    return <Form.Check type="checkbox" label={props.label} {...field} checked={field.value} />;
}
