import React from 'react';
import { getIn, Field, FieldProps } from 'formik';
import { Form } from 'react-bootstrap';
import { useCachedFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from '../../../../../utils/requests/requestId';
import { ReportDefinitionResponse, ReportGraphContentType } from '../../../../../types/responseTypes';
import { findReportDefinitions } from 'core/dashboard/utils/requests/requestHandlers';

type Props = {
    name: string;
    freeText?: boolean;
    optional?: boolean;
    reportSource: string | undefined;
};

function renderField(name: string, fieldNames: string[], optional: boolean | undefined) {
    // validates the field input
    function validate(input: string): string | undefined {
        if (!input) {
            return optional === true ? undefined : 'No Column-name selected';
        }

        if (fieldNames.length) {
            for (let fieldName of fieldNames) {
                if (fieldName === input) return;
            }

            // the current value isn't available as an option
            return 'Invalid column';
        }
    }

    return (
        <Field
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => {
                const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);

                // if definition model contains fields, show dropdown list
                if (fieldNames.length) {
                    // if the value entered isn't an option in the model fields list, fallback to ""
                    const value =
                        fieldNames.findIndex(fieldName => fieldName === field.value) < 0
                            ? ''
                            : field.value;

                    return (
                        <Form.Control as="select" isInvalid={!!error} {...field} value={value}>
                            <option value="" disabled>
                                {'  -- Column -- '}
                            </option>
                            {fieldNames.map(fieldName => (
                                <option key={fieldName} value={fieldName}>
                                    {fieldName}
                                </option>
                            ))}
                        </Form.Control>
                    );
                } else {
                    // no fields are available, free-text field
                    return <Form.Control placeholder="quantity" isInvalid={!!error} {...field} />;
                }
            }}
        />
    );
}

export default React.memo(function ReportColumnChooserField({
    freeText,
    name,
    reportSource,
    optional
}: Props) {
    // extracts model fields for the current report-uuid
    function mapDataToFields(data: ReportDefinitionResponse[] = []) {
        const definition = data.find(x => x.uuid === reportSource);
        if (definition == null) return [];

        const model = definition.models[0];
        if (model == null || freeText) return [];

        let fieldsName: string[] = [];

        for (let model of definition.models) {
            for(let field of model.fields){
                if(definition.contentType === ReportGraphContentType.DIAGRAM){
                    fieldsName.push(field.name);
                }    
            }
        }
        return fieldsName;
    }

    const { data } = useCachedFetcher(findReportDefinitions, RequestId.REPORT_DEFINITIONS);
    const fields = mapDataToFields(data);
    return renderField(name, fields, optional);
});
