import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { success, error } from "react-notification-system-redux";

export type Opts = {
    messageSuccess?: string;
    messageError?: string;
}

export function useRequestHandler<T, P extends any[]>(req: (...params: P) => Promise<T>, reqName: string) {
    const dispatch = useDispatch();
    return useCallback((...params: P) => {
        return req.apply(null, params)
            .then(x => {
                if (reqName)
                    dispatch(success({ title: reqName }));
                return x;
            })
            .catch((err) => {
                if (reqName)
                    dispatch(error({ title: reqName, message: '' + err }));
                throw err;
            });
    }, [req]);
}

