import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetDefinition } from '../../types/widgetTypes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A selectable item in the widget-library modal
 */

type Props = {
    widgetName: string,
    widgetIcon?: IconProp,
    widgetType: string;
    widget: WidgetDefinition;
    selected: boolean,
    onSelect: (widgetType: string) => void
}

const DEFAULT_ICON: IconProp = 'puzzle-piece';

export default React.memo(
    function WidgetLibraryItem(props: Props) {
        const selected = props.selected;
        const widgetType = props.widgetType;
        const widgetName = props.widgetName;
        const widgetIcon = props.widgetIcon;

        return (
            <div
                className={ 'widget-library-item' + (selected ? ' selected' : '') }
                onClick={() => props.onSelect(widgetType)}>

                <div className="widget-library-item-frame">
                    <div className="widget-library-item-icon">
                        <FontAwesomeIcon icon={(widgetIcon as IconProp) || DEFAULT_ICON}/>
                    </div>
                </div>
                <div className="widget-library-item-title">{ widgetName }</div>
            </div>
        );
    }
);
