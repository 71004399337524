import React, { useRef, useLayoutEffect } from 'react';
import ReactGridLayout, { WidthProvider, Layout } from 'react-grid-layout';
import { useDispatch } from 'react-redux';

import { GridLayoutConfig } from './layoutTypes';
import { setDashboardLayout } from '../../actions/dashboard-actions';
import WidgetLoader from '../widget/WidgetLoader';

const ResponsiveReactGridLayout = (WidthProvider(ReactGridLayout) as any) as typeof ReactGridLayout;

/**
 * Dashboard grid layout
 */

type Props = {
    dashboardUuid: string;
    layout: GridLayoutConfig;
    splitSize: number;
};

function useRefCallback<P extends any[], R>(fn: (...args: P) => R): (...args: P) => R {
    const ref = useRef<(...args: P) => R>();
    ref.current = fn;
    return (...args: P) => ref.current!.apply(null, args);
}

function layoutsAreEqual(as: Layout[], bs: Layout[]): boolean {
    if (as.length !== bs.length) return false;
    for (let i = 0, n = as.length; i < n; i++) {
        const a = as[i];
        const b = bs[i];

        for (let key in a) {
            if ((b as any)[key] !== (a as any)[key]) return false;
        }
    }

    return true;
}

export default function GridLayout(props: Props) {
    const layout = props.layout;

    function generateWidgets() {
        const widgetComponents = [];
        for (let layoutItem of layout.layout) {
            const widgetUuid = layoutItem.i!;

            // create a widget for each uuid in the layout
            widgetComponents.push(
                <div key={layoutItem.i}>
                    <WidgetLoader
                        widgetUuid={widgetUuid}
                        dashboardUuid={props.dashboardUuid}
                    />
                </div>
            );
        }

        return widgetComponents;
    }

    const dispatch = useDispatch();

    /**
     * A work-around, because react-grid-layout triggers onLayoutChange in componentWillReceiveProps, which causes the PREVIOUS
     * callback passed in to be triggered.
     *
     * https://github.com/STRML/react-grid-layout/issues/558
     */
    const callback = useRefCallback((l: Layout[]) => {
        // check that the layout really did change
        if (layoutsAreEqual(l, layout.layout)) return;
        
        // update the layout
        dispatch(setDashboardLayout(props.dashboardUuid, l));
    });

    // track the grid layout ref, and imperatively call "onWindowSize" when the 
    // split-pane was resized
    const resRef = useRef<any>();
    useLayoutEffect(() => {
        if (resRef.current == null) return;
        resRef.current.onWindowResize();
    }, [props.splitSize]);

    return (
        <ResponsiveReactGridLayout
            ref={resRef}
            cols={layout.cols}
            rowHeight={layout.rowHeight}
            layout={layout.layout}
            onLayoutChange={callback}
            draggableHandle=".widget-header-title"
        >
            {generateWidgets()}
        </ResponsiveReactGridLayout>
    );
}
