import { DialogResultForType, DialogType, DialogOptsForType } from "./dialogTypes";
import { useStore } from "react-redux";
import { showDialog } from "./actions";
import { useCallback, useEffect, useRef } from "react";
import { getDialogResult } from "./selectors";
import { StoreState } from "../../types/storeTypes";

export function useDialog<T extends DialogType>(dialogId: string, onResult: (result: DialogResultForType<T>) => void): (opts: DialogOptsForType<T>) => void {
    const store = useStore<StoreState>();
    const awaitingResult = useRef(false);

    useEffect(() => {
        return store.subscribe(() => {
            const result = getDialogResult(store.getState(), dialogId);
            if (result !== null && awaitingResult.current) {
                awaitingResult.current = false;
                onResult(result as DialogResultForType<T>);
            }
        });
    }, [store, dialogId, onResult]);

    const onShowDialog = useCallback((opts: DialogOptsForType<T>) => {
        awaitingResult.current = true;
        store.dispatch(showDialog(dialogId, opts));
    }, [store, dialogId]);

    return onShowDialog;
}
