import { DataSource, WidgetDefinition } from "../../../types/widgetTypes";
import echartsImage from '../../../../../img/echarts.png';
import LineChartWidget from "./LineChartWidget";
import { LineChartConfig } from "./lineChartTypes";

export const LINE_CHART_WIDGET_TYPE = 'LINE_CHART';

export const LINE_CHART_WIDGET: WidgetDefinition = {
    name: 'Line-Chart',
    description: 'Displays report results in a line-chart',
    icon: 'chart-line',
    image: echartsImage,
    component: LineChartWidget,
    listSources(config: LineChartConfig) {
        let dataSources: DataSource[] = []
        config.sources.map((src) => {
            dataSources.push({source: src.reportSource, params: src.reportParams || []}) 
        })
        return dataSources;
    }
}
