import React from 'react';
import { List } from 'immutable';
import { connect, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StoreState } from '../../../types/storeTypes';
import { getDashboardDescriptions } from '../../../utils/selector/selectors';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';
import { DrilldownEntry } from '../../../types/drilldownTypes';

type OwnProps = {
    widgetName: string;
    drilldown: DrilldownEntry[];
    actions: WidgetActions;
};

function WidgetHeaderTitle(props: OwnProps) {
    const { drilldown, widgetName } = props;
    const nEntries = drilldown.length;

    function createClickHandler(index: number) {
        return () => {
            const n = nEntries - index;
            if (n > 0) {
                props.actions.popDrilldown(n);
            }
        };
    }

    function getClassName(index: number) {
        return classNames('widget-header-title-item', { 'drilldown': index < nEntries });
    }

    const labels: string[] = [];
    labels.push(widgetName);

    const uuids = drilldown.map(item => {
        return item.dashboardUuid
    })
    

    const descriptions = useSelector((state: StoreState) => getDashboardDescriptions(state, uuids), shallowEqual);
    if(descriptions){
        for(let description of descriptions){
            labels.push(description)
        }
    }
    

    return (
        <div className="widget-header-title">
            {labels.map((label, i) => (
                <div key={i} className={getClassName(i)} onClick={createClickHandler(i)}>
                    {label}
                    { i < nEntries ? (
                        <FontAwesomeIcon icon="angle-right"/>
                    ) : undefined}
                </div>
            ))}
        </div>
    );
}

export default connect()(WidgetHeaderTitle);
