import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import { getOpenPageState } from "../utils/selector/selectors";
import { hidePage } from "../actions/dashboard-actions";

export default function OpenPageContainer() {
    const dispatch = useDispatch();
    const openPage = useSelector(getOpenPageState);
    const [show, setShow] = useState(false);

    const handleExit = () => {
        dispatch(hidePage());
    };

    const handleHide = () => {
        setShow(false);
    };

    useEffect(() => {
        if (openPage) {
            setShow(true);
        }
    }, [openPage]);

    return (
        <Modal show={show} dialogClassName="modal-full" centered onExit={handleExit} onHide={handleHide}>
            <Modal.Header closeButton>
                Open Page
            </Modal.Header>
            <Modal.Body>
                <iframe className="iframe-full" src={openPage?.pageUrl}/>
            </Modal.Body>
        </Modal>
    );
}
