import React from 'react';
import { FieldProps } from 'formik';

import { Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { FieldType } from '../sourceMappingTypes';

const columnTypeOpts: { label: string; value: FieldType }[] = [
    { label: 'Raw', value: FieldType.RAW },
    { label: 'Count', value: FieldType.COUNT },
    { label: 'Sum', value: FieldType.SUM },
    { label: 'Group-By', value: FieldType.GROUP_BY }
];

function findLabelByValue(value: string) {
    const opt = columnTypeOpts.find(x => x.value === value);
    return opt ? opt.label : '';
}

export default function FieldTypeInput({ field, form }: FieldProps) {
    const name = field.name;
    return (
        <DropdownButton
            id={name}
            variant="secondary"
            as={InputGroup.Prepend}
            title={findLabelByValue(field.value)}
        >
            {columnTypeOpts.map(opt => (
                <Dropdown.Item key={opt.value} onClick={() => form.setFieldValue(name, opt.value)}>
                    {opt.label}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}
