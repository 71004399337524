import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { FastField } from 'formik';

import ConfigPanel from "./ConfigPanel";
import FormControlFieldComponent from './formik/FormControlFieldComponent';
import FormFieldErrorFeedback from './formik/FormFieldErrorFeedback';
import { validateRequired } from '../../../../utils/validate/validators';
import EventActionsInput from 'core/dashboard/components/config/formik/EventActionsInput';
import EvaluatableFieldComponent from 'core/dashboard/components/form/formik/EvaluatableFieldComponent';

export const intervals = [
    { value: 0, name: 'none' },
    { value: 10000, name: '10 sec' },
    { value: 120000, name: '2 min' },
    { value: 900000, name: '15 min' },
    { value: 3600000, name: '1 hour' }
];

type Props = {
    fieldName?: string;
    eventNames?: string[]
}

function getFieldName(name: string | undefined, fieldName: string) {
    return name ? name + '.' + fieldName : fieldName;
}

export default function CommonConfig(props: Props) {
    const { t } = useTranslation();
    return (
        <ConfigPanel defaultExpanded header="Common">
            <div className="p-2">
                <Form.Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>{t('widget.name')}</Form.Label>
                            <FastField name={getFieldName(props.fieldName, "widgetName")} validate={validateRequired} component={EvaluatableFieldComponent}/>
                            <FormFieldErrorFeedback name="widgetName"/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>{t('dashboard.refresh.interval')}</Form.Label>
                            <FastField name={getFieldName(props.fieldName, "pollInterval")} as="select" component={FormControlFieldComponent} validate={validateRequired}>
                                {intervals.map((opt, index) => 
                                    <option key={index} value={opt.value}>{ opt.name }</option>
                                )}
                            </FastField>
                            <FormFieldErrorFeedback name="pollInterval"/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                { props.eventNames
                    ? <FastField name={getFieldName(props.fieldName, 'events')} component={EventActionsInput} eventNames={props.eventNames}/>
                    : undefined
                }
            </div>
        </ConfigPanel>
    );
}
