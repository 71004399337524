import { WidgetDefinition } from '../types/widgetTypes';
import { SINGLE_SOURCE_WIDGET_TYPE, SINGLE_SOURCE_WIDGET } from '../components/widgets/singlesource/singleSourceWidgetDefinition';
import { PIE_CHART_WIDGET_TYPE, PIE_CHART_WIDGET } from '../components/widgets/pieChart/pieChartWidgetDefinition';
import { LINE_CHART_WIDGET_TYPE, LINE_CHART_WIDGET } from '../components/widgets/lineChart/lineChartWidgetDefinition';
import { BAR_CHART_WIDGET_TYPE, BAR_CHART_WIDGET } from '../components/widgets/barChart/barChartWidgetDefinition';
import { HUMAN_TASKS_WIDGET_TYPE, HUMAN_TASKS_WIDGET } from '../components/widgets/humanTasks/humanTasksWidgetDefinition';
import { IFRAME_WIDGET_TYPE, IFRAME_WIDGET } from '../components/widgets/iframe/iframeWidgetDefinition';
import { ENTERPRISE_DESIGNER_WIDGET_TYPE, ENTERPRISE_DESIGNER_WIDGET } from '../components/widgets/enterprisedesigner/enterpriseDesignerWidgetDefinition';

export const WIDGETS: Map<string, WidgetDefinition> = new Map([
    [PIE_CHART_WIDGET_TYPE, PIE_CHART_WIDGET],
    [LINE_CHART_WIDGET_TYPE, LINE_CHART_WIDGET],
    [BAR_CHART_WIDGET_TYPE, BAR_CHART_WIDGET],
    [SINGLE_SOURCE_WIDGET_TYPE, SINGLE_SOURCE_WIDGET],
    [HUMAN_TASKS_WIDGET_TYPE, HUMAN_TASKS_WIDGET],
    [IFRAME_WIDGET_TYPE, IFRAME_WIDGET],
    [ENTERPRISE_DESIGNER_WIDGET_TYPE, ENTERPRISE_DESIGNER_WIDGET]
]);

export const WIDGET_KEYS = [...WIDGETS.keys()];

export function getWidgetDefinitionByType(widgetType: string) {
    const widgetDef = WIDGETS.get(widgetType);
    if (widgetDef == null) {
        throw new Error('no widget definition exists with type: ' + widgetType);
    }
    return widgetDef;
}
