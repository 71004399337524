import React, { useState } from 'react';
import SplitPane from 'react-split-pane';

import SideMenu from './sidemenu/SideMenu';
import DashboardHeader from './header/DashboardHeader';
import DashboardContainer from './dashboard/DashboardContainer';
import Dialogs from './dialog/Dialogs';
import DashboardPluginsLoader from './dashboard/DashboardPluginsLoader';
import OpenPageContainer from './OpenPageContainer';

type Props = {
    dashboardUuid: string | undefined;
    properties: { [key: string]: unknown };
};

const DEFAULT_SIZE = 300;

export default function DashboardPageSplitPane(props: Props) {
    const [splitSize, setSplitSize] = useState(DEFAULT_SIZE);
    const [isSideMenuVisible, setSideMenuVisible] = useState(false);

    function onToggleSideMenu() {
        setSideMenuVisible(!isSideMenuVisible);
    }

    const size = isSideMenuVisible ? splitSize : 0;

    return (
        <div className="dashboard-page">
            <SplitPane size={size} allowResize={isSideMenuVisible} onChange={(newSize) => setSplitSize(newSize)}>
                <SideMenu
                    isVisible={isSideMenuVisible}
                    dashboardUuid={props.dashboardUuid}
                    onToggleSideMenu={onToggleSideMenu}
                />
                <DashboardPluginsLoader>
                    <div className="dashboard-container">
                        <DashboardHeader
                            isSideMenuVisible={isSideMenuVisible}
                            onToggleSideMenu={onToggleSideMenu}
                        />
                            {props.dashboardUuid ? (
                                <DashboardContainer
                                    splitSize={size}
                                    dashboardUuid={props.dashboardUuid}
                                    rootParent={undefined}
                                    parentProperties={props.properties}
                                    readonly={false}
                                />
                            ) : null}
                    </div>
                </DashboardPluginsLoader>
            </SplitPane>
            <Dialogs/>
            <OpenPageContainer/>
        </div>
    );
}
