import React from 'react';

type Props = {
    name: string;
    enabled: boolean;
    onToggled: () => void;
};

export default function Switch(props: Props) {
    return (
        <div className="custom-control custom-switch mb-2">
            <input
                type="checkbox"
                className="custom-control-input"
                id={props.name}
                checked={props.enabled}
                onChange={props.onToggled}
            />
            <label className="custom-control-label" onClick={props.onToggled}>
                {props.enabled ? 'Advanced' : 'Basic'}
            </label>
        </div>
    );
}
