import React, { useState } from 'react';
import { FastField, ArrayHelpers } from 'formik';
import { Button, CloseButton, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ColumnConfig } from '../singleSourceTypes';
import ReportColumnChooserField from '../../common/config/formik/ReportColumnChooserField';
import ColumnTypeDropdown, { ColumnType } from './ColumnTypeDropdown';
import FormFieldErrorFeedback from '../../common/config/formik/FormFieldErrorFeedback';
import FormControlFieldComponent from '../../common/config/formik/FormControlFieldComponent';
import ColumnFormattersField from '../../common/config/formik/ColumnFormattersField';

type Props = {
    name: string;
    value: ColumnConfig;
    reportSource: string;
    index: number;
    size: number;
    arrayHelpers: ArrayHelpers;
};

export function validate(value: ColumnConfig): string | undefined {
    if (!value.count && !value.sum && !value.raw && !value.groupBy && !value.action) {
        return 'No column type selected';
    }
}

export default function SingleSourceColumnConfig(props: Props) {
    const { t } = useTranslation();
    
    const index = props.index;
    const value = props.value;
    const name = props.name;
    const size = props.size;

    const [isFormattersOpen, setFormattersOpen] = useState(false);

    function renderColumnChooser() {
        return (
            <Col key="column-chooser">
                <ReportColumnChooserField name={name + '.value'} reportSource={props.reportSource} />
                <FormFieldErrorFeedback name={name + '.value'} />
            </Col>
        );
    }

    function createAddons() {
        const addons: React.ReactElement[] = [];
        if (value.raw || value.sum || value.groupBy) {
            addons.push(renderColumnChooser());
        }

        return addons;
    }

    function onColumnTypeChanged(columnType: ColumnType) {
        const nextValue = { ...value, ...columnType };
        props.arrayHelpers.replace(index, nextValue);
    }

    function onchangeTranslate(translate: boolean) {
        const nextValue = { ...value, translate };
        props.arrayHelpers.replace(index, nextValue);
    }

    function onMoveUp() {
        props.arrayHelpers.move(index, index - 1);
    }

    function onMoveDown() {
        props.arrayHelpers.move(index, index + 1);
    }

    const addons = createAddons();
    return (
        <React.Fragment>
            <Form.Row className="column-config-row">
                <Col className="flex-content flex-column">
                    { index > 0 ? (
                        <div className="column-config-btn" onClick={onMoveUp}>
                            <FontAwesomeIcon icon="chevron-up"/>
                        </div>
                    ) : undefined }
                    { index < size - 1 ? (
                        <div className="column-config-btn"  onClick={onMoveDown}>
                            <FontAwesomeIcon icon="chevron-down"/>
                        </div>
                    ) : undefined }
                </Col>
                <Col className="flex-content">
                    <ColumnTypeDropdown
                        value={{
                            count: value.count,
                            sum: value.sum,
                            raw: value.raw,
                            groupBy: value.groupBy,
                            action: value.action
                        }}
                        onChange={onColumnTypeChanged}
                    />
                </Col>
                {addons}
                <Col>
                    <FastField name={name + '.title'} placeholder="Title" component={FormControlFieldComponent} />
                    <FormFieldErrorFeedback name={name + '.title'} />
                </Col>
                <Col className="flex-content">
                    <Button onClick={() => setFormattersOpen(true)}>
                        <FontAwesomeIcon icon="brush" />
                    </Button>
                </Col>
                <Col className="flex-content">
                    <CloseButton onClick={() => props.arrayHelpers.remove(index)} />
                </Col>
            </Form.Row>
            <ColumnFormattersField
                name={name + '.format'}
                isOpen={isFormattersOpen}
                onClose={() => setFormattersOpen(false)}/>
        </React.Fragment>
    );
}
