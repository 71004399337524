import { Layout } from "react-grid-layout";

export enum LayoutType {
    GRID = 'grid',
    SPLIT = 'split',
    FULL = 'full'
}

export type GridLayoutConfig = {
    type: LayoutType.GRID,
    layout: Layout[],
    cols: number,
    rowHeight: number
}

export type SplitLayoutConfig = {
    type: LayoutType.SPLIT,
    cols: number,
    layout: string[]
}

export type FullLayoutConfig = {
    type: LayoutType.FULL,
    widgetUuid: string
}

export type LayoutConfig =
    | GridLayoutConfig
    | SplitLayoutConfig
    | FullLayoutConfig;
