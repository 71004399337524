import React from 'react';

import { WidgetProps } from '../widget/widgetTypes';
import { useAvailableWidgets } from './widgets';

/**
 * Determines which widget to show based on the widget type
 */

export default function WidgetFactory(props: WidgetProps) {
    const type = props.state.widget.type;
    const widgets = useAvailableWidgets();
    if (!widgets) return null;

    const entry = widgets.find(w => w.value === type);
    if (!entry) return null;

    return React.createElement(entry.component, props);
}
