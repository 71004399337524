import { FormikProps, Field, FastField, FieldProps } from "formik";
import React from "react";
import { Form, InputGroup, Button, Row } from "react-bootstrap";
import { FormValues } from "./ExportDataModalForm";

/**
 * Copy of ExportDataModalForm but without any reportdefinition stuff, so should work for all datasources
 */

type Props = FormikProps<FormValues> & {
    widgetParams: {
        [key: string]: string;
    };
    sourceIds: string[];
}

export default function DataSourceExportDataModalForm({ values, isSubmitting, setFieldValue, handleSubmit, widgetParams, sourceIds }: Props) {
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Source</Form.Label>
                <Field
                    name="source"
                    render={(params: FieldProps<string>) => (
                        <Form.Control as="select">
                            {sourceIds.map((source) => (
                                <option key={source} value={source}>
                                    {source}
                                </option>
                            ))}
                        </Form.Control>
                    )}
                />
            </Form.Group>
                <Form.Group>
                    <Form.Label>Params</Form.Label>
                    {Object.keys(values.params).filter(param => {
                        const paramValue = widgetParams[param];
                        return paramValue == null || paramValue === ''; 
                    }).map((k) => (
                        <Form.Group key={k} className="ml-2">
                            <Form.Label>{k}</Form.Label>
                            <FastField
                                name={"params." + k}
                                render={(params: FieldProps<string>) => (
                                    <Form.Control {...params.field} placeholder="Filename"/>
                                )}
                            />
                        </Form.Group>
                    ))}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Output</Form.Label>
                    <InputGroup>
                        <FastField
                            name="fileName"
                            render={(params: FieldProps<string>) => (
                                <Form.Control {...params.field} placeholder="File Name"/>
                            )}
                        />
                        <InputGroup.Append>
                            <Button type="submit" disabled={isSubmitting}>Export as Excel</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
        </Form>
    );
}
