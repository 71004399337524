import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';

import { Dashboard, StoreState } from '../../types/storeTypes';
import { getDashboard } from '../../utils/selector/selectors';
import { loadDashboard } from '../../actions/dashboard-actions';

type Props = {
    dashboardUuid: string;
    dashboard: Dashboard | undefined;
    onDashboardLoaded?: (dashboard: Dashboard) => void;
    render?: (dashboard: Dashboard | undefined) => React.ReactElement;
    loadDashboard: typeof loadDashboard;
};

function DashboardLoader(props: Props) {
    const { dashboard, dashboardUuid, onDashboardLoaded, loadDashboard, render } = props;

    const ref = useRef<{ dashboardUuid: string; isLoaded: boolean } | null>(null);
    useEffect(() => {
        const isLoaded = dashboard != null;
        if (
            ref.current == null ||
            ref.current.dashboardUuid !== dashboardUuid ||
            ref.current.isLoaded !== isLoaded
        ) {
            ref.current = { dashboardUuid, isLoaded };
            if (dashboard != null) {
                onDashboardLoaded && onDashboardLoaded(dashboard);
            } else {
                loadDashboard(dashboardUuid);
            }
        }
    }, [dashboardUuid, dashboard, onDashboardLoaded]);

    return useMemo(() => render ? render(dashboard) : null, [render, dashboard]);
}

export default connect(
    (state: StoreState, props: { dashboardUuid: string }) => ({
        dashboard: getDashboard(state, props.dashboardUuid)
    }),
    { loadDashboard }
)(DashboardLoader);
