import { useRef } from "react";
import { TrackAccessorsPropertySource } from "./TrackAccessorsPropertySource";
import { IPropertySource } from "../PropertySource";

/**
 * Memoizes based on which properties were accessed inside the widget.
 */

export function useTrackAccessorsPropertySource(widgetConfig: unknown, propertySource: IPropertySource): IPropertySource {
    const source = useRef<TrackAccessorsPropertySource>();
    if (!source.current) {
        source.current = new TrackAccessorsPropertySource(propertySource);
    }

    const prevRef = useRef({ config: widgetConfig, propertySource });

    // reset the tracked dependencies when the widget config changed
    if (prevRef.current.config !== widgetConfig) {
        prevRef.current.config = widgetConfig;
        source.current = new TrackAccessorsPropertySource(propertySource);

    } else if (prevRef.current.propertySource !== propertySource) {
        prevRef.current.propertySource = propertySource;
        const src = source.current;

        // compare with the the new property-source
        if (!src.compare(propertySource)) {
            source.current = new TrackAccessorsPropertySource(propertySource, src.getDependencies());
        }
    }

    return source.current;
}
