import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { BarChartConfig } from '../barChartTypes';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { ReportDataSource, WidgetActions } from '../../../../types/widgetTypes';
import { AxisType } from '../../../../types/configTypes';
import BarChartWidgetConfigForm from './BarChartWidgetConfigForm';

type Props = {
    common: WidgetCommonConfig;
    config: BarChartConfig | undefined;
    actions: WidgetActions;
    dataSources: ReportDataSource[];
};

export type BarChartFormValues = BarChartConfig & WidgetCommonConfig;

const DEFAULT_VALUES: BarChartConfig = {
    reportSource: '',
    type:'',
    reportParams: [],
    xAxis: {
        label: '',
        column: '',
        type: AxisType.CATEGORY,
        timeUnit: undefined
    },
    yAxis: {
        label: '',
        column: '',
        type: AxisType.LINEAR,
        timeUnit: undefined
    },
    action: undefined,
    sourceMapping: undefined,
    sqlParams: {},
    orderColumn: '',
    providersHandlers: ''
};

export default function BarChartWidgetConfig(props: Props) {
    const common = props.common;
    const config = props.config;

    const initialValues = useMemo((): BarChartFormValues => {
        const configValues = config || DEFAULT_VALUES;
        return Object.assign({}, common, configValues);
    }, [config, common]);

    function onSubmit(values: BarChartFormValues) {
        const common: WidgetCommonConfig = {
            widgetName: values.widgetName,
            pollInterval: values.pollInterval,
            events: values.events
        };

        const config: BarChartConfig = {
            reportSource: values.reportSource,
            reportParams: values.reportParams,
            xAxis: values.xAxis,
            yAxis: values.yAxis,
            action: values.action,
            sourceMapping: values.sourceMapping,
            format: values.format,
            type: values.type,
            sqlParams: values.sqlParams,
            orderColumn: values.orderColumn,
            providersHandlers: values.providersHandlers
        };

        const uuid = (props.dataSources && props.dataSources.length > 0) ? props.dataSources[0].uniqueIdentifier : v4();
        const dataSources: ReportDataSource[] = [{definition: values.reportSource, type: values.type, uniqueIdentifier: uuid, params: values.sqlParams, orderColumn: values.orderColumn}]

        // configure the widget in the store
        props.actions.configure(common, config, dataSources);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={(formikProps) => (
                <BarChartWidgetConfigForm
                    {...formikProps}
                    isCancellable={config != null}
                    onCancel={props.actions.cancelConfig}
                />
            )}
        />
    );
}
