import React from 'react';
import { WidgetProps } from '../../widget/widgetTypes';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';
import EnterpriseDesignerView from './EnterpriseDesignerView';
import { EnterpriseDesignerConfig } from './enterpriseDesignerTypes';
import EnterpriseDesignerConfigComponent from './EnterpriseDesignerConfig';

export const ENTERPRISE_DESIGNER_TYPE = 'ENTERPRISE_DESIGNER';

export default function EnterpriseDesignerWidget(props: WidgetProps) {
    const widget = props.state.widget;
    const config = widget.config as EnterpriseDesignerConfig | undefined;

    if (props.state.viewMode === WidgetViewMode.VIEW && config) {
        const widgetUuid = props.widgetUuid;
        const designId = config.designId;
        const selectorConfigs = config.selectorConfigs;

        return (
            <EnterpriseDesignerView
                designId={designId}
                selectorConfigs={selectorConfigs}
                refreshCount={props.state.refreshCount}
                widgetUuid={widgetUuid}
                widgetActions={props.actions}
            />
        );
    } else {
        return (
            <EnterpriseDesignerConfigComponent
                common={widget.common}
                config={config}
                actions={props.actions} 
            />
        );
    }
}
