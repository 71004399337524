import { Dashboard, Widget, WidgetReference } from "../types/storeTypes";
import { WebComponentSaveResponse } from "../types/responseTypes";
import { saveWebComponent, deleteWebComponent } from "../utils/requests/requestHandlers";
import { serializeDashboard } from '../utils/serialization/dashboardSerialization';
import { serializeWidget } from '../utils/serialization/widgetSerialization';

export type DashboardSyncState = {
    dashboard: Dashboard;
    widgets: Map<string, Widget<unknown>>;
}

export function isDashboardChanged(prev: Dashboard, next: Dashboard) {
    if (prev === next) return false;
    return prev.layout !== next.layout
        || prev.properties !== next.properties
        || prev.name !== next.name;
}

export function isWidgetChanged(prev: Widget<unknown>, next: Widget<unknown>) {
    if (prev === next) return false;
    return prev.common !== next.common
        || prev.config !== next.config
        || prev.type !== next.type;
}

export async function saveDashboardAndWidgetChanges(prev: Dashboard, next: Dashboard): Promise<[number, { [uuid: string]: number }]> {
    let version = next.version;
    if (isDashboardChanged(prev, next)) {
        // save dashboard changes
        const { version: v } = await saveDashboard(next);
        version = v;
    }

    const prevWidgets = prev.widgets;
    const nextWidgets = next.widgets;

    // compute lookup by widget uuid
    const prevLookup = new Map<string, Widget<unknown>>();
    for (const widgetUuid in prevWidgets) {
        prevLookup.set(widgetUuid, prevWidgets[widgetUuid]);
    }

    // save created / updated widgets
    let widgetVersions: { [uuid: string]: number } = {};
    for (const widgetUuid in nextWidgets) {
        const prevWidget = prevWidgets[widgetUuid];
        if (prevWidget != null) {
            prevLookup.delete(widgetUuid);
        }

        const nextWidget = nextWidgets[widgetUuid];
        if (prevWidget == null || isWidgetChanged(prevWidget, nextWidget)) {
            const { version } = await saveWidget(nextWidget);
            widgetVersions[widgetUuid] = version;
        }
    }

    // remove deleted widgets
    for (let [uuid, widget] of prevLookup.entries()) {
        const ref: WidgetReference = { uuid, version: widget.version };
        await deleteWidget(ref);
    }

    return [version, widgetVersions];
}

function saveDashboard(dashboard: Dashboard): Promise<WebComponentSaveResponse> {
    const params = serializeDashboard(dashboard);
    return saveWebComponent(params);
}

function saveWidget(widget: Widget<unknown>): Promise<WebComponentSaveResponse> {
    const params = serializeWidget(widget);
    return saveWebComponent(params);
}

function deleteWidget(ref: WidgetReference): Promise<Response> {
    return deleteWebComponent({
        uuid: ref.uuid,
        version: ref.version
    });
}
