import React from 'react';
import { FastField, FieldProps } from 'formik';
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';

import { MinMaxConfig } from '../../../../../types/configTypes';

type Props = {
    name: string;
};

const THRESHOLD_MIN = 0;
const THRESHOLD_MAX = 100;
const THRESHOLD_MED_LOW = 25;
const THRESHOLD_MED_HIGH = 75;

function overlaps(a: number, b: number, a2: number, b2: number) {
    return a2 < b && b2 > a;
}

function toggle(
    min: number,
    max: number,
    thresholdMin: number,
    thresholdMax: number,
    toggled: boolean
) {
    if (toggled) {
        if (min < thresholdMin) {
            max = thresholdMin;
        } else if (max > thresholdMax) {
            min = thresholdMax;
        } else {
            min = 0;
            max = 0;
        }
    } else {
        if (min === max) {
            min = thresholdMin;
            max = thresholdMax;
        } else {
            min = Math.min(min, thresholdMin);
            max = Math.max(max, thresholdMax);
        }
    }
    return { min, max };
}

function MinMaxFormFieldComponent({ field, form }: FieldProps) {
    const name = field.name;
    const value = field.value as MinMaxConfig | undefined;
    const inputMin: string | undefined = value && value.min;
    const inputMax: string | undefined = value && value.max;

    const min = inputMin != null ? parseFloat(inputMin) : 0;
    const max = inputMax != null ? parseFloat(inputMax) : 100;

    const isLowShowing = overlaps(THRESHOLD_MIN, THRESHOLD_MED_LOW, min, max);
    const isMedShowing = overlaps(THRESHOLD_MED_LOW, THRESHOLD_MED_HIGH, min, max);
    const isHighShowing = overlaps(THRESHOLD_MED_HIGH, THRESHOLD_MAX, min, max);

    function onToggleLow() {
        form.setFieldValue(name, toggle(min, max, THRESHOLD_MIN, THRESHOLD_MED_LOW, isLowShowing));
    }

    function onToggleMed() {
        form.setFieldValue(
            name,
            toggle(min, max, THRESHOLD_MED_LOW, THRESHOLD_MED_HIGH, isMedShowing)
        );
    }

    function onToggleHigh() {
        form.setFieldValue(
            name,
            toggle(min, max, THRESHOLD_MED_HIGH, THRESHOLD_MAX, isHighShowing)
        );
    }

    return (
        <Form.Row>
            <Col>
                <Form.Control
                    value={inputMin != null ? '' + inputMin : ''}
                    placeholder="Min"
                    onChange={(e: any) =>
                        form.setFieldValue(name, {
                            min: e.target.value,
                            max: inputMax
                        })
                    }
                />
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonGroup>
                    <Button
                        variant={isLowShowing ? 'success' : 'outline-secondary'}
                        onClick={onToggleLow}
                    >
                        Low
                    </Button>
                    <Button
                        variant={isMedShowing ? 'success' : 'outline-secondary'}
                        onClick={onToggleMed}
                    >
                        Med
                    </Button>
                    <Button
                        variant={isHighShowing ? 'success' : 'outline-secondary'}
                        onClick={onToggleHigh}
                    >
                        High
                    </Button>
                </ButtonGroup>
            </Col>
            <Col>
                <Form.Control
                    value={inputMin != null ? '' + inputMax : ''}
                    placeholder="Max"
                    onChange={(e: any) =>
                        form.setFieldValue(name, {
                            min: inputMin,
                            max: e.target.value
                        })
                    }
                />
            </Col>
        </Form.Row>
    );
}

export default function MinMaxFormField(props: Props) {
    return <FastField name={props.name} component={MinMaxFormFieldComponent} />;
}
