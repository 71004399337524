import React from 'react';
import { FieldArray, FastField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';

import { ProcessVariableConfig } from '../humanTasksTypes';
import TextInput from '../../../config/formik/TextInput';

type Props = {
    name: string;
    processVars: ProcessVariableConfig[];
};

function createProcesssVariable(): ProcessVariableConfig {
    return {
        key: '',
        value: ''
    };
}

export default function HumanTasksProcessVariablesConfig({ name, processVars }: Props) {
    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="p-2">
                    <Table>
                        <thead>
                            <tr className="text-center">
                                <th>Key</th>
                                <th>Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {processVars.map((procVar, idx) => {
                                const rowName = name + '.' + idx;
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FastField
                                                placeholder="key"
                                                name={rowName + '.key'}
                                                component={TextInput}
                                            />
                                        </td>
                                        <td>
                                            <FastField
                                                placeholder="key"
                                                name={rowName + '.value'}
                                                component={TextInput}
                                            />
                                        </td>
                                        <td
                                            className="text-center"
                                            onClick={() => arrayHelpers.remove(idx)}
                                        >
                                            <FontAwesomeIcon icon="times" />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td />
                                <td />
                                <td className="text-center" onClick={() => arrayHelpers.push(createProcesssVariable())}>
                                    <FontAwesomeIcon icon="plus" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        />
    );
}
