import { toString } from "../dataTypes";

export function groupDataBySeries(keySeries: string, data: { [key: string]: unknown }[]) {
    const lookupSeries = new Map<string, number>();
    const seriesData: { [key: string]: unknown }[][] = [];

    for (let datum of data) {
        const seriesName = (datum[keySeries] || '') as string;
        let seriesIdx = lookupSeries.get(seriesName);
        if (seriesIdx == null) {
            seriesIdx = seriesData.length;
            lookupSeries.set(seriesName, seriesIdx);
            seriesData.push([]);
        }

        const currSeriesData = seriesData[seriesIdx];
        currSeriesData.push(datum);
    }

    return seriesData;
}

export function resolveLabels(keyLabel: string, seriesData: { [key: string]: unknown }[][]) {
    const lookupLabels = new Map<string, number>();
    const labels: string[] = [];

    for (let currSeriesData of seriesData) {
        for (let datum of currSeriesData) {
            const name = toString(datum[keyLabel]);
            if (!lookupLabels.has(name)) {
                lookupLabels.set(name, labels.length);
                labels.push(name);
            }
        }
    }

    return { labels, lookupLabels };
}
