import { LineChartConfig } from "./lineChartTypes";
import { SourceMappingConfig, FieldMappingConfig, FieldType, SourceConfig, SeriesConfig } from "../../sourceMapping/sourceMappingTypes";
import { reportParamsConfigToRequest } from "../../../utils/requests/requestHelpers";

export const KEY_X_AXIS = 'x';
export const KEY_Y_AXIS = 'y';
export const KEY_SERIES = 'series';

export function sourceMappingFromConfig(config: LineChartConfig): SourceMappingConfig {
    // return the "advanced" config if defined
    if (config.sourceMapping) {
        return config.sourceMapping;
    }

    const sources: SourceConfig[] = [];
    const series: SeriesConfig[] = [];

    let i = 0;
    for (let source of config.sources) {
        const params = reportParamsConfigToRequest(source.reportParams)
        sources.push({
            name: 'ds' + i,
            report: source.reportSource,
            type: source.type,
            params,
            sqlParams: source.sqlParams,
            orderColumn: "",
            providersHandlers: source.providersHandlers
        });

        const mappings: FieldMappingConfig[] = [];

        mappings.push({
            from: source.xColumn,
            to: KEY_X_AXIS,
            type: FieldType.RAW
        });

        mappings.push({
            from: source.yColumn,
            to: KEY_Y_AXIS,
            type: FieldType.RAW
        });

        if (source.groupBy) {
            mappings.push({
                from: source.groupBy,
                to: KEY_SERIES,
                type: FieldType.RAW
            });
        }

        series.push({
            series: '',
            source: 'ds' + i,
            mappings
        });
        
        i++
    }

    return {
        sources,
        series
    };
}

