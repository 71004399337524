import { RequestBuilder } from "@pearlchain/component-lib-common";
import { HumanTaskOverviewResponse, HumanTaskFormPropertyResponse } from "./types/responseTypes";
import { ServiceMessageResultResponse } from "../../../types/responseTypes";
import { HumanTasksConfig } from "./humanTasksTypes";
import { procVarsToObject } from "./view/humanTasksRequestHandler";

export type FindHumanTasksRequestParams = {
    minPriority: number,
    maxPriority: number,
    user?: string,
    assignedToMe: boolean,
    unassigned: boolean,
    filter: string[],
    formKeys: string[],
    processVariables: { [key: string]: string }
}

const URL = {
    LIST_HUMAN_TASKS_FORM_KEYS: 'sec/api/humanTasks/formkeys',
    FIND_HUMAN_TASKS: 'sec/api/humanTasks/find',
    FIND_HUMAN_TASKS_SIMPLE: 'sec/api/humanTasks/findSimple',
    FETCH_TASK_DETAIL_INFO: 'sec/api/humanTasks/detail',
    APPROVE_TASK: 'sec/api/humanTasks/approve',
    REJECT_TASK: 'sec/api/humanTasks/reject',
    SUBMIT_TASK: 'sec/api/humanTasks/submit',
    SAVE_TASK: 'sec/api/humanTasks/save',
    CLAIM_TASK: 'sec/api/humanTasks/claim',
    UNCLAIM_TASK: 'sec/api/humanTasks/unclaim',
    ASSIGN_TASK: 'sec/api/humanTasks/assign',
    ASSIGNABLE_USERS: 'sec/api/humanTasks/assignable-users'
}

export const requestId = {
    FORM_KEYS: 'HUMAN_TASKS/LIST_FORM_KEYS'
}

export function listHumanTasksFormKeys(): Promise<string[]> {
    return new RequestBuilder()
        .get(URL.LIST_HUMAN_TASKS_FORM_KEYS)
        .toJS();
}

export function findHumanTasks(params: FindHumanTasksRequestParams): Promise<HumanTaskOverviewResponse[]> {
    return new RequestBuilder()
        .post(URL.FIND_HUMAN_TASKS)
        .setBodyJson(params)
        .toJS();
}

export function fetchTaskDetailInfo(taskId: string): Promise<HumanTaskFormPropertyResponse[]> {
    return new RequestBuilder()
        .setBodyText(taskId)
        .post(URL.FETCH_TASK_DETAIL_INFO)
        .toJS();
}

export function submitTask(taskId: string, formValues: { [key: string]: unknown }, isApproved: boolean | undefined): Promise<ServiceMessageResultResponse> {
    return new RequestBuilder()
        .setBodyJson({
            taskId,
            properties: formValues,
            approved: isApproved
        })
        .post(URL.SUBMIT_TASK)
        .toJS();
}

export function findHumanTasksSimple(config: HumanTasksConfig): Promise<HumanTaskOverviewResponse> {
    const minPriority = parseFloat(config.priority.min);
    const maxPriority = parseFloat(config.priority.max);
    const processVariables = procVarsToObject(config.processVariables);
    
    const params = {
        processVariables,
        minPriority,
        maxPriority,
        assignedToMe: config.assignedToMe,
        unassigned: config.unassigned,
        formKeys: config.formKeys,
        filter: config.filter
    };

    return new RequestBuilder()
        .post(URL.FIND_HUMAN_TASKS_SIMPLE)
        .setBodyJson(params)
        .toJS();
}

export function saveTask(taskId: string, formValues: { [key: string]: unknown }): Promise<ServiceMessageResultResponse> {
    return new RequestBuilder()
        .setBodyJson({
            taskId,
            properties: formValues
        })
        .post(URL.SAVE_TASK)
        .toJS();
}

/*export function approveTask(taskId: string) {
    return new RequestBuilder()
        .setBodyText(taskId)
        .post(URL.APPROVE_TASK)
        .fetch();
}

export function rejectTask(taskId: string) {
    return new RequestBuilder()
        .setBodyText(taskId)
        .post(URL.REJECT_TASK)
        .fetch();
}*/

export function claimTask(taskId: string) {
    return new RequestBuilder()
        .setBodyJson({ taskId })
        .post(URL.CLAIM_TASK)
        .fetch();
}

export function unclaimTask(taskId: string) {
    return new RequestBuilder()
        .setBodyJson({ taskId })
        .post(URL.UNCLAIM_TASK)
        .fetch();
}

export function assignTask(taskId: string, assignee: string) {
    return new RequestBuilder()
        .setBodyJson({ taskId, assignee })
        .post(URL.ASSIGN_TASK)
        .fetch();
}

export function listAssignableUsers(taskId: string): Promise<string[]> {
    return new RequestBuilder()
        .setBodyJson({ taskId })
        .post(URL.ASSIGNABLE_USERS)
        .toJS();
}
