import React, { useReducer, useEffect, Reducer, useMemo, useCallback, useState } from 'react';
import '@pearlchain/powergrid';

import PagerMenu from './PagerToolbar';
import WidgetError from '../../widget/WidgetError';
import PagerSql from './config/PagerSql';

/**
 * Handles result paging. Displays the current page number in a toolbar below the rendered component, and has
 * buttons to navigate forward and backward.
 *
 * When the input passed in through props is changed (because the widget was refreshed), the current page
 * will be reset.
 */

type Props<T> = {
    data: T | undefined;
    resultsPerPage: number;
    totalResults: number;
    fetcher: (pageOffset: number) => Promise<T>;
    nextPage?: boolean;
    render: (data: T | undefined) => React.ReactElement;
    widgetError?: {hasError: boolean, error: string}
    isDefault: boolean
};

type State<T> = {
    data: T | undefined;
    isFetching: boolean;
    resultOffset: number;
};

enum ActionType {
    FETCH_BEGIN,
    DATA_RECEIVED,
    INIT
}

type Action<T> =
    | { type: ActionType.FETCH_BEGIN }
    | { type: ActionType.DATA_RECEIVED; resultOffset: number; data: T }
    | { type: ActionType.INIT; data: T | undefined };

function init<T>(data: T | undefined): State<T> {
    return {
        data,
        isFetching: false,
        resultOffset: 0
    };
}

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
        case ActionType.INIT:
            return init(action.data);

        case ActionType.FETCH_BEGIN:
            return { ...state, isFetching: true };

        case ActionType.DATA_RECEIVED:
            return {
                ...state,
                isFetching: false,
                resultOffset: action.resultOffset,
                data: action.data
            };
    }
}

export default function PagingGridComponent<T>(props: Props<T>) {
    const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>, T | undefined>(reducer, props.data, init);
    const [resultsLength, setResultsLength] = useState(props.totalResults);

    useEffect(() => {
        // reset the state when the data passed in changed
        dispatch({ type: ActionType.INIT, data: props.data });

        if(props.data){
            let a: any = props.data
            setResultsLength(a.length)
        }
    }, [props.data]);

    useEffect(() => {
        if(state.data){
            let a: any = state.data
            setResultsLength(a.length)
        }
    }, [state.data]);


    const onPageChanged = useCallback((resultOffset: number) => {
        dispatch({ type: ActionType.FETCH_BEGIN });
        props.fetcher(resultOffset).then(data => {
            dispatch({
                type: ActionType.DATA_RECEIVED,
                data,
                resultOffset
            });
        });
    }, [dispatch, props.fetcher]);

    const content = useMemo(
        () => props.render(state.data), [state.data, props.render]);


    //there is no pagination
    if(!props.isDefault){
        return (
            <div className="d-flex flex-column h-100">
                <div className="h-100" style={{overflowY: "auto", overflowX: "auto"}}>
                    {props.widgetError?.hasError ? <WidgetError error={props.widgetError.error} /> : content }
                </div>
            </div>
        )
        
    }

    if(props.nextPage){
        return(
            <div className="d-flex flex-column h-100">
                <div className="h-100" style={{overflowY: "auto", overflowX: "auto"}}>
                    {content}
                </div>
                <PagerSql
                    onPageChanged={onPageChanged}
                    fetching={state.isFetching}
                    resultOffset={state.resultOffset}
                    resultsPerPage={props.resultsPerPage}
                    totalResults={props.nextPage ? resultsLength + 1 : resultsLength}
                />
            </div>
        )
    }

    return (
        <div className="d-flex flex-column h-100">
            <div className="h-100" style={{overflowY: "auto", overflowX: "auto"}}>
                {props.widgetError?.hasError ? <WidgetError error={props.widgetError.error} /> : content }
            </div>
            {props.totalResults > props.resultsPerPage ? (
                <PagerMenu
                    onPageChanged={onPageChanged}
                    fetching={state.isFetching}
                    resultOffset={state.resultOffset}
                    resultsPerPage={props.resultsPerPage}
                    totalResults={props.totalResults}
                />
            ) : null}
        </div>
    );
}
