import React from 'react';

import { WidgetProps } from '../../widget/widgetTypes';
import IframeWidgetConfig from './config/IframeWidgetConfig'
import IframeWidgetView from './view/IframeWidgetView'
import { IframeSourceConfig } from './iframeTypes';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';

export const IFRAME_TYPE = 'IFRAME_TYPE';

export default function IframeWidget(props: WidgetProps) {
    const { state, actions, propertySource } = props;

    const widget = state.widget;
    const config = widget.config as IframeSourceConfig | undefined;
    const common = widget.common;

    function renderView() {
        if (config == null) return renderConfig();
        const refreshCount = state.refreshCount;

        return <IframeWidgetView 
            config={config}
            refreshCount={refreshCount}
            actions={actions}
            properties={propertySource}/>
    }
    function renderConfig() {
        return <IframeWidgetConfig
            config={config}
            common={common}
            actions={actions}/>;
    }

    if (state.viewMode === WidgetViewMode.VIEW) {
        return renderView();
    } else {
        return renderConfig();
    }
}
