import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { StoreState } from 'core/dashboard/types/storeTypes';
import { getAvailableOwnedWebComponents, getAvailableStarredWebComponents } from 'core/dashboard/utils/selector/selectors';
import { DashboardContext } from '../dashboard/context';
import { WebComponentBriefResponse } from 'core/dashboard/types/responseTypes';

type Props = {
    error: string
}

export default function WidgetError(props: Props) {
    const { t } = useTranslation();

    const uuid = useContext(DashboardContext).state.dashboard.uuid
    const { owned, starred } = useSelector((state: StoreState) => ({
        owned: getAvailableOwnedWebComponents(state),
        starred: getAvailableStarredWebComponents(state),
    }));

    let combined: WebComponentBriefResponse[] =  []
    if(owned){
        combined = combined.concat(owned)
    }
    if(starred){
        combined = combined.concat(starred)
    }

    var isOwned = false;
    for(var i = 0; i < combined.length; i++) {
        if (combined[i].uniqueIdentifier == uuid) {
            isOwned = true;
            break;
        }
    }

    return (
        <Alert variant="danger">
            <Alert.Heading>{t('web.api.error.status.internalServerError')}</Alert.Heading>
            <p>
                {isOwned ? t(props.error) : "An error ocurred. Please check the logs"}
            </p>
        </Alert>
    )
}
