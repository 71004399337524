import { v4 } from 'uuid';
import { Dashboard } from '../../types/storeTypes';
import { AccessType } from '../../types/responseTypes';

export function buildEmptyDashboard(name: string): Dashboard {
    const dashboardUuid = v4();
    return {
        name,
        uuid: dashboardUuid,
        accessType: AccessType.WRITE,
        version: 0,
        properties: [],
        widgets: {},
        layout: {
            cols: 6,
            layout: [],
            rowHeight: 100
        },
        isModified: false,
        isSaving: false,
        refreshCount: 0
    };
}
