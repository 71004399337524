import { Formatter } from "../../../types/formatConfigTypes";
import { InputType } from "../../../types/inputTypes";

export const lineChartFormatters: Formatter[] = [
    {
        key: 'label',
        label: 'Label',
        inputType: InputType.TEXT,
        applyFormatter(value, { dataset }) {
            dataset.label = value;
        }
    },
    {
        key: 'pointBackgroundColor',
        label: 'Point BG Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset, index }) {
            if (dataset.pointBackgroundColor == null) {
                dataset.pointBackgroundColor = new Array(index);
            }
            dataset.pointBackgroundColor.push(value);
        }
    },
    {
        key: 'pointBorderColor',
        label: 'Point Border Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset, index }) {
            if (dataset.pointBorderColor == null) {
                dataset.pointBorderColor = new Array(index);
            }
            dataset.pointBorderColor.push(value);
        }
    },
    {
        key: 'pointBorderWidth',
        label: 'Point Border Width',
        inputType: InputType.NUMBER,
        inputParams: {
            min: 0
        },
        applyFormatter(value, { dataset, index }) {
            if (dataset.pointBorderWidth == null) {
                dataset.pointBorderWidth = new Array(index);
            }
            dataset.pointBorderWidth.push(value);
        }
    },
    {
        key: 'pointRadius',
        label: 'Point Radius',
        inputType: InputType.NUMBER,
        inputParams: {
            min: 0
        },
        applyFormatter(value, { dataset, index }) {
            if (dataset.pointRadius == null) {
                dataset.pointRadius = new Array(index);
            }
            dataset.pointRadius.push(value); 
        }
    },
    {
        key: 'backgroundColor',
        label: 'Background Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset }) {
            dataset.backgroundColor = value;
            dataset.fill = 'origin'
        }
    },
    {
        key: 'borderColor',
        label: 'Border Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset }) {
            dataset.borderColor = value;
        }
    },
    {
        key: 'borderWidth',
        label: 'Border Width',
        inputType: InputType.NUMBER,
        inputParams: {
            min: 0
        },
        applyFormatter(value, { dataset }) {
            dataset.borderWidth = value;
        }
    }
];
