import React, { useEffect } from 'react';
import { withFormik, FormikProps, Field, FastField, FieldProps } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';

import EvaluatableInputField from '../../widgets/common/config/formik/EvaluatableInputField';
import ActionInput from 'core/dashboard/actionhandler/input/ActionInput';
import FormatConfigFieldComponent from '../format/FormatConfigFieldComponent';
import { ColumnFormatConfig } from 'core/dashboard/types/gridConfigTypes';
import { gridFormatters } from 'core/dashboard/formatters/gridFormatters';

type OwnProps = {
    config?: ColumnFormatConfig;
    bindSubmit: (submit: () => void) => void;
    onSubmit: (config: ColumnFormatConfig) => void;
};

function FormatConfigForm({ bindSubmit, handleSubmit, submitForm }: OwnProps & FormikProps<ColumnFormatConfig>) {

    // provide the submit action to the outer component
    useEffect(() => {
        bindSubmit(submitForm);
    }, [bindSubmit, submitForm]);

    return (
        <form className="conditions-library-container" onSubmit={handleSubmit}>
            <FastField
                name="format"
                component={FormatConfigFieldComponent}
                formatters={gridFormatters}
            />
            <div className="conditions-view-footer">
                <Form.Group as={Row}>
                    <Col xs={3}>Value</Col>
                    <Col xs={9}>
                        <EvaluatableInputField name="value" showPreview={false} />
                    </Col>
                </Form.Group>
                <Field name="action" component={ActionInput}/>
                <Field
                    name="editable"
                    render={({ field }: FieldProps) => (
                    <input
                        id="editable"
                        {...field}
                        type="checkbox"
                        checked={field.value}
                    />
                    )}
                />
                <label  className="ml-2">
                    Editable
                </label>
                    
            </div>
        </form>
    );
};

type ComponentProps = {
    name: string;
}

export const Checkbox = ({ form, name }: FieldProps & ComponentProps) => {
    return (
        <input  type="checkbox" id={name} name={name} />
    );
  };

const INITIAL_VALUES: ColumnFormatConfig = {
    conditionalFormats: [],
    defaultFormat: {},
    value: '',
    action: undefined,
    editable: false
};

export default withFormik<OwnProps, ColumnFormatConfig>({
    enableReinitialize: true,
    mapPropsToValues(props) {
        return Object.assign({}, INITIAL_VALUES, props.config);
    },
    handleSubmit(values, { props }) {
        props.onSubmit(values);
    }
})(FormatConfigForm);
