import React, { Dispatch } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl } from 'react-bootstrap';

import { Action, ActionType } from './formatConfigReducer';
import { Formatter, ConditionalFmtBlock } from "../../../types/formatConfigTypes";
import FormatBlock from './FormatBlock';

type Props = {
    blockIndex: number;
    condition: ConditionalFmtBlock;
    formatters: Formatter[];
    dispatch: Dispatch<Action>
}

function FormatBlockWithCondition({ blockIndex, condition, formatters, dispatch }: Props) {
    const handleRemove = () => {
        dispatch({ type: ActionType.REMOVE_CONDITION_BLOCK, blockIndex });
    };

    const handleConditionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ActionType.SET_CONDITION_VALUE,
            blockIndex,
            condition: e.target.value
        });
    };

    return (
        <div className="format-block-with-condition">
            <div className="format-block-header">
                <span>Condition {blockIndex + 1}</span>
                <div className="format-block-condition">
                    <FormControl value={condition.condition} onChange={handleConditionChanged as any}/>
                </div>
                <div className="config-icon-btn" onClick={handleRemove}>
                    <FontAwesomeIcon icon="times"/>
                </div>
            </div>
            <FormatBlock
                blockIndex={blockIndex}
                formatValues={condition.format}
                formatters={formatters}
                dispatch={dispatch}
            />
        </div>
    );
}

export default React.memo(FormatBlockWithCondition);
