import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FastField, FieldProps } from 'formik';

import TextInput from 'core/dashboard/components/config/formik/TextInput';
import FormFieldErrorFeedback from 'core/dashboard/components/widgets/common/config/formik/FormFieldErrorFeedback';
import ActionPropertiesField from 'core/dashboard/components/widgets/common/config/formik/ActionPropertiesField';
import { validateRequired } from 'core/dashboard/utils/validate/validators';
import { ShowRcpViewActionConfig } from 'core/dashboard/types/actionHandlerTypes';

export default function ShowRcpViewActionInput(props: FieldProps) {
    const { field } = props;
    const name = field.name;
    const config = field.value as ShowRcpViewActionConfig;
    const viewParams = config.viewParams;

    return (
        <div className="action-config">
            <Form.Group as={Row}>
                <Col xs={3}>View Name:</Col>
                <Col xs={9}>
                    <FastField name={name + '.viewName'} validate={validateRequired} component={TextInput}/>
                    <FormFieldErrorFeedback name={name + '.viewName'}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={3}>View Params:</Col>
                <Col xs={9}>
                    <ActionPropertiesField name={name + '.viewParams'} properties={viewParams}/>
                </Col>
            </Form.Group>
        </div>
    );
}
