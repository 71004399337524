import React, { useEffect, useReducer } from "react";
import { FieldProps } from "formik";
import { Button } from "react-bootstrap";

import { Formatter, ConditionalFmtConfig } from "../../../types/formatConfigTypes";
import FormatConfigsComponent from "./FormatBlock";
import ConditionalFormatConfigComponent from "./FormatBlockWithCondition";
import { reducer, init, ActionType } from "./formatConfigReducer";

type ComponentProps = {
    formatters: Formatter[];
}

export default function FormatConfigFieldComponent(props: FieldProps & ComponentProps) {
    const value = props.field.value as ConditionalFmtConfig | undefined;
    const [state, dispatch] = useReducer(reducer, value, init);

    useEffect(() => {
        if (props.field.value !== state.value) {
            props.form.setFieldValue(props.field.name, state.value);
        }
    });

    const handleCreateConditionBlock = () => {
        dispatch({ type: ActionType.CREATE_CONDITION_BLOCK });
    }

    return (
        <div className="format-config">
            <div className="format-list">
                <Button className="mb-3" onClick={handleCreateConditionBlock}>Add Condition</Button>
                {state.value.conditions && state.value.conditions.map((_,index) => (
                    <ConditionalFormatConfigComponent
                        key={index}
                        blockIndex={index}
                        dispatch={dispatch}
                        condition={state.value.conditions[index]}
                        formatters={props.formatters}
                    />
                ))}
            </div>
            <FormatConfigsComponent
                blockIndex={-1}
                dispatch={dispatch}
                formatValues={state.value.format}
                formatters={props.formatters}
            />
        </div>
    );
}
