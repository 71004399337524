import echartsImage from '../../../../../img/echarts.png';
import { WidgetDefinition, DataSource } from "../../../types/widgetTypes";
import BarChartWidget from './BarChartWidget';
import { BarChartConfig } from './barChartTypes';

export const BAR_CHART_WIDGET_TYPE = 'BAR_CHART';

export const BAR_CHART_WIDGET: WidgetDefinition = {
    name: 'Bar-Chart',
    description: 'Displays report results in a bar-chart',
    icon: 'chart-bar',
    image: echartsImage,
    component: BarChartWidget,
    listSources: (config: BarChartConfig) : DataSource[] => [{
        source: config.reportSource,
        params: config.reportParams || [],
    }]
}

