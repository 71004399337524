
import React, { useState } from 'react'
import { Field, FieldProps } from 'formik'
import { DataSourceTypes, PropertyConfig } from '../../../../../../dashboard/types/actionHandlerTypes'
import DataSourcesSelectInput from '../../../../../components/config/formik/DataSourcesSelectInput'
import DataSourcesParams from './DataSourcesParams'
import { DataSourceProvider } from 'core/dashboard/types/responseTypes'
import DataSourceMetaData from 'core/dashboard/components/config/formik/DataSourceMetaData'

export default function DataProviderField({form, field}: FieldProps){
    const name = field.name;
    const [providersHandlers, setProvidersHandlers] = useState<DataSourceProvider[]>()
    const params = form.values.reportParams;

    function handleParams(params: PropertyConfig[] | undefined){
        form.setFieldValue('reportParams', params)
    }

    function handleProvidersHandlers(providers: DataSourceProvider[]){
        setProvidersHandlers(providers)
    }
    
    return(
        <div>
            <p>Data Source MetaData</p>
            <Field name={name} handleProvidersHandlers={handleProvidersHandlers} type={DataSourceTypes.PROVIDER} component={DataSourceMetaData}/>
            {(providersHandlers && providersHandlers.length) ?
                <div>
                    <p className="mt-2">Providers</p>
                    <Field 
                        name={'providersHandlers'} 
                        providersHandlers={providersHandlers}
                        handleParams={handleParams} 
                        type={DataSourceTypes.PROVIDER} 
                        component={DataSourcesSelectInput}
                    /> 
                </div> 
                : null
            }
            {params && params.length ? 
                <div>
                    <p className="mt-3">Params</p>
                    <DataSourcesParams name={'reportParams'} properties={params || []}/>
                </div>
                : null
            }
        </div>
    )
}
