import { StandardMessage } from '../types/standardMessageTypes';
import { Formatter } from '../types/formatConfigTypes';
import { InputType } from '../types/inputTypes';

export enum FormatterConfigType {
    COLOR='color',
    TEXT='text',
    CHECKBOX='checkbox',
    ACTION='action'
}

function toBoolean(input: unknown): boolean {
    if (typeof input === 'string') {
        return input.toUpperCase() !== 'false' && !!input;
    } else {
        return Boolean(input);
    }
}

export const gridFormatters: Formatter[] = [
    {
        key: 'backgroundColor',
        label: 'Background Color',
        inputType: InputType.COLOR,
        initialValue: 'white',
        applyFormatter(value, { el }) {
            el.style.backgroundColor = value;
        }
    },
    {
        key: 'color',
        label: 'Font Color',
        inputType: InputType.COLOR,
        initialValue: 'black',
        applyFormatter(value, { el }) {
            el.style.color = value;
        }
    },
    {
        key: 'value',
        label: 'Value',
        inputType: InputType.TEXT,
        initialValue: '',
        applyFormatter(value, { el }) {
            el.textContent = value;
        }
    },
    {
        key: 'fontSize',
        label: 'Hidden',
        inputType: InputType.CHECK,
        initialValue: 0,
        applyFormatter(value, { el }) {
            el.textContent = value;
        }
    },
    {
        key: 'translate',
        label: 'Translate',
        inputType: InputType.CHECK,
        initialValue: true,
        applyFormatter(value, { el, translate }) {
            if (toBoolean(value)) {
                const value = el.textContent!;
                el.textContent = translate(value);
            }
        }
    },
    {
        key: 'standardMessage',
        label: 'Standard Message',
        inputType: InputType.CHECK,
        initialValue: true,
        applyFormatter(value, { el, translate, actions }) {
            if (toBoolean(value)) {
                try {
                    const value = el.textContent!;
                    const standardMessage = JSON.parse(value) as StandardMessage;
                    let msg = standardMessage.message;
                    msg = translate(msg, standardMessage.params);
                    el.textContent = translate(msg);
                    el.classList.add('status-' + standardMessage.type);

                } catch(err) {
                    console.warn(err);
                    actions.show('error', 'Standard-Message Formatter', 'Error applying formatter: ' + err);
                }
            }
        }
    }
];
