import { Dispatch } from 'redux';
import { error } from 'react-notification-system-redux';
import { getAuthTokenPayload } from '@pearlchain/component-lib-common';

import { DashboardProperty } from '../../types/storeTypes';
import { EvaluationContext } from '../placeholders/placeholderTypes';
import { resolveExpression } from './evaluate';
import { DashboardPropertyType } from 'core/dashboard/types/dashboardPropertyTypes';

const KEY_LOGIN_NAME = 'loginName';
const KEY_REALM = 'realm';
const KEY_LANGUAGE = 'language'

function resolveBuiltinProperties(): { [key: string]: unknown } {
    const payload = getAuthTokenPayload();
    return {
        [KEY_LOGIN_NAME]: payload && payload.loginName,
        [KEY_REALM]: payload && payload.realm,
        [KEY_LANGUAGE]: payload && payload.language
    };
}

//Created because global properties like realm were not included in the propertyConfigs array so they couldn't be used for widget header for example 
export function addGlobalPropertiesToconfig(properties: DashboardProperty[], evaluationContext: EvaluationContext): DashboardProperty[]{
    let propertyConfigs = Object.assign([], properties);
    propertyConfigs.push({
        name: KEY_REALM,
        isEditable: false,
        type: DashboardPropertyType.STRING,
        val: evaluationContext.properties.realm
    })
    propertyConfigs.push({
        name: KEY_LANGUAGE,
        isEditable: false,
        type: DashboardPropertyType.STRING,
        val: evaluationContext.properties.language
    })
    return propertyConfigs;
}

function resolvePropertyValue(
    value: unknown,
    key: string,
    propertyType: DashboardPropertyType,
    context: EvaluationContext,
    dispatch: Dispatch
) {
    if (propertyType === DashboardPropertyType.STRING) {
        try {
            return resolveExpression(value as string, context, false);
        } catch (e) {
            console.warn(e);
            dispatch(
                error({
                    title: 'Error resolving dashboard property "' + key + '"',
                    message: '' + e
                })
            );

            return undefined;
        }
    }
    return value;
}

export function createEvaluationContext(
    drilldownProperties: { [key: string]: unknown } | undefined,
    dashboardProps: Iterable<DashboardProperty>,
    dispatch: Dispatch
): EvaluationContext {
    const properties: { [key: string]: unknown } = resolveBuiltinProperties();
    const context: EvaluationContext = { properties };

    for (let prop of dashboardProps) {
        let value = prop.val;
        if (drilldownProperties && drilldownProperties.hasOwnProperty(prop.name)) {
            value = drilldownProperties[prop.name];
        }

        const valueResolved = resolvePropertyValue(value, prop.name, prop.type, context, dispatch);
        properties[prop.name] = valueResolved;
    }

    return context;
}
