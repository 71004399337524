import React from 'react';
import { CloseButton, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FieldTypeInput from './FieldTypeInput';
import FieldTargetInput from './FieldTargetInput';
import FormFieldErrorFeedback from '../../widgets/common/config/formik/FormFieldErrorFeedback';
import { TargetKey, FieldMappingConfig } from '../sourceMappingTypes';
import { FastField } from 'formik';
import FieldSourceInput from './FieldSourceInput';
import TooltipIcon from '../../config/TooltipIcon';
import { validateRequired } from '../../../utils/validate/validators';

type Props = {
    name: string;
    mapping: FieldMappingConfig;
    onRemoved: () => void;
    targetKeys: TargetKey[];
    listId: string;
};

export default function FieldMappingConfig(props: Props) {
    const { name, mapping, listId, targetKeys, onRemoved } = props;

    const target = mapping.to;
    const targetKey = targetKeys.find(k => k.name === target);

    return (
        <div className="column-mapping-row">
            <div className="flex-row-grow">
                <InputGroup>
                    <FastField
                        name={name + '.type'}
                        validate={validateRequired}
                        component={FieldTypeInput}/>
                    <FastField
                        name={name + '.from'}
                        validate={validateRequired}
                        listId={listId}
                        component={FieldSourceInput}
                    />
                    <FormFieldErrorFeedback name={name + '.from'} />
                    <FormFieldErrorFeedback name={name + '.type'} />
                </InputGroup>
            </div>
            <div className="flex-row-shrink">
                <FontAwesomeIcon icon="arrow-right" />
            </div>
            <div className="flex-row-grow">
                <FastField
                    name={name + '.to'}
                    validate={validateRequired}
                    targetKeys={targetKeys}
                    component={FieldTargetInput}
                />
                <FormFieldErrorFeedback name={name + '.to'} />
            </div>
            <div className="flex-row-shrink">
                <TooltipIcon
                    id={name + '-tt'}
                    description={targetKey ? targetKey.description : ''}
                />
            </div>
            <div className="flex-row-shrink">
                <CloseButton onClick={onRemoved} />
            </div>
        </div>
    );
}
