import React, { useReducer, useCallback, useEffect, useRef, useMemo } from 'react';
import ReactDatetime from 'react-datetime';
import { Moment, isMoment } from 'moment';
import { Col, Form, Popover } from 'react-bootstrap';

import { DateTimeUnit } from '../../types/dateTimeTypes';

import { reducer, init, ActionType, InputType, State } from './dateInputPopoverReducer';
import { reverseLookup } from '../../utils/dates/dateTimeOffsetParser';
import { STANDARD_DATE_FORMAT } from '../../utils/dates/dateHelpers';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

type Props = {
    input: string;
    onChange: (input: string) => void;
}

function stateToInputString(state: State): string | undefined {
    if (state.type === InputType.OFFSET) {
        let input = state.offsetModifier + state.offsetAmount + reverseLookup.get(state.offsetUnit)!;
        return input + ' [' + state.formatString + ']';
        
    } else if (state.type === InputType.CALENDAR) {
        const d = state.calendarDate;
        return d != null && isMoment(d) ? d.format(state.formatString) : d;
    }
}

function DateInputPopover({ input, onChange }: Props) {
    const [state, dispatch] = useReducer(reducer, input, init);

    const handleSetTypeCalendar = useCallback(() => {
        dispatch({ type: ActionType.SET_DATE_TYPE, dateType: InputType.CALENDAR });
    }, []);

    const handleSetTypeOffset = useCallback(() => {
        dispatch({ type: ActionType.SET_DATE_TYPE, dateType: InputType.OFFSET });
    }, []);

    const handleOffsetChanged = useCallback((amount: number) => {
        dispatch({ type: ActionType.SET_OFFSET_AMOUNT, amount });
    }, []);

    const handleOffsetUnitChanged = useCallback((e: React.ChangeEvent<any>) => {
        dispatch({ type: ActionType.SET_OFFSET_UNIT, unit: e.target.value as DateTimeUnit });
    }, []);

    const handleOffsetModifierChanged = useCallback((e: React.ChangeEvent<any>) => {
        dispatch({ type: ActionType.SET_OFFSET_MODIFIER, modifier: e.target.value as string });
    }, []);

    const handleCalendarDateChanged = useCallback((value: Moment | string) => {
        // if the date is invalid, react-datetime supplies the value as a string
        if (typeof value === 'string') return;
        
        dispatch({ type: ActionType.SET_CALENDAR_DATE, value });
    }, []);

    const handleFormatStringChanged = useCallback((format: string) => {
        dispatch({ type: ActionType.SET_FORMAT_STRING, format });
    }, []);

    // compute the property value based on the state
    const stateInput = useMemo(() => stateToInputString(state), [state]);

    // pass the new value to the parent when the computed state changed
    const stateInputRef = useRef(stateInput);
    useEffect(() => {
        if (stateInput && stateInputRef.current !== stateInput) {
            stateInputRef.current = stateInput;
            onChange(stateInput);
        }
    }, [stateInput, onChange]);

    // reset the internal state when the input prop changed
    const inputRef = useRef(input);
    useEffect(() => {
        if (input && stateInputRef.current !== input) {
            inputRef.current = input;
            dispatch({ type: ActionType.RESET, input });
        }
    }, [input]);

    return (
        <Popover.Content>
            <Form.Group as={Form.Row}>
                <Form.Label column sm={3} onClick={handleSetTypeCalendar}>
                    <Form.Check
                        type="radio"
                        label="Calendar"
                        checked={state.type === InputType.CALENDAR}
                        value={InputType.CALENDAR}
                    />
                </Form.Label>
                <Col sm={9}>
                    <ReactDatetime
                        dateFormat={STANDARD_DATE_FORMAT}
                        value={state.calendarDate}
                        onChange={handleCalendarDateChanged}
                        inputProps={{ disabled: state.type !== InputType.CALENDAR }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
                <Form.Label column sm={3} onClick={handleSetTypeOffset}>
                    <Form.Check
                        type="radio"
                        label="Offset by"
                        checked={state.type === InputType.OFFSET}
                        value={InputType.OFFSET}
                    />
                </Form.Label>
                <Col sm={3}>
                    <Form.Control
                        as="select"
                        value={state.offsetModifier}
                        onChange={handleOffsetModifierChanged}
                        disabled={state.type !== InputType.OFFSET}
                    >
                        <option value="+">+</option>
                        <option value="-">-</option>
                    </Form.Control>
                </Col>
                <Col sm={3}>
                    <NumberInput
                        value={state.offsetAmount}
                        onChange={handleOffsetChanged}
                        min={0}
                        integer
                        disabled={state.type !== InputType.OFFSET}
                    />
                </Col>
                <Col sm={3}>
                    <Form.Control
                        as="select"
                        value={state.offsetUnit}
                        onChange={handleOffsetUnitChanged}
                        disabled={state.type !== InputType.OFFSET}
                    >
                        {Object.values(DateTimeUnit).map((unit, index) => (
                            <option key={index} value={unit}>{unit}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
                <Form.Label column sm={3}>Format String</Form.Label>
                <Col sm={6}>
                    <TextInput value={state.formatString} onChange={handleFormatStringChanged}/>
                </Col>
            </Form.Group>
        </Popover.Content>
    );
}

export default DateInputPopover;
