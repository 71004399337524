import React from 'react';
import { FieldProps } from 'formik';
import { FormCheck } from 'react-bootstrap';

type Props = {
    placeholder?: string;
} & FieldProps;

export default function CheckInput({ field, placeholder }: Props) {
    return (
        <FormCheck placeholder={placeholder} checked={field.value}
                   onChange={(event:any) => field.onChange(event.target.checked)} {...field} />
    );
}
