import React from 'react';
import { Field, FieldProps } from 'formik';
import { Form } from 'react-bootstrap';

import DashboardLoader from 'core/dashboard/components/dashboard/DashboardLoader';
import { DashboardProperty, Dashboard } from 'core/dashboard/types/storeTypes';
import EvaluatableInputField from './EvaluatableInputField';
import { PropertyConfig } from 'core/dashboard/types/actionHandlerTypes';

function dashboardPropToDrilldownProp(prop: DashboardProperty): PropertyConfig {
    return {
        name: prop.name,
        value: prop.val || ''
    };
}

function getDrilldownProperties(
    dashboardProps: DashboardProperty[],
    configProps: PropertyConfig[]
): PropertyConfig[] {
    const props: PropertyConfig[] = [];
    for (let dashboardProp of dashboardProps) {
        if (!dashboardProp.isEditable) continue;

        const name = dashboardProp.name;
        const cProp = configProps.find(configProp => configProp.name === name);
        if (cProp != null) {
            props.push(cProp);
        } else {
            props.push(dashboardPropToDrilldownProp(dashboardProp));
        }
    }

    return props;
}

type Props = {
    name: string;
    dashboardUuid: string;
    showPreview?: boolean;
};

function PropertiesField(props: Props) {
    const name = props.name;
    const showPreview = props.showPreview;
    return (
        <Field
            name={name}
            render={({ field, form }: FieldProps) => {
                const properties = field.value as PropertyConfig[];

                function handleDashboardLoaded(dashboard: Dashboard) {
                    // update the form when the dashboard is loaded
                    const props = getDrilldownProperties(dashboard.properties, properties);
                    form.setFieldValue(name, props);
                }

                return (
                    <React.Fragment>
                        <DashboardLoader
                            dashboardUuid={props.dashboardUuid}
                            onDashboardLoaded={handleDashboardLoaded}
                        />
                        {properties.map((property, index) => {
                            const fieldName = name + '.' + index;
                            return (
                                <Form.Group>
                                    <Form.Label>{property.name + ':'}</Form.Label>
                                    <EvaluatableInputField name={fieldName + '.value'} showPreview={showPreview}/>
                                </Form.Group>
                            );
                        })}
                    </React.Fragment>
                );
            }}
        />
    );
}

export default React.memo(PropertiesField);
