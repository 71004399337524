import React from 'react';
import { FieldProps, FieldArray, FastField } from "formik";

import ActionInput from 'core/dashboard/actionhandler/input/ActionInput';
import { FormGroup, FormLabel, Button, CloseButton } from 'react-bootstrap';
import SelectInput from './SelectInput';
import { WidgetEventConfig } from 'core/dashboard/types/storeTypes';

type Props = {
    eventNames?: string[];
} & FieldProps;

const DEFAULT_EVENT_CONFIG: WidgetEventConfig = {
    eventName: '',
    action: undefined
};

export default function EventActionsInput(props: Props) {
    const { field, eventNames } = props;
    const values: unknown[] = field.value;
    return (
        <FieldArray name={field.name} render={(arrayHelpers) => {
            return (
                <div className="field-group-nested">
                    {values.map((config, index) => {
                        const fieldName = field.name + '[' + index + ']';
                        return (
                            <div className="event-action-config">
                                <CloseButton onClick={arrayHelpers.handleRemove(index)}/>
                                <FormGroup>
                                    <FormLabel>Event</FormLabel>
                                    <FastField name={fieldName + '.eventName'} component={SelectInput} options={eventNames || []}/>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Action</FormLabel>
                                    <FastField name={fieldName + '.action'} component={ActionInput}/>
                                </FormGroup>
                            </div>
                        )
                    })}
                    <Button onClick={arrayHelpers.handlePush(DEFAULT_EVENT_CONFIG)}>+ Event</Button>
                </div>
            );
        }}/>
    )
}
