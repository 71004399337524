import React, { useState } from 'react';
import { FormikProps, Field, FastField } from 'formik';
import { Button, Form } from 'react-bootstrap';

import { LineChartFormValues } from './LineChartWidgetConfig';
import CommonConfig from '../../common/config/CommonConfig';
import ConfigPanel from '../../common/config/ConfigPanel';
import AxisField from '../../common/config/formik/AxisField';
import ActionInput from 'core/dashboard/actionhandler/input/ActionInput';
import Switch from '../../../config/Switch';
import SourcesMappingList from '../../../sourceMapping/config/SourceMappingConfig';
import LineChartWidgetBasicConfig from './LineChartWidgetBasicConfig';
import { sourceMappingFromConfig, KEY_X_AXIS, KEY_Y_AXIS, KEY_SERIES, } from '../lineChartHelpers';

import { MSG_TARGET_Y_AXIS, MSG_TARGET_X_AXIS, MSG_TARGET_SERIES } from '../../../../utils/messages';
import WidgetConfigFooter from '../../common/WidgetConfigFooter';
import FormatConfigFieldComponent from '../../../form/format/FormatConfigFieldComponent';
import { lineChartFormatters } from '../lineChartFormatters';
import { SourceMappingConfig } from 'core/dashboard/components/sourceMapping/sourceMappingTypes';

const TARGET_KEYS = [
    { name: KEY_X_AXIS, description: MSG_TARGET_X_AXIS },
    { name: KEY_Y_AXIS, description: MSG_TARGET_Y_AXIS },
    { name: KEY_SERIES, description: MSG_TARGET_SERIES  }
];

type OwnProps = {
    isCancellable: boolean;
    onCancel: () => void;
}

export default function LineChartWidgetConfigForm(props: FormikProps<LineChartFormValues> & OwnProps) {
    const sourceMapping = props.values.sourceMapping;
    const [isSourceMapping, setIsSourceMapping] = useState(sourceMapping ? true : false);

    const xAxis = props.values.xAxis;
    const yAxis = props.values.yAxis;
    const sources = props.values.sources;

    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig />
            <ConfigPanel defaultExpanded header="Line-Chart">
                <div className="p-2">
                    <Form.Group>
                        <Form.Label className="config-sub-header">X-Axis</Form.Label>
                        <AxisField name="xAxis" value={xAxis} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="config-sub-header">Y-Axis</Form.Label>
                        <AxisField name="yAxis" value={yAxis} />
                    </Form.Group>
                    <Field name="action" component={ActionInput}/>
                </div>
            </ConfigPanel>
            <ConfigPanel defaultExpanded header="Source-Mapping">
                <div className="p-2">
                    <Switch
                        name="toggle-advanced"
                        enabled={!!isSourceMapping}
                        onToggled={() => {
                            props.setFieldValue(
                                'sourceMapping',
                                sourceMappingFromConfig(props.values)
                            );
                            setIsSourceMapping(!isSourceMapping)
                        }}
                    />
                    {isSourceMapping ? (
                        <SourcesMappingList
                            name="sourceMapping"
                            config={sourceMapping ? sourceMappingFromConfig(props.values) : {} as SourceMappingConfig}
                            targetKeys={TARGET_KEYS}
                        />
                    ) : (
                        <LineChartWidgetBasicConfig sources={sources} setFieldValue={props.setFieldValue}/>
                    )}
                </div>
            </ConfigPanel>
            <ConfigPanel header="Conditional Formatting" >
                <FastField name="format" component={FormatConfigFieldComponent} formatters={lineChartFormatters}/>
            </ConfigPanel>
            <WidgetConfigFooter
                isFormValid={props.isValid}
                isCancellable={props.isCancellable}
                onCancel={props.onCancel}
            />
        </form>
    );
}
