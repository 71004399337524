import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { HumanTaskOverviewResponse } from '../../types/responseTypes';

type Props = {
    task: HumanTaskOverviewResponse;
};

function formatTimestamp(input: number) {
    return moment(input)
        .toDate()
        .toLocaleString();
}

export default React.memo(function HumanTaskDetailTable({ task }: Props) {
    const priority = task.priority;
    const assignee = task.assignee;
    const dueDate = task.dueDate;
    const creationDate = task.creationDate;

    return (
        <Table>
            <thead>
                <tr>
                    <th>Priority</th>
                    <th>Assignee</th>
                    <th>Due Date</th>
                    <th>Creation Date</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{priority}</td>
                    <td>{assignee}</td>
                    <td>{dueDate}</td>
                    <td>{creationDate}</td>
                </tr>
            </tbody>
        </Table>
    );
});
