import React, { useMemo, useCallback, useContext, useState } from 'react';

import { LineChartConfig } from '../lineChartTypes';
import { WidgetActions } from '../../../../types/widgetTypes';

import { useWidgetDataFetcher } from '../../../../utils/hooks/useWidgetDataFetcher';
import LineChartComponent from './LineChartComponent';
import { RunReportResponse } from '../../../../types/responseTypes';
import { sourceMappingFromConfig, KEY_SERIES } from '../lineChartHelpers';
import { fetchSourcesData } from '../../../sourceMapping/fetchSourcesData';
import { mapSourcesData } from '../../../sourceMapping/mapper/sourceMapper';
import { createActionHandler } from 'core/dashboard/actionhandler/actionHandler';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { IPropertySource } from 'core/dashboard/evaluator/PropertySource';
import { initialWidgetErrorState } from '../../singlesource/view/SingleSourceWidgetView';
import WidgetError from 'core/dashboard/components/widget/WidgetError';
import { WidgetCommonConfig } from 'core/dashboard/types/storeTypes';

type Props = {
    config: LineChartConfig;
    refreshCount: number;
    actions: WidgetActions;
    data: RunReportResponse[] | undefined;
    properties: IPropertySource;
    common: WidgetCommonConfig;
};

export default function LineChartWidgetView(props: Props) {
    let { actions, refreshCount, config, data, properties, common } = props;
    const evaluator = useMemo(() => new Evaluator(properties), [properties]);
    const [widgetError, setWidgetError] = useState(initialWidgetErrorState);
    
    // create a widget action handler
    const actionHandler = useMemo(() => createActionHandler(actions, evaluator), [actions, evaluator]);

    // resolve the source-mapping for the widget config
    const sourceMapping = useMemo(() => sourceMappingFromConfig(config), [config]);

    const isSourceAdvanced = config.sourceMapping ? true : false;

    // Setup the data-fetcher to update the backing data whenever the "refreshCount" was incremented
    const requestHandler = useCallback(() => fetchSourcesData(sourceMapping, evaluator, common.pollInterval), [sourceMapping, evaluator]);
    useWidgetDataFetcher(refreshCount, requestHandler, actions, handleWidgetError);

    function handleWidgetError(hasError: boolean, error: string){
        setWidgetError({hasError, error})
    }

    // apply mapping transform
    const dataTransformed = useMemo(() => data && mapSourcesData(data, sourceMapping, KEY_SERIES, isSourceAdvanced), [data, sourceMapping]);

    if(widgetError.hasError){
        return(
            <WidgetError error={widgetError.error} />
        )
    }
    else{
        return dataTransformed ? (
            <LineChartComponent
                data={dataTransformed}
                actionHandler={actionHandler}
                evaluator={evaluator}
                config={config}
            />
        ) : null;

    }
}
