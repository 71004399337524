import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChartSourceConfig } from '../lineChartTypes';
import { Form, CloseButton } from 'react-bootstrap';
import ReportColumnChooserField from '../../common/config/formik/ReportColumnChooserField';
import FormFieldErrorFeedback from '../../common/config/formik/FormFieldErrorFeedback';
import DataSourceField from '../../common/config/formik/DataSourceField';

type Props = {
    index: number;
    name: string;
    config: LineChartSourceConfig;
    onRemoved: () => void;
    setFieldValue: (field: string, value: any) => void;
};

export default function LineChartWidgetSourceConfig(props: Props) {
    const { t } = useTranslation();
    const reportSource = props.config.reportSource;
    const sqlParams = props.config.sqlParams;

    return (
        <div className="config-sub-panel">
            <CloseButton onClick={props.onRemoved} />
            <div className="config-sub-header">Source {'' + props.index}</div>
            <DataSourceField
                reportSource={reportSource}
                name={props.name + '.reportSource'}
                paramsName={props.name + '.reportParams'}
                typeName={props.name+'.type'}
                sqlParamsName={props.name+'.sqlParams'}
                sqlParamsValues={sqlParams}
                setFieldValue={props.setFieldValue}
                isSingleSource={false}
            />
            <Form.Group>
                <Form.Label>X-{t('dashboard.column')}</Form.Label>
                <ReportColumnChooserField
                    name={props.name + '.xColumn'}
                    reportSource={reportSource}
                    optional={true}
                />
                <FormFieldErrorFeedback name={props.name + '.xColumn'} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Y-{t('dashboard.column')}</Form.Label>
                <ReportColumnChooserField
                    name={props.name + '.yColumn'}
                    reportSource={reportSource}
                    optional={true}
                />
                <FormFieldErrorFeedback name={props.name + '.yColumn'} />
            </Form.Group>
            <Form.Group>
                <Form.Label>{t('dashboard.series.name')}</Form.Label>
                <ReportColumnChooserField
                    name={props.name + '.groupBy'}
                    reportSource={reportSource}
                    optional
                />
                <FormFieldErrorFeedback name={props.name + '.groupBy'} />
            </Form.Group>
        </div>
    );
}
