import { WebComponentResponse } from '../../types/responseTypes';
import { Dashboard, DashboardLayoutConfig, DashboardProperty, } from '../../types/storeTypes';
import { DashboardWebComponentData, WebComponentPropertyData } from '../../types/webComponentTypes';

/** Web-Component to Dashboard */

export function deserializeDashboard(
    dashboardUuid: string,
    webComponent: WebComponentResponse
): Dashboard {
    const dashboardData: DashboardWebComponentData = JSON.parse(webComponent.data);

    const layout = deserializeLayout(dashboardData);
    const properties = deserializeDashboardProperties(dashboardData.properties);

    return {
        uuid: dashboardUuid,
        version: webComponent.version,
        name: webComponent.description,
        accessType: webComponent['access-type'],
        layout,
        widgets: {},
        properties,
        isModified: false,
        isSaving: false,
        refreshCount: 0
    };
}

function deserializeLayout(data: DashboardWebComponentData): DashboardLayoutConfig {
    return {
        cols: data.cols,
        rowHeight: data.rowHeight,
        layout: data.layout
    };
}

function deserializeDashboardProperties(properties: WebComponentPropertyData[] | undefined): DashboardProperty[] {
    if (properties) {
        return properties.map(prop => ({
            name: prop.name,
            val: prop.val,
            type: prop.type,
            isEditable: prop.isEditable
        }));

    } else {
        return [];
    }
}
