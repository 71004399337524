import React, { Dispatch } from 'react';
import { Formatter, FmtInputValue } from '../../../types/formatConfigTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Action, ActionType } from './formatConfigReducer';
import { renderInput } from '../input/inputs';
import EvaluatableInput from '../input/EvaluatableInput';

type Props = {
    blockIndex: number;
    formatter: Formatter;
    formatValue: FmtInputValue;
    dispatch: Dispatch<Action>;
};

function FormatConfigFieldComponent({ blockIndex, formatValue, formatter, dispatch }: Props) {
    const isDynamic = formatValue ? formatValue.dynamic : false;

    const handleRemove = () => {
        dispatch({ type: ActionType.REMOVE_FORMAT_VALUE, blockIndex, key: formatter.key });
    };

    const handleInputValueChanged = (value: unknown) => {
        dispatch({ type: ActionType.SET_FORMAT_INPUT_VALUE, blockIndex, key: formatter.key, value });
    };

    const handleToggleDynamic = () => {
        dispatch({ type: ActionType.TOGGLE_FORMAT_VALUE_DYNAMIC, blockIndex, key: formatter.key });
    };

    return (
        <div className="format-block-field">
            <div className="format-block-field-header">
                <div className="config-icon-btn" onClick={handleToggleDynamic}>
                    <FontAwesomeIcon icon={isDynamic ? 'paint-brush' : 'keyboard'}/>
                </div>
                <div className="format-block-field-header-label">
                    {formatter.label}
                </div>
                <div className="config-icon-btn" onClick={handleRemove}>
                    <FontAwesomeIcon icon="times"/>
                </div>
            </div>
            <div className="format-block-field-input">
                {isDynamic ? (
                    <EvaluatableInput
                        value={formatValue.value as string | undefined}
                        onChange={handleInputValueChanged}
                    />
                ) : (
                    renderInput(formatter.inputType, {
                        value: formatValue.value,
                        onChange: handleInputValueChanged,
                        params: formatter.inputParams
                    })
                )}
            </div>
        </div>
    );
}

export default React.memo(FormatConfigFieldComponent);
