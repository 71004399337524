import React, { useContext, useMemo } from "react";
import { DashboardContext } from "../dashboard/context";
import { Evaluator } from "core/dashboard/evaluator/Evaluator";
import { PropertySource } from "core/dashboard/evaluator/PropertySource";

type Props = {
    input: string; 
}

export default function EvaluatableInputPreview(props: Props) {
    const input = props.input;
    const { evaluationContext, propertyConfigs } = useContext(DashboardContext);
    
    const valueResolved = useMemo(() => {
        try {
            const evaluator = new Evaluator(new PropertySource(evaluationContext.properties, propertyConfigs));
            return '' + evaluator.evaluate(input, evaluationContext.properties || {});
        } catch(e) {
            console.warn(e);
            return '<<error>> ' + e; 
        }
    }, [input, evaluationContext.properties, propertyConfigs]);

    return (
        <span>
            { valueResolved }
        </span>
    );
}

