import React, { useState } from 'react';
import { FormikProps, Field, FastField } from 'formik';
import { Form } from 'react-bootstrap';

import { BarChartFormValues } from './BarChartWidgetConfig';
import CommonConfig from '../../common/config/CommonConfig';
import ConfigPanel from '../../common/config/ConfigPanel';
import AxisField from '../../common/config/formik/AxisField';
import ActionInput from 'core/dashboard/actionhandler/input/ActionInput';
import { AxisType } from '../../../../types/configTypes';
import Switch from '../../../config/Switch';
import SourceMappingConfig from '../../../sourceMapping/config/SourceMappingConfig';
import { KEY_X_AXIS, KEY_Y_AXIS, KEY_SERIES, sourceMappingFromConfig } from '../barChartHelpers';
import { MSG_TARGET_X_AXIS, MSG_TARGET_Y_AXIS, MSG_TARGET_SERIES } from '../../../../utils/messages';
import BarChartWidgetBasicConfig from './BarChartWidgetBasicConfig';
import WidgetConfigFooter from '../../common/WidgetConfigFooter';
import FormatConfigFieldComponent from '../../../form/format/FormatConfigFieldComponent';
import { barChartFormatters } from '../barChartFormatters';
import { SourceMappingConfig as SourceMappingConfigType } from 'core/dashboard/components/sourceMapping/sourceMappingTypes';

const xAxisTypes: AxisType[] = [AxisType.CATEGORY];
const yAxisTypes: AxisType[] = [AxisType.LINEAR];

const TARGET_KEYS = [
    { name: KEY_X_AXIS, description: MSG_TARGET_X_AXIS },
    { name: KEY_Y_AXIS, description: MSG_TARGET_Y_AXIS },
    { name: KEY_SERIES, description: MSG_TARGET_SERIES }
];

type OwnProps = {
    isCancellable: boolean;
    onCancel: () => void;
}

export default function BarChartWidgetConfigForm(props: FormikProps<BarChartFormValues> & OwnProps) {
    const [isSourceMapping, setIsSourceMapping] = useState(false);
    
    const xAxis = props.values.xAxis;
    const yAxis = props.values.yAxis;
    const reportSource = props.values.reportSource;
    const sqlParamsValues = props.values.sqlParams;
    const sourceMapping = props.values.sourceMapping;

    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig />
            <ConfigPanel defaultExpanded header="Bar-Chart">
                <div className="p-2">
                    <Form.Group>
                        <Form.Label className="config-sub-header">X-Axis</Form.Label>
                        <AxisField
                            name="xAxis"
                            value={xAxis}
                            axisTypes={xAxisTypes}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="config-sub-header">Y-Axis</Form.Label>
                        <AxisField
                            name="yAxis"
                            value={yAxis}
                            axisTypes={yAxisTypes}
                        />
                    </Form.Group>
                    <Field name="action" component={ActionInput}/>
                </div>
            </ConfigPanel>
            <ConfigPanel defaultExpanded header="Source Mapping">
                <div className="p-2">
                     <Switch
                        name="toggle-advanced"
                        enabled={!!isSourceMapping}
                        onToggled={() => {
                            props.setFieldValue(
                                'sourceMapping',
                                sourceMappingFromConfig(props.values)
                            );
                            setIsSourceMapping(!isSourceMapping)
                        }}
                    />
                    {isSourceMapping ? (
                        <SourceMappingConfig
                            name="sourceMapping"
                            config={sourceMapping ? sourceMappingFromConfig(props.values) : {} as SourceMappingConfigType}
                            targetKeys={TARGET_KEYS}
                        />
                    ) : <BarChartWidgetBasicConfig reportSource={reportSource} sqlParamsValues={sqlParamsValues} setFieldValue={props.setFieldValue}/>}
                </div>
            </ConfigPanel>
            <ConfigPanel header="Conditional Formatting" >
                <FastField name="format" component={FormatConfigFieldComponent} formatters={barChartFormatters}/>
            </ConfigPanel>
            <WidgetConfigFooter
                isFormValid={props.isValid}
                isCancellable={props.isCancellable}
                onCancel={props.onCancel}
            />
        </form>
    );
}
