import { EvaluationContext } from "../placeholders/placeholderTypes";
import { getAuthTokenPayload } from "@pearlchain/component-lib-common";
import { jexl } from "core/dashboard/evaluator/jexl";

const CHAR_EQ = 61;
const REGEXP_SUBSTITUTE_PROPS = /\$\{.*?\}/g;

export function isExpression(input: string) {
    if (input != null) {
        input = String(input);
        return input.charCodeAt(0) === CHAR_EQ;
    }
}


function createContext() {
    const payload = getAuthTokenPayload();
    return {
        now: Date.now(),
        loginName: payload && payload.loginName,
        realm: payload && payload.realm,
        language: payload && payload.language
    };
}

function replacePlaceholders(input: string, fn: (key: string) => string) {
    if (input != null) {
        input = String(input);
        return input.replace(REGEXP_SUBSTITUTE_PROPS, (str) => {
            const key = str.substring(2, str.length - 1);
            return fn(key);
        });
    }
}


export function resolveExpression(input: string, context: EvaluationContext, alwaysEvaluate?: boolean): unknown {
    const isExpr = isExpression(input);
    if (alwaysEvaluate || isExpr) {
        if (isExpr) {
            input = input.substr(1);
        }

        input = replacePlaceholders(input, key => key);
        const properties = Object.assign(createContext(), context.properties);
        return jexl.evalSync(input, properties);

    } else {
        return replacePlaceholders(input, key => {
            const value = context.properties[key];
            return value != null ? '' + value : '';
        });
    }
}

export function resolveExpressionFallback(input: string, context: EvaluationContext, fallbackValue: any): unknown {
    try {
        return resolveExpression(input, context);
    } catch(err) {
        console.warn(err);
        return fallbackValue;
    }
}