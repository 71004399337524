import { Dispatch } from 'react';
import { Widget } from './storeTypes';
import { DrilldownEntry } from './drilldownTypes';
import { RunReportResponse } from './responseTypes';
import { WidgetActions } from './widgetTypes';

export type InitParams = {
    widget: Widget<unknown>;
};

export type WidgetState = {
    widget: Widget<unknown>;
    drilldown: DrilldownEntry[];
    viewMode: WidgetViewMode;
    refreshCount: number;
    isLoading: boolean;
    data: unknown;
    hasStateError?: boolean;
    cacheRefreshCount: number
};

export enum WidgetViewMode {
    VIEW = 'view',
    CONFIG = 'config'
}

export type WidgetInitParams = {
    widget: Widget<unknown>;
}

export type WidgetInitAction = {
    type: WidgetActionType.INIT,
    params: WidgetInitParams
};

export type WidgetPushDrilldownAction = {
    type: WidgetActionType.PUSH_DRILLDOWN,
    drilldown: DrilldownEntry
};

export type WidgetPopDrilldownAction = {
    type: WidgetActionType.POP_DRILLDOWN,
    n: number
};

export type WidgetSetDataAction = {
    type: WidgetActionType.SET_DATA;
    data: unknown[];
};

export type WidgetSetViewModeAction = {
    type: WidgetActionType.SET_VIEW_MODE;
    mode: WidgetViewMode;
};

export enum WidgetActionType {
    INIT = 'INIT',
    PUSH_DRILLDOWN = 'PUSH_DRILLDOWN',
    POP_DRILLDOWN = 'POP_DRILLDOWN',
    TRIGGER_REFRESH = 'TRIGGER_REFRESH',
    BEGIN_REFRESH = 'BEGIN_REFRESH',
    DONE_REFRESH = 'DONE_REFRESH',
    DONE_REFRESH_ERROR = 'DONE_REFRESH_ERROR',
    SET_VIEW_MODE = 'SET_VIEW_MODE',
    SET_LOADING = 'SET_LOADING',
    SET_DATA = 'SET_DATA',
    CANCEL_CONFIG = 'CANCEL_CONFIG',
    STATE_ERROR = 'STATE_ERROR'
}

type InitAction = {
    type: WidgetActionType.INIT;
    params: InitParams;
};

type PushDrilldownAction = {
    type: WidgetActionType.PUSH_DRILLDOWN;
    drilldown: DrilldownEntry;
};

type PopDrilldownAction = {
    type: WidgetActionType.POP_DRILLDOWN;
    n: number;
};

export type TriggerRefreshAction = {
    type: WidgetActionType.TRIGGER_REFRESH;
    cacheRefresh:boolean
};

export type BeginRefreshAction = {
    type: WidgetActionType.BEGIN_REFRESH;
};

export type DoneRefreshAction = {
    type: WidgetActionType.DONE_REFRESH;
    data: RunReportResponse[] | undefined;
};

type DoneRefreshErrorAction = {
    type: WidgetActionType.DONE_REFRESH_ERROR;
    error: string;
};

type SetViewModeAction = {
    type: WidgetActionType.SET_VIEW_MODE;
    mode: WidgetViewMode;
};

export type WidgetSetLoadingAction = {
    type: WidgetActionType.SET_LOADING;
    loading: boolean;
};

export type WidgetCancelConfigAction = {
    type: WidgetActionType.CANCEL_CONFIG;
};

export type WidgetSateError = {
    type: WidgetActionType.STATE_ERROR;
    error: boolean;
};

export type WidgetAction =
    | WidgetInitAction
    | WidgetPushDrilldownAction
    | WidgetPopDrilldownAction
    | WidgetSetDataAction
    | WidgetSetViewModeAction
    | InitAction
    | PushDrilldownAction
    | PopDrilldownAction
    | TriggerRefreshAction
    | BeginRefreshAction
    | DoneRefreshAction
    | DoneRefreshErrorAction
    | SetViewModeAction
    | WidgetSetLoadingAction
    | WidgetCancelConfigAction
    | WidgetSateError;

export type WidgetDispatch = Dispatch<WidgetAction>;

export function init(params: InitParams): InitAction {
    return {
        type: WidgetActionType.INIT,
        params
    };
}

export function pushDrilldown(drilldown: DrilldownEntry): PushDrilldownAction {
    return {
        type: WidgetActionType.PUSH_DRILLDOWN,
        drilldown
    };
}

export function popDrilldown(n: number): PopDrilldownAction {
    return {
        type: WidgetActionType.POP_DRILLDOWN,
        n
    };
}

export function triggerRefresh(cacheRefresh:boolean): TriggerRefreshAction {
    return {
        type: WidgetActionType.TRIGGER_REFRESH,
        cacheRefresh
    };
}

export function beginRefresh(): BeginRefreshAction {
    return {
        type: WidgetActionType.BEGIN_REFRESH
    };
}

export function doneRefresh(data?: RunReportResponse[]): DoneRefreshAction {
    return {
        type: WidgetActionType.DONE_REFRESH,
        data
    };
}

export function doneRefreshError(error: string): DoneRefreshErrorAction {
    return {
        type: WidgetActionType.DONE_REFRESH_ERROR,
        error
    };
}

export function setViewMode(mode: WidgetViewMode): SetViewModeAction {
    return {
        type: WidgetActionType.SET_VIEW_MODE,
        mode
    };
}

export function setLoading(loading: boolean): WidgetSetLoadingAction {
    return {
        type: WidgetActionType.SET_LOADING,
        loading
    };
}

export type WidgetContext = {
    state: WidgetState;
    actions: WidgetActions;
    dispatch: Dispatch<WidgetAction>;
}
