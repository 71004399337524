import React, { useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';

type Props = {
    value: number | undefined;
    onChange: (value: number) => void;
    integer?: boolean;
    min?: number;
    max?: number;
    [key: string]: unknown;
}

function clamp(value: number, min?: number, max?: number) {
    if (min != null && value < min) {
        value = min;
    }

    if (max != null && value > max) {
        value = max;
    }

    return value;
}

function asString(value: number | undefined): string | undefined {
    return value != null ? '' + value : undefined
}

function NumberInput({ value, onChange, integer, min, max, ...more }: Props, ref: React.Ref<any>) {
    const [inputValue, setInputValue] = useState(asString(value));

    useEffect(() => {
        setInputValue(asString(value));
    }, [value]);

    const handleChange = (e: any) => {
        setInputValue(e.target.value);
    }

    const handleBlur = () => {
        let n: number | undefined;
        if (inputValue != null && !isNaN(inputValue as any)) {
            if (integer) {
                n = parseInt(inputValue);
                
            } else {
                n = parseFloat(inputValue);
            }
        }

        if (n == null) {
            n = 0;
        }

        n = clamp(n, min, max);
        onChange(n);
        setInputValue(asString(n));
    }

    return (
        <FormControl
            ref={ref}
            value={inputValue}
            onBlur={handleBlur}
            onChange={handleChange}
            {...more}
        />
    );  
}

export default React.forwardRef(NumberInput);
