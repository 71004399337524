import { useRef, useEffect, useReducer, Reducer } from "react";
import { FieldOptions, FieldOption } from "./formBuilderTypes";

enum ActionType {
    SET_OPT,
    SET_DEP,
    BEGIN_FETCH_OPT
}

type Action<Opts> = 
    | { type: ActionType.SET_DEP, key: keyof Opts, deps?: unknown[] }
    | { type: ActionType.SET_OPT, key: keyof Opts, value: unknown, deps?: unknown[] };

type State<Opts> = {
    opts: Opts,
    deps: { [K in keyof Opts]?: unknown[] };
}

function reducer<Opts>(state: State<Opts>, action: Action<Opts>): State<Opts> {
    switch (action.type) {
        case ActionType.SET_DEP:
            const deps = Object.assign({}, state.deps);
            deps[action.key] = action.deps;
            return {
                deps,
                opts: state.opts
            };

        case ActionType.SET_OPT: {
            if (state.deps[action.key] !== action.deps ||
                state.opts[action.key] !== action.value) {
                return state;
            }

            const opts: Opts = Object.assign({}, state.opts);
            opts[action.key] = action.value as any;

            return {
                opts,
                deps: state.deps
            };
        }

        default:
            return state;
    }
}

function getInitialState<Opts, Values>(fieldOpts: FieldOptions<Opts, Values>): State<Opts> {
    const opts: Partial<Opts> = {};
    for (let key in fieldOpts) {
        opts[key] = fieldOpts[key].value;
    }

    return {
        opts: opts as Opts,
        deps: {}
    };
}

function getDeps<OptV, Values>(value: Values, opt: FieldOption<OptV, Values>): unknown[] | undefined {
    const deps = opt.deps;
    return deps && deps.map(d => value[d]);
}

function areDepsChanged(prev: unknown[] | undefined, next: unknown[] | undefined) {
    return prev && next ? next.some((value, index) => prev[index] !== value) : true;
}

/**
 * Resolves dynamic options for a form-field.
 */

export function useOptions<Values, Opts>(
    values: Values,
    fieldOpts: FieldOptions<Opts, Values>
): Opts {
    const [state, dispatch] = useReducer<Reducer<State<Opts>, Action<Opts>>, FieldOptions<Opts, Values>>(reducer, fieldOpts, getInitialState);
    const prevRef = useRef<Values>();
    useEffect(() => {
        const prev = prevRef.current;
        if (values !== prev) {
            for (let key in fieldOpts) {
                const fieldOpt = fieldOpts[key];

                const prevDeps = prev && getDeps(prev, fieldOpt);
                const deps = getDeps(values, fieldOpt);

                if (fieldOpt.valueAsync && areDepsChanged(prevDeps, deps)) {
                    dispatch({ type: ActionType.SET_DEP, key, deps });
                    const optValue = fieldOpt.valueAsync(values);

                    if (optValue instanceof Promise) {
                        optValue.then(value => {
                            dispatch({
                                type: ActionType.SET_OPT,
                                key, value, deps
                            });
                        });

                    } else {
                        dispatch({
                            type: ActionType.SET_OPT,
                            key, value: optValue, deps
                        });
                    }
                }
            }
            prevRef.current = values;
        }
    }, [values, fieldOpts]);

    return state.opts;
}
