import React, { useMemo } from 'react';
import { DesignViewer } from '@pearlchain/enterprise-designer';
import { WidgetActions } from '../../../types/widgetTypes';
import { fromJS, Map } from 'immutable';

type Props = {
    designId: string;
    selectorConfigs: unknown;
    refreshCount: number;
    widgetActions: WidgetActions;
    widgetUuid: string;
};

export default function EnterpriseDesignerView(props: Props) {
    // converts the datasource config to an immutable list
    const datasourceConfig = useMemo(() => {
        if (!props.selectorConfigs) return Map();

        let datasourceConfig = props.selectorConfigs;
        if (typeof props.selectorConfigs === 'string') {
            datasourceConfig = JSON.parse(props.selectorConfigs);
        }
        return fromJS(datasourceConfig);
    }, [props.selectorConfigs]);

    return (
        <DesignViewer
            designId={props.designId}
            refreshCount={props.refreshCount}
            widgetActions={props.widgetActions}
            widgetUuid={props.widgetUuid}
            datasourceConfig={datasourceConfig}
        />
    );
}
