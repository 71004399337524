import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';

import { HumanTaskOverviewResponse } from '../../types/responseTypes';
import HumanTaskDetailTable from './HumanTaskDetailTable';
import ServiceMessageResultDisplayer from './ServiceMessageResultDisplayer';
import HumanTaskDetailDescription from './HumanTaskDetailDescription';
import HumanTaskDetailForm from './HumanTaskDetailForm';
import { ServiceMessageResultResponse } from '../../../../../types/responseTypes';

type ModalProps = {
    task: HumanTaskOverviewResponse;
    onHide: (submitted: boolean) => void;
};

function ModalBody({ task, onHide }: ModalProps) {
    const [serviceMessage, setServiceMessage] = useState<ServiceMessageResultResponse | undefined>(undefined);
    const onTaskSubmitted = useCallback(() => onHide(true), [onHide]);

    return (
        <>
            <Modal.Body>
                <ServiceMessageResultDisplayer serviceMessage={serviceMessage}/>
                <HumanTaskDetailDescription description={task.description}/>
                <HumanTaskDetailTable task={task}/>
                <HumanTaskDetailForm task={task} onTaskSubmitted={onTaskSubmitted} onShowServiceMessage={setServiceMessage}/>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </>
    )
}

type Props = {
    task: HumanTaskOverviewResponse;
    show: boolean;
    onHide: (submitted: boolean) => void;
};

export default function HumanTaskDetail(props: Props) {
    const onCancelled = useCallback(() => props.onHide(false), [props.onHide]);

    return (
        <Modal show={props.show} onHide={onCancelled}>
            <Modal.Header onHide={onCancelled} closeButton>
                Task Detail
            </Modal.Header>
            <ModalBody onHide={props.onHide} task={props.task} />
        </Modal>
    );
}
