import { DashboardPropertyType } from 'core/dashboard/types/dashboardPropertyTypes';
import { DashboardProperty } from 'core/dashboard/types/storeTypes';
import {
    DashboardActionType,
    DashboardAction,
    DashboardInitParams,
    DashboardState,
    DashboardSetLayoutAction,
    DashboardSetDashboardAction,
    DashboardSetPropertyValueAction,
    DashboardSetMultiplePropertyValueAction
} from '../../types/dashboardTypes';

export function initialState({ dashboard }: DashboardInitParams): DashboardState {
    return {
        dashboard,
        properties: {},
        layout: undefined
    };
}

function handleSetDashboard(state: DashboardState, action: DashboardSetDashboardAction): DashboardState {
    if (state.dashboard === action.dashboard) {
        return state;
    }

    const nextState = { ...state, dashboard: action.dashboard };

    // clear the current state properties if the properties config changed
    if (state.dashboard.properties !== action.dashboard.properties) {
        nextState.properties = {};
    }

    return nextState;
}

function handleSetLayout(state: DashboardState, action: DashboardSetLayoutAction): DashboardState {
    return { ...state, layout: action.layout };
}

function handleSetPropertyValue(state: DashboardState, action: DashboardSetPropertyValueAction): DashboardState {
    if (state.properties[action.name] === action.value) {
        return state;
    }

    return {
        ...state,
        properties: {
            ...state.properties,
            [action.name]: action.value
        }
    };

}

function handleSetMultiplePropertyValue(state: DashboardState, action: DashboardSetMultiplePropertyValueAction): DashboardState {
    if (JSON.stringify(state.properties) == JSON.stringify(action.properties)) {
        return state;
    }

    const properties = {...state.properties}
    Object.entries(action.properties).map(([key, value]) => properties[key] = value)

    const aux = {
        ...state,
        properties
    };

    return aux;

}

export function reducer(state: DashboardState, action: DashboardAction): DashboardState {
    switch (action.type) {
        case DashboardActionType.SET_DASHBOARD:
            return handleSetDashboard(state, action);

        case DashboardActionType.SET_LAYOUT:
            return handleSetLayout(state, action);

        case DashboardActionType.SET_PROPERTY_VALUE:
            return handleSetPropertyValue(state, action);

        case DashboardActionType.SET_MULTIPLE_PROPERTY_VALUE:
            return handleSetMultiplePropertyValue(state, action);

        default:
            return state;
    }
}
