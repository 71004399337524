import React from 'react';
import { FormikProps } from 'formik';
import { Button } from 'react-bootstrap';

import ConfigPanel from '../../common/config/ConfigPanel';

import { HumanTasksFormValues } from './HumanTasksWidgetConfig';
import CommonConfig from '../../common/config/CommonConfig';
import HumanTasksBasicConfig from './HumanTasksBasicConfig';
import HumanTasksProcessVariablesConfig from './HumanTasksProcessVariablesConfig';
import HumanTasksStandardColumnsConfig from './HumanTasksStandardColumnsConfig';

export default function HumanTasksWidgetConfigForm(props: FormikProps<HumanTasksFormValues>) {
    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig />
            <HumanTasksBasicConfig />
            <ConfigPanel header="Process Variables" defaultExpanded>
                <HumanTasksProcessVariablesConfig
                    name="processVariables"
                    processVars={props.values.processVariables}
                />
            </ConfigPanel>
            <ConfigPanel header="Standard Column Variables">
                <HumanTasksStandardColumnsConfig
                    name="standardColumnVariables"
                    processVars={props.values.standardColumnVariables || []}
                />
            </ConfigPanel>
            <ConfigPanel header="Extra Column Variables">
                <HumanTasksProcessVariablesConfig
                    name="extraColumnVariables"
                    processVars={props.values.extraColumnVariables || []}
                />
            </ConfigPanel>
            <div className="config-form-footer">
                <Button variant="success" type="submit">
                    Save Changes
                </Button>
            </div>
        </form>
    );
}
