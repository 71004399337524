import React, { Dispatch } from 'react';

import { Formatter, FmtInputValue } from '../../../types/formatConfigTypes';
import FormatBlockField from './FormatBlockField';
import FormatBlockDropdown from './FormatBlockDropdown';
import { Action } from './formatConfigReducer';

type Props = {
    blockIndex: number;
    formatValues: { [key: string]: FmtInputValue }
    formatters: Formatter[];
    dispatch: Dispatch<Action>;
}

function FormatBlock({ blockIndex, formatValues, formatters, dispatch }: Props) {
    return (
        <div className="format-block">
            {formatters
                .filter(fmter => fmter.key in formatValues)
                .map(fmter => (
                    <FormatBlockField
                        key={fmter.key}
                        blockIndex={blockIndex}
                        formatter={fmter}
                        formatValue={formatValues[fmter.key]}
                        dispatch={dispatch}
                    />
                ))
            }
            <FormatBlockDropdown
                blockIndex={blockIndex}
                formatValues={formatValues}
                formatters={formatters}
                dispatch={dispatch}
            />
        </div>
    );
}

export default React.memo(FormatBlock);
