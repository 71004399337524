import { FieldProps } from "formik";
import TextInput from "../config/formik/TextInput";
import EventActionsInput from "../config/formik/EventActionsInput";
import PosRot3DInput from "../config/formik/PosRot3DInput";
import CheckInput from '../config/formik/CheckInput';


export const formBuilderInputs: { [key: string]: React.ComponentType<FieldProps> } = {
    'text': TextInput,
    'actions': EventActionsInput,
    'posRot3D': PosRot3DInput,
    'checkbox': CheckInput
};
