import { WidgetDefinition } from "../../../types/widgetTypes";
import EnterpriseDesignerImage from '../../../../../img/designer.png';
import EnterpriseDesignerWidget from "./EnterpriseDesignerWidget";

export const ENTERPRISE_DESIGNER_WIDGET_TYPE = 'ENTERPRISE_DESIGNER';

export const ENTERPRISE_DESIGNER_WIDGET: WidgetDefinition = {
    name: 'Enterprise Designer',
    description: 'Design viewer',
    component: EnterpriseDesignerWidget,
    icon: 'vector-square',
    image: EnterpriseDesignerImage,
    listSources: () => []
}
