import { EvaluatorOpts } from "@pearlchain/component-lib-plugins-core";
import { ConditionalFmtConfig, FmtBlock, Formatter } from "../types/formatConfigTypes";
import { Evaluator } from "../evaluator/Evaluator";

export function resolveConditionalFormat(formatConfig: ConditionalFmtConfig | undefined, evaluator: Evaluator, context: { [key: string]: unknown }): FmtBlock {
    if (formatConfig == null) return {};

    const opts: EvaluatorOpts = { alwaysEvaluate: true };
    for (const condition of formatConfig.conditions) {
        if (evaluator.evaluate(condition.condition, context, opts)) {
            return condition.format;
        }
    }

    return formatConfig.format;
}

export function resolveFormatValues(block: FmtBlock, evaluator: Evaluator, context: { [key: string]: unknown }): { [key: string]: unknown } {
    const result: { [key: string]: unknown } = {};

    for (const key in block) {
        const formatValue = block[key];
        if (formatValue.dynamic) {
            result[key] = evaluator.evaluate(formatValue.value as string, context);
        } else {
            result[key] = formatValue.value;
        }
    }

    return result;
}

export function applyFormatters(formatters: Formatter[], formatValues: { [key: string]: unknown }, context: any) {
    for (const formatter of formatters) {
        if (formatter.key in formatValues) {
            formatter.applyFormatter(formatValues[formatter.key], context);
        }
    }
}

