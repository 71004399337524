import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FastField } from 'formik';

import ConfigPanel from '../../common/config/ConfigPanel';
import CheckboxFormField from '../../common/config/formik/CheckboxFormField';
import MinMaxFormField from '../../common/config/formik/MinMaxFormField';
import FormFieldErrorFeedback from '../../common/config/formik/FormFieldErrorFeedback';
import MultiSelectInput from '../../../config/formik/MultiSelectInput';
import FormKeysInput from './FormKeysInput';

const filterOptions = [{ label: 'Active Resource', value: 'active-resource' }];


export default function HumanTasksBasicConfig() {
    return (
        <ConfigPanel defaultExpanded header="Human-Tasks">
            <div className="p-2">
                <Form.Group>
                    <Form.Label>Form Keys</Form.Label>
                    <FastField
                        name="formKeys"
                        component={FormKeysInput}
                    />
                    <FormFieldErrorFeedback name="formKeys" />
                </Form.Group>
                <Form.Row className="mb-2">
                    <Col>
                        <CheckboxFormField name="assignedToMe" label="Assigned To Me" />
                    </Col>
                    <Col>
                        <CheckboxFormField name="unassigned" label="Unassigned" />
                    </Col>
                </Form.Row>
                <Form.Group>
                    <Form.Label>Filter</Form.Label>
                    <FastField
                        name="filter"
                        options={filterOptions}
                        component={MultiSelectInput}
                        isMulti
                    />
                    <FormFieldErrorFeedback name="filter" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Priority</Form.Label>
                    <MinMaxFormField name="priority" />
                    <FormFieldErrorFeedback name="priority" />
                </Form.Group>
            </div>
        </ConfigPanel>
    );
}
