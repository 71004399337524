import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, CloseButton, FormGroup } from 'react-bootstrap';

import { DashboardProperty } from '../../../types/storeTypes';
import { DashboardPropertyType } from '../../../types/dashboardPropertyTypes';
import { dashboardPropertiesConfig } from '../../../properties/dashboardProperties';

import { PropertyCheckField } from './PropertyCheckField';
import { FormAction, FormActionType } from './dashboardPropertiesReducerTypes';
import DashboardPropertyInput from './DashboardPropertyInput';

interface Props {
    error: string | undefined;
    index: number;
    property: DashboardProperty;
    dispatch: Dispatch<FormAction>;
}

function DashboardPropertyRow(props: Props) {
    const { t } = useTranslation();
    const { property, error, index, dispatch } = props;
    const { name, type, val, isEditable } = property;

    function handleRemoved() {
        dispatch({ type: FormActionType.REMOVE_FIELD, index });
    }

    function handleTypeChanged(propertyType: DashboardPropertyType) {
        dispatch({ type: FormActionType.SET_FIELD_TYPE, index, propertyType });
    }

    function handleKeyChanged(key: string) {
        dispatch({ type: FormActionType.SET_FIELD_KEY, index, key });
    }

    function handleValueChanged(value: unknown) {
        dispatch({ type: FormActionType.SET_FIELD_VALUE, index, value });
    }

    function handleEditableChanged(editable: boolean) {
        dispatch({ type: FormActionType.SET_FIELD_EDITABLE, index, editable });
    }

    return (
        <Form.Row style={{ position: 'relative' }}>
            <Col sm={3}>
                <FormGroup>
                    {props.index === 0 ? <Form.Label>{t('common.binder.customer.grid.name')}</Form.Label> : undefined}
                    <Form.Control
                        placeholder="key"
                        value={name}
                        isInvalid={!!error}
                        onChange={(e: any) => handleKeyChanged(e.target.value)}
                    />
                    {error ? (
                        <Form.Control.Feedback type={'invalid'}>{error}</Form.Control.Feedback>
                    ) : null}
                </FormGroup>
            </Col>
            <Col sm={3}>
                <FormGroup>
                    {props.index === 0 ? <Form.Label>{t('common.Type')}</Form.Label> : undefined}
                    <Form.Control as="select" value={type} onChange={(e: any) => handleTypeChanged(e.target.value)}>
                        {Object.entries(dashboardPropertiesConfig).map(([key, config]) => (
                            <option key={key} value={key}>{config!.label}</option>
                        ))}
                    </Form.Control>
                </FormGroup>
            </Col>
            <Col sm={3}>
                <FormGroup>
                    {props.index === 0 ? <Form.Label>{t('Valeur par défaut')}</Form.Label> : undefined}
                    <DashboardPropertyInput
                        value={val}
                        type={type}
                        onChange={handleValueChanged}
                    />
                </FormGroup>
            </Col>
            <Col sm={3}>
                {props.index === 0 ? <Form.Label></Form.Label> : undefined}
                <PropertyCheckField
                    value={isEditable}
                    label={t('dashboard.editable.property')}
                    onChange={handleEditableChanged}
                />
            </Col>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <CloseButton onClick={handleRemoved} />
            </div>
        </Form.Row>
    );
};

export default React.memo(DashboardPropertyRow);
