import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
    children: React.ReactNode;
};

type State = {
    hasError: boolean;
};

export default class WidgetErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <ErrorBoundaryAlert  showProp={this.state.hasError} error='Uncaught Exception. Check the log for more details' /> 
                    {React.cloneElement(this.props.children as any, {hasStateError: true})}
                </>
            );
        }

        return this.props.children;
    }
}
type ErrorProps = {
    error: string
    showProp: boolean
}

function ErrorBoundaryAlert(props: ErrorProps) {
    const [show, setShow] = useState(props.showProp);
    if(show){
        return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible className='error-boundary-alert'>
                <Alert.Heading>Error</Alert.Heading>
                <p>
                    {props.error}
                </p>
            </Alert>
        )
    }

    return <></>;
    
}

