import { Dashboard, DashboardProperty } from "./storeTypes";
import { LayoutConfig } from "../components/layout/layoutTypes";
import { EvaluationContext } from "../utils/placeholders/placeholderTypes";
import { WidgetState, WidgetAction } from "./widgetStateTypes";

export enum DashboardActionType {
    SET_DASHBOARD,
    SET_LAYOUT,
    SET_PROPERTY_VALUE,
    SET_MULTIPLE_PROPERTY_VALUE
}

export type DashboardInitParams = {
    dashboard: Dashboard;
}

export type DashboardSetDashboardAction = {
    type: DashboardActionType.SET_DASHBOARD;
    dashboard: Dashboard;
}

export type DashboardSetLayoutAction = {
    type: DashboardActionType.SET_LAYOUT;
    layout: LayoutConfig | undefined;
};

export type DashboardSetPropertyValueAction = {
    type: DashboardActionType.SET_PROPERTY_VALUE;
    name: string;
    value: unknown;
}

export type DashboardSetMultiplePropertyValueAction = {
    type: DashboardActionType.SET_MULTIPLE_PROPERTY_VALUE;
    properties: {[key: string]: unknown};
}

export type DashboardAction =
    | DashboardSetDashboardAction
    | DashboardSetLayoutAction
    | DashboardSetPropertyValueAction
    | DashboardSetMultiplePropertyValueAction;

export type DashboardState = {
    dashboard: Dashboard;
    layout: LayoutConfig | undefined;
    properties: {
        [key: string]: unknown;
    };
}

export interface DashboardRootParentState {
    widget: WidgetState;
    dispatch: React.Dispatch<WidgetAction>;
}

export type DashboardContext = {
    state: DashboardState;
    parent?: DashboardRootParentState;
    dispatch: React.Dispatch<DashboardAction>;
    propertyConfigs: DashboardProperty[];
    evaluationContext: EvaluationContext
};
