
export enum DrilldownType {
    DASHBOARD = 'DASHBOARD'
}

export type DrilldownDashboardEntry = {
    type: DrilldownType;
    dashboardUuid: string;
    properties: { [key: string]: unknown };
}

export type DrilldownEntry = DrilldownDashboardEntry;
