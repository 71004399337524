import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import Notifications from 'react-notification-system-redux';
import { Notification } from 'react-notification-system';

import { StoreState } from './core/dashboard/types/storeTypes';
import i18n from 'core/dashboard/components/i18n/i18n';

type Props = {
    notifications: Notification[];
    children: React.ReactNode;
};

function App(props: Props) {
    
    useEffect(() => {
        if(i18n.isInitialized) {
            // force reload translations after login
            // (called after moving back from loginPage -> home)
            i18n.reloadResources();
        }
        // fetch translations when page first loaded
        i18n.init();
    }, []);

    return (
        <div className="app">
            <I18nextProvider i18n={i18n as any}>
                <Notifications notifications={props.notifications} />
                { props.children }
            </I18nextProvider>
        </div>
    );
}

export default connect(
    (state: StoreState) => ({
        notifications: state.notifications
    })
)(App);
