import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FastField, FieldProps } from 'formik';
import { DataSourceActionConfig, DataSourceTypes, PropertyConfig } from 'core/dashboard/types/actionHandlerTypes';
import DataSourcesSelectInput from 'core/dashboard/components/config/formik/DataSourcesSelectInput';
import DataSourcesParams from 'core/dashboard/components/widgets/common/config/formik/DataSourcesParams';
import DataSourceMetaData from 'core/dashboard/components/config/formik/DataSourceMetaData';
import { DataSourceProvider } from 'core/dashboard/types/responseTypes';

export default function DataSourcesActionInput(props: FieldProps) {
    const { field } = props;
    const name = field.name;
    const config = field.value as DataSourceActionConfig;
    const params = config.params
    const [providersHandlers, setProvidersHandlers] = useState<DataSourceProvider[]>()

    function handleProvidersHandlers(providers: DataSourceProvider[]){
        setProvidersHandlers(providers)
    }

    function handleParams(params: PropertyConfig[] | undefined){
        props.form.setFieldValue(name + '.params', params)
    }

    return (
        <div className="action-config">
            <Form.Group as={Row}>
                <Col xs={3}>Data Source</Col>
                <Col xs={9}>
                    <FastField name={name + '.dataSources'} handleProvidersHandlers={handleProvidersHandlers} type={DataSourceTypes.HANDLER} component={DataSourceMetaData}/>
                </Col>
            </Form.Group>
            {providersHandlers && providersHandlers.length ? 
                <Form.Group as={Row}>
                    <Col xs={3}>Functions</Col>
                    <Col xs={9}>
                        <FastField 
                            name={name + '.providersHandlers'} 
                            providersHandlers={providersHandlers}
                            handleParams={handleParams} 
                            type={DataSourceTypes.HANDLER} 
                            component={DataSourcesSelectInput}
                        />
                    </Col>
                </Form.Group> : null
            }
            {params && params.length ? 
                <Form.Group as={Row}>
                    <Col xs={3}>Params</Col>
                    <Col xs={9}>
                        <DataSourcesParams name={name + '.params'} properties={params || []} isAction={true}/>
                    </Col>
                </Form.Group>
                : null
            }
        </div>
    );
}
