const PROP_PREFIX = 'param.'

export function getKeyAndValues(searchParams: URLSearchParams){
    let properties: { [key: string]: unknown } = {}
    searchParams.forEach(function(value, key) {
        if(key.startsWith(PROP_PREFIX)){
            let aux = key.substr(PROP_PREFIX.length);
            properties[aux] = value
        }
    });
    return properties;
}
