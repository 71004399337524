import React, { useRef } from 'react';
import { Modal, ButtonGroup, Button } from 'react-bootstrap';
import ColumnFormatForm from './ColumnFormatForm';
import { ColumnFormatConfig } from 'core/dashboard/types/gridConfigTypes';

type Props = {
    config?: ColumnFormatConfig;
    visible: boolean;
    onClose: () => void;
    onSubmit: (config: ColumnFormatConfig) => void;
};

function ColumnFormatConfigModal(props: Props) {
    // a reference to formik submit function
    const submitRef = useRef<() => void>();

    return (
        <Modal show={props.visible} onHide={props.onClose} size="lg">
            <Modal.Header>
                <Modal.Title className="flex-center">Format</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ColumnFormatForm
                    bindSubmit={(submit) => submitRef.current = submit}
                    config={props.config}
                    onSubmit={props.onSubmit}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="secondary" onClick={props.onClose}>Cancel</Button>
                    <Button onClick={() => void (submitRef.current && submitRef.current())}>Save</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default React.memo(ColumnFormatConfigModal);
