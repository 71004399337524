import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FieldProps } from 'formik';

import { DrilldownActionConfig } from 'core/dashboard/types/actionHandlerTypes';
import DashboardPropertiesField from 'core/dashboard/components/widgets/common/config/formik/DashboardPropertiesField';
import DashboardChooserField from 'core/dashboard/components/widgets/common/config/formik/DashboardChooserField';

export default function DrilldownActionInput(props: FieldProps) {
    const { field } = props;

    const name = field.name;
    const config = field.value as DrilldownActionConfig;
    const dashboardUuid = config.dashboardUuid;

    return (
        <div className="action-config">
            <Form.Group as={Row}>
                <Col xs={3}>Dashboard:</Col>
                <Col xs={9}>
                    <DashboardChooserField name={name + '.dashboardUuid'} />
                </Col>
            </Form.Group>
            {dashboardUuid && (
                <Form.Group as={Row}>
                    <Col xs={3}>Dashboard Properties:</Col>
                    <Col xs={9}>
                    <DashboardPropertiesField
                        dashboardUuid={dashboardUuid}
                        name={name + '.properties'}
                        showPreview={false}
                    />
                    </Col>
                </Form.Group>
            )}
        </div>
    );
}
