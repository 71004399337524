import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Form, Button, FormLabel } from 'react-bootstrap';
import SqlEditorModal from 'core/dashboard/components/config/datasource/SqlEditorModal';
import { Param, paramsToObject } from 'core/dashboard/components/config/datasource/Editor';
import DataProviderField from './DataProviderField';

type Props = {
    reportSource: string;
    name: string;
    paramsName?: string;
    typeName?: string;
    sqlParamsName: string;
    sqlParamsValues: { [key: string]: any };
    setFieldValue: (field: string, value: any) => void;
    isSingleSource: boolean | undefined;
};

export type CloseModalValues = {
    field: string
    initialValue: string
    currentValue: string
}

export function showDataSourceComponent(dataSource: string, props:Props) {
    const [showSqlEditor, setshowSqlEditor] = useState(false);
    const [query, setQuery] = useState(props.reportSource);

    const [sqlParams, setSqlParams] = useState<{ [key: string]: any }>(paramsToObject(props.sqlParamsValues as Param[]));

    useEffect(() => {
        props.setFieldValue(props.sqlParamsName, sqlParams)
    }, [sqlParams])

    function handleSetQuery(query: string, params: { [key: string]: any }){
        setQuery(query);
        setSqlParams(params);
        setshowSqlEditor(false)
    }

    function handleChange(field: string, event: any){
        setSqlParams({...sqlParams, [field]: event.target.value});
    };

    function handleClose(closeModalValues: CloseModalValues[]){
        let flag = false
        closeModalValues.forEach(function (item) {
            if(item.initialValue !== item.currentValue){
                flag = true
            }
        });

        if(flag){
            var alert = confirm("You have unsaved Changes. Do you want to continue?");
            if (alert == true) {
                closeModalValues.forEach(function (item) {
                    props.setFieldValue(item.field, item.initialValue)
                });
                setshowSqlEditor(false);
            }
        }
        else {
            setshowSqlEditor(false)
        }
    }
     
    switch(dataSource) {
        case 'SQL':
            return (
                <>
                    <Button onClick={() => setshowSqlEditor(true)}>Change Statement</Button>
                    <SqlEditorModal 
                        name={props.name} 
                        show={showSqlEditor} 
                        handleClose={handleClose} 
                        setQuery={handleSetQuery}
                        sqlParamsName={props.sqlParamsName}
                        setFieldValue={props.setFieldValue}
                        isSingleSource={props.isSingleSource}
                        sqlParams={sqlParams}
                    />
                    {query ? <Form.Control className="text-truncate mt-3" type="text" disabled value={query}/> : ''}
                    {
                        Object.entries(sqlParams).map(([key, value]) => (
                            <div className="d-flex align-items-center justify-content-between mt-3" id={key}>
                                <FormLabel>{key}:</FormLabel>
                                <Form.Control className="w-75" type="text" value={value} onChange={(event:any) => handleChange(key, event)}/>
                            </div>
                        ))
                    }
                </>
            );
        case 'DATASOURCE':
            return <Field name={props.name} component={DataProviderField}/>;
        default :
            return 'Please select a Data Source';
    }
}
