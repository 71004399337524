import React from 'react';

import { WidgetProps } from '../../widget/widgetTypes';
import { LineChartConfig } from './lineChartTypes';
import LineChartWidgetConfig from './config/LineChartWidgetConfig';
import LineChartWidgetView from './view/LineChartWidgetView';
import { RunReportResponse } from '../../../types/responseTypes';
import { useTrackAccessorsPropertySource } from 'core/dashboard/evaluator/propertysource/useTrackAccessorsPropertySource';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';

export const LINE_CHART_TYPE = 'LINE_CHART';

export default function(props: WidgetProps) {
    const { state, actions, propertySource } = props;

    const widget = state.widget;
    const common = widget.common;
    const config = widget.config as LineChartConfig | undefined;

    // replace with a memoized proxy, so the widget updates only when observed properties changed
    const properties = useTrackAccessorsPropertySource(config, propertySource);

    function renderView() {
        if (config == null) return renderConfig();
        if (state.hasStateError) return <></>
        const data = state.data as RunReportResponse[] | undefined;

        return (
            <LineChartWidgetView
                refreshCount={state.refreshCount}
                config={config}
                actions={actions}
                data={data}
                properties={properties}
                common={common}
            />
        );
    }

    function renderConfig() {
        return <LineChartWidgetConfig common={common} config={config} actions={actions} dataSources={widget.dataSources} />;
    }

    if (state.viewMode === WidgetViewMode.VIEW) {
        return renderView();
    } else {
        return renderConfig();
    }
}
