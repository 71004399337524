import React from 'react';
import { Field, FieldArray, FieldProps } from 'formik';
import { Form, Col, Button } from 'react-bootstrap';

import { PropertyConfig } from 'core/dashboard/types/actionHandlerTypes';
import EvaluatableInputField from './EvaluatableInputField';
import FormFieldErrorFeedback from './FormFieldErrorFeedback';

type Props = {
    name: string;
    properties: PropertyConfig[];
    isAction?: boolean
};

export default function DataSourcesParams(props: Props) {
    const name = props.name;
    const properties = props.properties;

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div>
                    {properties.map((propertyConfig, index) => {
                        const propName = name + '.' + index;
                        return (
                            <Form.Row key={index} className="mb-2">
                                <Col>
                                    <Field
                                        name={propName + '.name'}
                                        value={propertyConfig.name}
                                        disabled={index==0}
                                        component={PropertyName}
                                    />
                                    <FormFieldErrorFeedback name={propName + '.name'} />
                                </Col>
                                <Col>
                                    <EvaluatableInputField
                                        name={propName + '.value'}
                                        placeholder="Value"
                                        showPreview={false}
                                    />
                                </Col>
                                {(index !== 0 && props.isAction) && (
                                    <div>
                                        <Button onClick={() => arrayHelpers.remove(index)}>
                                            x
                                        </Button>
                                    </div>
                                )}
                            </Form.Row>
                        );
                    })}
                    {props.isAction && (
                        <Button className="w-2" onClick={() => {
                                arrayHelpers.push({
                                    type: null,
                                    name: "",
                                    optional: true,
                                    value: ""
                                });
                            }}>
                            Add Param
                        </Button>
                    )}
                </div>
            )}
        />
    );
}

type OwnProps = {
    name: string
    value: string
    disabled: boolean
}

const PropertyName = (props: OwnProps & FieldProps) => {
    function handleChange(e: any) {
        const value = e.currentTarget.value
        props.form.setFieldValue(props.field.name, value);
    }

    return (
        <Form.Control type="text" disabled={props.disabled}  value={props.value} onChange={handleChange}/>
    )
}
