import React, { useRef, useLayoutEffect as useEffect } from 'react';
import Chart, { ChartData, ChartDataSets } from 'chart.js';

import { toNumber, toString } from '../../../../utils/dataTypes';
import { ActionHandler } from 'core/dashboard/types/actionHandlerTypes';
import { ConditionalFmtConfig } from 'core/dashboard/types/formatConfigTypes';

import { KEY_NAME, KEY_VALUE, KEY_SERIES } from '../pieChartHelpers';
import { getColorAt } from '../../../../utils/pallette';
import { groupDataBySeries } from '../../../../utils/graph/graphHelpers';
import { PieChartConfig } from '../pieChartTypes';
import { applyFormatters, resolveConditionalFormat, resolveFormatValues } from 'core/dashboard/formatters/formatHelpers';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { pieChartFormatters } from '../pieChartFormatters';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';

type Props = {
    data: { [key: string]: unknown }[];
    config: PieChartConfig;
    actionHandler: ActionHandler | undefined;
    evaluator: Evaluator;
    actions: WidgetActions;
};

function resolveChartData(seriesData: { [key: string]: unknown }[][], evaluator: Evaluator, formatConfig: ConditionalFmtConfig | undefined, actions: WidgetActions): ChartData {
    const lookup = new Map<string, number>();
    const datasets: ChartDataSets[] = [];
    const labels: string[] = [];
    let errorFlag = false;

    for (const data of seriesData) {
        const backgroundColors: string[] = [];
        const dataPoints: number[] = [];
        
        const dataset: ChartDataSets = {
            backgroundColor: backgroundColors,
            data: dataPoints
        };

        for (const datum of data) {
            const name = toString(datum[KEY_NAME]);
            const value = toNumber(datum[KEY_VALUE]);
            const series = (datum[KEY_SERIES] || '') as string;

            // by default, the dataset label is set to the series key
            if (dataset.label == null) {
                dataset.label = series;
            }

            let index: number | undefined = lookup.get(name);
            if (index == null) {
                index = labels.length;
                lookup.set(name, index);
                labels.push(name);
            }
            
            backgroundColors[index] = getColorAt(index);
            dataPoints[index] = value;

            try{
                // resolve the first format that matches the condition
                const formatBlock = resolveConditionalFormat(formatConfig, evaluator, datum);
                // then evaluate the dynamic properties of the format object
                const format = resolveFormatValues(formatBlock, evaluator, datum);

                // apply formatters to the current dataset
                applyFormatters(pieChartFormatters, format, {
                    dataset,
                    labels,
                    index
                });
            }
            catch (e) {
                if(!errorFlag){
                    console.warn(e);
                    const msg = 'Invalid expression: "' + e + '"';
                    actions.show('error', 'Formatter Uncaught Exception',  msg);
                    errorFlag = true;
                }
                
            }            
        }

        datasets.push(dataset);
    }
    
    return {
        datasets,
        labels
    };
}

export default function PieChartComponent({ data, config, actionHandler, evaluator, actions }: Props) {
    const elRef = useRef<HTMLCanvasElement | null>(null);
    const chartRef = useRef<Chart | null>(null);
    const seriesDataRef = useRef<{ [key: string]: unknown }[][] | null>(null);

    useEffect(() => {
        const seriesData = groupDataBySeries(KEY_SERIES, data);
        seriesDataRef.current = seriesData;

        const chartData = resolveChartData(seriesData, evaluator, config.format, actions);

        let chart = chartRef.current;
        if (chart == null) {
            const ctx = elRef.current;
            if (ctx == null) return;

            chartRef.current = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: {
                    maintainAspectRatio: false,
                    onClick: (x, g: any) => {
                        if (!g || !g.length) return;

                        const datasetIndex = g[0]._datasetIndex as number;
                        const index = g[0]._index as number;
                        const datum = seriesDataRef.current![datasetIndex][index];
                        
                        if (actionHandler && config.action) {
                            actionHandler(config.action, datum);
                        }
                    }
                }
            });
        } else {
            chart.data = chartData;
            chart.update();
        }
    }, [data, config, actionHandler, evaluator]);

    return (
        <div className='canvasWrapper'>
            <canvas width="100%" height="100%" ref={elRef} />
        </div>
    );
}
