import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleRight,
    faArrowRight,
    faArrowUp,
    faBars,
    faBolt,
    faBrush,
    faCalendar,
    faChartBar,
    faChartPie,
    faChartLine,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCog,
    faCompress,
    faCross,
    faExpand,
    faFilter,
    faKeyboard,
    faMinus,
    faPaintBrush,
    faPlus,
    faProjectDiagram,
    faPuzzlePiece,
    faQuestionCircle,
    faSave,
    faSpinner,
    faSync,
    faStar,
    faTimes,
    faTimesCircle,
    faThLarge,
    faThList,
    faUsers,
    faWindowMaximize,
    faEllipsisH,
    faExpandArrowsAlt,
    faTrash,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import { faCaretSquareLeft, faCaretSquareRight, faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';

library.add(
    faAngleRight,
    faArrowRight,
    faArrowUp,
    faBars,
    faBolt,
    faBrush,
    faCalendar,
    faCaretSquareLeft,
    faCaretSquareRight,
    faChartBar,
    faChartPie,
    faChartLine,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCog,
    faCompress,
    faCross,
    faEllipsisH,
    faExpand,
    faFilter,
    faKeyboard,
    faMinus,
    faPaintBrush,
    faPlus,
    faProjectDiagram,
    faPuzzlePiece,
    faQuestionCircle,
    faSave,
    faSpinner,
    faSync,
    faStar,
    faStarOutline,
    faTimes,
    faTimesCircle,
    faThLarge,
    faThList,
    faUsers,
    faWindowMaximize,
    faExpandArrowsAlt,
    faTrash,
    faEdit
);
