import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Form } from 'react-bootstrap';

type Props = {
    [key: string]: any
} & FieldProps;

export default function FormControlFieldComponent({ field, form, meta, ...more }: Props) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return <Form.Control isInvalid={!!error} {...field} value={field.value || ''} {...more}/>;
}
