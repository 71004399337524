import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ConfirmDialogOpts } from './dialogTypes';

type Props = {
    show: boolean;
    opts: ConfirmDialogOpts;
    onResult: (result: boolean) => void;
    onExit: () => void;
};

export default React.memo(function ConfirmDialog(props: Props) {
    const opts = props.opts;

    const onCancel = useCallback(() => {
        props.onResult(false);
    }, [props.onResult]);

    const onConfirm = useCallback(() => {
        props.onResult(true);
    }, [props.onResult])

    return (
        <Modal show={props.show} onHide={onCancel} onExit={props.onExit}>
            <Modal.Header closeButton>{opts.title}</Modal.Header>
            <Modal.Body>{opts.text}</Modal.Body>
            <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={onConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
});
