import { useAuthTokenRefresher } from '@pearlchain/component-lib-common';
import React, { useEffect } from 'react';
import DashboardContainer from './dashboard/DashboardContainer';

export default function ReadonlyDashboard(props:any) {
    const token = useAuthTokenRefresher();
    const url = props.history.location.search;
    const searchParams = new URLSearchParams(url);
    const uuid = searchParams.get('uuid');

    useEffect(() => {
        if (token == null) {
            // redirect the user to the login page if the token is absent
            props.history.replace('/login');
        }
    }, [token, props.push]);

    let properties: { [key: string]: unknown } = {}
    for(var key of searchParams.keys()) {
        if(key != 'uuid'){
            properties[key] = searchParams.get(key);
        }
    }

    return (
        <div className='vh-100' >
            <DashboardContainer
                splitSize={0}
                dashboardUuid={uuid as string}
                rootParent={undefined}
                parentProperties={properties}
                readonly={true}
            />
        </div>
    )
}
