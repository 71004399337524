import React from "react";
import { FormControl } from "react-bootstrap";
import { InputProps } from "../../../types/inputTypes";

export default function TextInput(props: InputProps<string>) {
    return (
        <FormControl
            value={props.value || ''}
            onChange={(e: any) => {
                props.onChange(e.target.value);
            }}
        />
    );
}
