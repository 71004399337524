import { Widget } from "../../types/storeTypes";
import { ReportDataSourceAux, WebComponentRequest } from "../../types/requestTypes";
import { WidgetWebComponentData } from "../../types/webComponentTypes";
import { ReportDataSource } from "core/dashboard/types/widgetTypes";

/** Widget to Web-Component */

export function serializeWidget(widget: Widget<unknown>): WebComponentRequest {
    const data: WidgetWebComponentData = serializeData(widget);
    const dataSources: ReportDataSourceAux[] = serializeDataSources(widget.dataSources)
    const commonConfig = widget.common;
    
    
    return {
        type: 'widget',
        uniqueIdentifier: widget.uuid,
        description: commonConfig.widgetName,
        version: widget.version,
        data: JSON.stringify(data),
        dataSources
    };
}

function serializeData(widget: Widget<unknown>): WidgetWebComponentData {
    const commonConfig = widget.common;
    const config = widget.config;

    return {
        type: widget.type,
        common: {
            pollInterval: commonConfig.pollInterval,
            widgetName: commonConfig.widgetName,
            events: commonConfig.events
        },
        config,
    };
}

function serializeDataSources(dataSources: ReportDataSource[]): ReportDataSourceAux[] {
    return dataSources.map((dataSource) => {
        return (
            {
                definition: dataSource.definition,
                type: dataSource.type,
                uniqueIdentifier: dataSource.uniqueIdentifier,
                params: JSON.stringify(dataSource.params)
            }
        )
    })
}

