import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardsListContainer from './DashboardsListContainer';

type Props = {
    dashboardUuid: string | undefined;
    isVisible: boolean;
    onToggleSideMenu: () => void;
}

export default function SideMenu(props: Props) {
    return (
        <div className="side-menu">
            <div className="side-menu-header">
                { props.dashboardUuid && (
                    <div className="side-menu-header-icon" onClick={props.onToggleSideMenu}>
                        <FontAwesomeIcon icon={['far', 'caret-square-left']} />
                    </div>
                )}
            </div>
            <DashboardsListContainer/>
            <div className="side-menu-footer">
                {
                    // @ts-ignore
                    'v' + PACKAGE_VERSION
                }
            </div>
        </div>
    );
}
