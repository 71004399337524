import React from 'react';

export function getInitialValue(propsName: string, initialValues: any) {
    if(propsName === "reportSource") {
      return initialValues.reportSource;
    } 
    else if (propsName === "orderColumn"){
      return initialValues.orderColumn;
    }
    else {
      var splitString = propsName.split(".");
      var index = splitString[1];
      if(typeof initialValues.sources[index] !== "undefined") {
        return initialValues.sources[index].reportSource;
      }
    }
  }
  
export function getColumns(results: { [key: string]: unknown }[]){
    let columns = []
  
    if(results.length > 0){
      for (var key in results[0]) {
        columns.push({Header: key, accessor: key, Cell: (value: any) => {

          if(typeof value.value === "boolean"){
            return (
              <div className="h-100 d-flex align-items-center p-2">
                <span className="cell-over">{+value.value}</span>
              </div>
            )
          }

          return (
            <div className="h-100 d-flex align-items-center p-2">
                <span className="cell-over">{value.value}</span>
            </div>
          )
        }})
      }
    }
  
    return columns
  }
  
export function getData(results: { [key: string]: any }[]){
    let data = []
  
    for (let key in results) {
      let value = results[key];
      data.push(value)
    }
  
    return data
}
  
  //Auto generate columns in sql query
export function getColumnConfig(keys: string[]){
    let columns = []
  
    for (let key in keys) {
      let column = {
        value: keys[key],
        raw: true,
        count: false,
        sum: false,
        groupBy: false,
        action: false,
        format: undefined
      }
      columns.push(column)
    }
  
    return columns
}
  