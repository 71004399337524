import React from 'react';
import { Modal } from 'react-bootstrap';

import SharingForm from './SharingForm';

type Props = {
    dashboardUuid: string | undefined;
    visible: boolean;
    onHide: () => void;
};

export default function SharingModal(props: Props) {
    return (
        <Modal show={props.visible} onHide={props.onHide}>
            <Modal.Header onHide={props.onHide} closeButton>Shared User Groups</Modal.Header>
            {props.dashboardUuid ?
                <SharingForm
                    dashboardUuid={props.dashboardUuid}
                    onSubmitSuccess={props.onHide}
                /> : null}
        </Modal>
    );
}
