import { combineReducers, Action } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';
import { History } from 'history';
import { reducers as commonReducers } from '@pearlchain/component-lib-common';
import { reducers as stackCommonReducers } from '@pearlchain/stackbase-common';

import dashboard from '../core/dashboard/reducers/dashboard-reducers';
import { StoreState } from '../core/dashboard/types/storeTypes';
import dialog from '../core/dashboard/components/dialog/reducers';

export default (history: History) => {
  const reducers = combineReducers<StoreState>({
    dashboard,
    router: connectRouter(history),
    dialog,
    stackbaseCommon: stackCommonReducers,
    common: commonReducers,
    notifications
  });

  return (state: StoreState|undefined, action: Action): StoreState => {
    state = reducers(state, action);
    return state!;
  }
}
