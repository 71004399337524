import React from 'react';

import { WidgetProps } from './widgetTypes';
import WidgetDrilldownContainer from './WidgetDrilldownContainer';
import WidgetFactory from '../widgets/WidgetFactory';
import { WidgetViewMode } from '../../types/widgetStateTypes';

function WidgetContent(props: WidgetProps) {
    const viewMode = props.state.viewMode;
    const drilldown = props.state.drilldown;

    return (
        <div className="widget-content">
            {viewMode === WidgetViewMode.VIEW && drilldown.length > 0 ? (
                // show the drilldown in-place of the widget content
                <WidgetDrilldownContainer/>
            ) : (
                // show the widget content
                <WidgetFactory {...props} />
            )}
        </div>
    );
}

export default React.memo(WidgetContent);
