import React, { MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { WebComponentBriefResponse } from '../../types/responseTypes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    index: number;
    activeDashboardUuid: string | undefined,
    dashboard: WebComponentBriefResponse,
    isFirst: boolean,
    isLast: boolean,
    onSelect: (uuid: string) => void,
    onSetFavourite: (uuid: string, favourite: boolean) => void,
    onSetIndex: (uuid: string, index: number) => void
}

function noPropagate<T extends any[], R>(f: (...args: T) => R, ...args: T) {
    return (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        f(...args);
    }
}

export default function DashboardsListItem({ index, activeDashboardUuid, dashboard, onSelect, onSetFavourite, onSetIndex, isFirst, isLast }: Props) { 
    const { t } = useTranslation();

    const isFavourite = dashboard.favourite;
    const description = dashboard.description;
    const uuid = dashboard.uniqueIdentifier;
    const active = activeDashboardUuid === uuid;

    return (
        <div className={classNames("flex p-2 align-center cursor-pointer dashboard-item", { active })} onClick={() => onSelect(uuid)}>
            <div className="dashboard-item-icon mr-2 flex-center" onClick={noPropagate(onSetFavourite, uuid, !isFavourite) }>
                <FontAwesomeIcon icon={isFavourite ? faStarSolid : faStarOutline}/>
            </div>
            <span className="dashboard-item-text flex-grow">{t(description)}</span>
            {isFavourite && (
                <div className="flex-column justify-center">
                    <div
                        className={classNames("dashboard-item-icon flex-center", { disabled: isFirst })}
                        onClick={isFirst ? undefined : noPropagate(onSetIndex, uuid, index! - 1)}
                    >
                        <FontAwesomeIcon icon="chevron-up"/>
                    </div>
                    <div
                        className={classNames("dashboard-item-icon flex-center", { disabled: isLast })}
                        onClick={isLast ? undefined : noPropagate(onSetIndex, uuid, index! + 1)}
                    >
                        <FontAwesomeIcon icon="chevron-down"/>
                    </div>
                </div>
            )}
        </div>
    );
}
