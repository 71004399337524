import {v4} from 'uuid';
import { Widget } from '../../types/storeTypes';
import { WidgetEntry } from '../../types/widgetLibraryTypes';

/**
 * Builds an empty widget.
 */

export function buildEmptyWidget(type: string, entry: WidgetEntry): Widget<unknown> {
    const widgetUuid = v4();
    const version = 0;

    if (entry.create) {
        return entry.create(widgetUuid, version);
        
    } else {
        return {
            uuid: widgetUuid,
            version: 0,
            common: {
                widgetName: 'Widget',
                pollInterval: 0,
                events: []
            },
            type,
            config: undefined,
            dataSources: []
        };
    }
}
