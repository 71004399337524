import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import DashboardPropertiesForm from './DashboardPropertiesForm';
import { PropertiesDialogOpts, PropertiesDialogResult } from '../../dialog/dialogTypes';

type Props = {
    show: boolean;
    opts: PropertiesDialogOpts;
    onResult: (result: PropertiesDialogResult) => void;
    onExit: () => void;
};

export default function DashboardPropertiesDialog(props: Props) {
    const { dashboardUuid } = props.opts;

    const onHide = useCallback(() => {
        props.onResult(null);
    }, [props.onResult]);

    return (
        <Modal show={props.show} onExit={props.onExit} enforceFocus={false} onHide={onHide}>
            <Modal.Header onHide={onHide} closeButton>Dashboard Properties</Modal.Header>
            <DashboardPropertiesForm dashboardUuid={dashboardUuid} onHide={onHide} onResult={props.onResult}/>
        </Modal>
    )
}
