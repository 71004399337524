import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FieldArray } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { v4 } from 'uuid';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { SingleSourceConfig, ColumnConfig } from '../singleSourceTypes';

import CommonConfig from '../../common/config/CommonConfig';
import ConfigPanel from '../../common/config/ConfigPanel';
import SingleSourceColumnConfig from './SingleSourceColumnConfig';
import FormFieldErrorFeedback from '../../common/config/formik/FormFieldErrorFeedback';
import { ReportDataSource, WidgetActions } from '../../../../types/widgetTypes';
import WidgetConfigFooter from '../../common/WidgetConfigFooter';
import DataSourceField from '../../common/config/formik/DataSourceField';
import PaginationField from '../../common/config/formik/PaginationField';

/**
 * Single-source widget configuration
 */

type Props = {
    common: WidgetCommonConfig;
    config: SingleSourceConfig | undefined;
    actions: WidgetActions;
    dataSources: ReportDataSource[];
};

type SingleSourceFormValues = SingleSourceConfig & WidgetCommonConfig;

const DEFAULT_VALUES: SingleSourceConfig = {
    reportSource: '',
    columns: [],
    reportParams: undefined,
    limit: -1,
    type: '',
    sqlParams: {},
    orderColumn: '',
    providersHandlers: '',
    pagination: ''
};

function createColumn(): ColumnConfig {
    return {
        value: '',
        raw: true,
        count: false,
        sum: false,
        groupBy: false,
        action: false,
        format: undefined
    };
}

export default function SingleSourceWidgetConfig(props: Props) {
    const { t } = useTranslation();
    const config = props.config;
    const common = props.common;

    const initialValues = useMemo((): SingleSourceFormValues => {
        return Object.assign({}, common, DEFAULT_VALUES, config);
    }, [config, common]);

    function onFormSubmit(values: SingleSourceFormValues) {
        const common: WidgetCommonConfig = {
            pollInterval: values.pollInterval,
            widgetName: values.widgetName,
            events: values.events
        };

        const config: SingleSourceConfig = {
            columns: values.columns,
            reportSource: values.reportSource,
            limit: values.limit,
            reportParams: values.reportParams,
            type: values.type,
            sqlParams: values.sqlParams,
            orderColumn: values.orderColumn,
            providersHandlers: values.providersHandlers,
            pagination: values.pagination
        };

        const uuid = (props.dataSources && props.dataSources.length > 0) ? props.dataSources[0].uniqueIdentifier : v4();
        const dataSources: ReportDataSource[] = [{definition: values.reportSource, type: values.type, uniqueIdentifier: uuid, params: values.sqlParams, orderColumn: values.orderColumn}]

        // configure the widget in the store
        props.actions.configure(common, config, dataSources);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            render={({ handleSubmit, values, isValid, setFieldValue  }) => (
                <form className="config-form" onSubmit={handleSubmit}>
                    <CommonConfig />
                    <PaginationField name="pagination" />
                    <ConfigPanel defaultExpanded header="Single Source">
                        <div className="p-2">
                            <DataSourceField
                                reportSource={values.reportSource}
                                name='reportSource'
                                typeName='type'
                                sqlParamsName="sqlParams"
                                sqlParamsValues={values.sqlParams}
                                setFieldValue={setFieldValue}
                                isSingleSource={true}
                            />
                            <Form.Group>
                                <Form.Label>{t('dashboard.columns')}</Form.Label>
                                <FieldArray
                                    name="columns"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.columns.map((column, index) => {
                                                const name = 'columns.' + index;
                                                return (
                                                    <React.Fragment key={name}>
                                                        <SingleSourceColumnConfig
                                                            name={name}
                                                            value={column}
                                                            reportSource={values.reportSource}
                                                            index={index}
                                                            size={values.columns.length}
                                                            arrayHelpers={arrayHelpers}
                                                        />
                                                        <FormFieldErrorFeedback name={name} />
                                                    </React.Fragment>
                                                );
                                            })}
                                            <Button
                                                onClick={() => {
                                                    arrayHelpers.push(createColumn());
                                                }}
                                            >
                                                + {t('dashboard.column')}
                                            </Button>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                        </div>
                    </ConfigPanel>
                    <WidgetConfigFooter
                        isFormValid={isValid}
                        isCancellable={config != null}
                        onCancel={props.actions.cancelConfig}
                    />
                </form>
            )}
        />
    );
}
