import { createElement } from 'react';
import TextInput from "./TextInput"
import ColorInput from "./ColorInput"
import CheckInput from "./CheckInput"
import NumberInput from './NumberInput';
import { InputType, InputProps } from '../../../types/inputTypes';

const inputs: { [K in InputType]: React.ComponentType<InputProps<any>> } = {
    [InputType.TEXT]: TextInput,
    [InputType.NUMBER]: NumberInput,
    [InputType.COLOR]: ColorInput,
    [InputType.CHECK]: CheckInput,
};

export function renderInput<T>(type: InputType | undefined, props: InputProps<T>) {
    if (type == null) {
        // default input type is text
        type = InputType.TEXT;
    }

    const input = inputs[type];
    return createElement(input, props);
}
