import { DataSourcesResponseDto, RunReportResponse } from '../../types/responseTypes';
import { SourceMappingConfig } from './sourceMappingTypes';
import { invokeDataSource, runQuery, runReport } from '../../utils/requests/requestHandlers';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { Param, paramsToObject } from '../config/datasource/Editor';
import { ReportDataSource } from 'core/dashboard/types/widgetTypes';
import { RESULTS_PER_PAGE } from '../widgets/singlesource/view/singleSourceRequestHandler';
import { SortColumn } from '../widgets/singlesource/view/SingleSourceWidgetGrid';
import { SingleSourceConfig } from '../widgets/singlesource/singleSourceTypes';

const LARGE_SIZE_RESULTS = 1000000;
export const DEFAULT = "Paging with 50 results/page (default)";

export async function fetchSourcesData(
    sourceMapping: SourceMappingConfig,
    evaluator: Evaluator,
    pollInterval: number | undefined,
    refreshCache: boolean
): Promise<RunReportResponse[]> {
    const results: RunReportResponse[] = [];
    const sources = sourceMapping.sources;

    for (let source of sources) {
        if(source.type){
            if(source.type === "SQL") {
                const params = evaluator.evaluateObject(paramsToObject(source.sqlParams as Param[]));
                const request = {sql: source.report, preview: false, offset: 0, count: LARGE_SIZE_RESULTS, params, sortColumns: [], orderColumn: source.orderColumn, pagination:"", pollInterval,refreshCache }
                const result = await runQuery(request);
                const mapDataToReportResponse: RunReportResponse  = {data: result, nextOffset: 0, totalResults: 0 }
                results.push(mapDataToReportResponse)
            }
            if(source.type === "DATASOURCE") {
                const params = evaluator.evaluateObject(source.params);
                const request = { dataSourceName: source.report, functionName: source.providersHandlers ,params}
                const result = await invokeDataSource(request);
                const mapDataToReportResponse = {data: result.result, nextOffset: 0, totalResults: 0 }
                results.push(mapDataToReportResponse)
            }
            if(source.type === "REPORT") {
                const params = evaluator.evaluateObject(source.params);
                const result = await runReport({
                    "report-uuid": source.report,
                    params,
                    count: -1,
                    offset: 0,
                    transforms: []
                });
    
                results.push(result);
            }
        }
    }

    return results;
}


export async function fetchSingleSourceData(sources: ReportDataSource[], 
    evaluator: Evaluator, 
    offset: number | undefined, 
    sortColumns: SortColumn[], 
    orderColumn: string,
    pagination:string,
    pollInterval: number | undefined,
    refreshCache: boolean): Promise<{[key: string]: unknown;}[]> {
    const source = sources[0];

    const params = evaluator.evaluateObject(paramsToObject(source.params as Param[]));
    const request = {sql: source.definition, pollInterval,
                    preview: false, offset: offset || 0, 
                    count: RESULTS_PER_PAGE, params, 
                    sortColumns, orderColumn, pagination: !pagination ? DEFAULT : pagination,
                    refreshCache }
    return await runQuery(request);
}

export async function fetchDataSourceData(config: SingleSourceConfig, 
    evaluator: Evaluator, 
    offset: number | undefined, 
    sortColumns: SortColumn[]): Promise<DataSourcesResponseDto> {
    const params = evaluator.evaluateObject(config.reportParams as any);
    
    const request = { dataSourceName: config.reportSource, 
        functionName: config.providersHandlers, 
        offset:  offset || 0, 
        count: RESULTS_PER_PAGE,
        params,
        sortColumns
    }

    return await invokeDataSource(request);
}