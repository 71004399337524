import React, { useState, useMemo, useEffect } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

import { UserGroupItem } from './types';

type Props = {
    onChooseUserGroup: (userGroup: string) => void;
    dashboardUserGroups: string[];
    userGroups: UserGroupItem[];
};

export default React.memo(function UserGroupChooser(props: Props) {
    const { userGroups, dashboardUserGroups } = props;
    const [userGroup, setUserGroup] = useState('');

    const userGroupsFiltered = useMemo(
        () => userGroups.filter(userGroup => dashboardUserGroups.indexOf(userGroup.uuid) < 0),
        [userGroups, dashboardUserGroups]
    );

    useEffect(() => {
        const fst = userGroupsFiltered[0];
        setUserGroup(fst ? fst.uuid : '');
    }, [userGroupsFiltered]);

    return (
        <InputGroup>
            <FormControl
                as="select"
                value={userGroup}
                onChange={(e: any) => setUserGroup(e.target.value)}
            >
                {userGroupsFiltered.map(ug => (
                    <option key={ug.uuid} value={ug.uuid}>{ug.name}</option>
                ))}
            </FormControl>
            <InputGroup.Append>
                <Button disabled={!userGroup} onClick={() => props.onChooseUserGroup(userGroup)}>
                    Add
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
});
