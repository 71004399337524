import React from 'react';

import { DashboardRootParentState } from '../../types/dashboardTypes';
import DashboardLoader from './DashboardLoader';
import LoadingSpinner from '../LoadingSpinner';
import DashboardComponent from './Dashboard';

/**
 * Loads the dashboard when not yet available
 */

type Props = {
    dashboardUuid: string;
    splitSize: number;
    rootParent: DashboardRootParentState | undefined;
    parentProperties: { [key: string]: unknown }
    readonly: boolean | undefined;
};

export default function DashboardContainer(props: Props) {
    return (
        <div className={`dashboard ${props.readonly ? 'h-100' : ''}`}>
            <DashboardLoader
                dashboardUuid={props.dashboardUuid}
                render={dashboard =>
                    dashboard ? (
                        // the Dashboard was loaded successfully
                        <DashboardComponent {...props} dashboard={dashboard} />
                    ) : (
                        // while the Dashboard is unavailable
                        <LoadingSpinner />
                    )
                }
            />
        </div>
    );
}
