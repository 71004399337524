import React from 'react';

import { DashboardPropertyType } from '../../../types/dashboardPropertyTypes';
import DateInputComponent from '../../input/DateInput';
import TextInput from '../../input/TextInput';

type Props = {
    type: DashboardPropertyType;
    value: unknown;
    onChange: (value: unknown) => void;
};

function renderTextInput(value: unknown, onChange: (value: unknown) => void) {
    return <TextInput value={'' + value} onChange={onChange}/>;
}

function renderDateInput(value: unknown, onChange: (value: unknown) => void) {
    return <DateInputComponent value={value as string | undefined} onChange={onChange}/>
}

function renderUnknownInput(value: unknown, onChange: (value: unknown) => void) {
    return <TextInput value={'' + value} onChange={onChange}/>;
}

function DashboardPropertyInput({ type, value, onChange }: Props) {
    switch (type) {
        case DashboardPropertyType.STRING:
            return renderTextInput(value, onChange);

        case DashboardPropertyType.DATE:
            return renderDateInput(value, onChange);

        default:
            return renderUnknownInput(value, onChange);
    }
}

export default React.memo(DashboardPropertyInput);
