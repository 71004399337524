import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { hasPermission, Permission, getAuthToken, useFetcher } from '@pearlchain/component-lib-common';
import { FilePicker } from 'react-file-picker';
import { info } from 'react-notification-system-redux';

import { WebComponentBriefResponse } from '../../types/responseTypes';
import DashboardsListItem from './DashboardsListItem';
import { canImport, importDashboard } from 'core/dashboard/utils/requests/requestHandlers';
import { useDispatch } from 'react-redux';
import { fetchAvailableDashboards } from 'core/dashboard/actions/dashboard-actions';

type Props = {
    activeDashboardUuid: string | undefined;
    starred: WebComponentBriefResponse[] | undefined;
    owned: WebComponentBriefResponse[] | undefined;
    shared: WebComponentBriefResponse[] | undefined;
    onSelectDashboard: (uuid: string) => void;
    onSetFavourite: (uuid: string, favourite: boolean) => void;
    onSetIndex: (uuid: string, index: number) => void;
    onCreate: (name: string) => void;
    view: string | undefined;
};

function applyFilter(filter: string, webComponents: WebComponentBriefResponse[] | undefined): WebComponentBriefResponse[] {
    if (!webComponents) {
        return [];

    } else {
        if (filter) {
            return webComponents.filter(d => startsWithIgnoreCase(d.description, filter));

        } else {
            return webComponents;
        }
    }
}

function startsWithIgnoreCase(str: string, test: string) {
    const n = test.length;
    if (str.length < n) return false;

    for (let i = 0; i < n; i++) {
        if (test[i].toUpperCase() !== str[i].toUpperCase()) return false;
    }

    return true;
}

export default function DashboardsList(props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [filterInput, setFilterInput] = useState('');
    const [loadingImport, setLoadingImport] = useState(false);

    const { data } = useFetcher(useCallback(() => canImport(), []));

    // apply filters
    const starredFiltered = useMemo(() => applyFilter(filterInput, props.starred), [filterInput, props.starred]);
    const ownedFiltered = useMemo(() => applyFilter(filterInput, props.owned), [filterInput, props.owned]);
    const sharedFiltered = useMemo(() => applyFilter(filterInput, props.shared), [filterInput, props.shared]);

    const translatedStarred = t('web.api.error.status.gatewayTimeout');

    // check the user has permission to create dashboard
    const hasCreatePermission = useMemo(() => hasPermission(Permission.CREATE_DASHBOARD), []);

    function onFilterChanged(value: string) {
        setFilterInput(value);
    }

    function onCreate() {
        props.onCreate(filterInput);
        setFilterInput('');
    }

    function onImportDashboard(file: File) {
        setLoadingImport(true)
        var reader = new window.FileReader();
        reader.readAsText(file);
        reader.onloadend = function() {
            var data = String(reader.result);
            importDashboard(data).finally(() => {
                setLoadingImport(false)
                dispatch(fetchAvailableDashboards(props.view))
                dispatch(info({ message: 'The import finished' }))
            });
            
        }
    }

    function renderGroup(name: string, items: WebComponentBriefResponse[]) {
        if (items.length) {
            const group = [];
            group.push(<div key="divider" className="dashboards-list-divider"/>);
            group.push(<div key="header" className="dashboards-list-header">{ name }</div>);
            for (let i = 0, n = items.length; i < n; i++) {
                group.push(
                    <DashboardsListItem
                        key={i}
                        index={i}
                        activeDashboardUuid={props.activeDashboardUuid}
                        isFirst={i === 0}
                        isLast={i === n - 1}
                        dashboard={items[i]}
                        onSelect={props.onSelectDashboard}
                        onSetFavourite={props.onSetFavourite}
                        onSetIndex={props.onSetIndex}
                    />
                );
            }
            return group;
    
        } else {
            return [];
        }
    }

    return (
        <React.Fragment>
            <div className="dashboards-list-filter flex-content">
                {data && <FilePicker
                    extensions={['json']} 
                    onChange={(fileObject: File) => (onImportDashboard(fileObject))}
                >
                    <div className="mb-3">
                        <Button>
                            {loadingImport ? "Loading import":"Import Dashboard"}
                        </Button>
                    </div>
            </FilePicker>}
                
                <InputGroup>
                    <Form.Control
                        placeholder={t('create.dashboard')}
                        value={filterInput}
                        onChange={(e: any) => onFilterChanged(e.target.value)}
                    />
                    {filterInput.length > 0 && hasCreatePermission ? (
                        <InputGroup.Append>
                            <Button
                                variant="outline-secondary"
                                onClick={onCreate}
                            >
                                {t('common.selector.actions.create')}
                            </Button>
                        </InputGroup.Append>
                    ) : null}
                </InputGroup>
            </div>
            <div className="dashboards-list-items">
                {renderGroup(t('starred.dashboards'), starredFiltered)}
                {renderGroup(t('your.dashboards'), ownedFiltered)}
                {renderGroup(t('other.dashboards'), sharedFiltered)}
            </div>
        </React.Fragment>
    );
}
