import React, { useEffect, useReducer, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

import { StoreState } from '../../../types/storeTypes';
import { getDashboardProperties } from '../../../utils/selector/selectors';
import { FormActionType } from './dashboardPropertiesReducerTypes';
import { PropertiesDialogResult } from '../../dialog/dialogTypes';
import { reducer } from './dashboardPropertiesReducer';
import { validate } from './dashboardPropertiesValidator';
import DashboardPropertyRow from './DashboardPropertyRow';

type Props = {
    dashboardUuid: string;
    onHide: () => void;
    onResult: (result: PropertiesDialogResult) => void;
};

function DashboardPropertiesForm(props: Props) {
    const { t } = useTranslation();
    const storeProperties = useSelector((state: StoreState) => getDashboardProperties(state, props.dashboardUuid)) ?? [];
    const [state, dispatch] = useReducer(reducer, { properties: storeProperties });

    // reset the form when the properties were changed from outside
    const prevRef = useRef(storeProperties);
    useEffect(() => {
        if (prevRef.current !== storeProperties) {
            dispatch({
                type: FormActionType.RESET,
                properties: storeProperties
            });
        }
    }, [storeProperties]);

    // validate the current state
    const errors = useMemo(() => validate(state.properties), [state.properties]);
    const hasErrors = Object.keys(errors).length > 0;

    // convert properties to rows
    const propRows = state.properties.map((prop, index) => {
        return (
            <DashboardPropertyRow
                key={index}
                index={index}
                property={prop}
                error={errors[index]}
                dispatch={dispatch}
            />
        );
    });

    const handleAdd = () => {
        dispatch({
            type: FormActionType.ADD_FIELD
        });
    };

    const handleSubmit = () => {
        if (!hasErrors) {
            props.onResult({
                dashboardUuid: props.dashboardUuid,
                properties: state.properties
            });
        }
    };

    return (
        <>
            <Modal.Body>
                { propRows.length ? propRows : <p>{t('dashboard.no.property')}</p> }
                <Button variant="secondary" onClick={handleAdd}>+ {t('dashboard.property')}</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={hasErrors} onClick={handleSubmit}>{t('accept.btn')}</Button>
            </Modal.Footer>
        </>
    );
}

export default React.memo(DashboardPropertiesForm);
