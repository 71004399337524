import React from 'react';
import { IframeSourceConfig } from '../iframeTypes';
import { WidgetActions } from '../../../../types/widgetTypes';
import { IPropertySource } from 'core/dashboard/evaluator/PropertySource';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';

type Props = {
    config: IframeSourceConfig | undefined;
    refreshCount: number;
    actions: WidgetActions;
    properties: IPropertySource;
};

export default function IframeWidgetView(props: Props) {
    const config = props.config;
    const urlParams = config!.urlParams;
    let url = config!.url;
    let urlQueryString = "?"

    const evaluator = new Evaluator(props.properties);

    urlParams.forEach(function(element) {
        const substitueStr = '' + evaluator.evaluate(element.value, {}, { fallback: '' });
        urlQueryString = urlQueryString + element.name + "=" + substitueStr + "&"
    });

    urlQueryString = urlQueryString.substring(0, urlQueryString.length - 1); 
    url = url + urlQueryString

    return <iframe className='iframe-container'
            title="Iframe Widget"
            src={url}>
        </iframe>
}
