import moment, { Moment } from "moment";
import { DateTimeUnit } from "../../types/dateTimeTypes";
import { parseDateTimeOffset } from "../../utils/dates/dateTimeOffsetParser";
import { STANDARD_DATE_TIME_FORMAT } from "core/dashboard/utils/dates/dateHelpers";

export enum ActionType {
    RESET,
    SET_DATE_TYPE,
    SET_CALENDAR_DATE,
    SET_FORMAT_STRING,
    SET_OFFSET_MODIFIER,
    SET_OFFSET_AMOUNT,
    SET_OFFSET_UNIT
}

export enum InputType {
    CALENDAR='calendar',
    OFFSET='offset'
}

export type State = {
    type: InputType | undefined;
    calendarDate: Moment;
    offsetModifier: string;
    offsetAmount: number;
    offsetUnit: DateTimeUnit;
    formatString: string;
}

export type ResetAction = {
    type: ActionType.RESET;
    input: string;
}

export type SetDateTypeAction = {
    type: ActionType.SET_DATE_TYPE,
    dateType: InputType
}

export type SetFormatStringAction = {
    type: ActionType.SET_FORMAT_STRING,
    format: string;
}

export type SetCalendarDateAction = {
    type: ActionType.SET_CALENDAR_DATE,
    value: Moment;
}

export type SetOffsetModifierAction = {
    type: ActionType.SET_OFFSET_MODIFIER;
    modifier: string;
}

export type SetOffsetAmountAction = {
    type: ActionType.SET_OFFSET_AMOUNT;
    amount: number;
}

export type SetOffsetUnitAction = {
    type: ActionType.SET_OFFSET_UNIT;
    unit: DateTimeUnit;
}

export type Action = ResetAction | SetDateTypeAction | SetFormatStringAction | SetCalendarDateAction | SetOffsetModifierAction | SetOffsetAmountAction | SetOffsetUnitAction;

function handleSetDateType(state: State, action: SetDateTypeAction): State {
    return {
        ...state,
        type: action.dateType
    }
}

function handleSetFormatString(state: State, action: SetFormatStringAction): State {
    return {
        ...state,
        formatString: action.format
    };
}

function handleSetCalendarDate(state: State, action: SetCalendarDateAction): State {
    return {
        ...state,
        type: InputType.CALENDAR,
        calendarDate: action.value
    };
}

function handleSetOffsetModifier(state: State, action: SetOffsetModifierAction): State {
    return {
        ...state,
        type: InputType.OFFSET,
        offsetModifier: action.modifier
    };
}

function handleSetOffsetAmount(state: State, action: SetOffsetAmountAction): State {
    return {
        ...state,
        type: InputType.OFFSET,
        offsetAmount: action.amount
    };
}

function handleSetOffsetUnit(state: State, action: SetOffsetUnitAction): State {
    return {
        ...state,
        type: InputType.OFFSET,
        offsetUnit: action.unit
    };
}

/**
 * Compute the initial state for the pop-over based on the input string passed in
 */
export function init(dateInput: string): State {
    let type: InputType | undefined;
    let calendarDate: Moment = moment();
    let offsetModifier: string = '+';
    let offsetAmount: number = 1;
    let offsetUnit: DateTimeUnit = DateTimeUnit.HOUR;
    let formatString = STANDARD_DATE_TIME_FORMAT;

    const offsetValue = parseDateTimeOffset(dateInput);
    if (offsetValue) {
        const offset = offsetValue.offsets[0];

        if (offset) {
            type = InputType.OFFSET;
            offsetModifier = offset.modifier!;
            offsetAmount = offset.amount;
            offsetUnit = offset.timeUnit;
        }

        if (offsetValue.format) {
            formatString = offsetValue.format;
        }

    } else {
        const m = moment(dateInput);
        if (m.isValid()) {
            type = InputType.CALENDAR;
            calendarDate = m;
        }
    }

    return {
        type,
        calendarDate,
        offsetModifier,
        offsetAmount,
        offsetUnit,
        formatString
    };
}

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.RESET:
            return init(action.input);

        case ActionType.SET_DATE_TYPE:
            return handleSetDateType(state, action);
            
        case ActionType.SET_FORMAT_STRING:
            return handleSetFormatString(state, action);

        case ActionType.SET_CALENDAR_DATE:
            return handleSetCalendarDate(state, action);

        case ActionType.SET_OFFSET_MODIFIER:
            return handleSetOffsetModifier(state, action);

        case ActionType.SET_OFFSET_AMOUNT:
            return handleSetOffsetAmount(state, action);

        case ActionType.SET_OFFSET_UNIT:
            return handleSetOffsetUnit(state, action);

        default:
            return state;
    }
}
