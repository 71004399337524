import { Layout } from "react-grid-layout";

import { Dashboard, DashboardProperty, Widget, WidgetCommonConfig } from "../types/storeTypes";
import { WebComponentBriefResponse, RunReportResponse } from "../types/responseTypes";
import { ReportDataSource } from "../types/widgetTypes";

enum ActionType {
    CREATE_DASHBOARD = 'core.dashboard.CREATE_DASHBOARD',
    DONE_CREATE_DASHBOARD = 'core.dashboard.DONE_CREATE_DASHBOARD',
    DELETE_DASHBOARD = 'core.dashboard.DELETE_DASHBOARD',
    DONE_DELETE_DASHBOARD = 'core.dashboard.DONE_DELETE_DASHBOARD',
    FETCH_AVAILABLE_DASHBOARDS = 'core.dashboard.FETCH_AVAILABLE_DASHBOARDS',
    RECEIVE_AVAILABLE_DASHBOARDS = 'core.dashboard.RECEIVE_AVAILABLE_DASHBOARDS',
    SET_AVAILABLE_STARRED_DASHBOARDS = 'core.dashboard.SET_AVAILABLE_STARRED_DASHBOARDS',
    SET_ACTIVE_DASHBOARD = 'core.dashboard.SWITCH_MAIN_DASHBOARD',
    MARK_DASHBOARD_STARRED = 'core.dashboard.MARK_DASHBOARD_STARRED',
    UNMARK_DASHBOARD_STARRED = 'core.dashboard.UNMARK_DASHBOARD_STARRED',
    MOVE_STARRED_DASHBOARD = 'core.dashboard.MOVE_STARRED_DASHBOARD',
    LOAD_DASHBOARD = 'core.dashboard.REQUEST_LOAD_DASHBOARD',
    RECEIVE_DASHBOARD = 'core.dashboard.RECEIVE_DASHBOARD',
    LOAD_DASHBOARD_WIDGET = 'core.dashboard.REQUEST_LOAD_DASHBOARD_WIDGET',
    RECEIVE_DASHBOARD_WIDGET = 'core.dashboard.RECEIVE_DASHBOARD_WIDGET',
    SET_DASHBOARD_LAYOUT = 'core.dashboard.SET_DASHBOARD_LAYOUT',
    SET_DASHBOARD_PROPERTIES = 'core.dashboard.properties.SET_DASHBOARD_PROPERTIES',
    RENAME_DASHBOARD_NAME = 'core.dashboard.RENAME_DASHBOARD_NAME',
    REFRESH_DASHBOARD_PROPERTIES = 'core.dashboard.properties.REFRESH_DASHBOARD_PROPERTIES',
    SAVE_DASHBOARD_CHANGES = 'core.dashboard.SAVE_DASHBOARD_CHANGES',
    BEGIN_SAVING_CHANGES = 'core.dashboard.BEGIN_SAVING_CHANGES',
    DONE_SAVING_CHANGES = 'core.dashboard.DONE_SAVING_CHANGES',
    ERROR_SAVING_CHANGES = 'core.dashboard.ERROR_SAVING_CHANGES',
    MARK_DASHBOARD_MODIFIED = 'core.dashboard.MARK_DASHBOARD_MODIFIED',
    CREATE_WIDGET = 'core.dashboard.CREATE_WIDGET',
    CONFIGURE_WIDGET = 'core.dashboard.CONFIGURE_WIDGET',
    REMOVE_WIDGET = 'core.dashboard.REMOVE_DASHBOARD_WIDGET',
    REFRESH_DASHBOARD = 'core.dashboard.REFRESH_DASHBOARD',
    CLEAR_STATE = 'core.dashboard.CLEAR_STATE',
    SET_SHOW_PROPERTIES_PANEL = 'core.dashboard.SET_SHOW_PROPERTIES_PANEL',
    SHOW_PAGE = 'core.dashboard.SHOW_OPEN_PAGE',
    HIDE_PAGE = 'core.dashboard.HIDE_OPEN_PAGE',
    SET_FIREBASE_TOKEN = 'core.dashboard.SET_FIREBASE_TOKEN',
    SUBSCRIBE_FIREBASE_TOKEN = 'core.dashboard.SUBSCRIBE_FIREBASE_TOKEN',
    UNSUBSCRIBE_FIREBASE_TOKEN = 'core.dashboard.UNSUBSCRIBE_FIREBASE_TOKEN'
}

export default ActionType;

export function createDashboard(name: string): CreateDashboardAction {
    return { type: ActionType.CREATE_DASHBOARD, name };
}

export function deleteDashboard(dashboardUuid: string, version: number): DeleteDashboardAction {
    return { type: ActionType.DELETE_DASHBOARD, dashboardUuid, version };
}

export function doneDeleteDashboard(dashboardUuid: string): DoneDeleteDashboardAction {
    return { type: ActionType.DONE_DELETE_DASHBOARD, dashboardUuid };
}

export function doneCreateDashboard(dashboard: Dashboard) {
    return { type: ActionType.DONE_CREATE_DASHBOARD, dashboard };
}

export function fetchAvailableDashboards(view: string | undefined) {
    return { type: ActionType.FETCH_AVAILABLE_DASHBOARDS, view };
}

export function receiveAvailableDashboards(starred: WebComponentBriefResponse[], owned: WebComponentBriefResponse[], shared: WebComponentBriefResponse[]): ReceiveAvailableDashboardsAction {
    return { type: ActionType.RECEIVE_AVAILABLE_DASHBOARDS, starred, owned, shared };
}

export function setAvailableStarredDashboards(starred: WebComponentBriefResponse[]): SetAvailableStarredDashboardsAction {
    return { type: ActionType.SET_AVAILABLE_STARRED_DASHBOARDS, starred };
}

export function setActiveDashboard(dashboardUuid: string): SetActiveDashboardAction {
    return { type: ActionType.SET_ACTIVE_DASHBOARD, dashboardUuid };
}

export function loadDashboard(dashboardUuid: string): LoadDashboardAction {
    return { type: ActionType.LOAD_DASHBOARD, dashboardUuid };
}

export function receiveDashboard(dashboardUuid: string, dashboard: Dashboard): ReceiveDashboardAction {
    return { type: ActionType.RECEIVE_DASHBOARD, dashboardUuid, dashboard };
}

export function loadDashboardWidget(dashboardUuid: string, widgetUuid: string): LoadDashboardWidgetAction {
    return { type: ActionType.LOAD_DASHBOARD_WIDGET, dashboardUuid, widgetUuid };
}

export function receiveDashboardWidget(dashboardUuid: string, widget: Widget<unknown>): ReceiveDashboardWidgetAction {
    return { type: ActionType.RECEIVE_DASHBOARD_WIDGET, dashboardUuid, widget };
}

export function renameDashboardName(dashboardUuid: string, name: string): RenameDashboardAction {
    return { type: ActionType.RENAME_DASHBOARD_NAME, dashboardUuid, name };
}

export function markDashboardFavourite(dashboardUuid: string, view: string | undefined): MarkDashboardStarredAction {
    return { type: ActionType.MARK_DASHBOARD_STARRED, dashboardUuid, view };
}

export function unmarkDashboardFavourite(dashboardUuid: string, view: string | undefined): UnmarkDashboardStarredAction {
    return { type: ActionType.UNMARK_DASHBOARD_STARRED, dashboardUuid, view };
}

export function moveDashboardFavourite(dashboardUuid: string, index: number, view: string | undefined): MoveStarredDashboardAction {
    return { type: ActionType.MOVE_STARRED_DASHBOARD, dashboardUuid, index, view };
}

export function setDashboardLayout(dashboardUuid: string, layout: Layout[]): SetDashboardLayoutAction {
    return { type: ActionType.SET_DASHBOARD_LAYOUT, dashboardUuid, layout };
}

export function markDashboardModified(dashboardUuid: string, isModified: boolean): MarkDashboardModifiedAction {
    return { type: ActionType.MARK_DASHBOARD_MODIFIED, dashboardUuid, isModified };
}

export function saveDashboardChanges(dashboardUuid: string): SaveDashboardChangesAction {
    return { type: ActionType.SAVE_DASHBOARD_CHANGES, dashboardUuid };
}

export function beginSavingChanges(dashboardUuid: string): BeginSaveChangesAction {
    return { type: ActionType.BEGIN_SAVING_CHANGES, dashboardUuid };
}

export function doneSavingChanges(dashboardUuid: string, version: number, widgetVersions: { [key: string]: number }): DoneSavingChangesAction {
    return { type: ActionType.DONE_SAVING_CHANGES, dashboardUuid, version, widgetVersions };
}

export function errorSavingChanges(dashboardUuid: string): ErrorSavingChangesAction {
    return { type: ActionType.ERROR_SAVING_CHANGES, dashboardUuid };
}

export function createWidget(dashboardUuid: string, widget: Widget<unknown>): CreateWidgetAction {
    return { type: ActionType.CREATE_WIDGET, dashboardUuid, widget };
}

export function configureWidget(dashboardUuid: string, widgetUuid: string, common: WidgetCommonConfig, config: any, dataSources: ReportDataSource[]): ConfigureWidgetAction {
    return { type: ActionType.CONFIGURE_WIDGET, dashboardUuid, widgetUuid, common, config, dataSources };
}

export function removeWidget(dashboardUuid: string, widgetUuid: string): RemoveWidgetAction {
    return { type: ActionType.REMOVE_WIDGET, dashboardUuid, widgetUuid };
}

export function refreshDashboard(dashboardUuid: string): RefreshDashboardAction {
    return { type: ActionType.REFRESH_DASHBOARD, dashboardUuid };
}

export function setDashboardProperties(dashboardUuid: string, properties: DashboardProperty[]): SetDashboardPropertiesAction {
    return { type: ActionType.SET_DASHBOARD_PROPERTIES, dashboardUuid, properties };
}

export function clearState() {
    return { type: ActionType.CLEAR_STATE };
}

export function setShowPropertiesPanel(show: boolean): SetShowPropertiesPanelAction {
    return { type: ActionType.SET_SHOW_PROPERTIES_PANEL, show };
}

export function showPage(pageUrl: string): ShowPageAction {
    return { type: ActionType.SHOW_PAGE, pageUrl };
}

export function hidePage(): HidePageAction {
    return { type: ActionType.HIDE_PAGE };
}

export function setFirebaseToken(token: string): SetFirebaseTokenAction {
    return { type: ActionType.SET_FIREBASE_TOKEN, token };
}

export function subscribeFirebaseToken(token: string): SubscribeFirebaseTokenAction {
    return { type: ActionType.SUBSCRIBE_FIREBASE_TOKEN, token };
}

export function unsubscribeFirebaseToken(): UnsubscribeFirebaseTokenAction {
    return { type: ActionType.UNSUBSCRIBE_FIREBASE_TOKEN };
}

export type DeleteDashboardAction = {
    type: ActionType.DELETE_DASHBOARD;
    dashboardUuid: string;
    version: number
}

export type DoneDeleteDashboardAction = {
    type: ActionType.DONE_DELETE_DASHBOARD;
    dashboardUuid: string;
}


export type RenameDashboardAction = {
    type: ActionType.RENAME_DASHBOARD_NAME;
    dashboardUuid: string,
    name: string,
}

export type LoadDashboardAction = {
    type: ActionType.LOAD_DASHBOARD;
    dashboardUuid: string;
}

export type SetActiveDashboardAction = {
    type: ActionType.SET_ACTIVE_DASHBOARD;
    dashboardUuid: string;
}

export type ReceiveDashboardAction = {
    type: ActionType.RECEIVE_DASHBOARD;
    dashboardUuid: string;
    dashboard: Dashboard
}

export type LoadDashboardWidgetAction = {
    type: ActionType.LOAD_DASHBOARD_WIDGET;
    dashboardUuid: string;
    widgetUuid: string;
}

export type ReceiveDashboardWidgetAction = {
    type: ActionType.RECEIVE_DASHBOARD_WIDGET;
    dashboardUuid: string;
    widget: Widget<unknown>;
}

export type CreateDashboardAction = {
    type: ActionType.CREATE_DASHBOARD;
    name: string
}

export type RefreshDashboardAction = {
    type: ActionType.REFRESH_DASHBOARD;
    dashboardUuid: string;
}

export type DoneCreateDashboardAction = {
    type: ActionType.DONE_CREATE_DASHBOARD;
    dashboard: Dashboard
}

export type FetchAvailableDashboardsAction = {
    type: ActionType.FETCH_AVAILABLE_DASHBOARDS;
    view: string | undefined;
}

export type ReceiveAvailableDashboardsAction = {
    type: ActionType.RECEIVE_AVAILABLE_DASHBOARDS;
    starred: WebComponentBriefResponse[];
    owned: WebComponentBriefResponse[];
    shared: WebComponentBriefResponse[];
}

export type SetAvailableStarredDashboardsAction = {
    type: ActionType.SET_AVAILABLE_STARRED_DASHBOARDS;
    starred: WebComponentBriefResponse[];
}

export interface SetDashboardPropertiesAction {
    type: ActionType.SET_DASHBOARD_PROPERTIES;
    dashboardUuid: string;
    properties: DashboardProperty[];
}

export type MarkDashboardStarredAction = {
    type: ActionType.MARK_DASHBOARD_STARRED;
    view: string | undefined;
    dashboardUuid: string;
}

export type UnmarkDashboardStarredAction = {
    type: ActionType.UNMARK_DASHBOARD_STARRED;
    view: string | undefined;
    dashboardUuid: string;
}

export type MoveStarredDashboardAction = {
    type: ActionType.MOVE_STARRED_DASHBOARD;
    index: number;
    view: string | undefined;
    dashboardUuid: string;
}

export type SetDashboardLayoutAction = {
    type: ActionType.SET_DASHBOARD_LAYOUT;
    dashboardUuid: string;
    layout: Layout[];
}

export type MarkDashboardModifiedAction = {
    type: ActionType.MARK_DASHBOARD_MODIFIED;
    dashboardUuid: string;
    isModified: boolean;
}

export type DoneSavingChangesAction ={
    type: ActionType.DONE_SAVING_CHANGES;
    dashboardUuid: string;
    version: number,
    widgetVersions: {
        [key: string]: number
    };
}

export type CreateWidgetAction = {
    type: ActionType.CREATE_WIDGET;
    dashboardUuid: string;
    widget: Widget<unknown>;
}

export type ConfigureWidgetAction = {
    type: ActionType.CONFIGURE_WIDGET;
    dashboardUuid: string;
    widgetUuid: string;
    common: WidgetCommonConfig;
    config: any;
    dataSources: ReportDataSource[]
}

export type RemoveWidgetAction = {
    type: ActionType.REMOVE_WIDGET;
    dashboardUuid: string;
    widgetUuid: string;
}

export type SetShowPropertiesPanelAction = {
    type: ActionType.SET_SHOW_PROPERTIES_PANEL;
    show: boolean;
}

export type SaveDashboardChangesAction = {
    type: ActionType.SAVE_DASHBOARD_CHANGES;
    dashboardUuid: string;
}

export type BeginSaveChangesAction = {
    type: ActionType.BEGIN_SAVING_CHANGES;
    dashboardUuid: string;
}

export type ErrorSavingChangesAction = {
    type: ActionType.ERROR_SAVING_CHANGES;
    dashboardUuid: string;
}

export type ShowPageAction = {
    type: ActionType.SHOW_PAGE;
    pageUrl: string;
}

export type HidePageAction = {
    type: ActionType.HIDE_PAGE;
}

export interface SetFirebaseTokenAction {
    type: ActionType.SET_FIREBASE_TOKEN;
    token: string;
}

export type SubscribeFirebaseTokenAction = {
    type: ActionType.SUBSCRIBE_FIREBASE_TOKEN;
    token: string;
}

export type UnsubscribeFirebaseTokenAction = {
    type: ActionType.UNSUBSCRIBE_FIREBASE_TOKEN;
}
