import { FmtInputValue, ConditionalFmtConfig } from "core/dashboard/types/formatConfigTypes";
import { produce } from "immer";

type State = {
    value: ConditionalFmtConfig;
};

export enum ActionType {
    REMOVE_CONDITION_BLOCK,
    CREATE_CONDITION_BLOCK,
    SET_CONDITION_VALUE,
    SET_FORMAT_INPUT_VALUE,
    TOGGLE_FORMAT_VALUE_DYNAMIC,
    REMOVE_FORMAT_VALUE,
    SET_FORMAT_VALUE
}

export type RemoveConditionBlockAction = {
    type: ActionType.REMOVE_CONDITION_BLOCK;
    blockIndex: number;
}

export type CreateConditionBlockAction = {
    type: ActionType.CREATE_CONDITION_BLOCK;
}

export type SetConditionValueAction = {
    type: ActionType.SET_CONDITION_VALUE;
    blockIndex: number;
    condition: string;
}

export type SetFormatValueAction = {
    type: ActionType.SET_FORMAT_VALUE;
    blockIndex: number;
    key: string;
    value: FmtInputValue;
}

export type SetFormatInputValueAction = {
    type: ActionType.SET_FORMAT_INPUT_VALUE;
    blockIndex: number;
    key: string;
    value: unknown;
}

export type ToggleFormatDynamicAction = {
    type: ActionType.TOGGLE_FORMAT_VALUE_DYNAMIC;
    blockIndex: number;
    key: string;
}

export type RemoveFormatValueAction = {
    type: ActionType.REMOVE_FORMAT_VALUE;
    blockIndex: number;
    key: string;
}

export type Action = RemoveConditionBlockAction | CreateConditionBlockAction | SetConditionValueAction | SetFormatValueAction | SetFormatInputValueAction | ToggleFormatDynamicAction | RemoveFormatValueAction;

function getFormatBlockByIndex(value: ConditionalFmtConfig, index: number) {
    if (index < 0) {
        return value.format;

    } else {
        return value.conditions[index].format;
    }
}

const handleRemoveConditionBlock = produce((state: State, action: RemoveConditionBlockAction) => {
    state.value.conditions.splice(action.blockIndex, 1);
});

const handleCreateConditionBlock = produce((state: State) => {
    state.value.conditions.push({
        condition: '',
        format: {}
    });
});

const handleSetConditionValue = produce((state: State, action: SetConditionValueAction) => {
    const condition = state.value.conditions[action.blockIndex];
    if (!condition) return;
    condition.condition = action.condition;
});

const handleSetFormatValue = produce((state: State, action: SetFormatValueAction) => {
    const block = getFormatBlockByIndex(state.value, action.blockIndex);
    block[action.key] = action.value;
});

const handleSetFormatInputValue = produce((state: State, action: SetFormatInputValueAction) => {
    const block = getFormatBlockByIndex(state.value, action.blockIndex);
    const field = block[action.key];
    if (!field) return;

    field.value = action.value;
});

const handleToggleFormatDynamic = produce((state: State, action: ToggleFormatDynamicAction) => {
    const block = getFormatBlockByIndex(state.value, action.blockIndex);
    const field = block[action.key];
    if (!field) return;

    if (field.dynamic) {
        block[action.key] = { dynamic: false, value: undefined };

    } else {
        block[action.key] = { dynamic: true, value: '' };
    }
});

const handleRemoveFormatValue = produce((state: State, action: RemoveFormatValueAction) => {
    const block = getFormatBlockByIndex(state.value, action.blockIndex);
    delete block[action.key];
});

export function init(value: ConditionalFmtConfig | undefined): State {
    if (value == null) {
        value = {
            conditions: [],
            format: {}
        };
    }
    
    return {
        value
    };
}

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.REMOVE_CONDITION_BLOCK:
            return handleRemoveConditionBlock(state, action);

        case ActionType.CREATE_CONDITION_BLOCK:
            return handleCreateConditionBlock(state);

        case ActionType.SET_CONDITION_VALUE:
            return handleSetConditionValue(state, action);

        case ActionType.SET_FORMAT_VALUE:
            return handleSetFormatValue(state, action); 

        case ActionType.SET_FORMAT_INPUT_VALUE:
            return handleSetFormatInputValue(state, action);

        case ActionType.TOGGLE_FORMAT_VALUE_DYNAMIC:
            return handleToggleFormatDynamic(state, action);

        case ActionType.REMOVE_FORMAT_VALUE:
            return handleRemoveFormatValue(state, action);

        default:
            return state;
    }
}
