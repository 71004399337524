import { FieldMappingConfig, FieldType } from "../sourceMappingTypes";
import { MultiMap } from "../../../utils/MultiMap";
import { createFieldValueResolver, FieldValueResolver } from "./fieldValueResolver";
import { Evaluator } from "core/dashboard/evaluator/Evaluator";

export const CHAR_SEP = '-';

function aggSum(agg: number | undefined, value: unknown): number | undefined {
    const c = typeof value === 'number' ? value : 0;
    return agg ? agg + c : c;
}

function aggCount(agg: number | undefined, value: unknown): number {
    const c = value ? 1 : 0;
    return agg ? agg + c : c;
}

function aggregate(type: FieldType, agg: number | undefined, value: unknown) {
    if (type === FieldType.SUM) {
        return aggSum(agg, value);
    } else if (type === FieldType.COUNT) {
        return aggCount(agg, value);
    }
}

type FieldConfigResolved = {
    resolver: FieldValueResolver
} & FieldMappingConfig;

export function mapData(data: { [key: string]: unknown }[], fields: FieldMappingConfig[], isSourceAdvanced: boolean) {
    const groupFields: FieldConfigResolved[] = [];
    const aggFields: FieldConfigResolved[] = [];
    const remainingFields: FieldConfigResolved[] = [];

    for (let field of fields) {
        const resolver = createFieldValueResolver(field.from);
        const fieldResolved = { resolver, ...field };

        switch (field.type) {
            case FieldType.GROUP_BY:
                groupFields.push(fieldResolved);
                break;
            case FieldType.COUNT:
                if(isSourceAdvanced){
                    remainingFields.push(fieldResolved);
                    break;
                }
            case FieldType.SUM:
                aggFields.push(fieldResolved);
                break;
            default:
                remainingFields.push(fieldResolved);
        }
    }

    const groups = new MultiMap<unknown, { [key: string]: unknown }>();
    const result: { [key: string]: unknown }[] = [];

    for (let row of data) {
        const groupKeys: unknown[] = [];
        let selection: { [key: string]: unknown } = {};

        for (let field of groupFields) {
            const key = field.resolver(row);
            groupKeys.push(key);

            if (field.to in selection) {
                selection[field.to] += CHAR_SEP + key;
            } else {
                selection[field.to] = key;
            }
        }

        if (groupKeys.length || aggFields.length) {
            const groupSel = groups.get(groupKeys);
            if (groupSel) {
                selection = groupSel;
            } else {
                groups.set(groupKeys, selection);
                result.push(selection);
            }
        } else {
            result.push(selection);
        }

        for (let field of aggFields) {
            const value = field.resolver(row);
            const agg = selection[field.to] as number | undefined;
            selection[field.to] = aggregate(field.type, agg, value);
        }

        for (let field of remainingFields) {
            if(field.to === "series") {
                selection[field.to] = field.from;
            } else {
                selection[field.to] = field.resolver(row);
            }
        }
    }

    return result;
}
