import { DateTimeUnit } from "./dateTimeTypes";

export enum AxisType {
    LINEAR='linear',
    CATEGORY='category',
    LOGARITHMIC='logarithmic',
    TIME='time',
    STEPPED='stepped-line'
}

export type MinMaxConfig = {
    min: string,
    max: string
};

export type AxisConfig = {
    label?: string,
    column?: string,
    type: AxisType,
    timeUnit?: DateTimeUnit,
    beginAtZero?: boolean,
    stacked?: boolean
}

export type ReportParamConfig = { name: string, value: string };
