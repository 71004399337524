import React, { useMemo, useCallback, useState } from 'react';

import { PieChartConfig } from '../pieChartTypes';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';

import { fetchSourcesData } from '../../../sourceMapping/fetchSourcesData';
import { useWidgetDataFetcher } from '../../../../utils/hooks/useWidgetDataFetcher';
import PieChartComponent from './PieChartComponent';
import { RunReportResponse } from '../../../../types/responseTypes';
import { mapSourcesData } from '../../../sourceMapping/mapper/sourceMapper';
import { sourcesMappingFromConfig, KEY_SERIES } from '../pieChartHelpers';
import { createActionHandler } from 'core/dashboard/actionhandler/actionHandler';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { IPropertySource } from 'core/dashboard/evaluator/PropertySource';
import { initialWidgetErrorState } from '../../singlesource/view/SingleSourceWidgetView';
import WidgetError from 'core/dashboard/components/widget/WidgetError';
import { WidgetCommonConfig } from 'core/dashboard/types/storeTypes';

type Props = {
    config: PieChartConfig;
    refreshCount: number;
    actions: WidgetActions;
    data: RunReportResponse[] | undefined;
    properties: IPropertySource;
    common: WidgetCommonConfig;
};

function PieChartWidgetView(props: Props) {
    let { actions, refreshCount, config, data, properties, common } = props;
    const evaluator = useMemo(() => new Evaluator(properties), [properties]);
    const [widgetError, setWidgetError] = useState(initialWidgetErrorState);

    // create a widget action handler
    const actionHandler = useMemo(() => createActionHandler(actions, evaluator), [actions, evaluator]);

    // resolve the source-mapping for the widget config
    const sourceMapping = useMemo(() => sourcesMappingFromConfig(config), [config]);

    const isSourceAdvanced = config.sourceMapping ? true : false;

    // Setup the data-fetcher to update the backing data whenever the "refreshCount" was incremented
    const requestHandler = useCallback(() => fetchSourcesData(sourceMapping, evaluator, common.pollInterval), [sourceMapping, evaluator]);
    useWidgetDataFetcher(refreshCount, requestHandler, actions, handleWidgetError);

    // apply mapping transform
    const dataMapped = useMemo(() => data && mapSourcesData(data, sourceMapping, KEY_SERIES, isSourceAdvanced), [data, sourceMapping]);

    function handleWidgetError(hasError: boolean, error: string){
        setWidgetError({hasError, error})
    }

    if(widgetError.hasError){
        return(
            <WidgetError error={widgetError.error} />
        )
    }
    else{
        return dataMapped ? (
            <PieChartComponent
                data={dataMapped}
                config={config}
                actionHandler={actionHandler}
                evaluator={evaluator}
                actions={actions}
            />
        ) : null;
    }
}

export default React.memo(PieChartWidgetView);
