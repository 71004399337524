
export type ExportFormat = {
    resultType: string;
    fileExt: string;
}

export const EXPORT_FORMATS: ExportFormat[] = [
    { resultType: 'CSV', fileExt: '.csv' },
    { resultType: 'XLS', fileExt: '.xls' }
];
