import React from 'react';
import { SourceConfig } from '../sourceMappingTypes';
import { Form, Col, FormGroup, InputGroup, CloseButton } from 'react-bootstrap';
import { Field, FastField } from 'formik';

import TextInput from '../../config/formik/TextInput';
import FormFieldErrorFeedback from '../../widgets/common/config/formik/FormFieldErrorFeedback';
import ReportSourceInput from '../../config/formik/ReportSourceInput';
import ReportParamsInput from '../../config/formik/ReportParamsInput';
import { validateRequired } from '../../../utils/validate/validators';

type Props = {
    name: string;
    index: number;
    sources: SourceConfig[];
    onRemoved: (() => void) | undefined;
};

function validateName(sources: SourceConfig[], index: number) {
    return (name: string) => {
        if (!name) return 'Id cannot be empty';
        for (let i = 0, n = sources.length; i < n; i++) {
            if (index !== i && sources[i].name === name) {
                return 'Duplicate source name';
            }
        }
    };
}

export default function SourceConfig({ name, index, sources, onRemoved }: Props) {
    const source = sources[index];
    const reportSource = source.report;

    return (
        <div className="source-config">
            <Form.Row>
                <Col md={6}>
                    <FormGroup>
                        <Form.Label>Name</Form.Label>
                        <Field
                            validate={validateName(sources, index)}
                            name={name + '.name'}
                            component={TextInput}
                        />
                        <FormFieldErrorFeedback name={name + '.name'} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Form.Label>Report</Form.Label>
                        <InputGroup>
                            <FastField
                                validate={validateRequired}
                                name={name + '.report'}
                                component={ReportSourceInput}
                            />
                            <InputGroup.Append>
                                <Field
                                    name={name + '.params'}
                                    reportSource={reportSource}
                                    component={ReportParamsInput}
                                />
                            </InputGroup.Append>
                            <FormFieldErrorFeedback name={name + '.report'} />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Form.Row>
            {onRemoved && <CloseButton onClick={onRemoved} />}
        </div>
    );
}
