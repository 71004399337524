import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import { WidgetActions } from '../../../types/widgetTypes';
import { AccessType } from '../../../types/responseTypes';

import ExportDataModal from '../export/ExportDataModal';
import { useDialog } from '../../dialog/useDialog';
import { DialogType, ConfirmDialogOpts } from '../../dialog/dialogTypes';
import { DashboardContext } from '../../dashboard/context';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';
import { DashboardContext as DashboardContextType } from "../../../types/dashboardTypes";

type Props = {
    isShowing: boolean;
    widgetUuid: string;
    actions: WidgetActions;
    onHide: (e: any) => void;
};

const removeDialogOpts: ConfirmDialogOpts = {
    type: DialogType.CONFIRM,
    title: 'Remove widget',
    text: 'Are you sure you want to remove this widget?'
};

const widgetIsIframe = (ctx: DashboardContextType, widgetUuid: string ) => {
    const widgets = ctx.state.dashboard.widgets;
    if(widgets[widgetUuid].type === "IFRAME_TYPE"){
        return true;
    }
    else{
        return false;
    }
}

export default React.memo(function WidgetHeaderOptionsDropdown({ isShowing, widgetUuid, actions, onHide }: Props) {
    const { t } = useTranslation();

    const [isExportDataShowing, setExportDataShowing] = useState(false);

    const ctx = useContext(DashboardContext);
    const isReadonly = ctx.state.dashboard.accessType === AccessType.READ_ONLY;
    const isIframe = widgetIsIframe(ctx, widgetUuid);

    useEffect(() => {
        if (isShowing) {
            document.body.addEventListener('click', onHide);
            return () => {
                document.body.removeEventListener('click', onHide);
            };
        }
    }, [onHide, isShowing]);

    const onConfigure = useCallback(() => {
        actions.setViewMode(WidgetViewMode.CONFIG);
    }, []);

    const onToggleExportData = useCallback(() => {
        setExportDataShowing(!isExportDataShowing);
    }, [isExportDataShowing]);

    const dialogId = 'widget-remove-' + widgetUuid;
    const showConfirmRemoveDialog = useDialog<DialogType.CONFIRM>(dialogId, (result) => {
        result && actions.remove();
    });

    const onRemove = useCallback(() => {
        showConfirmRemoveDialog(removeDialogOpts);
    }, [showConfirmRemoveDialog]);

    const items: React.ReactElement[] = [];

    if (!isReadonly) {
        items.push(
            <Dropdown.Item key="item-configure" onClick={onConfigure}>
                {t('config.title')}
            </Dropdown.Item>
        );
    }

    if(!isIframe){
        items.push(
            <Dropdown.Item key="item-export" onClick={onToggleExportData}>
                {t('export.data')}
            </Dropdown.Item>
        );
    }

    if (!isReadonly) {
        items.push(
            <Dropdown.Item key="item-remove" onClick={onRemove}>
                {t('remove.widget')}
            </Dropdown.Item>
        );
    }

    return (
        <>
            <ExportDataModal isShowing={isExportDataShowing} onHide={onToggleExportData} />
            <Dropdown show={isShowing} alignRight>
                <Dropdown.Menu>
                    {items}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
});
