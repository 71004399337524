import { all } from 'redux-saga/effects';
import { sagas as commonSagas } from '@pearlchain/component-lib-common';

import dashboardSagas from '../core/dashboard/sagas/dashboard-sagas';

export default function *() {
    yield all([
        dashboardSagas(),
        commonSagas() 
    ]);
}
