import React, { useMemo, useReducer, useEffect, Dispatch, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dashboard } from '../../types/storeTypes';
import { DashboardContext, DashboardRootParentState, DashboardInitParams, DashboardActionType, DashboardAction, DashboardState } from "../../types/dashboardTypes";
import { DashboardContext as Context } from './context';
import { reducer, initialState } from './dashboardReducer';
import DashboardLayoutComponent from './DashboardLayout';
import DashboardPropertyValues from './DashboardPropertyValues';
import { createEvaluationContext, addGlobalPropertiesToconfig } from 'core/dashboard/utils/resolver/evaluationContext';

/**
 * Manages the dashboard context, and renders the layout
 */

type InnerProps = {
    state: DashboardState;
    rootParent?: DashboardRootParentState;
    dispatch: Dispatch<DashboardAction>;
    splitSize: number;
    parentProperties: {
        [key: string]: unknown
    };
}

function DashboardInner(props: InnerProps) {
    const { state, rootParent, parentProperties, dispatch, splitSize } = props;

    // Construct the Dashboard context object. These properties will be available to all children in the tree.
    // Be careful to only include properties that don't change often to avoid unnecessay renders.
    const reduxDispatch = useDispatch();
    const properties = Object.assign({}, parentProperties, state.properties);
    const propertyConfigs = state.dashboard.properties;
    const evaluationContext = useMemo(() =>
        createEvaluationContext(properties, propertyConfigs, reduxDispatch),
    [properties, propertyConfigs, reduxDispatch]);

    const propertyConfigsWithGlobalProperties = addGlobalPropertiesToconfig(propertyConfigs,evaluationContext)

    // fallback to defaults from the dashboard config
    for (const propertyConfig of propertyConfigs) {
        const name = propertyConfig.name;
        if (!(name in properties)) {
            properties[name] = propertyConfig.val;
        }
    }

    const ctx = useMemo((): DashboardContext => {
        return {
            dispatch,
            state,
            parent: rootParent,
            propertyConfigs: propertyConfigsWithGlobalProperties,
            evaluationContext
        };
    }, [dispatch, state, rootParent]);

    return (
        <Context.Provider value={ctx}>
            <DashboardPropertyValues/>
            <DashboardLayoutComponent
                dashboard={state.dashboard}
                splitSize={splitSize}
                hasParent={rootParent != null}
                layout={state.layout}
            />
        </Context.Provider>
    );
}

const DashboardInnerMemo = React.memo(DashboardInner);

/**
 * Manages the dashboard internal state
 */

type Props = {
    dashboard: Dashboard;
    rootParent?: DashboardRootParentState;
    splitSize: number;
    parentProperties: {
        [key: string]: unknown;
    };
};

function Dashboard(props: Props) {
    const { dashboard, rootParent: parent, parentProperties, splitSize } = props;

    // setup the dashboard state reducer
    const params: DashboardInitParams = { dashboard };
    const [state, dispatch] = useReducer(reducer, params, initialState);

    // dashboard config changed
    const prevDashboard = useRef(dashboard);
    useEffect(() => {
        if (prevDashboard.current !== dashboard) {
            prevDashboard.current = dashboard;
            dispatch({ type: DashboardActionType.SET_DASHBOARD, dashboard });
        }
    });

    return (
        <DashboardInnerMemo
            state={state}
            rootParent={parent}
            dispatch={dispatch}
            splitSize={splitSize}
            parentProperties={parentProperties}
        />
    );
}

export default React.memo(Dashboard);
