import { 
    WidgetAction,  
    WidgetViewMode,
    WidgetActionType,
    WidgetInitParams,
    WidgetState,
    WidgetInitAction,
    WidgetPushDrilldownAction,
    WidgetPopDrilldownAction,
    WidgetSetLoadingAction,
    WidgetSetDataAction,
    WidgetSetViewModeAction, 
    WidgetSateError,
    TriggerRefreshAction } from 'core/dashboard/types/widgetStateTypes';

export function initialState({ widget }: WidgetInitParams): WidgetState {
    let viewMode = WidgetViewMode.VIEW;

    // show the config if the widget not yet configured
    if (widget.config == null || widget.common == null) {
        viewMode = WidgetViewMode.CONFIG;
    }

    // the initial widget state
    return {
        widget,
        isLoading: false,
        refreshCount: 0,
        viewMode,
        data: undefined,
        drilldown: [],
        cacheRefreshCount: 0
    };
}

export function reducer(state: WidgetState, action: WidgetAction): WidgetState {
    switch (action.type) {
        case WidgetActionType.INIT:
            return handleInit(state, action);
        case WidgetActionType.PUSH_DRILLDOWN:
            return handlePushDrilldown(state, action);
        case WidgetActionType.POP_DRILLDOWN:
            return handlePopDrilldown(state, action);
        case WidgetActionType.TRIGGER_REFRESH:
            return handleTriggerRefresh(state, action);
        case WidgetActionType.SET_LOADING:
            return handleSetLoading(state, action);
        case WidgetActionType.STATE_ERROR:
            return handleStateError(state, action);
        case WidgetActionType.SET_DATA:
            return handleSetData(state, action);
        case WidgetActionType.SET_VIEW_MODE:
            return handleSetViewMode(state, action);
        case WidgetActionType.CANCEL_CONFIG:
            return handleCancelConfig(state);
        default:
            return state;
    }
}

function handleInit(state: WidgetState, action: WidgetInitAction): WidgetState {
    const nextState = initialState(action.params);
    
    // don't change the refresh-count
    nextState.refreshCount = state.refreshCount;
    return nextState;
}

function handlePushDrilldown(state: WidgetState, action: WidgetPushDrilldownAction): WidgetState {
    const drilldown = state.drilldown.concat(action.drilldown);
    return { ...state, drilldown };
}

function handlePopDrilldown(state: WidgetState, action: WidgetPopDrilldownAction): WidgetState {
    const drilldown = state.drilldown.slice(0, state.drilldown.length - action.n);
    return { ...state, drilldown };
}

function handleTriggerRefresh(state: WidgetState, action:TriggerRefreshAction): WidgetState {
    if(action.cacheRefresh) {
        return {
            ...state,
            drilldown: [],
            refreshCount: state.refreshCount + 1,
            cacheRefreshCount: state.cacheRefreshCount + 1
        }
    }
    
    return {
        ...state,
        drilldown: [],
        refreshCount: state.refreshCount + 1
    };
}

function handleSetLoading(state: WidgetState, action: WidgetSetLoadingAction): WidgetState {
    return {
        ...state,
        isLoading: action.loading
    };
}

function handleStateError(state: WidgetState, action: WidgetSateError): WidgetState {
    return {
        ...state,
        hasStateError: action.error
    };
}

function handleSetData(state: WidgetState, action: WidgetSetDataAction): WidgetState {
    return {
        ...state,
        isLoading: false,
        data: action.data
    };
}

function handleSetViewMode(state: WidgetState, action: WidgetSetViewModeAction): WidgetState {
    return {
        ...state,
        data: undefined,
        drilldown: [],
        viewMode: action.mode
    };
}

function handleCancelConfig(state: WidgetState): WidgetState {
    return {
        ...state,
        viewMode: WidgetViewMode.VIEW
    };
}
