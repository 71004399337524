import { Column } from '@pearlchain/component-lib-react-pg';

import { ColumnConfig } from '../singleSourceTypes';
import { getColumnAlias, getColumnTitle } from './gridHelpers';

/**
 * Map column-config to grid columns
 */

export function configToColumns(columnConfigs: ColumnConfig[]): Column[] {
    return columnConfigs.map((cfg, i) => {
        const alias = getColumnAlias(cfg, i);
        const title = getColumnTitle(cfg, i);
        const format = cfg.format || false;
        return {
            _key: alias,
            title,
            width: 100,
            autoSize: true,
            translateValue: cfg.translate,
            format
        };
    });
}
