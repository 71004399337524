import { getLoginName } from '@pearlchain/component-lib-common';

import { Dashboard, Widget } from "../../types/storeTypes";
import { DashboardWebComponentData } from "../../types/webComponentTypes";
import { WebComponentRequest } from '../../types/requestTypes';
import { WebComponentBriefResponse, AccessType } from '../../types/responseTypes';

/** Dashboard to Web-Component */

export function dashboardToWebComponentBrief(dashboard: Dashboard): WebComponentBriefResponse {
    const description = dashboard.name;
    const uniqueIdentifier = dashboard.uuid;
    const version = dashboard.version;

    return {
        type: 'dashboard',
        "access-type": AccessType.WRITE,
        creationDate: '',
        description,
        favourite: false,
        modificationDate: '',
        order: null,
        owner: getLoginName()!,
        uniqueIdentifier,
        version,
        dataSources: []
    };
}

export function serializeDashboard(dashboard: Dashboard): WebComponentRequest {
    const data: DashboardWebComponentData = serializeData(dashboard);

    return {
        type: 'dashboard',
        uniqueIdentifier: dashboard.uuid,
        description: dashboard.name,
        version: dashboard.version,
        data: JSON.stringify(data),
        dataSources: []
    };
}

function serializeData(dashboard: Dashboard): DashboardWebComponentData {
    const layout = dashboard.layout;
    const widgetRefs = widgetsToRefs(dashboard.widgets);
    const properties = dashboard.properties;

    return {
        cols: layout.cols,
        rowHeight: layout.rowHeight,
        layout: layout.layout,
        widgets: widgetRefs,
        properties
    };
}

function widgetsToRefs(widgets: { [widgetUuid: string]: Widget<unknown> | undefined }): { id: string }[] {
    const refs: { id: string }[] = [];
    for (const widgetUuid in widgets) {
        refs.push({ id: widgetUuid });
    }

    return refs;
}
