import React from 'react';
import { FastField } from 'formik';
import EvaluatableFieldComponent from 'core/dashboard/components/form/formik/EvaluatableFieldComponent';
import { Suggestion } from 'core/dashboard/components/form/input/AutoSuggestInput';

type Props = {
    name: string;
    showPreview?: boolean;
    placeholder?: string;
};

const suggestions: Suggestion[] = [
    {
        name: 'value'
    },
    {
        name: 'row'
    },
    {
        name: 'index'
    }
];

export default function EvaluatableInputField(props: Props) {
    return (
        <FastField
            name={props.name}
            component={EvaluatableFieldComponent}
            suggestions={suggestions}
            placeholder={props.placeholder}
            showPreview={props.showPreview}
        />
    );
}
