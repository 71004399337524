import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCachedFetcher } from '@pearlchain/component-lib-common';
import { PluginsManager } from '@pearlchain/component-lib-plugins-core';

import { RequestId } from 'core/dashboard/utils/requests/requestId';
import { listAvailablePlugins } from 'core/dashboard/utils/requests/requestHandlers';
import LoadingSpinner from '../LoadingSpinner';
import { WebPluginDefinitions } from 'core/dashboard/types/responseTypes';

type Props = {
    children: React.ReactElement;
}

function registerPlugins(plugins: WebPluginDefinitions) {
    const pluginsManager = new PluginsManager();
    window.plugins = pluginsManager;
    (window as any).react = React;
    (window as any).reactDom = ReactDOM;

    for (let plugin of Object.values(plugins)) {
        console.log(`plugin [${plugin.id}] registered`)
        pluginsManager.addPlugin(plugin.id, plugin.src);           
    }
}

export default function DashboardPluginsLoader(props: Props) {
    const { data: plugins } = useCachedFetcher(listAvailablePlugins, RequestId.AVAILABLE_PLUGINS);
    const [pluginsLoaded, setPluginsLoaded] = useState(false);

    useEffect(() => {
        if (plugins && !pluginsLoaded) {
            console.log('...registering plugins');
            registerPlugins(plugins);
            setPluginsLoaded(true);
        }
    }, [plugins, pluginsLoaded]);

    // wait for the plugins to be loaded before rendering the Dashboard
    if (pluginsLoaded) {
        return React.Children.only(props.children);
    } else {
        return <LoadingSpinner/>;
    }
}
