import React from 'react';
import { FastField, FieldArray } from 'formik';
import { Form, Col, Button, CloseButton } from 'react-bootstrap';

import { PropertyConfig } from 'core/dashboard/types/actionHandlerTypes';
import EvaluatableInputField from './EvaluatableInputField';
import FormControlFieldComponent from './FormControlFieldComponent';
import FormFieldErrorFeedback from './FormFieldErrorFeedback';

type Props = {
    name: string;
    properties: PropertyConfig[];
};

function createInitialProperty(): PropertyConfig {
    return {
        name: '',
        value: ''
    };
}

function validatePropertyName(input: string) {
    return input ? undefined : 'Property name required';
}

export default function ActionPropertiesField(props: Props) {
    const name = props.name;
    const properties = props.properties;

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div>
                    {properties.map((propertyConfig, index) => {
                        const propName = name + '.' + index;
                        return (
                            <Form.Row key={index} className="mb-2">
                                <Col>
                                    <FastField
                                        name={propName + '.name'}
                                        validate={validatePropertyName}
                                        component={FormControlFieldComponent}
                                        placeholder="Name"
                                    />
                                    <FormFieldErrorFeedback name={propName + '.name'} />
                                </Col>
                                <Col>
                                    <EvaluatableInputField
                                        name={propName + '.value'}
                                        placeholder="Value"
                                        showPreview={false}
                                    />
                                </Col>
                                <CloseButton onClick={arrayHelpers.handleRemove(index)}/>
                            </Form.Row>
                        );
                    })}
                    <Button onClick={() => arrayHelpers.push(createInitialProperty())}>
                        Add Param
                    </Button>
                </div>
            )}
        />
    );
}
