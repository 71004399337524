import React from 'react';
import { FastField, FieldProps } from "formik";
import { FormGroup, FormLabel, Form, Col } from 'react-bootstrap';
import TextInput from './TextInput';

export default function Vector3DInput(props: FieldProps) {
    const { field } = props;

    return (
        <Form.Row>
            <Col>
                <div className="field-inline">
                    <div className="field-inline-label">X</div>
                    <FastField name={field.name + ".x"} render={TextInput}/>
                </div>
            </Col>
            <Col>
                <div className="field-inline">
                    <div className="field-inline-label">Y</div>
                    <FastField name={field.name + ".y"} component={TextInput}/>
                </div>
            </Col>
            <Col>
                <div className="field-inline">
                    <div className="field-inline-label">Z</div>
                    <FastField name={field.name + '.z'} component={TextInput}/>
                </div>
            </Col>
        </Form.Row>
    )
}
