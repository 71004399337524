import React from "react";
import { FormCheck } from "react-bootstrap";
import { InputProps } from "../../../types/inputTypes";

export default function CheckInput(props: InputProps<boolean>) {
    return (
        <FormCheck
            checked={props.value ?? false}
            onChange={(e: any) => props.onChange(e.target.checked)}
        />
    );
}
