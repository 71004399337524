export const MSG_TARGET_PIECHART_NAME = 'Name of the pie-chart segment';
export const MSG_TARGET_PIECHART_VALUE = 'Area of the pie-chart segment';

export const MSG_TARGET_X_AXIS = 'Value on the X axis';
export const MSG_TARGET_Y_AXIS = 'Value on the Y axis';
export const MSG_TARGET_SERIES = 'Name of the series';

export const MSG_SERIES_NAME_TT = 'The name of the chart series (can be blank)';
export const MSG_GROUP_NAME_TT = 'The name of the group, within the series (can be blank)';
export const MSG_SOURCE_NAME_TT = 'The source to use to map data from';
