import { resolvePropertyValue } from "../properties/dashboardProperties";
import { DashboardProperty } from "../types/storeTypes";

export interface IPropertySource {
    getProperty(key: string): unknown;
    hasProperty(key: string): boolean;
    listProperties(): string[];
}

export class PropertySource implements IPropertySource {
    private propertyValues: { [key: string]: unknown };
    private propertyConfigs: { [key: string]: DashboardProperty };

    constructor(
        propertyValues: { [key: string]: unknown },
        propertyConfigs: DashboardProperty[]
    ) {
        this.propertyValues = propertyValues;
        this.propertyConfigs = this.computePropertyConfigsLookupByName(propertyConfigs);
    }

    getProperty(key: string) {
        if (this.hasProperty(key)) {
            const config = this.propertyConfigs[key];
            const value = this.propertyValues[key];
            return resolvePropertyValue(config.type, value);
        }
    }

    hasProperty(key: string) {
        return this.propertyValues.hasOwnProperty(key)
            && this.propertyConfigs.hasOwnProperty(key);
    }

    listProperties() {
        return Object.keys(this.propertyValues);
    }

    private computePropertyConfigsLookupByName(propertyConfigs: Iterable<DashboardProperty>) {
        const result: { [key: string]: DashboardProperty } = {};
        for (const propertyConfig of propertyConfigs) {
            result[propertyConfig.name] = propertyConfig;
        }
        return result;
    }
}
