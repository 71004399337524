import { Formatter } from "../../../types/formatConfigTypes";
import { InputType } from "../../../types/inputTypes";

export const barChartFormatters: Formatter[] = [
    {
        key: 'label',
        label: 'Label',
        inputType: InputType.TEXT,
        applyFormatter(value, { labels, index }) {
            labels[index] = value;
        }
    },
    {
        key: 'backgroundColor',
        label: 'Background Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset, index }) {
            if (!Array.isArray(dataset.backgroundColor)) {
                dataset.backgroundColor = [];
            }
            dataset.backgroundColor[index] = value;
        }
    },
    {
        key: 'borderColor',
        label: 'Border Color',
        inputType: InputType.COLOR,
        applyFormatter(value, { dataset, index }) {
            if (dataset.borderColor == null) {
                dataset.borderColor = new Array(index);
            }
            dataset.borderColor.push(value);
        }
    },
    {
        key: 'borderWidth',
        label: 'Border Width',
        inputType: InputType.NUMBER,
        applyFormatter(value, { dataset, index }) {
            if (dataset.borderWidth == null) {
                dataset.borderWidth = new Array(index);
            }
            dataset.borderWidth.push(value);
        }
    }
];
