import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-bootstrap';

type Props = {
    className?: string,
    header: string,
    defaultExpanded?: boolean,
    children: React.ReactNode
}

export default function ConfigPanel(props: Props) {
    const [open, setOpen] = useState(!!props.defaultExpanded);
    const iconName = open ? 'chevron-up' : 'chevron-down';

    return (
        <div className={ 'config-panel ' + (props.className || '') }>
            <div className="config-panel-header" onClick={() => setOpen(!open)}>
                <div className="config-panel-toggle">
                    <FontAwesomeIcon icon={iconName}/>
                </div>
                <div className="flex-grow">
                    { props.header }
                </div>
            </div>
            <Collapse in={open}>
                <div className="config-panel-body">
                    { props.children }
                </div>
            </Collapse>
        </div>);
}
