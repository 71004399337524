import React from 'react';
import { FieldProps, Field } from 'formik';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

import { useOptions } from './useOptions';
import { FieldConfig, FormOpts } from './formBuilderTypes';

type Props<V, Values> = {
    fieldName: string;
    fieldConfig: FieldConfig<V, Values>;
    values: Values;
    error: string | undefined;
    opts: FormOpts;
}

export default function FormBuilderField<V, Values>(props: Props<V, Values>) {
    const { fieldName, fieldConfig, error, values, opts: inputOpts } = props;
    const components = inputOpts.inputs;

    // resolve the input params
    const params = useOptions(values, fieldConfig.params || {});

    // resolve the input component
    let component: React.ComponentType<FieldProps> | undefined;
    const type = fieldConfig.type;
    if (typeof type === 'string') {
        if (components.hasOwnProperty(type)) {
            component = components[type];
        }
    } else {
        component = type;
    }

    // render the input inside a formik field
    return (
        <FormGroup>
            <FormLabel className="field-group-header">{fieldConfig.label}</FormLabel>
            { component ? <Field name={fieldName} component={component} {...params}/> : undefined }
            <FormControl.Feedback>{ '' + error }</FormControl.Feedback>
        </FormGroup>
    );
}
