import { useEffect, useRef } from 'react';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';
import { getTimeOutException } from 'core/dashboard/components/widgets/widgetUtils';

export function useWidgetDataFetcher(
    refreshCount: number,
    fetcher: () => Promise<unknown>,
    actions: WidgetActions,
    setWidgetError: (hasError: boolean, error: string) => void
) {
    const prevRefreshCount = useRef<number>(refreshCount);
    useEffect(() => {

        // widget will be refreshed with new data whenever
        // the refreshCount changed
        if (prevRefreshCount.current !== refreshCount)  {
            prevRefreshCount.current = refreshCount
            actions.setLoading(true);

            fetcher()
                .then(result => {
                    const data = Array.isArray(result) ? result : [result];
                    setWidgetError(false, '');
                    actions.setData(data);
                    actions.setLoading(false);
                })
                .catch(err => {
                    setWidgetError(true, getTimeOutException(err.message));
                    actions.setLoading(false);
                });
        }
    });

    useEffect(() => {
        // trigger the widget to refresh every time the "fetcher" param is changed
        actions.triggerRefresh(false);
    }, [fetcher]);
}
