import { DashboardProperty } from "../../../types/storeTypes";

const PATTERN_ALPHANUMERIC = /^[\w]*$/;

function validateName(p: DashboardProperty): string | undefined {
    const name = p.name;
    if (!name.length) {
        return 'Key is required';
    }

    if (!PATTERN_ALPHANUMERIC.test(name)) {
        return 'key must be alphanumeric';
    }
}

function validateProperty(property: DashboardProperty, isUnique: boolean): string | undefined {
    if (!isUnique) {
        return 'key must be unique';
    }
    return validateName(property);
}

export function validate(properties: DashboardProperty[]): { [key: number]: string } {
    const seenKeys = new Set<string>();
    const validations: { [key: number]: string } = {};

    properties.forEach((prop, i) => {
        const isUnique = !seenKeys.has(prop.name);
        seenKeys.add(prop.name);
        const n = validateProperty(prop, isUnique);
        if (n) validations[i] = n;
    });

    return validations;
}
