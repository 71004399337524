import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPropertiesPanel } from 'core/dashboard/actions/dashboard-actions';
import { isShowPropertiesPanel } from 'core/dashboard/utils/selector/selectors';

export default function HeaderItemProperties() {
    const [show, setShow] = useState(false)
    const reduxDispatch = useDispatch();
    
    const handleToggle = useCallback(() => {
        reduxDispatch(setShowPropertiesPanel(!show));
        setShow(!show)
    }, [reduxDispatch, show]);

    return (
        <div className="dashboard-header-item">
            <div className="dashboard-header-btn" onClick={handleToggle}>
                <FontAwesomeIcon icon="filter" />
            </div>
        </div>
    );
}
