import { RequestBuilder, getAuthToken } from "@pearlchain/component-lib-common";
import { WebComponentBriefResponse, WebComponentResponse, WebComponentSaveResponse, ReportDefinitionResponse, RunReportResponse, WebPluginDefinition, DataSourcesResponse, DataSourcesResponseDto } from "../../types/responseTypes";
import { WebComponentRequest, ReportTransformRequestParams, WebComponentDeleteRequestParams, ListWebComponentsRequest, RunQueryRequest, InvokeDataSourceRequest, RegisterFirebaseTokenRequest } from '../../types/requestTypes';

const URL = {
    LIST_WEB_COMPONENTS: 'sec/api/webcomponent/list',
    UPDATE_STARRED_WEB_COMPONENTS: 'sec/api/webcomponent/starred/update',
    SAVE_WEBCOMPONENT: 'sec/api/webcomponent/save',
    DELETE_WEBCOMPONENT: 'sec/api/webcomponent/delete',
    LOAD_WEB_COMPONENT(webComponentUuid: string) {
        return `sec/api/webcomponent/${webComponentUuid}`;
    },
    LIST_WEBCOMPONENT_USERGROUPS(webComponentUuid: string) {
        return `sec/api/webcomponent/${webComponentUuid}/user-groups`;
    },
    LIST_USER_GROUPS: 'sec/api/user-groups/list',
    FIND_REPORTS: 'sec/api/report-definition/list',
    FIND_REPORT_BY_UUID(uuid: string | undefined) {
        return `sec/api/report-definition/by-uuid/${uuid}`;
    },
    RUN_REPORT_TRANSFORM: 'sec/api/report-transform/run',
    DATASOURCE_DEFINITION(datasourceId: string) {
        return `sec/api/datasource/definition/${datasourceId}`;
    },
    LIST_PLUGINS: 'sec/api/plugins',
    LIST_PLUGINS_REFRESH: 'plugin/refresh',
    IMPORT_DASHBOARD: 'sec/api/webcomponent/dashboard/import',
    CAN_IMPORT_DASHBOARD: 'sec/api/webcomponent/canimport',
    RUN_QUERY: '/sec/api/datasource/run',
    FIND_DATA_SOURCES: '/sec/api/datasources-execution/metadata',
    INVOKE_DATA_SOURCE: '/sec/api/datasources-execution/invoke',
    REGISTER_FIREBASE_TOKEN: '/auth/firebase/register-token',
    UNREGISTER_FIREBASE_TOKEN: '/auth/firebase/unregister-token'
}

export function listWebComponents(request: ListWebComponentsRequest): Promise<WebComponentBriefResponse[]> {
    return new RequestBuilder()
        .post(URL.LIST_WEB_COMPONENTS)
        .setBodyJson(request)
        .toJS();
}

export function findAvailableDashboards(view: string | undefined): Promise<WebComponentBriefResponse[]> {
    return listWebComponents({
        type: 'dashboard',
        listShared: true,
        view
    });
}

export function loadWebComponent(dashboardUuid: string): Promise<WebComponentResponse> {
    return new RequestBuilder()
        .get(URL.LOAD_WEB_COMPONENT(dashboardUuid))
        .toJS();
}

export function saveWebComponent(params: WebComponentRequest): Promise<WebComponentSaveResponse> {
    return new RequestBuilder()
        .post(URL.SAVE_WEBCOMPONENT)
        .setBodyJson(params)
        .toJS();
}

export function deleteWebComponent(params: WebComponentDeleteRequestParams): Promise<Response> {
    return new RequestBuilder()
        .post(URL.DELETE_WEBCOMPONENT)
        .setBodyJson(params)
        .fetch();
}

export function saveStarredWebComponents(view: string, uuids: string[]) {
    return new RequestBuilder()
        .post(URL.UPDATE_STARRED_WEB_COMPONENTS)
        .setBodyJson({ view, uuids })
        .fetch();
}

export function findReportDefinitions(): Promise<ReportDefinitionResponse[]> {
    return new RequestBuilder()
        .get(URL.FIND_REPORTS)
        .toJS();
}

export function findReportDefinitionByUuid(uuid: string | undefined): Promise<ReportDefinitionResponse | undefined> {
    return new RequestBuilder()
        .get(URL.FIND_REPORT_BY_UUID(uuid))
        .toJS();
}

export function runReport(params: ReportTransformRequestParams): Promise<RunReportResponse> {
    return new RequestBuilder()
        .post(URL.RUN_REPORT_TRANSFORM)
        .setBodyJson(params)
        .toJS();
}

export function fetchDatasourceDefinition(datasource: string) {
    return new RequestBuilder()
        .get(URL.DATASOURCE_DEFINITION(datasource))
        .toJS();
}

export function listAvailablePlugins(): Promise<{ [key: string]: WebPluginDefinition }> {
    return new RequestBuilder()
        .get(URL.LIST_PLUGINS)
        .toJS();
}

export function refreshPlugins() {
    return new RequestBuilder()
        .post(URL.LIST_PLUGINS_REFRESH)
        .toJS();
}

export function exportDashboard(baseUrl: string, name: string) {
    fetch(baseUrl)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            // the filename you want
            //link.download = values.fileName + expFormat.fileExt;
            link.download = name+".json";
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('Failed to download widget export, please contact your administrator.'));
}

export function importDashboard(data: string) {
    return new RequestBuilder()
        .post(URL.IMPORT_DASHBOARD)
        .setBodyText(data)
        .toJS();
}

export function runQuery(request: RunQueryRequest): Promise<{ [key: string]: unknown }[]> {
    return new RequestBuilder()
        .post(URL.RUN_QUERY)
        .setBodyJson(request)
        .toJS();
}

export function findDataSources(): Promise<DataSourcesResponse[]> {
    return new RequestBuilder()
        .get(URL.FIND_DATA_SOURCES)
        .toJS();
}

export function invokeDataSource(request: InvokeDataSourceRequest): Promise<DataSourcesResponseDto> {
    return new RequestBuilder()
        .post(URL.INVOKE_DATA_SOURCE)
        .setBodyJson(request)
        .toJS();
}

export function canImport() {
    return new RequestBuilder()
        .get(URL.CAN_IMPORT_DASHBOARD).toJS()
    }

export function registerFirebaseToken(request: RegisterFirebaseTokenRequest) {
    return new RequestBuilder()
        .post(URL.REGISTER_FIREBASE_TOKEN)
        .setBodyJson(request)
        .fetch();
}

export function unregisterFirebaseToken() {
    return new RequestBuilder()
        .post(URL.UNREGISTER_FIREBASE_TOKEN)
        .fetch();
}
