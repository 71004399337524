import React, { useContext, useReducer, useEffect, useMemo, useRef, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Widget, StoreState } from '../../types/storeTypes';

import { reducer, initialState } from './widgetReducer';
import { usePollInterval } from '../../utils/hooks/usePollInterval';
import WidgetHeader from './header/WidgetHeader';
import WidgetContent from './WidgetContent';
import { widgetContext } from './context';
import { DashboardContext } from '../dashboard/context';
import { createWidgetActions } from './widgetActions';
import { PropertySource } from '../../evaluator/PropertySource';
import { Evaluator } from '../../evaluator/Evaluator';
import { getDashboardRefreshCount } from '../../utils/selector/selectors';
import { WidgetState, WidgetAction, WidgetActionType, WidgetViewMode, WidgetInitParams, WidgetContext } from 'core/dashboard/types/widgetStateTypes';

type InnerProps = {
    dashboardUuid: string;
    widgetUuid: string;
    state: WidgetState;
    dispatch: Dispatch<WidgetAction>;
};

function useWidgetName(widget: Widget<unknown>, propertySource: PropertySource): string {
    const commonConfig = widget.common;
    const widgetName = commonConfig.widgetName;
    
    return useMemo(() => {
        const evaluator = new Evaluator(propertySource);
        return '' + evaluator.evaluate(widgetName, {}, { fallback: '' });
    }, [widgetName, propertySource]);
}

function WidgetInner(props: InnerProps) {
    const { dashboardUuid, widgetUuid, state, dispatch } = props;
    const widget = state.widget;

    // select from the Dashboard context
    const { dispatch: dashboardDispatch, evaluationContext: propertyValues, propertyConfigs, parent } = useContext(DashboardContext)!;
    const parentDispatch = parent?.dispatch;
    const dashboardRefreshCount = useSelector((state: StoreState) => getDashboardRefreshCount(state, dashboardUuid));

    // trigger widget refresh whenever the dashboard refresh count changed
    const prevRefreshCount = useRef<number | undefined>(dashboardRefreshCount);
    useEffect(() => {
        if (prevRefreshCount.current !== dashboardRefreshCount) {
            prevRefreshCount.current = dashboardRefreshCount;
            dispatch({ type: WidgetActionType.TRIGGER_REFRESH });
        }
    });

    // create the widget actions callback
    const reduxDispatch = useDispatch();
    const widgetActions = useMemo(() => {
        return createWidgetActions(
            dashboardUuid,
            widgetUuid,
            dispatch,
            dashboardDispatch,
            parentDispatch,
            reduxDispatch
        );
    }, [dashboardUuid, widgetUuid, dispatch, dashboardDispatch, parentDispatch, reduxDispatch]);

    // wrap the dashboard properties in a PropertySource accessor
    const propertySource = useMemo(() => new PropertySource(propertyValues.properties, propertyConfigs), [propertyValues.properties, propertyConfigs]);

    // resolve the widget-name, substituting in placeholder variables
    const widgetName = useWidgetName(state.widget, propertySource);

    // setup widget refresh poller. It will dispatch a "refresh" action every [n] seconds
    // If we are in a drilldown, polling is disable for the upper widget. The widget in the drilldown can still refresh.
    // This is done so that you don't get pushed back to the parent widget when you are in a drilldown and the refresh Interval
    // triggers a refresh
    const pollInterval = widget.common.pollInterval;

    // If we are not in a drilldown, state.drilldown will be any empty array, otherwise it will be a filled array
    if(state.drilldown != null && state.drilldown.length>0) {
        console.log("Not polling for Dashboard: '"+dashboardUuid+"', widget: '"+widgetName+"', as we are in drilldown.");
        usePollInterval(0, widgetActions.triggerRefresh);
    } else {
        console.log("Polling for Dashboard: '"+dashboardUuid+"', widget: '"+widgetName+"' enabled.");
        usePollInterval(pollInterval, widgetActions.triggerRefresh);
    }

    const isConfiguring = state.viewMode === WidgetViewMode.CONFIG;
    const className = classNames('widget-container', { configuring: isConfiguring });

    // construct the context object, that will be available to all child components
    const context = useMemo(
        (): WidgetContext => ({
            state,
            dispatch,
            actions: widgetActions
        }),
        [widgetActions, state]
    );

    return (
        <div className={className}>
            <widgetContext.Provider value={context}>
                <WidgetHeader
                    widgetUuid={props.widgetUuid}
                    widgetName={widgetName}
                    drilldown={state.drilldown}
                    viewMode={state.viewMode}
                    loading={state.isLoading}
                    actions={widgetActions}
                />
                <WidgetContent
                    dashboardUuid={dashboardUuid}
                    widgetUuid={widgetUuid}
                    state={state}
                    actions={widgetActions}
                    propertySource={propertySource}
                />
            </widgetContext.Provider>
        </div>
    );
}

const WidgetInnerMemo = React.memo(WidgetInner);

/**
 * Manages the widget internal state
 */

type Props = {
    dashboardUuid: string;
    widgetUuid: string;
    widget: Widget<unknown>;
    hasStateError?: boolean;
};

function Widget(props: Props) {
    const { dashboardUuid, widgetUuid, widget } = props;

    // setup the widget state reducer
    const params: WidgetInitParams = { widget };
    const [state, dispatch] = useReducer(reducer, params, initialState);

    // reset the state when initial params changed
    useEffect(() => {
        if (state.widget !== widget) {
            dispatch({ type: WidgetActionType.INIT, params });
        }
        if(props.hasStateError) {
            dispatch({ type: WidgetActionType.STATE_ERROR, error: true })
        }
    }, [widget]);

    return (
        <WidgetInnerMemo
            dashboardUuid={dashboardUuid}
            widgetUuid={widgetUuid}
            state={state}
            dispatch={dispatch}
        />
    );
}

export default React.memo(Widget);
