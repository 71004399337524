
export function validateRequired(input: unknown) {
    return input == null || (typeof input === 'string' && !input) ? 'Required' : undefined;
}

export function createOneOfValidator(options: string[]) {
    return (input: string) => {
        return !options.some(opt => opt === input) ? 'Invalid option' : undefined;
    }
}

export function composeValidators<T>(...validators: ((value: T) => string | undefined)[]) {
    return (input: T) => {
        for (let validator of validators) {
            const v = validator(input);
            if (v) return v;
        }
    }
}
