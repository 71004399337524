import moment from 'moment';

import { DashboardPropertyType, DashboardPropertyTypeConfig } from "../types/dashboardPropertyTypes";
import { parseDateTimeOffset } from "../utils/dates/dateTimeOffsetParser";
import { Evaluator } from '../evaluator/Evaluator';

export const dashboardPropertiesConfig: { [K in DashboardPropertyType]?: DashboardPropertyTypeConfig } = {
    [DashboardPropertyType.STRING]: {
        label: 'Text',
        initialValue: '',
        resolveValue(i) {
            const input = i as string | undefined;
            if (input != null) {
                // property input can be an expression
                return new Evaluator().evaluate(input, {});
    
            } else {
                return input;
            }
        },
        asString(i) {
            const input = i as string | undefined;
            return input == null ? '' : input;
        }
    },

    [DashboardPropertyType.DATE]: {
        label: 'Date',
        initialValue: undefined,
        resolveValue(i) {
            const dateInput = i as string | undefined;
            if (typeof dateInput === 'string') {
                const offsetValue = parseDateTimeOffset(dateInput);

                if (offsetValue) {
                    // resolve date-time as an offset from the current time
                    const m = moment();
                    let dateAdd = true;

                    for (const offset of offsetValue.offsets) {
                        if (offset.modifier === '+') {
                            dateAdd = true;
                        } else if (offset.modifier === '-') {
                            dateAdd = false;
                        }

                        if (dateAdd) {
                            m.add(offset.amount, offset.timeUnit);

                        } else {
                            m.subtract(offset.amount, offset.timeUnit);
                        }
                    }

                    return m.format(offsetValue.format);

                } else {
                    return dateInput;
                }
            }
        },
        asString(i) {
            const input = i as string | undefined;
            return input == null ? '' : '' + input;
        }
    }
}

export function resolvePropertyValue(type: DashboardPropertyType, value: unknown): unknown {
    const config = dashboardPropertiesConfig[type];
    if (config) {
        return config.resolveValue(value);
    }
    return undefined;
}

export function resolvePropertyValueAsString(type: DashboardPropertyType, value: unknown): string {
    const config = dashboardPropertiesConfig[type];
    return config ? config.asString(value) : '[unknown]';
}

export function resolveInitialValue(type: DashboardPropertyType) {
    return dashboardPropertiesConfig[type]?.initialValue;
}
