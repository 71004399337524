import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col } from 'react-bootstrap';
import ReportColumnChooserField from '../../common/config/formik/ReportColumnChooserField';
import DataSourceField from '../../common/config/formik/DataSourceField';

type Props = {
    reportSource: string;
    sqlParamsValues: { [key: string]: any };
    setFieldValue: (field: string, value: any) => void
}

export default function BarChartWidgetBasicConfig(props: Props) {
    const { t } = useTranslation();
    //TODO add translations to sql editor labels

    return (
        <> 
            <DataSourceField
                reportSource={props.reportSource}
                name='reportSource' typeName='type'
                sqlParamsName="sqlParams"
                sqlParamsValues={props.sqlParamsValues}
                setFieldValue={props.setFieldValue}
                isSingleSource={false}
                />
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label>X-{t('dashboard.column')}</Form.Label>
                        <ReportColumnChooserField name="xAxis.column" reportSource={props.reportSource} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Y-{t('dashboard.column')}</Form.Label>
                        <ReportColumnChooserField name="yAxis.column" reportSource={props.reportSource} />
                    </Form.Group>
                </Col>
            </Form.Row>
        </>
    );
} 
