import React from 'react';

import { WidgetProps } from '../../widget/widgetTypes';
import SingleSourceWidgetView from './view/SingleSourceWidgetView';
import SingleSourceWidgetConfig from './config/SingleSourceWidgetConfig';
import { SingleSourceConfig } from './singleSourceTypes';
import { RunReportResponse } from '../../../types/responseTypes';
import { useTrackAccessorsPropertySource } from 'core/dashboard/evaluator/propertysource/useTrackAccessorsPropertySource';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';

/**
 * Single-source widget
 */

export const SINGLE_SOURCE_TYPE = 'GRID_SINGLE_SOURCE';

export default function SingleSourceWidget(props: WidgetProps) {
    const { state, actions, propertySource } = props;

    const widget = state.widget;
    const config = widget.config as SingleSourceConfig | undefined;
    const common = widget.common;

    // only update the widget when observed properties changed
    const properties = useTrackAccessorsPropertySource(config, propertySource);

    function renderView() {
        if (config == null) return renderConfig();
        if (state.hasStateError) return <></>
        const refreshCount = state.refreshCount;
        const responseData = state.data as RunReportResponse[] | undefined;

        return (
            <SingleSourceWidgetView
                config={config}
                refreshCount={refreshCount}
                actions={actions}
                widgetData={responseData}
                properties={properties}
                dataSources={widget.dataSources}
                common={common}
                cacheRefreshCount={state.cacheRefreshCount}
            />
        );
    }

    function renderConfig() {
        return <SingleSourceWidgetConfig
            config={config}
            common={common}
            actions={actions}
            dataSources={widget.dataSources}/>;
    }

    if (state.viewMode === WidgetViewMode.VIEW) {
        return renderView();
    } else {
        return renderConfig();
    }
}
