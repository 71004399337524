import React, { useMemo } from 'react';
import { FastField, Field } from 'formik';
import { CloseButton, Form, Col } from 'react-bootstrap';

import {
    SeriesConfig,
    SourceConfig,
    TargetKey
} from '../sourceMappingTypes';
import TextInput from '../../config/formik/TextInput';
import SelectInput from '../../config/formik/SelectInput';
import FieldMappingConfigList from './FieldMappingConfigList';
import FormFieldErrorFeedback from '../../widgets/common/config/formik/FormFieldErrorFeedback';
import { composeValidators, validateRequired, createOneOfValidator } from '../../../utils/validate/validators';

type Props = {
    name: string;
    series: SeriesConfig;
    sources: SourceConfig[];
    targetKeys: TargetKey[];
    onRemoved: (() => void) | undefined;
};

function getSourceNames(sources: SourceConfig[]) {
    return sources.map(source => ({
        value: source.name,
        label: source.name
    }));
}

function createSourceValidator(sources: SourceConfig[]) {
    return composeValidators(
        validateRequired,
        createOneOfValidator(sources.map(src => src.name))
    );
}

export default function SeriesConfig(props: Props) {
    const { name, series, sources, targetKeys, onRemoved } = props;
    const sourceOpts = useMemo(() => getSourceNames(sources), [sources]);

    return (
        <div className="series-config">
            <Form.Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Series</Form.Label>
                        <FastField
                            name={name + '.series'}
                            component={TextInput}
                            placeholder="Series"
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Source</Form.Label>
                        <Field
                            name={name + '.source'}
                            validate={createSourceValidator(sources)}
                            component={SelectInput}
                            options={sourceOpts}
                        />
                        <FormFieldErrorFeedback name={name + '.source'}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <FieldMappingConfigList
                name={name + '.mappings'}
                mapping={series.mappings}
                sources={sources}
                sourceId={series.source}
                targetKeys={targetKeys}
            />
            { onRemoved ? <CloseButton onClick={onRemoved}/> : undefined }
        </div>
    );
}
