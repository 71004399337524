import { Action } from "redux";
import produce from "immer";
import { DialogsState } from "./dialogTypes";

import { ActionType, DialogResultAction, ShowDialogAction, ClearDialogAction } from "./actions";

const handleShowDialog = produce((state: DialogsState, action: ShowDialogAction) => {
    state.dialogs[action.dialogId] = {
        show: true,
        opts: action.opts,
        result: null
    };
});

const handleDialogResult = produce((state: DialogsState, action: DialogResultAction) => {
    const dialog = state.dialogs[action.dialogId];
    dialog.show = false;
    dialog.result = action.result;
});

const handleClearDialog = produce((state: DialogsState, action: ClearDialogAction) => {
    delete state.dialogs[action.dialogId];
});

function createDialogsState(): DialogsState {
    return { dialogs: {} }
}

const handlers: { [key: string]: (state: DialogsState, action: any) => DialogsState } = {
    [ActionType.SHOW_DIALOG]: handleShowDialog,
    [ActionType.DIALOG_RESULT]: handleDialogResult,
    [ActionType.CLEAR_DIALOG]: handleClearDialog
}

export default function (state: DialogsState = createDialogsState(), action: Action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    }
    return state;
}

