import { ReportParamConfig } from "core/dashboard/types/configTypes";
import { EvaluationContext } from "../placeholders/placeholderTypes";
import { resolveExpressionFallback } from "./evaluate";

export function evaluateReportParams(params: ReportParamConfig[], ctx: EvaluationContext) {
    return params.map((param): ReportParamConfig => {
        const value = '' + resolveExpressionFallback(param.value, ctx, '');
        return { name: param.name, value };
    });
}

export function evaluateReportParamsObject(params: { [key: string]: string }, ctx: EvaluationContext) {
    const result: { [key: string]: unknown } = {};
    for (const key in params) {
        result[key] = resolveExpressionFallback(params[key], ctx, undefined);
    }
    return result;
}
