import { IPropertySource } from "../PropertySource";

export class TrackAccessorsPropertySource implements IPropertySource {
    public readonly deps: Set<string>;
    private readonly source: IPropertySource;

    constructor(source: IPropertySource, deps?: Set<string>) {
        this.deps = deps || new Set<string>();
        this.source = source;
    }

    getProperty(name: string): unknown {
        this.deps.add(name);
        return this.source.getProperty(name);
    }

    hasProperty(name: string): boolean {
        return this.source.hasProperty(name);
    }

    listProperties(): string[] {
        return this.source.listProperties();
    }

    getDependencies(): Set<string> {
        return this.deps;
    }

    compare(other: IPropertySource) {
        return this.compareKeys(other) && this.compareDeps(other);
    }

    private compareKeys(other: IPropertySource) {
        const keys = this.listProperties();
        const otherKeys = other.listProperties();

        if (keys.length !== otherKeys.length) {
            return false;
        }

        for (let i = 0, n = keys.length; i < n; i++) {
            if (keys[i] !== otherKeys[i]) {
                return false;
            }
        }

        return true;
    }

    private compareDeps(other: IPropertySource) {
        for (let dep of this.deps) {
            if (this.source.getProperty(dep) !== other.getProperty(dep)) {
                return false;
            }
        }

        return true;
    }
}
