import { Dashboard, Widget } from "../../types/storeTypes";
import { loadWebComponent } from "../../utils/requests/requestHandlers";
import { deserializeDashboard } from "../../utils/serialization/dashboardDeserialization";
import { deserializeWidget } from "../../utils/serialization/widgetDeserialization";

export async function loadDashboard(dashboardUuid: string): Promise<Dashboard> {
    const webComponent = await loadWebComponent(dashboardUuid);
    return deserializeDashboard(dashboardUuid, webComponent);
}

export async function loadWidget(widgetUuid: string): Promise<Widget<unknown>> {
    const webComponent = await loadWebComponent(widgetUuid);
    return deserializeWidget(widgetUuid, webComponent);
}
