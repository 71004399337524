import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FastField, FieldProps } from 'formik';

import { HttpRequestActionConfig } from 'core/dashboard/types/actionHandlerTypes';
import EvaluatableFieldComponent from 'core/dashboard/components/form/formik/EvaluatableFieldComponent';
import ActionPropertiesField from 'core/dashboard/components/widgets/common/config/formik/ActionPropertiesField';
import CheckboxInput from 'core/dashboard/components/widgets/common/config/formik/input/CheckboxInput';

export default function HttpRequestActionInput(props: FieldProps) {
    const { field } = props;
    const name = field.name;
    const config = field.value as HttpRequestActionConfig;
    const params = config.params;

    return (
        <div className="action-config">
            <Form.Group as={Row}>
                <Col xs={3}>URL</Col>
                <Col xs={9}>
                    <FastField name={name + '.url'} component={EvaluatableFieldComponent}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={3}>Params</Col>
                <Col xs={9}>
                    <ActionPropertiesField name={name + '.params'} properties={params}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={3}>Refresh Dashboard</Col>
                <Col xs={3}>
                    <FastField name={name + '.refreshDashboard'} component={CheckboxInput}/>
                </Col>
            </Form.Group>
        </div>
    );
}
