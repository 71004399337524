import { CommonAxe } from "chart.js";
import { AxisConfig, AxisType } from "../../types/configTypes";

export function axisConfigToChartOptions(axisConfig: AxisConfig | undefined): CommonAxe {
    const axis: CommonAxe = {
        type: 'linear'
    };

    if (axisConfig) {
        axis.type = axisConfig.type;

        if (axisConfig.label) {
            axis.scaleLabel = {
                labelString: axisConfig.label,
                display: true
            };
        }

        if (axisConfig.type === AxisType.TIME && axisConfig.timeUnit) {
            axis.time = {
                unit: axisConfig.timeUnit
            };
        }

        if (axisConfig.stacked) {
            axis.stacked = true;
        }

        if (axisConfig.type === AxisType.LINEAR && axisConfig.beginAtZero) {
            axis.ticks = {
                beginAtZero: true
            };
        }

        if (axisConfig.type === AxisType.STEPPED && axisConfig.beginAtZero) {
            axis.ticks = {
                beginAtZero: true
            };
            axis.type = AxisType.LINEAR;
        }

        if (axisConfig.type === AxisType.STEPPED) {
            axis.type = AxisType.LINEAR;
        }
    }

    return axis;
}
