import { BarChartConfig } from "./barChartTypes";
import { SourceMappingConfig, FieldType } from "../../sourceMapping/sourceMappingTypes";
import { reportParamsConfigToRequest } from "../../../utils/requests/requestHelpers";

export const KEY_X_AXIS = 'x';
export const KEY_Y_AXIS = 'y';
export const KEY_SERIES = 'series';

export function sourceMappingFromConfig(config: BarChartConfig): SourceMappingConfig {
    // return the "advanced" config if defined
    if (config.sourceMapping) {
        return config.sourceMapping;
    }

    const params = reportParamsConfigToRequest(config.reportParams);
    return {
        sources: [
            {
                name: 'ds0',
                params,
                report: config.reportSource,
                type: config.type,
                sqlParams: config.sqlParams,
                orderColumn: "",
                providersHandlers: config.providersHandlers
            }
        ],
        series: [
            {
                series: '',
                source: 'ds0',
                mappings: [
                    { from: config.xAxis.column!, type: FieldType.RAW, to: KEY_X_AXIS },
                    { from: config.yAxis.column!, type: FieldType.RAW, to: KEY_Y_AXIS }
                ]
            }
        ]
    };
}
