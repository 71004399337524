import { DashboardProperty } from "../../../types/storeTypes";
import { DashboardPropertyType } from "../../../types/dashboardPropertyTypes";

export enum FormActionType {
    RESET,
    ADD_FIELD,
    REMOVE_FIELD,
    SET_FIELD_KEY,
    SET_FIELD_TYPE,
    SET_FIELD_VALUE,
    SET_FIELD_EDITABLE
}

export type FormState = {
    properties: DashboardProperty[];
}

export type ResetFormAction = {
    type: FormActionType.RESET;
    properties: DashboardProperty[];
}

export type SetFieldKeyAction = {
    type: FormActionType.SET_FIELD_KEY;
    index: number;
    key: string;
}

export type SetFieldValueAction = {
    type: FormActionType.SET_FIELD_VALUE;
    index: number;
    value: unknown;
}

export type SetFieldTypeAction = {
    type: FormActionType.SET_FIELD_TYPE;
    index: number;
    propertyType: DashboardPropertyType;
}

export type SetFieldEditableAction = {
    type: FormActionType.SET_FIELD_EDITABLE;
    index: number;
    editable: boolean;
}

export type AddFieldAction = {
    type: FormActionType.ADD_FIELD;
}

export type RemoveFieldAction = {
    type: FormActionType.REMOVE_FIELD;
    index: number;
}

export type FormAction =
    | ResetFormAction
    | AddFieldAction
    | RemoveFieldAction
    | SetFieldKeyAction
    | SetFieldValueAction
    | SetFieldTypeAction
    | SetFieldEditableAction
    ;
