import { useMemo } from 'react';
import { useCachedFetcher } from '@pearlchain/component-lib-common';

import echartsImage from '../../../../img/echarts.png';
import singleSourceImage from '../../../../img/ssource.png';
import humanTasksImage from '../../../../img/humtasks.png';
import iframeImage from '../../../../img/iframe.png';
import EnterpriseDesignerImage from '../../../../img/designer.png';
import pieChartImage from '../../../../img/pieChart.png';
import barChartImage from '../../../../img/barChart.png';
import SingleSourceWidget, { SINGLE_SOURCE_TYPE } from './singlesource/SingleSourceWidget';
import PieChartWidget, { PIE_CHART_TYPE } from './pieChart/PieChartWidget';
import LineChartWidget, { LINE_CHART_TYPE } from './lineChart/LineChartWidget';
import BarChartWidget, { BAR_CHART_TYPE } from './barChart/BarChartWidget';
import IframeWidget, { IFRAME_TYPE } from './iframe/IframeWidget';
import EnterpriseDesignerWidget, { ENTERPRISE_DESIGNER_TYPE } from './enterprisedesigner/EnterpriseDesignerWidget';
import { WidgetEntry } from '../../types/widgetLibraryTypes';
import HumanTasksWidget from './humanTasks/HumanTasksWidget';

import { RequestId } from 'core/dashboard/utils/requests/requestId';
import { listAvailablePlugins } from 'core/dashboard/utils/requests/requestHandlers';
import { WebPluginDefinition, WebPluginDefinitions } from 'core/dashboard/types/responseTypes';
import { createPluginWidget } from './plugin/PluginWidget';

const HumanTasks = 'HUMAN_TASKS';


const builtInWidgets: WidgetEntry[] = [
    {
        name: 'Pie-Chart',
        value: PIE_CHART_TYPE,
        description: 'Displays report results in a pie-chart',
        icon: 'chart-pie',
        image: pieChartImage,
        component: PieChartWidget
    },
    {
        name: 'Line-Chart',
        value: LINE_CHART_TYPE,
        description: 'Displays report results in a line-chart',
        icon: 'chart-line',
        image: echartsImage,
        component: LineChartWidget
    },
    {
        name: 'Bar-Chart',
        value: BAR_CHART_TYPE,
        description: 'Displays report results in a bar-chart',
        icon: 'chart-bar',
        image: barChartImage,
        component: BarChartWidget
    },
    {
        name: 'Single-source grid',
        value: SINGLE_SOURCE_TYPE,
        description: 'Shows a single report result in a grid.',
        icon: 'th-list',
        image: singleSourceImage,
        component: SingleSourceWidget
    },
    {
        name: 'Human Tasks',
        value: HumanTasks,
        description: 'Human tasks management component',
        icon: 'users',
        image: humanTasksImage,
        component: HumanTasksWidget
    },
    {
        name: 'Iframe',
        value: IFRAME_TYPE,
        description: 'Shows an Iframe containing an embedded page',
        icon: 'window-maximize',
        image: iframeImage,
        component: IframeWidget
    },
    {
        name: 'Enterprise Designer',
        value: ENTERPRISE_DESIGNER_TYPE,
        description: 'Design viewer',
        image: EnterpriseDesignerImage,
        component: EnterpriseDesignerWidget
    }
];

function pluginToWidgetEntry(plugin: WebPluginDefinition): WidgetEntry {
    return {
        value: plugin.id,
        name: plugin.name,
        description: plugin.description,
        component: createPluginWidget(plugin.id)
    };
}

export function useAvailableWidgets(): WidgetEntry[] | undefined {
    const { data } = useCachedFetcher<WebPluginDefinitions>(listAvailablePlugins, RequestId.AVAILABLE_PLUGINS);
    return useMemo(() => {
        if (!data) {
            return undefined;
        }

        // map the plugin definitions from the API response to widgets
        const pluginWidgets = Object.values(data).map(pluginToWidgetEntry);

        // merge built-in with plugin widgets
        return builtInWidgets.concat(pluginWidgets);
    }, [data]);
}
