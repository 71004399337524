import React, { useContext } from "react";
import { FieldProps } from "formik";
import { DashboardContext } from "../../dashboard/context";
import AutoSuggestInput, { Suggestion } from "../input/AutoSuggestInput";

type Props = {
    suggestions?: Suggestion[];
    showPreview?: boolean;
}

export default function EvaluatableFieldComponent({ field, form, suggestions, showPreview }: FieldProps & Props) {
    const { evaluationContext: properties } = useContext(DashboardContext);

    function handleChange(value: string) {
        form.setFieldValue(field.name, value);
    }

    let allSuggestions: Suggestion[] = [];

    if (suggestions != null) {
        allSuggestions = suggestions.slice();
    }

    for (let key in properties.properties) {
        if (key !== '') {
            const value = "${"+ key +"}";

            if (!allSuggestions.some(sugg => sugg.name === value)){
                allSuggestions.push({ name: value });
            }
        }
    }

    const inputValue = field.value || "";
    return(
        <AutoSuggestInput
            value={inputValue}
            suggestions={allSuggestions}
            onNameChange={handleChange}
            showPreview={showPreview}
        />
    )
}
