import React from 'react';

import { FullLayoutConfig } from './layoutTypes';
import WidgetLoader from '../widget/WidgetLoader';
import { useSelector } from 'react-redux';
import { isShowPropertiesPanel } from 'core/dashboard/utils/selector/selectors';

type Props = {
    dashboardUuid: string;
    layout: FullLayoutConfig;
};

export default function FullLayout({ dashboardUuid, layout }: Props) {
    const show = useSelector(isShowPropertiesPanel);

    console.log(show)
    
    return (
        <div className={show ? "full-layout-properties":"full-layout"}>
            <WidgetLoader dashboardUuid={dashboardUuid} widgetUuid={layout.widgetUuid}/>
        </div>
    );
}
