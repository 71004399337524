import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    id: string;
    description: string;
};

export default function TooltipIcon(props: Props) {
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={props.id}>{props.description}</Tooltip>}
        >
            <div className="tooltip-icon">
                <FontAwesomeIcon icon="question-circle" />
            </div>
        </OverlayTrigger>
    );
}
