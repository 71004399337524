import React, { useEffect, useState } from 'react';
import { Column, useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { SortColumn } from '../widgets/singlesource/view/SingleSourceWidgetGrid';
import { getSortedColumns } from 'core/dashboard/utils/react-table/ReactTableUtils';

type Props = {
    columns: Column<{}>[],
    data: {}[],
    onSortColumnsChanged?: (sortColumns: SortColumn[]) => void;
    hiddenColumns?: String []
    // updateData: (index: number, columnName: string, value: any) => void
}

export default function ReactTableGrid(props: Props) {
    const data = props.data
    const columns = props.columns

    const [selected, setSelected] = useState(false)
    const [index, setIndex] = useState(-1)
    const initialState = { hiddenColumns: props.hiddenColumns || [] };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        state: { sortBy }
        // @ts-ignore
    } = useTable({ columns, data, initialState, manualSortBy: true, autoResetPage: !props.skipPageReset }, useResizeColumns, useSortBy)

    useEffect(() => {
        if(!sortBy.length){
            if(props.onSortColumnsChanged){
                props.onSortColumnsChanged([])
            }
        }
        sortBy.map((o: any) => {
            if(props.onSortColumnsChanged){
                props.onSortColumnsChanged(getSortedColumns(o))
            }
        })

    }, [sortBy]);

    function handleSelectedRow(i: number){
        setSelected(!selected)
        setIndex(i)
    }


    return (
      <table className={'pearlchain-table stickyHead'} {...getTableProps()} >
        <thead className="">
            {headerGroups.map(headerGroup => (
                <tr className='tr sticky' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        /*// @ts-ignore */
                        <th className="th p-2 cell-over" {...column.getHeaderProps()}>
                            {/*// @ts-ignore */}
                            <div className="d-flex" {...column.getSortByToggleProps()}>
                                {column.render('Header')}
                                {/*// @ts-ignore */}
                                <span className="ml-1">{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                            </div>
                            {/*// @ts-ignore */}
                            <div {...column.getResizerProps()} className={`resizer ${ column.isResizing ? 'isResizing' : ''}`}  />
                        </th>
                    ))}
                </tr>
                )
            )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
                prepareRow(row)
                return (
                    <tr className={`tr ${idx === index ? "row-selected" : ""}`} {...row.getRowProps()} onClick={() => handleSelectedRow(idx)}>
                        {row.cells.map(cell => {
                            return (
                                <td className={'cell td'} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                </tr>
                )
          })}
        </tbody>
      </table>
    )
}
