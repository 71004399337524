import React from 'react';
import { getIn, FieldProps } from 'formik';
import { FormControl } from 'react-bootstrap';
import { TargetKey } from '../sourceMappingTypes';

type Props = {
    targetKeys: TargetKey[];
} & FieldProps;

export default function FieldTargetInput({ field, form, targetKeys }: Props) {
    const name = field.name;
    const isInvalid = getIn(form.touched, name) && getIn(form.errors, name)
    return (
        <FormControl
            as="select"
            defaultValue=""
            placeholder="target"
            isInvalid={isInvalid}
            {...field}
        >
            <option disabled value="">
                Choose Target
            </option>
            {targetKeys.map(({ name }) => (
                <option key={name} value={name}>
                    {name}
                </option>
            ))}
        </FormControl>
    );
}
