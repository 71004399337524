import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history'
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/index';
import rootSaga from './sagas/root-sagas'
import { StoreState } from './core/dashboard/types/storeTypes';
import { saveDashboardsMiddleware } from './core/dashboard/middleware/saveDashboardsMiddleware';

export const history = createHashHistory();

const sagaMiddleware = createSagaMiddleware();
const store = createStore<StoreState, any, any, any>(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(
    thunkMiddleware,
    sagaMiddleware,
    saveDashboardsMiddleware,
    routerMiddleware(history)
  ))
);

sagaMiddleware.run(rootSaga);

if((module as any).hot) {
  (module as any).hot.accept('./reducers/',() => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
