import React from 'react';
import { Button } from 'react-bootstrap';
import { FieldArray } from 'formik';

import { TargetKey, SourceConfig, SeriesConfig } from '../sourceMappingTypes';
import SeriesConfigComponent from './SeriesConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    name: string;
    targetKeys: TargetKey[];
    sources: SourceConfig[];
    series: SeriesConfig[];
};

function createSeries(sources: SourceConfig[]): SeriesConfig {
    const source = sources.length ? sources[0].name : '';
    return {
        series: '',
        source,
        mappings: []
    };
}

export default function SeriesConfigList({ name, targetKeys, sources, series }: Props) {
    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="mapping-config-list">
                    <div className="config-sub-header">
                        Series
                        <Button
                            variant="secondary"
                            className="header-add-btn"
                            onClick={() => arrayHelpers.push(createSeries(sources))}
                        >
                            <FontAwesomeIcon icon="plus" />
                        </Button>
                    </div>

                    {series.map((series, index) => {
                        return (
                            <SeriesConfigComponent
                                key={index}
                                name={name + '.' + index}
                                series={series}
                                sources={sources}
                                targetKeys={targetKeys}
                                onRemoved={index > 0 ? arrayHelpers.handleRemove(index) : undefined}
                            />
                        );
                    })}
                </div>
            )}
        />
    );
}
