import React, { useRef } from 'react';
import { OverlayTrigger, Tooltip, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { isExpression } from 'core/dashboard/evaluator/Evaluator';
import EvaluatableInputPreview from './EvaluatableInputPreview';

type Props = {
    name?: string;
    value: string;
    showPreview?: boolean;
    onChange: (event: any) => void;
    [key: string]: unknown;
};

function useId() {
    const ref = useRef<string>();
    if (!ref.current) {
        ref.current = v4();
    }
    return ref.current;
}

function renderIcon(dynamic: boolean) {
    return <FontAwesomeIcon icon="bolt" className={classNames('icon-dynamic', { dynamic })} />;
}

export default function EvaluatableInput(props: Props) {
    const value = props.value;
    const isDynamic = isExpression(value);
    return (
        <div className="evaluatable-input">
            {props.showPreview === false ? (
                renderIcon(isDynamic)
            ) : (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id={'tooltip-' + useId()}>
                            <EvaluatableInputPreview input={value} />
                        </Tooltip>
                    }
                >
                    {renderIcon(isDynamic)}
                </OverlayTrigger>
            )}
            <FormControl name={props.name} value={value} onChange={props.onChange}/>
        </div>
    );
}
