import React, { useEffect, useState } from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-textmate";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ParamsModal from './ParamsModal';
import produce from 'immer';
import { Field, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    query: string
    handleQuery: (query:string) => void
    handleParams: (params: { [key: string]: any }) => void
    form: FormikProps<any>
    sqlParamsName: string;
    isSingleSource: boolean | undefined;
    sqlParams: {[key: string]: any};
}

export type Param = {
    name: string
    value: any
}

export default function Editor(props: Props) {
    const [params, setParams] = useState<Param[]>(sqlParamsToParamType(props.sqlParams));
    const [param, setParam] = useState<Param>();
    const [index, setIndex] = useState(-1);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(params.length){
            props.handleParams(paramsToObject(params))
            props.form.setFieldValue(props.sqlParamsName, params)
        }
    }, [params])

    function handleAddParam (param: Param){
        setParams([...params, param]);
        setParam(undefined)
        setShow(false)
    }

    function handleEditParam (param: Param){
        const nextState = produce(params, draftParams => {
            draftParams.map((p, i) =>{
                if(i === index){
                    p.name = param.name
                    p.value = param.value
                }
            })
        })
        setParams(nextState)
        setParam(undefined)
        setShow(false)
    }

    function handleDeleteParam(index: Number){
        let filteredArray = params.filter((p, i) => i !== index)
        setParams(filteredArray)
    }

    function setParamAndIndex(param: Param, index: number){
        setParam({name: param.name, value: param.value})
        setIndex(index)
        setShow(true)
    }

    function handleCloseModal(){
        setShow(false)
    }

    return (
        <Row className="editor overflow-auto">
            <Col sm={8} >
                <AceEditor
                    className="border-bottom"
                    width="100%"
                    height="100%"
                    mode="sql"
                    theme="textmate"
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: Infinity }}
                    value={props.query}
                    onChange={props.handleQuery}
                />
            </Col>
            <Col sm={4} className="border-bottom border-left">
                {props.isSingleSource && 
                    <div className="my-2">
                        <Form.Label className="font-weight-bold">Order by column (applied only when no order by is found in the SQL query)</Form.Label>
                        <Field className="form-control" type="text" placeholder="id" name={"orderColumn"}/>
                    </div>
                }
                
                <div>
                    <p className="font-weight-bold my-2 border-bottom">Parameters</p>
                    <Button  className="my-2" onClick={() => setShow(true)}>Add</Button>
                    <ParamsModal show={show} handleClose={handleCloseModal} param={param} handleAddParam={handleAddParam} handleEditParam={handleEditParam} />
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {params.map((param, index) =>(
                                <tr key={param.name}>
                                    <td>{param.name}</td>
                                    <td>{param.value}</td>
                                    <td>
                                        <Button variant="success" className="mr-1" 
                                            onClick={() => setParamAndIndex({name: param.name, value: param.value}, index)}><FontAwesomeIcon icon="edit"/></Button>
                                        <Button variant="danger" onClick={() => handleDeleteParam(index)}><FontAwesomeIcon icon="trash"/></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    )
}

export function paramsToObject(params: Param[]){
    let objectParams: { [key: string]: any } = {};
    
    if(params){
        if(!params.map){
            return params;
        }
        if(Object.keys(params).length !== 0){
            params.map((param) => {
                var name = param.name;
                var value = param.value;
                objectParams[name] = value;
            })
        }
    }
    return objectParams;
}

function sqlParamsToParamType(sqlParams: {[key: string]: any}){
    if(!sqlParams) return []
    const params:Param[] = []

    Object.entries(sqlParams).forEach(
        ([key, value]) => params.push({name: key, value})
    );

    return params;
}
