import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { getDialogs } from './selectors';
import { DialogType, DialogState } from './dialogTypes';
import ConfirmDialog from './ConfirmDialog';
import { dialogResult, clearDialog } from './actions';
import DashboardPropertiesDialog from '../header/properties/DashboardPropertiesDialog';

function createDialog(dialogId: string, dialog: DialogState, dispatch: Dispatch) {
    const opts = dialog.opts;

    const onResult = (result: unknown) => {
        dispatch(dialogResult(dialogId, result));
    };

    const onExit = () => {
        dispatch(clearDialog(dialogId));
    };

    if (opts.type === DialogType.CONFIRM) {
        return (
            <ConfirmDialog
                key={dialogId}
                show={dialog.show}
                opts={opts}
                onResult={onResult}
                onExit={onExit}
            />
        );

    } else if (opts.type === DialogType.PROPERTIES) {
        return (
            <DashboardPropertiesDialog
                key={dialogId}
                show={dialog.show}
                opts={opts}
                onResult={onResult}
                onExit={onExit}
            />
        );
    }
}

export default function Dialogs() {
    const dispatch = useDispatch();
    const dialogs = useSelector(getDialogs);

    return (
        <React.Fragment>
            {Object.entries(dialogs).map(([dialogId, dialog]) => {
                return createDialog(dialogId, dialog, dispatch);
            })}
        </React.Fragment>
    );
}
