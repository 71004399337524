import React, { useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { PieChartConfig } from '../pieChartTypes';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import PieChartWidgetConfigForm from './PieChartWidgetConfigForm';
import { ReportDataSource } from 'core/dashboard/types/widgetTypes';

type Props = {
    common: WidgetCommonConfig;
    config: PieChartConfig | undefined;
    configure: (common: WidgetCommonConfig, config: PieChartConfig, dataSources: ReportDataSource[]) => void;
    onCancel: () => void;
    dataSources: ReportDataSource[];
}

export type PieChartFormValues = PieChartConfig & WidgetCommonConfig;

const DEFAULT_VALUES: PieChartConfig = {
    action: undefined,
    reportSource: '',
    groupBy: '',
    reportParams: [],
    sourceMapping: undefined,
    type: '',
    sqlParams: {},
    orderColumn: '',
    providersHandlers: ''
};

export default function PieChartWidgetConfig(props: Props) {
    const common = props.common;
    const config = props.config;

    // the initial values for the form
    const initialValues = useMemo((): PieChartFormValues => {
        const configValues = config || DEFAULT_VALUES;
        return Object.assign({}, common, configValues);
    }, [config, common]);

    // the function called to save the form values on the widget
    const onSubmit = useCallback((values: PieChartFormValues) => {
        const common: WidgetCommonConfig = {
            pollInterval: values.pollInterval,
            widgetName: values.widgetName,
            events: values.events
        };

        const config: PieChartConfig = {
            action: values.action,
            groupBy: values.groupBy,
            reportSource: values.reportSource,
            reportParams: values.reportParams,
            sourceMapping: values.sourceMapping,
            format: values.format,
            type: values.type,
            sqlParams: values.sqlParams,
            orderColumn: values.orderColumn,
            providersHandlers: values.providersHandlers
        };

        const uuid = (props.dataSources && props.dataSources.length > 0) ? props.dataSources[0].uniqueIdentifier : v4();
        const dataSources: ReportDataSource[] = [{definition: values.reportSource, type: values.type, uniqueIdentifier: uuid, params: values.sqlParams, orderColumn: values.orderColumn,}]

        props.configure(common, config, dataSources);
    }, [props.configure]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={(formikProps) => (
                <PieChartWidgetConfigForm
                    {...formikProps}
                    isCancellable={config != null}
                    onCancel={props.onCancel}
                />
            )}
        />
    );
}
