import { ColumnConfig } from '../singleSourceTypes';

export function getColumnAlias(config: ColumnConfig, colIndex: number) {
    return config.value;
}

export function getColumnEventKey(colIndex: number) {
    return 'action-' + colIndex;
}

export function getColumnTitle(config: ColumnConfig, colIndex: number): string {
    if (config.title) return config.title;

    if (config.groupBy) {
        return getLabel(config.value, 'group-by');
    } else if (config.sum) {
        return getLabel(config.value, 'sum');
    } else if (config.count) {
        return getLabel(config.value, 'count');
    } else if (config.raw) {
        return config.value;
    } else if (config.action) {
        return 'Action';
    } else {
        return 'Column ' + colIndex;
    }
}

function getLabel(col: string, type: string): string {
    return type + ' (' + col + ')';
}
