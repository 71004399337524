import React, { useMemo, useState } from 'react';
import { Button, Modal, Form, Col, FormControl } from 'react-bootstrap';
import { FieldProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCachedFetcher } from '@pearlchain/component-lib-common';

import { findReportDefinitions } from '../../../utils/requests/requestHandlers';
import { RequestId } from '../../../utils/requests/requestId';
import { useToggle } from '../../../utils/hooks/useToggle';

type Props = {
    reportSource: string;
} & FieldProps;

type InnerProps = {
    onHide: () => void;
} & Props;

function DatasourceParamsModalBody({ reportSource, field, form, onHide }: InnerProps) {
    const [value, setValue] = useState(field.value as { [key: string]: string });

    const { data } = useCachedFetcher(findReportDefinitions, RequestId.REPORT_DEFINITIONS);
    const reportDef = useMemo(() => data && data.find(repDef => repDef.uuid === reportSource), [data, reportSource]);

    function onAcceptChanges() {
        form.setFieldValue(field.name, value);
        onHide();
    }

    function onParamChanged(name: string, v: string) {
        const nextValue = Object.assign({}, value);
        nextValue[name] = v;
        setValue(nextValue);
    }

    return (
        <>
            <Modal.Body>
                {reportDef ? (
                    reportDef.parameters.map(param => (
                        <Form.Row key={param.name} className="mb-2">
                            <Col md={4}>{param.name}</Col>
                            <Col md={8}>
                                <FormControl
                                    value={value[param.name] || ''}
                                    onChange={(e: any) =>
                                        onParamChanged(param.name, e.target.value)
                                    }
                                />
                            </Col>
                        </Form.Row>
                    ))
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onAcceptChanges}>Accept</Button>
            </Modal.Footer>
        </>
    );
}

export default function DatasourceParamsInput(props: Props) {
    const [isShowModal, toggleShowModal] = useToggle(false);
    return (
        <>
            <Button variant="secondary" onClick={toggleShowModal}>
                <FontAwesomeIcon icon="th-list" />
            </Button>
            <Modal show={isShowModal} onHide={toggleShowModal}>
                <Modal.Header closeButton>Report Params</Modal.Header>
                <DatasourceParamsModalBody onHide={toggleShowModal} {...props} />
            </Modal>
        </>
    );
}
