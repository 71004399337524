import { useEffect } from 'react';

/**
 * Handles polling on a regular interval. Sets up a timer, that periodically emits a "tick" event whenever
 * a new refresh should be triggered.
 */
export function usePollInterval(pollInterval: number | undefined, tickFn: (cacheRefresh:boolean) => void) {
    useEffect(() => {
        if (pollInterval == null || pollInterval <= 0) {
            return;
        }

        const handle = window.setInterval(tickFn, pollInterval);
        return () => {
            if (handle != null) {
                // clear the previous poller
                window.clearInterval(handle);
            }
        }
    }, [pollInterval]);
}
