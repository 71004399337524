import React, { useMemo } from 'react';
import { FieldArray } from 'formik';
import { useCachedFetcher } from '@pearlchain/component-lib-common';
import { Button, Form } from 'react-bootstrap';

import { SourceConfig, FieldMappingConfig, TargetKey, FieldType } from '../sourceMappingTypes';
import { getSourceFields } from '../sourceMappingHelpers';
import { findReportDefinitions } from '../../../utils/requests/requestHandlers';
import { RequestId } from '../../../utils/requests/requestId';
import FieldMappingConfigComponent from './FieldMappingConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    name: string;
    sourceId: string;
    sources: SourceConfig[];
    mapping: FieldMappingConfig[];
    targetKeys: TargetKey[];
};

function createSeriesMapping(sourceFields: string[], targetKeys: TargetKey[]): FieldMappingConfig {
    const from = sourceFields.length ? sourceFields[0] : '';
    const to = targetKeys.length ? targetKeys[0].name : '';

    return {
        from,
        to,
        type: FieldType.RAW
    };
}

export default function SeriesMappingConfigList(props: Props) {
    const { name, sourceId, sources, mapping, targetKeys } = props;
    const listId = name;
    const { data } = useCachedFetcher(findReportDefinitions, RequestId.REPORT_DEFINITIONS);
    const sourceFields = useMemo(() => getSourceFields(sourceId, sources, data), [
        sourceId,
        sources,
        data
    ]);

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="series-mapping-list">
                    <div className="config-sub-header">
                        Mapping
                        <Button
                            variant="secondary"
                            className="header-add-btn"
                            onClick={() =>
                                arrayHelpers.push(createSeriesMapping(sourceFields, targetKeys))
                            }
                        >
                            <FontAwesomeIcon icon="plus" />
                        </Button>
                    </div>

                    {mapping.map((m, index) => {
                        return (
                            <FieldMappingConfigComponent
                                name={name + '.' + index}
                                mapping={m}
                                key={index}
                                listId={listId}
                                targetKeys={targetKeys}
                                onRemoved={arrayHelpers.handleRemove(index)}
                            />
                        );
                    })}

                    <datalist id={listId}>
                        {sourceFields.map((name, i) => (
                            <option key={i} value={name} />
                        ))}
                    </datalist>
                </div>
            )}
        />
    );
}
