import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

/**
 * Pager menu. Shows the current page number, with a set of navigation buttons.
 */

type Props = {
    fetching: boolean,
    resultOffset: number,
    totalResults: number,
    resultsPerPage: number,
    onPageChanged: (resultOffset: number) => void
}

export default React.memo(
    function PagerSql(props: Props) {
        const resultsPerPage = props.resultsPerPage;
        const pageIndex = Math.floor(props.resultOffset / resultsPerPage);
        const lastPageIndex = Math.floor(props.totalResults / resultsPerPage);

        const isMoveNextDisabled = props.fetching || props.totalResults <= resultsPerPage ;
        const isMovePrevDisabled = props.fetching || pageIndex <= 0;

        function onMoveFirst() {
            props.onPageChanged(0);
        }

        function onMovePrev() {
            props.onPageChanged((pageIndex - 1) * resultsPerPage);
        }

        function onMoveNext() {
            props.onPageChanged((pageIndex + 1) * resultsPerPage);
        }

        function onMoveLast() {
            const lastPageOffset = lastPageIndex * resultsPerPage;
            props.onPageChanged(lastPageOffset);
        }

        return (
            <div className="pager-toolbar">
                <ButtonGroup>
                    <Button disabled={isMovePrevDisabled} onClick={onMoveFirst}>{'<<'}</Button>
                    <Button disabled={isMovePrevDisabled} onClick={onMovePrev}>{'<'}</Button>
                    <span className="pager-currentpage">Page { pageIndex + 1 }</span>
                    <Button disabled={isMoveNextDisabled} onClick={onMoveNext}>{'>'}</Button>
                </ButtonGroup>
            </div>
        );
    });
