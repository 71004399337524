import React, { useEffect } from 'react'
import { useCachedFetcher } from '@pearlchain/component-lib-common';
import { RequestId } from 'core/dashboard/utils/requests/requestId';
import { FieldProps, getIn } from 'formik';
import { FormControl } from 'react-bootstrap';
import { DataSourceTypes } from 'core/dashboard/types/actionHandlerTypes';
import { findDataSources } from 'core/dashboard/utils/requests/requestHandlers';
import { DataSourceProvider } from 'core/dashboard/types/responseTypes';

type Props = {
    handleProvidersHandlers: (providers: DataSourceProvider[] | undefined) => void,
    type: string
}

export default function DataSourceMetaData({ field, form, handleProvidersHandlers, type }: Props & FieldProps) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    const { data } = useCachedFetcher(findDataSources, RequestId.DATA_SOURCES)

    const dataSourceMetaData = data && data.map(meta => {
        if(type === DataSourceTypes.PROVIDER){
            if(meta.providers && meta.providers.length > 0){
                return meta
            }
        } 
        else {
            if(meta.handlers && meta.handlers.length > 0){
                return meta
            }
        }
    })

    useEffect(() => {
        const providersHandlersVal = form.values.action ? form.values.action.providersHandlers : form.values.providersHandlers;
        const reportSourceVal = form.values.action ? form.values.action.dataSources : form.values.reportSource;
        if(providersHandlersVal){
            const metaData = dataSourceMetaData?.filter(meta => meta?.name === reportSourceVal)[0]
            const providersHandlers = type === DataSourceTypes.PROVIDER ? metaData?.providers : metaData?.handlers;
            handleProvidersHandlers(providersHandlers)
        }
    }, [data])
    
    function handleOnChange(value: string | undefined){
        form.setFieldValue(field.name, value)
        const metaData = dataSourceMetaData?.filter(meta => meta?.name === value)[0]
        const providersHandlers = type === DataSourceTypes.PROVIDER ? metaData?.providers : metaData?.handlers;
        handleProvidersHandlers(providersHandlers)
    }
    
    return (
        <FormControl as="select" isInvalid={error} onChange={(e) => handleOnChange(e.currentTarget.value)} name={field.name} value={field.value}>
            <option value="" disabled></option>
            { (dataSourceMetaData && dataSourceMetaData.length) && dataSourceMetaData.map((dataSource, idx) => {
                    if(dataSource){
                        return (
                            <option key={idx} id={idx+""} title={dataSource.description} value={dataSource.name}>{ dataSource.name }</option>
                        )
                    }
                } 
            )}
        </FormControl>
    )
}
