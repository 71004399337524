import React from 'react';
import { FieldArray, FastField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import { columns } from '../view/humanTasksGridColumns';

import { StandardColumnConfig } from '../humanTasksTypes';
import TextInputReadOnly from '../../../config/formik/TextInputReadOnly';
import CheckboxInput from '../../common/config/formik/input/CheckboxInput';

type Props = {
    name: string;
    processVars: StandardColumnConfig[];
};

//This method is almost the same as the populateStandardColumnConfig method in HumanTasksWidgetConfig, but we do it here again, just to make sure that
//if there is an old config saved, and a new column is added in the code, that this new column is available in the list.
function matchColumnsWithProcessVars(processVars: StandardColumnConfig[]): StandardColumnConfig[] {
    const returnVar: StandardColumnConfig[] = [];

    let i=0;
    columns.forEach((column) => {
        if(column.hidden) {
            return;
        }
        var visible = true;
        processVars.forEach((processVar) => {
            if(processVar.key === column._key) {
                visible = processVar.visible;
            }
        });
        const scc: StandardColumnConfig = {
            visible: visible, 
            key: column._key
        };
        returnVar.push(scc);
    });

    return returnVar;
}

export default function HumanTasksStandardColumnsConfig({ name, processVars }: Props) {
    const actualProcessVars: StandardColumnConfig[] = matchColumnsWithProcessVars(processVars);
    processVars = actualProcessVars;
    //console.log(processVars);
    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="p-2">
                    <Table>
                        <thead>
                            <tr className="text-center">
                                <th>Visible</th>
                                <th>Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {processVars.map((procVar, idx) => {
                                const rowName = name + '.' + idx;
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FastField
                                                placeholder="visible"
                                                name={rowName + '.visible'}
                                                component={CheckboxInput}
                                            />
                                        </td>
                                        <td>
                                            <FastField
                                                placeholder="key"
                                                name={rowName + '.key'}
                                                component={TextInputReadOnly}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            )}
        />
    );
}
