import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useAvailableWidgets } from '../widgets/widgets';
import { buildEmptyWidget } from './widgetBuilder';
import WidgetLibraryItem from './WidgetLibraryItem';
import { WidgetEntry } from '../../types/widgetLibraryTypes';
import { createWidget } from '../../actions/dashboard-actions';
import LoadingSpinner from '../LoadingSpinner';

type Props = {
    dashboardUuid: string,
    onClose: () => void
}

function WidgetLibrary(props: Props) {
    const dashboardUuid = props.dashboardUuid;

    // select the list of available widgets
    const widgets = useAvailableWidgets();

    const dispatch = useDispatch();
    const [selectedType, setSelectedWidgetType] = useState<string>('');

    // automatically select the first widget in the list as soon as it becomes available
    useEffect(() => {
        if (widgets && widgets.length) {
            const defaultWidget = widgets[0];
            setSelectedWidgetType(defaultWidget.value);
        }
    }, [widgets]);

    // find the selected widget entry
    const selectedEntry = useMemo((): WidgetEntry | undefined => {
        return widgets && widgets.find(w => w.value === selectedType);
    }, [widgets, selectedType]);  

    function renderWidgetEntry(entry: WidgetEntry, idx: number) {
        const selected = selectedType === entry.value;
        return <WidgetLibraryItem key={idx}
            widgetName={entry.name}
            widgetType={entry.value}
            widgetIcon={entry.icon}
            selected={selected}
            onSelect={setSelectedWidgetType}
        />
    }

    function renderWidgetDescription(entry: WidgetEntry) {
        const title = entry.name;
        const description = entry.description || 'no description';
        const image = entry.image;

        return <div className="widget-library-description">
            <div className="widget-library-description-title">{ title }</div>
            <div className="widget-library-description-text">{ description }</div>
            <div className="widget-library-description-image-container">
                { image ? <img src={image}/> : undefined }
            </div>
        </div> 
    }

    const onCreateConfirm = useCallback(() => {
        if (selectedType && selectedEntry) {
            const widget = buildEmptyWidget(selectedType, selectedEntry);
            dispatch(createWidget(dashboardUuid, widget));
            props.onClose();
        }
    }, [dispatch, selectedType, selectedEntry, props.onClose]);

    return (
        <React.Fragment>
            <Modal.Body style={{ padding: 0 }}>
                <div className="widget-library-container">
                    <div className="widget-library-list">
                        { widgets && widgets.map(renderWidgetEntry) }
                    </div>
                    { selectedEntry
                        ? renderWidgetDescription(selectedEntry)
                        : <LoadingSpinner/>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="widget-library-btn-confirm" onClick={onCreateConfirm}>Add to Dashboard</Button>
            </Modal.Footer>
        </React.Fragment>
    );
}

export default React.memo(WidgetLibrary);
