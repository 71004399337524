import { ReportDataSourceAux } from "./requestTypes";

export enum AccessType {
    WRITE='WRITE',
    READ_ONLY='READ_ONLY'
};

export type WebComponentSaveResponse = {
    uniqueIdentifier: string,
    version: number
}

export type WebComponentBriefResponse = {
    uniqueIdentifier: string,
    description: string,
    type: string,
    owner: string,
    order: number|null,
    creationDate: string,
    modificationDate: string,
    version: number,
    favourite: boolean,
    'access-type': AccessType,
    dataSources: ReportDataSourceAux[]
}

export type WebComponentResponse = {
    data: string
} & WebComponentBriefResponse;

export type ReportDefinitionFieldResponse = {
    name: string,
    position: number
}

export type ReportModelResponse = {
    name: string,
    fields: ReportDefinitionFieldResponse[]
}

export type ReportParameterResponse = {
    name: string,
    defaultValue: unknown
}

export enum ReportGraphContentType {
    DIAGRAM = "DIAGRAM",
    PQL = "PQL"
}

export type ReportDefinitionResponse = {
    models: ReportModelResponse[],
    parameters: ReportParameterResponse[],
    uuid: string,
    name: string,
    contentType: ReportGraphContentType
}

export type RunReportResponse = {
    data: { [key: string]: unknown }[],
    nextOffset: number,
    totalResults: number
}

export type ServiceMessageResultResponse = {
    messages: ServiceMessageResponse[];
}

export type ServiceMessageResponse = {
    message: string;
    params: unknown[];
}

export type DatasourceDefinitionResponse = {
    name: string;
    description: string;
    set: any;
    get: any;
}

export type WebPluginDefinition = {
    id: string;
    name: string;
    description: string;
    src: string;
}

export type DataSourcesResponse = {
    name: string,
    description: string,
    handlers: DataSourceHandler[],
    providers: DataSourceProvider[],
}

export type DataSourcesParamsResponse = {
    type: string,
    optional: boolean,
    name: string,
    value: string
}

export type DataSourcesResponseDto = {
    succeeded: boolean,
    failReason: string,
    result: { [key: string]: any }[]
}

export type DataSourceHandler = {
    name: string,
    description: string,
    params: DataSourcesParamsResponse[]
}

export type DataSourceProvider = {
    name: string,
    description: string,
    params: DataSourcesParamsResponse[]
}

export type WebPluginDefinitions = { [key: string]: WebPluginDefinition };
