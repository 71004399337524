import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Param } from './Editor';

type Props = {
    show: boolean,
    handleClose: () => void
    param: Param | undefined
    handleAddParam: (param: Param) => void,
    handleEditParam: (param: Param) => void
}

export default function ParamsModal(props: Props) {
    const [name, setName] = useState('')
    const [value, setValue] = useState('')

    useEffect(() => {
        if(props.param){
            setName(props.param.name);
            setValue(props.param.value)
        }
    }, [props.param])

    function handleParam(){
        if(props.param){
            props.handleEditParam({name, value})
            setName("")
            setValue("")
        }
        else{
            props.handleAddParam({name, value})
            setName("")
            setValue("")
        }
    }

    return (
            <Modal className="results-modal" show={props.show} onHide={props.handleClose} enforceFocus={false} centered>
                <Modal.Header closeButton>
                        <Modal.Title>Parameters</Modal.Title>
                </Modal.Header>
                <div className="p-3">
                    <Modal.Body>
                    <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e: any) => setName(e.target.value)}/>
                        <Form.Label className="mt-2">Value</Form.Label>
                        <Form.Control type="text" value={value} onChange={(e: any) => setValue(e.target.value)}/>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button className="mt-2" onClick={handleParam}>Apply</Button>
                </Modal.Footer>  
            </Modal>
    )
}
