import { DashboardLayoutConfig } from "../../types/storeTypes";
import { SplitLayoutConfig, LayoutType, GridLayoutConfig, FullLayoutConfig } from "./layoutTypes";

export function createSplitLayoutFromDashboardConfig(layoutConfig: DashboardLayoutConfig): SplitLayoutConfig {
    const widgetUuids = layoutConfig.layout.map(entry => entry.i!);
    return {
        type: LayoutType.SPLIT,
        cols: 2,
        layout: widgetUuids
    }
}

export function createGridLayoutFromDashboardConfig(layoutConfig: DashboardLayoutConfig): GridLayoutConfig {
    return {
        type: LayoutType.GRID,
        cols: layoutConfig.cols,
        rowHeight: layoutConfig.rowHeight,
        layout: layoutConfig.layout
    }
}

export function createFullLayout(widgetUuid: string): FullLayoutConfig {
    return {
        type: LayoutType.FULL,
        widgetUuid
    };
}
