import React, { useCallback, useMemo } from 'react';
import { ReactPluginLoader } from '@pearlchain/component-lib-plugins-core';

import { WidgetProps } from '../../widget/widgetTypes';
import { createActionHandler } from 'core/dashboard/actionhandler/actionHandler';
import PluginWidgetConfig from './config/PluginWidgetConfig';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { WidgetViewMode } from '../../../types/widgetStateTypes';

type Props = {
    pluginId: string;
} & WidgetProps;

export default function PluginWidget(props: Props) {
    const { propertySource, actions } = props;
    const config = props.state.widget.config;
    const common = props.state.widget.common;
    const evaluator = useMemo(() => new Evaluator(propertySource), []);

    /**
     * Create the action-handler. Resolves the action config and calls the real action on the widget.
     */
    const actionHandler = useMemo(() => {
        return createActionHandler(props.actions, evaluator);
    }, [evaluator]);

    // Create the event-handler. Reacts to events triggered by the plugin by performing actions defined in the
    // widget configuration.
    const events = common.events;
    const eventHandler = useCallback((eventName: string, params?: { [key: string]: unknown }) => {
        if (events) {
            for (let event of events) {
                if (event.eventName === eventName) {
                    const action = event.action;
                    if (action) actionHandler(action, params || {});
                }
            }
        }
    }, [actionHandler, events]);

    function renderView() {
        if (config == null) {
            return renderConfig();
        }
        
        return (
            <ReactPluginLoader
                pluginId={props.pluginId}
                config={config}
                refreshCount={props.state.refreshCount}
                data={props.state.data}
                evaluator={evaluator.evaluate.bind(evaluator)}
                actions={{
                    setConfig: actions.configure.bind(null, common, []),
                    setData: actions.setData,
                    setLoading: actions.setLoading,
                    show: actions.show,
                    event: eventHandler
                }}
                props={{}}
            />
        );
    }

    function renderConfig() {
        return (
            <PluginWidgetConfig
                pluginId={props.pluginId}
                common={common}
                config={config}
                configure={actions.configure}
                cancelConfig={actions.cancelConfig}
            />
        );
    }

    if (WidgetViewMode.CONFIG === props.state.viewMode) {
        return renderConfig();
    } else {
        return renderView();
    }
}

export function createPluginWidget(pluginId: string) {
    return function WrappedPluginWidget(props: WidgetProps) {
        return <PluginWidget {...props} pluginId={pluginId}/>
    }
}
