import { EvaluatorOpts } from "@pearlchain/component-lib-plugins-core";
import { getAuthTokenPayload, getAuthToken } from "@pearlchain/component-lib-common";

import { jexl } from "./jexl";
import { IPropertySource } from "./PropertySource";
import { ReportParamConfig } from "../types/configTypes";

const CHAR_EQ = 61;
const REGEXP_SUBSTITUTE_PROPS = /\$\{.*?\}/g;
const builtInPropertyDescriptors: PropertyDescriptorMap = {
    now: {
        get() {
            return Date.now();
        },
        configurable: true
    },
    loginName: {
        get() {
            const payload = getAuthTokenPayload();
            return payload ? payload.loginName : '';
        },
        configurable: true
    },
    realm: {
        get() {
            const payload = getAuthTokenPayload();
            return payload ? payload.realm : '';
        },
        configurable: true
    },
    language: {
        get() {
            const payload = getAuthTokenPayload();
            return payload ? payload.language : '';
        },
        configurable: true
    },
    'auth-token': {
        get() {
            return getAuthToken();
        },
        configurable: true
    }
};

export function isExpression(input: string) {
    return input.charCodeAt && input.charCodeAt(0) === CHAR_EQ;
}

export class Evaluator {
    public readonly propertySource?: IPropertySource;

    constructor(props?: IPropertySource) {
        this.propertySource = props;
    }

    evaluateReportParams(params: ReportParamConfig[]) {
        return params.map((param): ReportParamConfig => {
            const value = '' + this.evaluate(param.value, {}, { fallback: '' });
            return { name: param.name, value };
        });
    }
    
    evaluateObject(input: { [key: string]: string }) {
        const result: { [key: string]: string } = {};
        for (let key in input) {
            result[key] = '' + this.evaluate(input[key], {}, { fallback: '' });
        }
        return result;
    }

    evaluate(input: string, args: { [key: string]: unknown }, opts?: EvaluatorOpts): unknown {
        const context = this.createExecutionContext(args);
        const isExpr = isExpression(input);
        
        if (opts?.alwaysEvaluate || isExpr) {
            if (isExpr) {
                input = input.substr(1);
            }
    
            input = this.replacePlaceholders(input, key => key) || "";

            if(input){
                try {
                    return jexl.evalSync(input, context);
                    
                } catch(e) {
                    console.warn(e);
                    
                    if (opts && ('fallback' in opts)) {
                        return opts.fallback;
        
                    } else {
                        throw e;
                    }
                }
            }
        } else {
            return this.replacePlaceholders(input, key => {
                const value = context[key];
                return value != null ? '' + value : '';
            });
        }
    }

    /**
     * Returns the object to use during evaluation.
     * Uses Object.defineProperty to provide getter access to the propertySource
     */
    createExecutionContext(args: { [key: string]: unknown }): { [key: string]: unknown } {
        // define built-in properties
        const o = {};
        Object.defineProperties(o, builtInPropertyDescriptors);

        // define dashboard properties
        const propertySource = this.propertySource;
        if (propertySource) {
            const propertyKeys = propertySource.listProperties();
            for (const key of propertyKeys) {    
                Object.defineProperty(o, key, {
                    get: propertySource.getProperty.bind(propertySource, key),
                    configurable: true
                })
            }
        }

        // define arguments
        for (const key in args) {
            Object.defineProperty(o, key, {
                value: args[key]
            });
        }

        return o;
    }

    private replacePlaceholders(input: string, fn: (key: string) => string) {
        if (input != null) {
            return ('' + input).replace(REGEXP_SUBSTITUTE_PROPS, (str) => {
                const key = str.substring(2, str.length - 1);
                return fn(key);
            });
        }
    }
}
