import React, { useMemo } from 'react';
import { Formik } from 'formik';

import HumanTasksWidgetConfigForm from './HumanTasksWidgetConfigForm';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { HumanTasksConfig, StandardColumnConfig } from '../humanTasksTypes';
import { WidgetActions } from '../../../../types/widgetTypes';
import { columns } from '../view/humanTasksGridColumns';

type Props = {
    common: WidgetCommonConfig;
    config: HumanTasksConfig | undefined;
    actions: WidgetActions;
};

export type HumanTasksFormValues = HumanTasksConfig & WidgetCommonConfig;

const DEFAULT_VALUES: HumanTasksConfig = {
    filter: [],
    assignedToMe: false,
    unassigned: false,
    formKeys: [],
    processVariables: [],
    extraColumnVariables: [],
    standardColumnVariables: populateStandardColumnConfig(),
    priority: {
        min: '0',
        max: '100'
    }
};

function populateStandardColumnConfig(): StandardColumnConfig[] {
    const returnVar: StandardColumnConfig[] = [];

    let i=0;
    columns.forEach((column) => {
        if(column.hidden === true) {
            return;
        }
        var visible = true;
        const scc: StandardColumnConfig = {
            visible: visible, 
            key: column._key
        };
        returnVar.push(scc);
    });

    return returnVar;
}

export default function HumanTasksWidgetConfig(props: Props) {
    const common = props.common;
    const config = props.config;

    const initialValues = useMemo((): HumanTasksFormValues => {
        var configValues = config || DEFAULT_VALUES;
        if(configValues !== undefined) {
            if(configValues.standardColumnVariables === undefined) {
                configValues = Object.assign([], configValues);
                configValues.standardColumnVariables = populateStandardColumnConfig();
            }
        }
        return Object.assign({}, common, configValues);
    }, [config, common]);

    function onSubmit(values: HumanTasksFormValues) {
        const common: WidgetCommonConfig = {
            widgetName: values.widgetName,
            pollInterval: values.pollInterval,
            events: values.events
        };

        const config: HumanTasksConfig = {
            filter: values.filter,
            assignedToMe: values.assignedToMe,
            unassigned: values.unassigned,
            formKeys: values.formKeys,
            priority: values.priority,
            processVariables: values.processVariables,
            extraColumnVariables: values.extraColumnVariables,
            standardColumnVariables: values.standardColumnVariables
        };

        props.actions.configure(common, config, []);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            component={HumanTasksWidgetConfigForm}
        />
    );
}
