import { Middleware, Dispatch } from 'redux';
import { error, success } from 'react-notification-system-redux';

import { StoreState, Dashboard, Widget } from '../types/storeTypes';
import {
    saveDashboardAndWidgetChanges,
    DashboardSyncState,
    isDashboardChanged,
    isWidgetChanged
} from './dashboardChangesSaver';

import ActionType, {
    beginSavingChanges,
    doneSavingChanges,
    markDashboardModified,
    errorSavingChanges,
    SaveDashboardChangesAction
} from '../actions/dashboard-actions';

function dashboardToSync(dashboard: Dashboard) {
    const widgets = new Map<string, Widget<unknown>>();
    for (let [widgetUuid, widget] of Object.entries(dashboard.widgets)) {
        widgets.set(widgetUuid, widget!);
    }
    return { dashboard, widgets };
}

export const saveDashboardsMiddleware: Middleware<{}, StoreState, Dispatch> = store => {
    let syncDashboards: Map<string, DashboardSyncState> = new Map();

    function getCurrentDashboards() {
        return store.getState().dashboard.dashboards;
    }

    function handleUnknownAction(dashboardsState: { [uuid: string]: Dashboard }) {
        // detect changes to the Dashboard, marking the dashboard as "modified"
        for (let [dashboardUuid, dashboard] of Object.entries(dashboardsState)) {
            let modified = dashboard.isModified;

            // Handle DASHBOARD changes
            let prevSync = syncDashboards.get(dashboardUuid);

            if (prevSync == null) {
                // the first time the Dashboard was "seen"
                prevSync = { dashboard, widgets: new Map<string, Widget<unknown>>() };
                syncDashboards.set(dashboardUuid, prevSync);

            } else if (!modified && isDashboardChanged(prevSync.dashboard, dashboard)) {
                // the dashboard was changed
                modified = true;
                store.dispatch(markDashboardModified(dashboardUuid, true));
            }

            // Handle WIDGET changes
            const prevSyncWidgets = prevSync.widgets;
            for (let [widgetUuid, widget] of Object.entries(dashboard.widgets)) {
                const prevWidget = prevSyncWidgets.get(widgetUuid);

                if (prevWidget == null) {
                    // the first time the widget was "seen"
                    prevSyncWidgets.set(widgetUuid, widget);

                } else if (!modified && isWidgetChanged(prevWidget, widget)) {
                    // the widget was changed
                    modified = true;
                    store.dispatch(markDashboardModified(dashboardUuid, true));
                }
            }
        }

        // clear deleted dashboards sync state. Clean-up some memory..
        for (let dashboardUuid of syncDashboards.keys()) {
            if (!(dashboardUuid in dashboardsState)) {
                syncDashboards.delete(dashboardUuid);
            }
        }
    }

    function handleSaveDashboardChanges(action: SaveDashboardChangesAction, dashboardsState: { [uuid: string]: Dashboard }) {
        const dashboardUuid = action.dashboardUuid;
        const dashboard = dashboardsState[dashboardUuid];

        // ignore if another save is in progess
        if (dashboard == null || dashboard.isSaving) return;

        const prevDashboard = syncDashboards.get(dashboardUuid);
        if (prevDashboard == null) return;

        store.dispatch(beginSavingChanges(dashboardUuid));

        saveDashboardAndWidgetChanges(prevDashboard.dashboard, dashboard).then(
            ([version, widgetVersions]) => {
                // saved successfully
                syncDashboards.set(dashboardUuid, dashboardToSync(dashboard));
                store.dispatch(doneSavingChanges(dashboardUuid, version, widgetVersions));
                store.dispatch(
                    success({
                        title: 'Changes Saved'
                    })
                );
            },
            e => {
                // threw an error
                console.error(e);
                store.dispatch(
                    error({
                        title: 'Error Saving Dashboard',
                        message: 'Check the console for details'
                    })
                );
                store.dispatch(errorSavingChanges(dashboardUuid));
            }
        );
    }

    return next => action => {
        const prevDashboardsState = getCurrentDashboards();
        next(action);
        const nextDashboardsState = getCurrentDashboards();

        
        switch (action.type) {
            case ActionType.SAVE_DASHBOARD_CHANGES:
                handleSaveDashboardChanges(action, nextDashboardsState);
                break;
                
            default:
                // ignore actions that didn't update the dashboards state
                if (prevDashboardsState !== nextDashboardsState) {
                    handleUnknownAction(nextDashboardsState);
                }
                break;
        }
    };
};
