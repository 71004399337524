import React, { useRef, useEffect } from 'react';
import Chart, { ChartData, ChartDataSets } from 'chart.js';

import { ActionHandler } from 'core/dashboard/types/actionHandlerTypes';
import { BarChartConfig } from '../barChartTypes';
import { toString, toNumber } from '../../../../utils/dataTypes';
import { KEY_X_AXIS, KEY_Y_AXIS, KEY_SERIES } from '../barChartHelpers';
import { getColorAt } from '../../../../utils/pallette';
import { groupDataBySeries } from '../../../../utils/graph/graphHelpers';
import { axisConfigToChartOptions } from '../../../../utils/graph/chartjsHelpers';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { ConditionalFmtConfig } from 'core/dashboard/types/formatConfigTypes';
import { resolveConditionalFormat, applyFormatters, resolveFormatValues } from 'core/dashboard/formatters/formatHelpers';
import { barChartFormatters } from '../barChartFormatters';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';

type Props = {
    config: BarChartConfig,
    data: { [key: string]: unknown }[];
    actionHandler?: ActionHandler;
    evaluator: Evaluator;
    row?: { [key: string]: unknown }[];
    actions: WidgetActions;
}

function resolveChartData(seriesData: { [key: string]: unknown }[][], evaluator: Evaluator, formatConfig: ConditionalFmtConfig | undefined, actions: WidgetActions): ChartData {
    const lookup = new Map<string, number>();
    const datasets: ChartDataSets[] = [];
    const labels: string[] = [];
    let errorFlag = false;

    for (let i = 0, m = seriesData.length; i < m; i++) {
        const data = seriesData[i];
        const dataPoints: number[] = [];
        
        // if more than one series, then different color per series (stacked bar-chart).
        // If exactly one series, then different color per bar.
        let backgroundColors: string[] | string;
        if (seriesData.length > 1) {
            backgroundColors = getColorAt(i);
        } else {
            backgroundColors = [];
        }

        const dataset: ChartDataSets = {
            backgroundColor: backgroundColors,
            data: dataPoints
        }

        for (const datum of data) {
            const label = toString(datum[KEY_X_AXIS]);
            const value = toNumber(datum[KEY_Y_AXIS]);
            const series = (datum[KEY_SERIES] || '') as string;

            // by default, the dataset label is set to the series key
            if (dataset.label == null) {
                dataset.label = series;
            }

            let index: number | undefined = lookup.get(label);
            if (index == null) {
                index = labels.length;
                lookup.set(label, index);
                labels.push(label);
            }

            if (Array.isArray(backgroundColors)) {
                backgroundColors[index] = getColorAt(index);
            }

            dataPoints[index] = value;

            try {
                // resolve the first format that matches the condition
                const formatBlock = resolveConditionalFormat(formatConfig, evaluator, datum);

                // then evaluate the dynamic properties of the format object
                const formatValues = resolveFormatValues(formatBlock, evaluator, datum);

                // apply formatters to the current dataset
                applyFormatters(barChartFormatters, formatValues, {
                    dataset,
                    labels,
                    index
                });
            }
            catch (e) {
                if(!errorFlag){
                    console.warn(e);
                    const msg = 'Invalid expression: "' + e + '"';
                    actions.show('error', 'Formatter Uncaught Exception',  msg);
                    errorFlag = true;
                }
                
            }
        }

        datasets.push(dataset);
    }

    return {
        datasets,
        labels
    };
}

export default function BarChartComponent({ data, config, actionHandler, evaluator, row, actions }: Props) {
    const elRef = useRef<HTMLCanvasElement | null>(null);
    const chartRef = useRef<Chart | null>(null);
    const seriesDataRef = useRef<{ [key: string]: unknown }[][] | null>(null);

    useEffect(() => {
        const xAxis = axisConfigToChartOptions(config.xAxis);
        const yAxis = axisConfigToChartOptions(config.yAxis);

        const seriesData = groupDataBySeries(KEY_SERIES, data);
        seriesDataRef.current = seriesData;
        const chartData = resolveChartData(seriesData, evaluator, config.format, actions);

        let chart = chartRef.current;
        if (chart == null) {
            const ctx = elRef.current;
            if (ctx == null) return;

            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    legend: {
                        display: false
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [xAxis],
                        yAxes: [yAxis]
                    },
                    onClick: (e, g: any) => {
                        if (!g || !g.length) return;

                        const index = g[0]._index as number;
                        const datasetIndex = g[0]._datasetIndex as number;
                        const datum = seriesDataRef.current![datasetIndex][index];

                        const auxRow:any = {}
                        auxRow[config?.xAxis?.column+""] = datum.x
                        auxRow[config?.yAxis?.column+""] = datum.y

                        if (actionHandler && config.action) {
                            if(row){
                                if(!row[index]) {
                                    actionHandler(config.action, {row: auxRow});
                                }
                                else {
                                    actionHandler(config.action, {row: row[index]});
                                }
                            }
                            else{
                                actionHandler(config.action, datum);
                            }
                        }
                    }
                }
            });

        } else {
            chart.data = chartData;
            chart.update();
        }
        
    }, [data, config, actionHandler, evaluator]);

    return (
        <div className='canvasWrapper'>
            <canvas width="100%" height="100%" ref={elRef} />
        </div>
    );
}
