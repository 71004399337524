import React, { useState } from 'react';
import cn from 'classnames';

import { DashboardProperty } from "../../types/storeTypes";
import { DashboardAction, DashboardActionType } from '../../types/dashboardTypes';
import DashboardPropertyValueEditor from './DashboardPropertyValueEditor';
import { resolvePropertyValueAsString } from 'core/dashboard/properties/dashboardProperties';

type Props = {
    name: string;
    value: unknown;
    propertyConfig: DashboardProperty;
    handleSetProperties: (name: string, value:string) => void;
    handleSubmit: () => void;
}

function DashboardPropertyValue({ name, value, propertyConfig, handleSetProperties, handleSubmit }: Props) {
    const [editing, setEditing] = useState(false);
    const editable = propertyConfig.isEditable;

    const handleEditorSubmit = (value: unknown) => {
        setEditing(false);
        handleSubmit();
    }

    const handleEditorCancel = () => {
        setEditing(false);
    };

    const handleEnterEditMode = () => {
        if (editable && !editing) setEditing(true);
    };

    return (
        <div className="dashboard-property" onClick={handleEnterEditMode}>
            <span className="dashboard-property-label">{propertyConfig.name}</span>
            <span className="dashboard-property-label">=</span>
            {editing ? (
                <DashboardPropertyValueEditor
                    type={propertyConfig.type}
                    value={value}
                    onSubmit={handleEditorSubmit}
                    onCancel={handleEditorCancel}
                    handleSetProperties={handleSetProperties}
                    name={name}
                />
            ) : (
                <div
                    className={cn("dashboard-property-value", { editable })}
                >
                    {resolvePropertyValueAsString(propertyConfig.type, value)}
                </div>
            )}
        </div>
    );
}

export default React.memo(DashboardPropertyValue);
