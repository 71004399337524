import { DialogOpts } from './dialogTypes';

export enum ActionType {
    SHOW_DIALOG = 'dashboards.common.SHOW_DIALOG',
    DIALOG_RESULT = 'dashboards.common.DIALOG_RESULT',
    CLEAR_DIALOG = 'dashboards.common.CLEAR_DIALOG'
}

export function showDialog(dialogId: string, opts: DialogOpts): ShowDialogAction {
    return {
        type: ActionType.SHOW_DIALOG,
        dialogId,
        opts
    };
}

export function dialogResult(dialogId: string, result: unknown): DialogResultAction {
    return {
        type: ActionType.DIALOG_RESULT,
        dialogId,
        result
    };
}

export function clearDialog(dialogId: string): ClearDialogAction {
    return {
        type: ActionType.CLEAR_DIALOG,
        dialogId
    };
}

export type ShowDialogAction = {
    type: ActionType.SHOW_DIALOG;
    dialogId: string;
    opts: DialogOpts;
}

export type DialogResultAction = {
    type: ActionType.DIALOG_RESULT;
    dialogId: string;
    result: unknown;
}

export type ClearDialogAction = {
    type: ActionType.CLEAR_DIALOG;
    dialogId: string;
}
