import { createContext, useContext } from 'react';

import { DashboardContext as Context } from '../../types/dashboardTypes';


export const DashboardContext = createContext<Context>(null as any);

export const DashboardContextProvider = DashboardContext.Provider;

export const DashboardContextConsumer = DashboardContext.Consumer;

export function useDashboardContext() {
    return useContext(DashboardContext);
}

