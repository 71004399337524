import React from 'react';
import { ModalContainer } from '@pearlchain/component-lib-ui';
import { Field, FieldProps } from 'formik';
import ColumnFormatConfigModal from '../../../../form/columnformat/ColumnFormatConfigModal';

type Props = {
    name: string;
    isOpen: boolean;
    onClose: () => void;
};

export default function ColumnFormattersField(props: Props) {
    const name = props.name;
    return (
        <Field
            name={name}
            render={({ field, form }: FieldProps) => (
                <ModalContainer>
                    <ColumnFormatConfigModal
                        config={field.value}
                        visible={props.isOpen}
                        onSubmit={c => {
                            props.onClose();
                            form.setFieldValue(name, c);
                        }}
                        onClose={props.onClose}
                    />
                </ModalContainer>
            )}
        />
    );
}
