export type GroupByTransform = {
    type: 'GROUP_BY',
    params: {
        'group-by': string | null,
        alias: string | null
    }
};

export type CountTransform = {
    type: 'COUNT',
    params: {
        alias: string
    }
};

export type SumTransform = {
    type: 'SUM',
    params: {
        'sum-by': string,
        alias: string
    }
};

export type LimitTransform = {
    type: 'LIMIT',
    params: {
        limit: number
    }
}

export type SortByTransform = {
    type: 'SORT',
    params: {
        'sort-by': string,
        direction?: string
    }
}

export type SelectTransform = {
    type: 'SELECT',
    params: {
        fields: string[],
        default: string
    }
}

export type FilterTransform = {
    type: 'FILTER',
    params: {
        field: string,
        value: number,
        comp: 'eq'
    }
}

export type ReportTransform = 
    GroupByTransform |
    CountTransform |
    SumTransform |
    LimitTransform |
    SortByTransform |
    SelectTransform |
    FilterTransform;

/** Transform factories */

export function createGroupByTransform(groupBy: string | null, alias: string | null): GroupByTransform {
    return {
        type:'GROUP_BY',
        params: { 'group-by': groupBy, alias }
    };
}

export function createSumTransform(sumBy: string, alias: string): SumTransform {
    return {
        type: 'SUM',
        params: { 'sum-by': sumBy, alias }
    };
}

export function createCountTransform(alias: string): CountTransform {
    return {
        type: 'COUNT',
        params: { alias }
    };
}

export function createLimitTransform(limit: number): LimitTransform {
    return {
        type: 'LIMIT',
        params: { limit }
    };
}

export function createSelectTransform(fields: string[], def?: string): SelectTransform {
    return {
        type: 'SELECT',
        params: { fields, default: def || '' }
    };
}

export function createSortByTransform(sortBy: string, direction: string): SortByTransform {
    return {
        type: 'SORT',
        params: {
            "sort-by": sortBy,
            direction
        }
    };
}
