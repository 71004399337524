import { createSelector } from 'reselect';
import { StoreState, Dashboard, DashboardLayoutConfig, Widget, DashboardProperty, OpenPageState } from "../../types/storeTypes";
import { AccessType, WebComponentBriefResponse, ReportDefinitionResponse } from "../../types/responseTypes";

export function isShowPropertiesPanel(state: StoreState): boolean {
    return state.dashboard.showPropertiesPanel;
}

export function getActiveDashboardUuid(state: StoreState): string | undefined {
    return state.dashboard.activeDashboard;
}

export function getOpenPageState(state: StoreState): OpenPageState | undefined {
    return state.dashboard.openPage;
}

export function getDashboardProperties(state: StoreState, dashboardUuid: string): DashboardProperty[] | undefined {
    return state.dashboard.dashboards[dashboardUuid]?.properties;
}

export function getDashboard(state: StoreState, dashboardUuid: string): Dashboard | undefined {
    return state.dashboard.dashboards[dashboardUuid];
}

export function getDashboardLayout(state: StoreState, dashboardUuid: string): DashboardLayoutConfig|undefined {
    return state.dashboard.dashboards[dashboardUuid]?.layout;
}

export function getFirebaseToken(state: StoreState): string {
    return state.dashboard.firebaseToken;
}

export function getAvailableOwnedWebComponents(state: StoreState): WebComponentBriefResponse[] | undefined {
    return state.dashboard.available?.owned;
}

export function getAvailableStarredWebComponents(state: StoreState): WebComponentBriefResponse[] | undefined {
    return state.dashboard.available?.starred;
}

export function getAvailableSharedWebComponents(state: StoreState): WebComponentBriefResponse[] | undefined {
    return state.dashboard.available?.shared;
}

export function getActiveDashboard(state: StoreState): Dashboard|undefined {
    const activeUuid = getActiveDashboardUuid(state);
    return activeUuid ? state.dashboard.dashboards[activeUuid] : undefined;
}

export function getDashboardAccessType(state: StoreState, dashboardUuid: string): AccessType {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.accessType : AccessType.READ_ONLY;
}

export function getDashboardWidget(state: StoreState, dashboardUuid: string, widgetUuid: string): Widget<unknown> | undefined {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.widgets[widgetUuid] : undefined;
}

export function getDashboardVersion(state: StoreState, dashboardUuid: string): number {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.version : 0;
}

export function isDashboardReadonly(state: StoreState, dashboardUuid: string | undefined) {
    const dashboard = dashboardUuid && getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.accessType === AccessType.READ_ONLY : true;
}

export function getDashboardName(state: StoreState, dashboardUuid: string): string | undefined {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.name : '';
}

export function getDashboardRefreshCount(state: StoreState, dashboardUuid: string): number | undefined {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.refreshCount : undefined;
}

export function isDashboardModified(state: StoreState, dashboardUuid: string): boolean {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.isModified : false;
}

export function isDashboardSaving(state: StoreState, dashboardUuid: string): boolean {
    const dashboard = getDashboard(state, dashboardUuid);
    return dashboard ? dashboard.isSaving : false;
}

export function getDashboardDescriptions(state: StoreState, dashboardUuids: string[]): string[] | undefined {
    const descriptions: string[] = [];
    for (const uuid of dashboardUuids) {
        const dashboard = getDashboard(state, uuid);

        if (dashboard){
            descriptions.push(dashboard.name);
        }
    };
    return descriptions;
}

export function getReportDefinitions(state: StoreState): ReportDefinitionResponse[] | undefined {
    return state.dashboard.reportDefinitions;
}


export const getAllAvailableWebComponents = createSelector(
    getAvailableStarredWebComponents,
    getAvailableOwnedWebComponents,
    getAvailableSharedWebComponents,
    (starred, owned, shared) => {
        if (starred && owned && shared) {
            return starred.concat(owned).concat(shared);
        } else {
            return [];
        }
    }
)


