import { SourceConfig, SourceMappingConfig } from '../sourceMappingTypes';
import { RunReportResponse } from '../../../types/responseTypes';
import { mapData, CHAR_SEP } from './dataMapper';

function groupDataBySourceId(responses: RunReportResponse[], sources: SourceConfig[]) {
    const lookupById = new Map<string, { [key: string]: unknown }[]>();
    for (let i = 0, n = Math.min(responses.length, sources.length); i < n; i++) {
        const response = responses[i];
        const source = sources[i];
        lookupById.set(source.name, response.data);
    }
    return lookupById;
}

export function mapSourcesData(
    responses: RunReportResponse[],
    sourceMapping: SourceMappingConfig,
    seriesKey: string,
    isSourceAdvanced: boolean
) {
    const sources = sourceMapping.sources;
    const dataById = groupDataBySourceId(responses, sources);

    const series = sourceMapping.series;
    const sourcesData: { [key: string]: unknown }[] = [];

    for (let currSeries of series) {
        const data = dataById.get(currSeries.source);
        if (!data) {
            console.warn('no source exists with id: ' + currSeries.source);
            continue;
        }

        const dataMapped = mapData(data, currSeries.mappings, isSourceAdvanced);
        const series = currSeries.series;
        
        for (let datum of dataMapped) {
            if (series) {
                if (seriesKey in datum) {
                    datum[seriesKey] += CHAR_SEP + datum[seriesKey];
                } else {
                    datum[seriesKey] = series;
                }
            }

            sourcesData.push(datum);
        }
    }

    return sourcesData;
}
