import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HeaderItemSave from './HeaderItemSave';
import { StoreState } from '../../types/storeTypes';
import { getActiveDashboardUuid, getDashboard } from '../../utils/selector/selectors';
import { AccessType } from '../../types/responseTypes';

import HeaderItemTitle from './HeaderItemTitle';
import WidgetLibraryMenuItem from './HeaderItemWidgetLibrary';
import HeaderItemSettings from './HeaderItemSettings';
import HeaderItemProperties from './HeaderItemProperties';

type Props = {
    isSideMenuVisible: boolean;
    onToggleSideMenu: () => void;
};

// select state from the store for use in the component
function selector(state: StoreState) {
    const dashboardUuid = getActiveDashboardUuid(state);
    let hasProperties = false;
    let accessType = AccessType.READ_ONLY;

    if (dashboardUuid) {
        const dashboard = getDashboard(state, dashboardUuid);

        if (dashboard) {
            hasProperties = dashboard.properties.length > 0;
            accessType = dashboard.accessType;
        }
    }

    return {
        dashboardUuid,
        accessType,
        hasProperties
    };
}

export default function SubMenu({ isSideMenuVisible, onToggleSideMenu }: Props) {
    const { dashboardUuid, accessType, hasProperties } = useSelector(selector, shallowEqual);

    return (
        <div className="dashboard-header">
            {!isSideMenuVisible && (
                <div key="item-toggle-sidemenu" className="dashboard-header-item">
                    <div className="dashboard-header-btn" onClick={onToggleSideMenu}>
                        <FontAwesomeIcon icon={['far', 'caret-square-right']} />
                    </div>
                </div>
            )}
            {dashboardUuid && <HeaderItemSave dashboardUuid={dashboardUuid}/>}
            {dashboardUuid && accessType === AccessType.WRITE && (
                <WidgetLibraryMenuItem dashboardUuid={dashboardUuid}/>
            )}
            <div className="flex-grow">
                {dashboardUuid && <HeaderItemTitle dashboardUuid={dashboardUuid}/>}
            </div>
            {dashboardUuid && hasProperties && <HeaderItemProperties/>}
            <HeaderItemSettings/>
        </div>
    );
}
