import React, { useMemo, useContext } from 'react';

import DashboardContainer from '../dashboard/DashboardContainer';
import { DashboardRootParentState } from '../../types/dashboardTypes';
import { DashboardContext } from '../dashboard/context';
import { widgetContext } from './context';

export default function WidgetDrilldownContainer() {
    const dashboardCtx = useContext(DashboardContext);
    const parent = dashboardCtx.parent;

    const widgetCtx = useContext(widgetContext);
    const widgetState = widgetCtx.state;
    const dispatch = widgetCtx.dispatch;

    const drilldown = widgetState.drilldown;
    const entry = drilldown[drilldown.length - 1];

    const rootParent = useMemo((): DashboardRootParentState => {
        if (parent != null) {
            // if this widget already has a parent, then pass through parent root
            // to the child dashboard
            return parent;

        } else {
            return {
                widget: widgetState,
                dispatch
            };
        }
    }, [widgetState, dispatch, parent]);

    function getParentProperties(){
        let props = entry.properties
        for (const key in dashboardCtx.state.properties) {
            props[key] = dashboardCtx.state.properties[key]
        }
        return props
    }

    return (
        <DashboardContainer
            key={entry.dashboardUuid}
            splitSize={0}
            dashboardUuid={entry.dashboardUuid}
            rootParent={rootParent}
            parentProperties={getParentProperties()}
            readonly={false}
        />
    );
}
