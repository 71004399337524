import React, { useEffect, useCallback, useRef } from "react";
import { useForceRender } from "@pearlchain/component-lib-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DashboardPropertyType } from "../../types/dashboardPropertyTypes";
import DashboardPropertyInput from "../header/properties/DashboardPropertyInput";

type Props = {
    type: DashboardPropertyType;
    value: unknown;
    onCancel: () => void;
    onSubmit: (value: unknown) => void;
    name: string
    handleSetProperties: (name: string, value:string) => void
}

function DashboardPropertyValueEditor({ type, value, onCancel, onSubmit, handleSetProperties, name }: Props) {
    const forceUpdate = useForceRender();
    const valueRef = useRef(value);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                onSubmit(valueRef.current);

            } else if (e.key === 'Escape') {
                onCancel();
            }
        };
        
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [onCancel, onSubmit]);

    const handleSubmit = useCallback(() => {
        onSubmit(valueRef.current);
    }, [onSubmit]);

    const handleChange = (value: any) => {
        valueRef.current = value;
        handleSetProperties(name,value)
        forceUpdate();
    }

    return (
        <div ref={ref} className="dashboard-property-value-editor">
            <DashboardPropertyInput
                type={type}
                value={valueRef.current}
                onChange={handleChange}
            />
            <FontAwesomeIcon icon="check" onClick={handleSubmit}/>
            <FontAwesomeIcon icon="times" onClick={onCancel}/>
        </div>
    );
}

export default DashboardPropertyValueEditor;
