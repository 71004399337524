import React, { Dispatch } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formatter, FmtInputValue } from '../../../types/formatConfigTypes';
import { Action, ActionType } from './formatConfigReducer';

type Props = {
    blockIndex: number;
    formatValues: { [key: string]: FmtInputValue };
    formatters: Formatter[];
    dispatch: Dispatch<Action>;
}

export default function FormatBlockDropdown({ blockIndex, formatValues, formatters, dispatch }: Props) {

    const handleAddFormatValue = (formatter: Formatter) => {
        const key = formatter.key;
        const value: FmtInputValue = { dynamic: false, value: formatter.initialValue };
        dispatch({
            type: ActionType.SET_FORMAT_VALUE,
            blockIndex,
            key,
            value
        });
    }

    const availableFormatters = formatters.filter(fmt => !(fmt.key in formatValues));

    if (!availableFormatters.length) {
        return null;
    }

    return (
        <div className="format-block-dropdown">
            <Dropdown>
                <Dropdown.Toggle
                    variant="link"
                    id={'dropdown-' + blockIndex}
                    style={{ color: 'inherit' }}
                >
                    <FontAwesomeIcon icon="plus" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {availableFormatters.map(fmter => {
                        return (
                            <Dropdown.Item
                                key={fmter.key}
                                onSelect={() => handleAddFormatValue(fmter)}
                            >
                                {fmter.label}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
