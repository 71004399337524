import React from 'react';
import { InputGroup, OverlayTrigger, Popover } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextInput from './TextInput';
import DateInputPopover from './DateInputPopover';

type Props = {
    value: string | undefined;
    onChange: (value: string) => void;
}

function DateInput({ value, onChange }: Props) {
    
    const popover = (
        <Popover id="popover-date" style={{ maxWidth: 350 }}>
            <Popover.Title as="h3">Date</Popover.Title>
            <DateInputPopover
                input={value || ''}
                onChange={onChange}
            />
        </Popover>
    );
    
    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <FontAwesomeIcon icon="calendar"/>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover}>
                <TextInput
                    value={value || ''}
                    onChange={onChange}
                />
            </OverlayTrigger>
        </InputGroup>
    );
}

export default DateInput;
