import { WidgetDefinition } from "../../../types/widgetTypes";
import iframeImage from '../../../../../img/iframe.png';
import IframeWidget from "./IframeWidget";

export const IFRAME_WIDGET_TYPE = 'IFRAME_TYPE';

export const IFRAME_WIDGET: WidgetDefinition = {
    name: 'Iframe',
    description: 'Shows an Iframe containing an embedded page',
    icon: 'window-maximize',
    image: iframeImage,
    component: IframeWidget,
    listSources: () => []
}
