import React from 'react';

import { SplitLayoutConfig } from './layoutTypes';
import WidgetLoader from '../widget/WidgetLoader';

type Props = {
    dashboardUuid: string;
    layout: SplitLayoutConfig;
    
};

export default function SplitLayout({ dashboardUuid, layout }: Props) {
    const groups: string[][] = [];
    const numCols = layout.cols;
    let i = 0;
    let group: string[] = [];
    for (let widgetUuid of layout.layout) {
        group.push(widgetUuid);

        if (++i >= numCols) {
            groups.push(group);
            group = [];
            i = 0;
        }
    }

    if (group.length)
        groups.push(group);

    return (
        <div className="split-layout">
            {groups.map(gp => (
                <div className="split-layout-row">
                    {gp.map(widgetUuid => (
                        <div key={widgetUuid} className="split-layout-item">
                            <WidgetLoader dashboardUuid={dashboardUuid} widgetUuid={widgetUuid} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
