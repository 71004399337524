import React, { useCallback, useMemo } from "react";
import { FormControl, FormGroup, FormLabel, Row, Col } from "react-bootstrap";

import { ActionConfig, ActionHandlerType } from "core/dashboard/types/actionHandlerTypes";
import { ActionInputDef } from "./actionInputTypes";
import SetPropertyActionParamsInput from "./SetPropertyActionInput";
import { FieldProps } from "formik";
import DrilldownActionInput from "./DrilldownActionInput";
import ShowRcpViewActionInput from "./ShowRcpViewActionInput";
import HttpRequestActionInput from "./HttpRequestActionInput";
import ShowPageActionInput from "./ShowPageActionInput";
import DataSourcesActionInput from "./DataSourcesActionInput";

/**
 * Form action field-type config
 */

const SET_PROPERTY_INPUT: ActionInputDef = {
    id: ActionHandlerType.SET_PROPERTY,
    label: 'Set Property',
    component: SetPropertyActionParamsInput,
    defaultValue: {
        type: ActionHandlerType.SET_PROPERTY,
        property: '',
        value: 'value'
    }
}

const DRILLDOWN_INPUT: ActionInputDef = {
    id: ActionHandlerType.DRILLDOWN,
    label: 'Drilldown',
    component: DrilldownActionInput,
    defaultValue: {
        type: ActionHandlerType.DRILLDOWN,
        dashboardUuid: '',
        properties: []
    }
}

const SHOW_RCP_INPUT: ActionInputDef = {
    id: ActionHandlerType.SHOW_RCP_VIEW,
    label: 'Show RCP View',
    component: ShowRcpViewActionInput,
    defaultValue: {
        type: ActionHandlerType.SHOW_RCP_VIEW,
        viewName: '',
        viewParams: []
    }
}

const HTTP_REQUEST_INPUT: ActionInputDef = {
    id: ActionHandlerType.HTTP_REQUEST,
    label: 'Http request',
    component: HttpRequestActionInput,
    defaultValue: {
        type: ActionHandlerType.HTTP_REQUEST,
        url: '',
        params: [],
        refreshDashboard: false
    }
}

const SHOW_PAGE_INPUT: ActionInputDef = {
    id: ActionHandlerType.SHOW_PAGE,
    label: 'Show Page',
    component: ShowPageActionInput,
    defaultValue: {
        type: ActionHandlerType.SHOW_PAGE,
        url: '',
        params: []
    }
};

const DATA_SOURCE_INPUT: ActionInputDef = {
    id: ActionHandlerType.DATA_SOURCE,
    label: 'Data Sources',
    component: DataSourcesActionInput,
    defaultValue: {
        type: ActionHandlerType.DATA_SOURCE,
        dataSources: '',
        url: '',
        params: [],
        refreshDashboard: false,
        providersHandlers: ''
    }
}

const ACTION_INPUTS: ActionInputDef[] = [
    SET_PROPERTY_INPUT,
    DRILLDOWN_INPUT,
    SHOW_RCP_INPUT,
    HTTP_REQUEST_INPUT,
    SHOW_PAGE_INPUT,
    DATA_SOURCE_INPUT
];

export default function ActionInput(props: FieldProps) {
    const { field, form } = props;

    const value = field.value as ActionConfig | undefined;
    const actionType = value ? value.type : '';
    const actionDef = useMemo(() => ACTION_INPUTS.find(act => act.id === actionType), [actionType]);

    // when the type of the action was changed
    const onTypeChanged = useCallback((type: ActionHandlerType) => {
        const action = ACTION_INPUTS.find(act => act.id === type);
        if (action) {
            form.setFieldValue(field.name, action.defaultValue);
        } else {
            form.setFieldValue(field.name, undefined);
        }
    }, [actionType, field.name]);

    // the input component to show
    const inputComponent = value && actionDef ? actionDef.component : undefined;
    
    return (
        <FormGroup as={Row}>
            <Col xs={3}>
                <FormLabel>Action Type</FormLabel>
            </Col>
            <Col xs={9}>
                <FormControl as="select" value={actionType} onChange={(e: any) => onTypeChanged(e.target.value)}>
                    <option value="">None</option>
                    {ACTION_INPUTS.map(action => (
                        <option key={action.id} value={action.id}>{ action.label }</option>
                    ))}
                </FormControl>
                {inputComponent ? React.createElement(inputComponent, props) : undefined}
            </Col>
        </FormGroup>
    );
}
