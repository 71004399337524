import React from 'react';
import { FieldArray } from "formik";
import { LineChartSourceConfig } from '../lineChartTypes';
import { Button, Form } from 'react-bootstrap';

import LineChartWidgetSourceConfig from './LineChartWidgetSourceConfig';

type Props = {
    sources: LineChartSourceConfig[];
    setFieldValue: (field: string, value: any) => void;
}

export default function LineChartWidgetBasicConfig(props: Props) {
    return (
        <FieldArray
            name="sources"
            render={arrayHelpers => (
                <Form.Group>
                    {props.sources.map((source, index) => (
                        <LineChartWidgetSourceConfig
                            index={index}
                            key={index}
                            name={'sources.' + index}
                            config={source}
                            onRemoved={arrayHelpers.handleRemove(index)}
                            setFieldValue={props.setFieldValue}
                        />
                    ))}
                    <Button onClick={() => arrayHelpers.push(createSource())}>
                        Add Source
                    </Button>
                </Form.Group>
            )}
        />
    )
}

function createSource(): LineChartSourceConfig {
    return {
        reportSource: '',
        reportParams: undefined,
        xColumn: '',
        yColumn: '',
        groupBy: undefined,
        type:'',
        sqlParams: {},
        orderColumn: '',
        providersHandlers: ''
    };
}
