import { Evaluator } from "core/dashboard/evaluator/Evaluator";
import { PropertySource } from "core/dashboard/evaluator/PropertySource";

export type FieldValueResolver = (args: { [key: string]: unknown }) => unknown;

function createFixedValueResolver(input: string): FieldValueResolver {
    input = input.substring(1, input.length - 1);
    return () => input;
}

function createDynamicValueResolver(input: string): FieldValueResolver {
    const evaluator = new Evaluator(new PropertySource({}, []));
    return (row: { [key: string]: unknown }) => {
        return evaluator.evaluate(input, { row });
    }
}

function createPickKeyResolver(input: string): FieldValueResolver {
    return (args: { [key: string]: unknown }) => args[input];
}

export function createFieldValueResolver(input: string): FieldValueResolver {
    if (input.startsWith('"') && input.endsWith('"')) {
        return createFixedValueResolver(input);

    } else if (input.startsWith('=')) {
        return createDynamicValueResolver(input);

    } else {
        return createPickKeyResolver(input);
    }
}
