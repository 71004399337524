import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { Dashboard } from "../../types/storeTypes";
import { AccessType } from "core/dashboard/types/responseTypes";
import { refreshPlugins, exportDashboard } from "core/dashboard/utils/requests/requestHandlers";
import { getAuthToken } from '@pearlchain/component-lib-common';

/**
 * The items shown in the Dashboard settings dropdown
 */

export type SettingsItemParams = {
    dashboard: Dashboard | undefined;
    toggleSharingDialog: () => void,
    togglePropertiesDialog: () => void,
    toggleDeleteDashboardDialog: () => void,
    dispatch: Dispatch;
}

export type SettingsItem = {
    label: string,
    action: (params: SettingsItemParams) => void,
    filter?: (params: SettingsItemParams) => boolean
}

const ITEM_CONFIGURE_PROPERTIES: SettingsItem = {
    label: 'dashboard.configure.properties',
    action: (params) => params.togglePropertiesDialog(),
    filter: (params) => params.dashboard && params.dashboard.accessType === AccessType.WRITE ? true : false
};

const ITEM_SHARE_DASHBOARD: SettingsItem = {
    label: 'dashboard.share',
    action: (params) => params.toggleSharingDialog(),
    filter: (params) => params.dashboard && params.dashboard.accessType === AccessType.WRITE ? true : false
};

const ITEM_DELETE_DASHBOARD: SettingsItem = {
    label: 'dashboard.delete',
    action: (params) => params.toggleDeleteDashboardDialog(),
    filter: (params) => params.dashboard && params.dashboard.accessType === AccessType.WRITE ? true : false
};

//TODO add translations
const ITEM_EXPORT_DASHBOARD: SettingsItem = {
    label: 'Export Dashboard',
    action: (params) => {
        const dashboard = params.dashboard;
        if (dashboard) {
            exportDashboard(constructUrl(dashboard.uuid), dashboard.name);
        }
    },
    filter: (params) => params.dashboard && params.dashboard.accessType === AccessType.WRITE ? true : false
};

function constructUrl(dashboardUuid: string): string {
    const searchParams = new URLSearchParams();
    searchParams.set("Authorization", 'Bearing ' + getAuthToken());
    return "/sec/api/webcomponent/dashboard/export/"+dashboardUuid + '?' + searchParams;
}

const ITEM_REFRESH_PLUGINS: SettingsItem = {
    label: 'Refresh Plugins',
    action: () => refreshPlugins()
};

export const SETTINGS_ITEMS: SettingsItem[] = [
    ITEM_CONFIGURE_PROPERTIES,
    ITEM_SHARE_DASHBOARD,
    ITEM_DELETE_DASHBOARD,
    ITEM_EXPORT_DASHBOARD,
    ITEM_REFRESH_PLUGINS
];
