import React, { useCallback, useMemo, useEffect } from 'react';
import { useFetcher } from '@pearlchain/component-lib-common';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { FieldProps } from 'formik';

import { loadWebComponent } from '../../../utils/requests/requestHandlers';
import { DesignerData } from './enterpriseDesignerTypes';
import { WebComponentResponse } from '../../../types/responseTypes';

type Props = {
    designId: string;
} & FieldProps;

function loadDesign(designId: string | undefined) {
    return designId ? loadWebComponent(designId) : Promise.resolve(undefined);
}

function extractDesignData(data: WebComponentResponse) {
    return data && (JSON.parse(data.data) as DesignerData);
}

export default React.memo(function EnterpriseDesignerDatasourcesFields({ designId, field, form }: Props) {
    const { data } = useFetcher(useCallback(() => loadDesign(designId), [designId]));

    useEffect(() => {
        if (data) {
            const designData = extractDesignData(data);
            let selectorConfig = designData['selector-configs'];
            const value = JSON.stringify(selectorConfig);
            form.setFieldValue(field.name, value);
        }
    }, [data]);

    const text = data && field.value != null ? (field.value as string | undefined) : '... loading';

    return (
        <FormGroup>
            <FormLabel>Datasources</FormLabel>
            <div>
                <textarea
                    value={text}
                    disabled={!data}
                    style={{ width: '100%' }}
                    onChange={field.onChange}/>
            </div>
        </FormGroup>
    );
});
