import React, { useMemo, useCallback } from 'react';
import {
    usePluginDefinition,
    PluginDefinition,
    PluginConfig
} from '@pearlchain/component-lib-plugins-core';

import { WidgetCommonConfig } from 'core/dashboard/types/storeTypes';
import LoadingSpinner from '../../../LoadingSpinner';
import PluginWidgetConfigForm, { FormValues } from './PluginWidgetConfigForm';
import { ReportDataSource } from 'core/dashboard/types/widgetTypes';

/**
 * TODO: move to component-lib
 */

type InnerProps<C, D> = {
    plugin: PluginDefinition<C, D>;
    common: WidgetCommonConfig;
    config?: C;
    configure: (common: WidgetCommonConfig, config: C, dataSources: ReportDataSource[]) => void;
    cancelConfig: () => void;
};

function extractPluginConfigValues<C>(config: PluginConfig<C>): C {
    const common: Partial<C> = {};
    for (const key in config.fields) {
        common[key] = config.fields[key].value;
    }
    return common as C;
}

function PluginWidgetConfig<C, D>(props: InnerProps<C, D>) {
    const { common, config, plugin, configure, cancelConfig } = props;
    const pluginConfig = plugin.config;

    const values = useMemo((): FormValues<C> => {
        // extract default form values from the plugin config
        const configValues: C = extractPluginConfigValues(pluginConfig);

        if (config) {
            // merge default config values with widget config
            Object.assign(configValues, config);
        }

        // the nested form structure that will be passed to formik
        return {
            config: configValues,
            common
        };
    }, [pluginConfig, common, config]);

    // handle the form was submit
    const handleSubmit = useCallback(
        (values: FormValues<C>) => {
            configure(values.common, values.config, []);
        },
        [configure]
    );

    // whether the widget has been configured yet
    const isConfigured = !!config && !!common;

    return (
        <PluginWidgetConfigForm
            values={values}
            config={pluginConfig}
            onSubmit={handleSubmit}
            onCancel={cancelConfig}
            isConfigured={isConfigured}
        />
    );
}

/** Loader component */

type Props<C> = {
    pluginId: string;
    common: WidgetCommonConfig;
    config?: C;
    configure: (common: WidgetCommonConfig, config: C, dataSources: ReportDataSource[]) => void;
    cancelConfig: () => void;
};

export default function PluginWidgetConfigLoader<C, R>(props: Props<C>) {
    const plugin = usePluginDefinition<C, R>(props.pluginId);
    if (plugin) {
        // show the configuration for the plugin
        return (
            <PluginWidgetConfig
                plugin={plugin}
                common={props.common}
                config={props.config}
                configure={props.configure}
                cancelConfig={props.cancelConfig}
            />
        );
    } else {
        // show loader until the plugin definition is loaded
        return <LoadingSpinner />;
    }
}
