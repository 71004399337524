import React from 'react';
import { getIn } from 'formik';

import FormBuilderField from './FormBuilderField';
import { FormConfig, FormOpts } from './formBuilderTypes';

type Props<Values> = {
    baseName?: string;
    values: Values;
    errors: any;
    config: FormConfig<Values>;
    opts: FormOpts;
};

function getFieldName(name: string | undefined, fieldName: string) {
    return name ? name + '.' + fieldName : fieldName;
}

export default function FormBuilder<Values>(props: Props<Values>) {
    const { baseName, values, errors, config } = props;
    const fields = config.fields;
    return (
        <div className="form-builder">
            {Object.keys(fields).map(name => {
                const fieldName = getFieldName(baseName, name);
                const error = getIn(errors, fieldName);
                return (
                    <FormBuilderField
                        key={fieldName}
                        values={values}
                        fieldName={fieldName}
                        fieldConfig={fields[name as keyof Values]}
                        opts={props.opts}
                        error={error}
                    />
                );
            })}
        </div>
    );
}
