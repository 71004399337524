
export const palette = [
    // scheme 1
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
    // scheme 2
    '#8dd3c7',
    '#ffffb3',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f'
];

export function generateColors(n: number) {
    const colors = new Array<string>(n);
    for (let i = 0; i < n; i++) {
        colors[i] = palette[i % palette.length];
    }
    return colors;
}

export function getColorAt(i: number) {
    return palette[i % palette.length];
}
