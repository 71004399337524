import React from 'react';
import { FieldProps } from "formik";
import { FormControl } from "react-bootstrap";

type Props = {
    listId: string;
} & FieldProps;

export default function FieldSourceInput({ field, listId }: Props) {
    return <FormControl autoComplete="off" placeholder="source" list={listId} {...field}/>
}
