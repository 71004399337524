export class MultiMap<K, T> {
    private values?: T | Map<K, MultiMap<K, T>>;
    private numKeys?: number;

    set(keys: K[], value: T) {
        const nKeys = keys.length;
        this.validateKeyLength(nKeys);

        let values = this.values;
        if (!nKeys) {
            this.values = value;
        } else {
            let vs = values as Map<K, MultiMap<K, T>> | undefined;
            if (vs == null) {
                vs = new Map<K, MultiMap<K, T>>();
                this.values = vs;
            }

            const k = keys[0];
            let sub = vs.get(k);
            if (sub == null) {
                sub = new MultiMap<K, T>();
                vs.set(k, sub);
            }

            sub.set(keys.slice(1), value);
        }
    }

    get(keys: K[]): T | undefined {
        const nKeys = keys.length;
        this.validateKeyLength(nKeys);

        let values = this.values;
        if (!nKeys) {
            return values as T | undefined;
        } else {
            const vs = values as Map<K, MultiMap<K, T>> | undefined;
            if (!vs) {
                return;
            }

            const sub = vs.get(keys[0]);
            if (!sub) {
                return;
            }

            return sub.get(keys.slice(1));
        }
    }

    private validateKeyLength(nKeys: number) {
        if (this.numKeys == null) {
            this.numKeys = nKeys;
        } else if (this.numKeys !== nKeys) {
            throw new Error(
                `Multi-map invalid number of keys provided. (Expected: ${
                    this.numKeys
                }. Received: ${nKeys})`
            );
        }
    }
}
