import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FieldProps, getIn, FastField } from 'formik';

import { getAllAvailableWebComponents } from '../../../../../utils/selector/selectors';
import { validateRequired } from '../../../../../utils/validate/validators';

/**
 * Form field to choose a dashboard from a dropdown list
 */

type Props = {
    name: string;
};

export default function DashboardChooserField(props: Props) {
    const available = useSelector(getAllAvailableWebComponents);
    return (
        <FastField
            name={props.name}
            validate={validateRequired}
            render={({ field, form }: FieldProps) => {
                const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);

                return (
                    <Form.Control as="select" isInvalid={!!error} {...field}>
                        <option value="" disabled />
                        {available.map((av, index) => (
                            <option key={index} value={av.uniqueIdentifier}>
                                {av.description}
                            </option>
                        ))}
                    </Form.Control>
                );
            }}
        />
    );
}
