import { FormikProps, Field, FastField, FieldProps } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import React from "react";
import { Form, Col, InputGroup, Button, Row } from "react-bootstrap";
import { useReportDefinition } from "../../../utils/hooks/useReportDefinition";
import { EXPORT_FORMATS } from "./fileUtils";
import LoadingSpinner from "../../LoadingSpinner";
import { findReportDefinitionByUuid } from "core/dashboard/utils/requests/requestHandlers";
import { ReportDefinitionResponse } from "core/dashboard/types/responseTypes";

export type FormValues = {
    source: string | undefined;
    params: {
        [key: string]: string;
    };
    fileName: string;
    resultType: string;
}

type Props = FormikProps<FormValues> & {
    widgetParams: {
        [key: string]: string;
    };
    sourceIds: string[];
}

export default function ExportDataModalForm({ values, isSubmitting, setFieldValue, handleSubmit, widgetParams, sourceIds }: Props) {
    const { t } = useTranslation();

    const [reportDefinition,setReportDefinition] = useState<ReportDefinitionResponse | undefined>(undefined)

    useEffect(() => {
        async function loadReportDefinitionAsync() {
            try {    
              const reportDefinitionAux = await findReportDefinitionByUuid(values.source);
              setReportDefinition(reportDefinitionAux)
              console.log("Completed finding report by uuid")
            } catch (e) {
              console.warn(e);
            }
          }
          loadReportDefinitionAsync();
    }, []);

    // update the datasource parameters when the datasource changed
    useEffect(() => {
        const params: { [key: string]: string } = { ...widgetParams };
        if (reportDefinition) {
            const reportParams = reportDefinition.parameters;
            for (const p of reportParams) {
                if (p.defaultValue != null && p.defaultValue !== '') {
                    params[p.name] = '' + p.defaultValue;
                }
            }
        }
        setFieldValue('params', params);
    }, [reportDefinition]);

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>{t('common.binder.product.grid.source')}</Form.Label>
                <Field
                    name="source"
                    render={(params: FieldProps<string>) => (
                        <Form.Control as="select">
                            {sourceIds.map((source) => (
                                <option key={source} value={source}>
                                    {source}
                                </option>
                            ))}
                        </Form.Control>
                    )}
                />
            </Form.Group>
            {reportDefinition ? (
                <>
                    <Form.Group>
                        <Form.Label>{t('export.parameters')}</Form.Label>
                        {Object.keys(values.params).filter(param => {
                            const paramValue = widgetParams[param];
                            return paramValue == null || paramValue === ''; 
                        }).map((k) => (
                            <Form.Group key={k} className="ml-2">
                                <Form.Label>{k}</Form.Label>
                                <FastField
                                    name={"params." + k}
                                    render={(params: FieldProps<string>) => (
                                        <Form.Control {...params.field} placeholder="Filename"/>
                                    )}
                                />
                            </Form.Group>
                        ))}
                    </Form.Group>
                    <Form.Group>
                            <Form.Label>{t('export.output')}</Form.Label>
                        <InputGroup>
                            <FastField
                                name="fileName"
                                render={(params: FieldProps<string>) => (
                                    <Form.Control {...params.field} placeholder="File Name"/>
                                )}
                            />
                            <InputGroup.Append>
                                <FastField
                                    name="resultType"
                                    render={(params: FieldProps<string>) => (
                                        <Form.Control as="select" {...params.field}>
                                            {EXPORT_FORMATS.map((fmt, i) => (
                                                <option key={i} value={fmt.resultType}>
                                                    {fmt.fileExt}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    )}
                                />
                            </InputGroup.Append>
                            <InputGroup.Append>
                                <Button type="submit" disabled={isSubmitting}>{t('export.export')}</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </>
            ) : (
                <LoadingSpinner/>
            )}
        </Form>
    );
}
