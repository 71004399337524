import React from 'react';
import { FastField, FieldProps } from 'formik';
import { Form } from 'react-bootstrap';
import { composeValidators, validateRequired, createOneOfValidator } from '../../../../../utils/validate/validators';

export type Option = {
    label: string,
    value: string
};

type Props = {
    name: string;
    options: Option[]
};

function createValidator(options: Option[]) {
    return composeValidators(validateRequired, createOneOfValidator(options.map(opt => opt.value)));
}

export default function FastSelectField(props: Props) {
    const options = props.options;
    const validate = createValidator(options);

    return (
        <FastField
            name={props.name}
            validate={validate}
            render={({ field }: FieldProps) => (
                <Form.Control as="select" {...field} value={field.value || ''} >
                    {options.map((opt, index) => (
                        <option key={index} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </Form.Control>
            )}
        />
    );
}
