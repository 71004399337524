import React, { useState, CSSProperties } from "react";
import { ColorResult, GithubPicker } from "react-color";
import { InputProps } from "../../../types/inputTypes";

export default function ColorInput({ value, onChange }: InputProps<string>) {
    const [isOpen, setShowPicker] = useState(false);

    const popover: CSSProperties = {
        position: 'absolute',
        zIndex: 2
    };

    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    };

    function onToggle() {
        setShowPicker(!isOpen);
    }

    const handleChanged = (color: ColorResult) => {
        onChange(color.hex);
        setShowPicker(false);
    }

    return (
        <div className="color-picker-trigger" style={{ background: value }} onClick={onToggle}>
            {isOpen && (
                <div style={popover}>
                    <div style={cover} onClick={onToggle}/>
                    <GithubPicker color={value} onChange={handleChanged}/>
                </div>
            )}
        </div>
    );
}
