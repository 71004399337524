import React, { useState, useMemo, useEffect } from 'react'
import { Button, Modal, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { runQuery } from 'core/dashboard/utils/requests/requestHandlers';
import Editor, { Param, paramsToObject } from './Editor';
import { Field, FieldProps } from 'formik';
import ReactTableGrid from '../../layout/ReactTableGrid';
import { getColumns, getData, getInitialValue } from 'core/dashboard/utils/sql/sqlEditorHelpers';
import { useDashboardContext } from '../../dashboard/context';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { PropertySource } from 'core/dashboard/evaluator/PropertySource';
import { CloseModalValues } from '../../widgets/common/config/formik/showDataSourceComponent';

type Props = {
    name: string
    show: boolean
    handleClose: (closeModalValues: CloseModalValues[]) => void
    setQuery: (query: string, params: { [key: string]: any }) => void
    sqlParamsName: string,
    setFieldValue: (field: string, value: any) => void
    isSingleSource: boolean | undefined
    sqlParams: {[key: string]: any}
}

export default function SqlEditorModal(props: Props) {
  const { evaluationContext, propertyConfigs } = useDashboardContext();
  const [results, setResults] = useState<{ [key: string]: unknown }[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [height, setHeight] = useState('sql-results');
  const [params, setParams] = useState<{ [key: string]: any }>({});

  function handleParams(params: { [key: string]: any }){
    setParams(params)
  }

  async function handleRunQuery(query: string, orderColumn: string) {
    if(orderColumn === ""){
      var alert = confirm("You didn't specify an order by column. Do you want to continue");
      if (alert == true) {
        try {
          setLoading(true)
          const evaluator = new Evaluator(new PropertySource(evaluationContext.properties, propertyConfigs));
          const parameters = evaluator.evaluateObject(paramsToObject(params as Param[]))
          const result = await runQuery({sql: query, offset:  0, count: 10, params: parameters, preview: true, pagination: "", pollInterval:0, refreshCache:false})
          setLoading(false)
          setResults(result)
          setError('')
          setHeight('sql-results')
        } catch (error:any) {
          setError(error.message)
          setResults([])
          setHeight('sql-results')
          setLoading(false)
        }
      }
    }
    else {
      try {
        setLoading(true)
        const evaluator = new Evaluator(new PropertySource(evaluationContext.properties, propertyConfigs));
        const parameters = evaluator.evaluateObject(paramsToObject(params as Param[]))
        const result = await runQuery({sql: query, offset:  0, count: 10, params: parameters, preview: true, pagination:"", pollInterval: 0, refreshCache:false})
        setLoading(false)
        setResults(result)
        setError('')
        setHeight('sql-results')
      } catch (error:any) {
        setError(error.message)
        setResults([])
        setHeight('sql-results')
        setLoading(false)
      }
    }
  }

  const errorText = error !== '' ? <p className="m-2 text-danger">An error ocurred while fetching data. Please check your query {error}</p> : null
  const disabledSave = error ? true : false

  const columns = useMemo(() =>  getColumns(results),[results])
  const data = useMemo(() => getData(results),[results])

  return (
      <Field name={props.name} render={({ field, form }: FieldProps) => {
        const queryInitialValue = getInitialValue(props.name, form.initialValues);
        const orderColumnInitialValue = getInitialValue('orderColumn', form.initialValues)

        let values:CloseModalValues[] = []
        values.push({field:props.name, initialValue:queryInitialValue, currentValue:field.value})
        values.push({field:"orderColumn",initialValue:orderColumnInitialValue, currentValue:form.values.orderColumn})
        
        return (
          <Modal dialogClassName="modal-full" centered show={props.show} onHide={() => props.handleClose(values)}>
            <Modal.Header closeButton>
              <Modal.Title>SQL Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column h-100">
                <Editor 
                  query={field.value} 
                  handleQuery={(value) => form.setFieldValue(props.name, value)}
                  handleParams={handleParams} 
                  form={form}
                  sqlParamsName={props.sqlParamsName}
                  isSingleSource={props.isSingleSource}
                  sqlParams={props.sqlParams}
                 />
                  <FontAwesomeIcon className="sql-err-btn" size="lg" icon={['fas', 'expand-arrows-alt']} onClick={() => height === 'd-none' ? setHeight('sql-results') : setHeight('d-none')}/>
                <div className={height}>
                  {results.length > 0 ? <ReactTableGrid columns={columns} data={data}/>: null}
                  {errorText}
                </div>
                
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button className="mr-2" variant="primary" onClick={() => handleRunQuery(field.value, form.values.orderColumn)}>
                  {loading ? "Loading..." : "Preview"}
                </Button>
                <Button variant="primary" disabled={disabledSave} onClick={() => props.setQuery(field.value, params)}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )
      }} 
    />
  )
}
