export enum ActionHandlerType {
    DRILLDOWN = 'drilldown',
    SHOW_RCP_VIEW = 'show-rcp',
    SET_PROPERTY = 'set-property',
    HTTP_REQUEST = 'http-request',
    SHOW_PAGE = 'show-page',
    DATA_SOURCE = 'data-source'
}

export type PropertyConfig = {
    name: string;
    value: unknown;
};

export type DrilldownActionConfig = {
    type: ActionHandlerType.DRILLDOWN;
    dashboardUuid: string;
    properties: PropertyConfig[];
};

export type ShowRcpViewActionConfig = {
    type: ActionHandlerType.SHOW_RCP_VIEW;
    viewName: string;
    viewParams: PropertyConfig[];
};

export type SetPropertyActionConfig = {
    type: ActionHandlerType.SET_PROPERTY;
    property: string;
    value: string;
};

export type HttpRequestActionConfig = {
    type: ActionHandlerType.HTTP_REQUEST;
    url: string;
    params: PropertyConfig[];
    refreshDashboard?: boolean;
}

export type ShowPageActionConfig = {
    type: ActionHandlerType.SHOW_PAGE;
    url: string;
    params: PropertyConfig[];
}

export type DataSourceActionConfig = {
    type: ActionHandlerType.DATA_SOURCE;
    dataSources: string,
    url: string;
    params: PropertyConfig[];
    refreshDashboard?: boolean;
    providersHandlers: string;
}

export type ActionConfig =
    | DrilldownActionConfig
    | ShowRcpViewActionConfig
    | SetPropertyActionConfig
    | HttpRequestActionConfig
    | ShowPageActionConfig
    | DataSourceActionConfig;

export type ActionHandler = (config: ActionConfig, context: { [key: string]: unknown }) => void;

export enum DataSourceTypes {
    HANDLER = "handler",
    PROVIDER = "provider"
  }
