
export enum InputType {
    TEXT='text',
    NUMBER='number',
    COLOR='color',
    CHECK='check'
}

export type InputProps<T> = {
    value: T | undefined;
    onChange: (value: T) => void;
    params?: { [key: string]: unknown };
}
