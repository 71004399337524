import { RequestBuilder } from "@pearlchain/component-lib-common";
import { UserGroupItem } from "./types";

export enum RequestId {
    WEB_COMPONENT_USER_GROUPS='web-component-user-groups',
    AVAILABLE_USER_GROUPS='available-user-groups'
}

const URL = {
    USER_GROUPS: 'sec/api/user-groups',
    WEBCOMPONENT_USERGROUPS(webComponentUuid: string) {
        return `sec/api/webcomponent/${webComponentUuid}/user-groups`;
    }
}

export function listWebComponentUserGroups(webComponentUuid: string): Promise<string[]> {
    return new RequestBuilder()
        .get(URL.WEBCOMPONENT_USERGROUPS(webComponentUuid))
        .toJS();
}

export function listUserGroups(): Promise<UserGroupItem[]> {
    return new RequestBuilder()
        .get(URL.USER_GROUPS)
        .toJS();
}

export function saveWebComponentUserGroups(webComponentUuid: string, version: number, userGroups: string[]) {
    return new RequestBuilder()
        .post(URL.WEBCOMPONENT_USERGROUPS(webComponentUuid))
        .setBodyJson({
            version,
            userGroups
        })
        .fetch();
}
