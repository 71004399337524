import React, { useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

type Props = {
    languageCodes?: string[];
}

//set language in local storage wehn dasboards is called via iframe
export function setLanguageInLocalStorage( url: string) {
    if(!window.localStorage.getItem("language")){
        const searchParams = new URLSearchParams(url);
        let language = searchParams.get('language');
        if(language){
            language = language.slice(0, -2);
            window.localStorage.setItem("language", language);
            window.location.reload();
        }
    }
}

// refreshes the cached translations by first
// clearing the local storage and then refreshing i18next
function refreshTranslations(i18n: any) {
    for(let i = 0, n = localStorage.length; i < n; ++i) {
        const key = localStorage.key(i);
        if(key && key.startsWith('i18next_res_')) {
            localStorage.removeItem(key);
        }
    }
    try{
        i18n.reloadResources();
    }
    catch(error){
        console.log(error)
    }
    //Makes sure it reloads translations
    window.location.reload();
}

export default React.memo(
    function LanguageChooser(props: Props) {
        const { i18n, t } = useTranslation();

        const onRefreshTranslations = useCallback(() => {
            refreshTranslations(i18n);
        }, [i18n]);

        return (
            <Dropdown.Item onClick={onRefreshTranslations}>
                {t('dashboard.refresh.translations')}
            </Dropdown.Item>
        );
    }
);
