import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getAvailableSharedWebComponents, getAvailableOwnedWebComponents, getAvailableStarredWebComponents } from '../../utils/selector/selectors';
import { getActiveDashboardUuid } from '../../utils/selector/selectors';
import {
    setActiveDashboard,
    markDashboardFavourite,
    unmarkDashboardFavourite,
    fetchAvailableDashboards,
    createDashboard,
    moveDashboardFavourite
} from '../../actions/dashboard-actions';
import DashboardsList from './DashboardsList';
import { StoreState } from 'core/dashboard/types/storeTypes';

type Props = {
    view?: string;
};

function DashboardsListContainer({ view }: Props) {
    const dispatch = useDispatch();
    const { starred, owned, shared, activeDashboardUuid } = useSelector((state: StoreState) => ({
        starred: getAvailableStarredWebComponents(state),
        owned: getAvailableOwnedWebComponents(state),
        shared: getAvailableSharedWebComponents(state),
        activeDashboardUuid: getActiveDashboardUuid(state)
    }), shallowEqual);

    // fetch available dashboards when the component is mounted
    useEffect(() => {
        dispatch(fetchAvailableDashboards(view));
    }, []);

    const isSwitchedToActive = useRef(false);
    useEffect(() => {
        if (starred && !isSwitchedToActive.current) {
            isSwitchedToActive.current = true;

            const active = starred[0];
            if (active) {
                // load the first dashboard in the list having the "favourite" flag set
                dispatch(setActiveDashboard(active.uniqueIdentifier));
            }
        }
    }, [starred]);

    const handleSelect = (uuid: string) => {
        dispatch(setActiveDashboard(uuid));
    }

    const handleSetFavourite = (uuid: string, favourite: boolean) => {
        if (favourite) {
            dispatch(markDashboardFavourite(uuid, view));
        } else {
            dispatch(unmarkDashboardFavourite(uuid, view));
        }
    }

    const handleSetIndex = (uuid: string, index: number) => {
        dispatch(moveDashboardFavourite(uuid, index, view));
    }

    const handleCreate = (name: string) => {
        dispatch(createDashboard(name));
    }

    return (
        <DashboardsList
            starred={starred}
            owned={owned}
            shared={shared}
            activeDashboardUuid={activeDashboardUuid}
            onSelectDashboard={handleSelect}
            onSetFavourite={handleSetFavourite}
            onCreate={handleCreate}
            onSetIndex={handleSetIndex}
            view={view}
        />
    );
}

export default React.memo(DashboardsListContainer);
