import React, { useCallback, useState } from "react";
import { useFetcher } from "@pearlchain/component-lib-common";
import { Modal, Button, FormGroup, Form, FormControl } from "react-bootstrap";

import { listAssignableUsers, assignTask } from "../humanTasksRequests";
import { useRequestHandler } from "../../../../utils/hooks/useRequestHandler";

type ModalBodyProps = {
    taskId: string;
    onHide: (submitted: boolean) => void;
}

function AssignTaskModalBody({ taskId, onHide }: ModalBodyProps) {
    const [assignee, setAssignee] = useState<string | undefined>(undefined);
    const { data: assignees } = useFetcher(useCallback(() => listAssignableUsers(taskId), [taskId]));

    const handler = useRequestHandler(assignTask, 'Assign Task');
    function handleSubmit() {
        if (assignee) {
            handler(taskId, assignee).then(() => {
                onHide(true);
            });
        }
    }

    return <>
        <Modal.Body>
            <FormGroup>
                <Form.Label>Assignee</Form.Label>
                <FormControl as="select" value={assignee || ''} onChange={(e: any) => setAssignee(e.target.value)}>
                    <option value="" disabled></option>
                    { assignees && assignees.map((assignee, index) => (
                        <option key={index} value={assignee}>{assignee}</option>
                    ))}
                </FormControl>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={!assignee} onClick={handleSubmit}>Assign</Button>
        </Modal.Footer>
    </>
}

type Props = {
    taskId: string;
    show: boolean;
    onHide: (submitted: boolean) => void;
}

export default function AssignTaskModal({ taskId, onHide, show }: Props) {
    const onCancelled = useCallback(() => onHide(false), [onHide]);

    return (
        <Modal onHide={onCancelled} show={show}>
            <Modal.Header onHide={onCancelled} closeButton>
                Assign Task
            </Modal.Header>
            <AssignTaskModalBody taskId={taskId} onHide={onHide}/>
        </Modal>
    );
}

