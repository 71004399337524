import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { isExpression } from '../../../evaluator/Evaluator';
import { InputProps } from '../../../types/inputTypes';

export default function EvaluatableInput({ value, onChange }: InputProps<string>) {
    const dynamic = isExpression(value || '');
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <div className="evaluatable-input">
            <FontAwesomeIcon icon="bolt" className={classNames('icon-dynamic', { dynamic })} />
            <FormControl
                value={value}
                onChange={handleChange as any}
            />
        </div>
    );
}
