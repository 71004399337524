import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import EvaluatableInput from '../../config/EvaluatableInput';

export type Suggestion = {
  name: string;
}

type Props = {
    onNameChange: (name: string) => void;
    suggestions: Suggestion[];
    value: string;
    showPreview: boolean | undefined;
}

export default function AutoSuggestInput(props: Props) {
    const [suggestions, setSuggestions] = useState(props.suggestions);

    function onChange(event: unknown,  { newValue, method }: { newValue:string, method:string }){
        if(method === 'down' || method === 'up'){
            return;
          }
          props.onNameChange(newValue)
    }

    function escapeRegexCharacters(str:string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function getSuggestions(value:string) {
        const escapedValue = value;
  
        let aux = "";
        for (var i = escapedValue.length - 1; i >= 0; i--){
          if(escapedValue.charAt(i) === ' ' || escapedValue.charAt(i) === '-'){
            aux = escapedValue.charAt(i);
            break;
          }
        }
  
        if(escapedValue === '') {
          return []
        }
        else{
          var regValue = "";
          if(aux === '-'){
            regValue = escapedValue.substring(escapedValue.lastIndexOf('-') + 1);
          }
          else{
            regValue = escapedValue.substring(escapedValue.lastIndexOf(' ') + 1);
          }
          regValue = escapeRegexCharacters(regValue.trim());
          const regex = new RegExp('^' + regValue , 'i');
          return props.suggestions.filter(option => regex.test(option.name));
        }
    }

    function renderSuggestion(suggestion: Suggestion) {
        return (
          <span>{suggestion.name}</span>
        );
    }

    function onSuggestionsFetchRequested({ value }: { value: string }){
        setSuggestions(getSuggestions(value))
    };
    
    function onSuggestionsClearRequested(){
        setSuggestions([])
    };
    
    function getSuggestionValue(option: Suggestion) {
        var aux = props.value;
        for (var i = aux.length - 1; i >= 0; i--){
          if(aux.charAt(i) == ' ' || aux.charAt(i) == '-'){
            break;
          }
          else{
            aux = aux.slice(0, i);
          }
        }
        return aux  + option.name;  
    }

    const value = props.value;
    const inputProps = {
        value,
        showPreview: props.showPreview,
        onChange: onChange
    };

    return(
        <Autosuggest 
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            focusInputOnSuggestionClick={false}
            renderInputComponent={(inputProps) => {
                const props = Object.assign({}, inputProps);
                delete props.className;
                return <EvaluatableInput { ...props } onChange={inputProps.onChange as any}/>
            }}
        />
        
    );
}