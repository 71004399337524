import React, { useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import { useFetcher } from '@pearlchain/component-lib-common';

import { listWebComponents } from '../../../utils/requests/requestHandlers';
import { WebComponentBriefResponse } from '../../../types/responseTypes';
import SelectInput from './SelectInput';

type Props = {
    view?: string;
    type?: string;
    listShared?: boolean;
} & FieldProps;

function webComponentsToOptions(data: WebComponentBriefResponse[]) {
    return data.map(wc => ({ label: wc.description, value: wc.uniqueIdentifier }));
}

export default function WebComponentInput(props: Props) {
    // list available web-components
    const { data } = useFetcher(
        useCallback(() => {
            const request = {
                view: props.view,
                listShared: props.listShared,
                type: props.type
            };

            return listWebComponents(request);
        }, [props.view, props.listShared, props.type])
    );

    // show the result in a select input
    const options = useMemo(() => (data ? webComponentsToOptions(data) : []), [data]);
    return <SelectInput field={props.field} form={props.form} options={options} />;
}
