import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

import { renameDashboardName } from '../../actions/dashboard-actions';
import { getDashboardName } from '../../utils/selector/selectors';
import { StoreState } from '../../types/storeTypes';

type OwnProps = {
    dashboardUuid: string;
    onToggle: () => void;
}

type StateProps = {
    dashboardName: string | undefined;
}

type DispatchProps = {
    renameDashboardName: typeof renameDashboardName
}

type Props = OwnProps & StateProps & DispatchProps;

function TitleEdit(props: Props) {
    const { t } = useTranslation();
    const [name, setName] = useState(props.dashboardName || '');
    const dashboardUuid = props.dashboardUuid;

    return <InputGroup id="input-group-title-edit">
        <FormControl
            id="txt-dashboard-edit-title"
            type="text"
            value={name}
            placeholder={props.dashboardName}
            onChange={(e: any) => setName(e.target.value)}/>
        <InputGroup.Append>
            <Button onClick={() => {
                    props.renameDashboardName(dashboardUuid, name)
                    props.onToggle();
                }}>
                {t('accept.btn')}
            </Button>
            <Button onClick={() => props.onToggle()}>
                {t('cancel.btn')}
            </Button>
        </InputGroup.Append>
    </InputGroup>
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    (state: StoreState, props: OwnProps) => ({
        dashboardName: getDashboardName(state, props.dashboardUuid)
    }),
    { renameDashboardName }
)(TitleEdit)
