import React, { useState } from 'react';

import { Form} from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import { showDataSourceComponent } from './showDataSourceComponent';

/**
 * Form field to choose a report-source from a dropdown list
 */

type Props = {
    reportSource: string;
    name: string;
    paramsName?: string;
    typeName?: string;
    sqlParamsName: string;
    sqlParamsValues: { [key: string]: any };
    setFieldValue: (field: string, value: any) => void;
    isSingleSource: boolean | undefined;
};

const options =    [
    {id: "SQL",         value: "SQL",           label: "SQL Statement"},
    {id: "DATASOURCE",      value: "DATASOURCE",        label: "Data Source"}
]

export default React.memo(function DataSourceField(props: Props) {
    const [dataSource, setDataSource] = useState("SQL");

    function handleSelectDataSource(value: string) {
        setDataSource(value);
    }

    return (
        <>
            <Form.Group>
                <div className='mb-2'>
                    <Form.Label>Data Source</Form.Label>
                    <Field
                        name={props.typeName}
                        render={({ field, form }: FieldProps) => {
                            handleSelectDataSource(field.value)
                            return (
                                <Form.Control as="select" {...field} >
                                    <option disabled value=""></option>
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            );
                        }}
                    />
                </div>
            </Form.Group>
            {showDataSourceComponent(dataSource, props)}
        </>
    );
});
