import React, { useState } from 'react';
import { FormikProps, Field, FastField } from 'formik';
import { Form, Button } from 'react-bootstrap';

import { PieChartFormValues } from './PieChartWidgetConfig';
import CommonConfig from '../../common/config/CommonConfig';
import ConfigPanel from '../../common/config/ConfigPanel';
import ActionInput from 'core/dashboard/actionhandler/input/ActionInput';
import SourcesMappingList from '../../../sourceMapping/config/SourceMappingConfig';
import { KEY_NAME, KEY_VALUE, KEY_SERIES, sourcesMappingFromConfig } from '../pieChartHelpers';
import { MSG_TARGET_PIECHART_NAME, MSG_TARGET_PIECHART_VALUE, MSG_TARGET_SERIES } from '../../../../utils/messages';
import Switch from '../../../config/Switch';
import PieChartWidgetBasicConfig from './PieChartWidgetBasicConfig';
import WidgetConfigFooter from '../../common/WidgetConfigFooter';
import FormatConfigFieldComponent from '../../../form/format/FormatConfigFieldComponent';
import { pieChartFormatters } from '../pieChartFormatters';
import { SourceMappingConfig } from 'core/dashboard/components/sourceMapping/sourceMappingTypes';

const TARGET_KEYS = [
    { name: KEY_NAME, description: MSG_TARGET_PIECHART_NAME },
    { name: KEY_VALUE, description: MSG_TARGET_PIECHART_VALUE },
    { name: KEY_SERIES, description: MSG_TARGET_SERIES }
];

type OwnProps = {
    isCancellable: boolean;
    onCancel: () => void;
}

export default function PieChartWidgetConfigForm(props: FormikProps<PieChartFormValues> & OwnProps) {
    const [isSourceMapping, setIsSourceMapping] = useState(false);
    
    const reportSource = props.values.reportSource;
    const sqlParamsValues = props.values.sqlParams;
    const sourceMapping = props.values.sourceMapping;

    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig />
            <ConfigPanel defaultExpanded header="Pie-Chart">
                <div className="p-2">
                    <Form.Group>
                        <Form.Label>Action</Form.Label>
                        <Field name="action" component={ActionInput}/>
                    </Form.Group>
                </div>
            </ConfigPanel>
            <ConfigPanel defaultExpanded header="Source Mapping">
                <div className="p-2">
                    <Switch
                        name="toggle-advanced"
                        enabled={!!isSourceMapping}
                        onToggled={() => {
                            props.setFieldValue(
                                'sourceMapping',
                                sourcesMappingFromConfig(props.values)
                            );
                            setIsSourceMapping(!isSourceMapping)
                        }}
                    />
                    {isSourceMapping ? (
                        <SourcesMappingList
                            name="sourceMapping"
                            config={sourceMapping ? sourcesMappingFromConfig(props.values) : {} as SourceMappingConfig}
                            targetKeys={TARGET_KEYS}
                        />
                    ) : (
                        <PieChartWidgetBasicConfig reportSource={reportSource} sqlParamsValues={sqlParamsValues} setFieldValue={props.setFieldValue} />
                    )}
                </div>
            </ConfigPanel>
            <ConfigPanel header="Conditional Formatting" >
                <FastField name="format" component={FormatConfigFieldComponent} formatters={pieChartFormatters}/>
            </ConfigPanel>
            <WidgetConfigFooter
                isFormValid={props.isValid}
                isCancellable={props.isCancellable}
                onCancel={props.onCancel}
            />
        </form>
    );
}
