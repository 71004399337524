import React from 'react';
import { FormikProps, FastField, Field } from "formik";
import { Button, FormLabel, FormGroup } from 'react-bootstrap';

import { EnterpriseDesignerFormValues } from "./EnterpriseDesignerConfig";
import CommonConfig from '../common/config/CommonConfig';
import ConfigPanel from '../common/config/ConfigPanel';
import WebComponentInput from '../../config/formik/WebComponentInput';
import { validateRequired } from '../../../utils/validate/validators';
import FormFieldErrorFeedback from '../common/config/formik/FormFieldErrorFeedback';
import EnterpriseDesignerSelectorConfigInput from './EnterpriseDesignerSelectorConfigInput';

export default function EnterpriseDesignerConfigForm(props: FormikProps<EnterpriseDesignerFormValues>) {
    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig/>
            <ConfigPanel defaultExpanded header="Enterprise-Designer">
                <div className="p-2">
                    <FormGroup>
                        <FormLabel>Design</FormLabel>
                        <FastField name="designId" type="design" component={WebComponentInput} validate={validateRequired}/>
                        <FormFieldErrorFeedback name="designId"/>
                    </FormGroup>
                    <Field name="selectorConfigs"
                        component={EnterpriseDesignerSelectorConfigInput}
                        designId={props.values.designId}/>
                </div>
            </ConfigPanel>
            <div className="config-form-footer">
                <Button variant="success" type="submit" disabled={!props.isValid}>
                    Save Changes
                </Button>
            </div>
        </form>
    )
}

