import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalContainer } from '@pearlchain/component-lib-ui';

import { deleteDashboard, setDashboardProperties } from '../../actions/dashboard-actions';
import { getActiveDashboardUuid, getDashboard } from '../../utils/selector/selectors';
import { StoreState } from '../../types/storeTypes';
import { SETTINGS_ITEMS, SettingsItemParams } from './settingsItems';
import SharingModal from './sharing/SharingModal';
import { useDialog } from '../dialog/useDialog';
import { ConfirmDialogOpts, DialogType } from '../dialog/dialogTypes';
import LanguageChooser from 'core/dashboard/utils/translation/LanguageChooser';


/**
 * Dropdown list of dashboard settings options
 */

const removeDashboardgOpts: ConfirmDialogOpts = {
    type: DialogType.CONFIRM,
    title: 'Remove Dashboard',
    text: 'Are you sure you want to remove this Dashboard?'
};


export default function HeaderItemSettings() {
    const { t } = useTranslation();

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isSharingModalOpen, setSharingModalOpen] = useState(false);
    const dispatch = useDispatch();

    const { dashboardUuid, dashboard } = useSelector((state: StoreState) => {
        const dashboardUuid = getActiveDashboardUuid(state);
        const dashboard = dashboardUuid != null ? getDashboard(state, dashboardUuid) : undefined;
        return { dashboardUuid, dashboard };
    }, shallowEqual);

    // callback to show / hide the dropdown menu
    const onToggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    // callback to show / hide the sharing modal
    const onToggleSharingModal = () => {
        setSharingModalOpen(!isSharingModalOpen);
    };

    // configure properties dialog
    const showPropertiesDialog = useDialog<DialogType.PROPERTIES>('dashboard-properties', (result) => {
        if (result) {
            dispatch(setDashboardProperties(result.dashboardUuid, result.properties));
        }
    });

    // configure properties dialog
    const dialogId = 'dashboard-remove-' + dashboardUuid;
    const showDeleteDashboardDialog = useDialog<DialogType.CONFIRM>(dialogId, (result) => {
        if (result) {
            if (dashboard) {
                dispatch(deleteDashboard(dashboard.uuid, dashboard.version));
            }
        }
    });
    
    // the dropdown item params
    const itemParams: SettingsItemParams = {
        toggleSharingDialog: onToggleSharingModal,
        togglePropertiesDialog() {
            showPropertiesDialog({ type: DialogType.PROPERTIES, dashboardUuid: dashboardUuid! });
        },
        toggleDeleteDashboardDialog() {
            showDeleteDashboardDialog(removeDashboardgOpts);
        },
        dashboard,
        dispatch
    };


    // filter available items
    const settingsItems = SETTINGS_ITEMS.filter(
        item => item.filter?.(itemParams) ?? true
    );

    return (
        <div className="dashboard-header-item">
            <div className="dashboard-header-btn" onClick={onToggleDropdown}>
                <FontAwesomeIcon icon="cog"/>
            </div>
            <Dropdown onToggle={onToggleDropdown} show={isDropdownOpen}>
                <Dropdown.Menu alignRight>
                    <LanguageChooser />
                    {settingsItems.map((item, index) => (
                        <Dropdown.Item key={index} onClick={item.action.bind(null, itemParams)}>
                            {t(item.label)}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <ModalContainer>
                <SharingModal
                    visible={isSharingModalOpen}
                    dashboardUuid={dashboardUuid}
                    onHide={onToggleSharingModal}
                />
            </ModalContainer>
        </div>
    );
}
