import React, { useMemo } from 'react';
import { LayoutType, LayoutConfig } from '../layout/layoutTypes';

import GridLayout from '../layout/GridLayout';
import SplitLayout from '../layout/SplitLayout';
import FullLayout from '../layout/FullLayout';
import { Dashboard, DashboardLayoutConfig } from 'core/dashboard/types/storeTypes';
import {
    createSplitLayoutFromDashboardConfig,
    createGridLayoutFromDashboardConfig
} from '../layout/layoutHelpers';

/**
 * Displays the widgets on the Dashboard
 */

type Props = {
    dashboard: Dashboard;
    splitSize: number;
    hasParent: boolean;
    layout?: LayoutConfig;
};

function resolveLayout(
    layout: LayoutConfig | undefined,
    layoutConfig: DashboardLayoutConfig,
    hasParent: boolean
) {
    if (layout) {
        return layout;
    } else {
        return hasParent
            ? createSplitLayoutFromDashboardConfig(layoutConfig)
            : createGridLayoutFromDashboardConfig(layoutConfig);
    }
}

function DashboardLayout(props: Props) {
    const { dashboard, splitSize, hasParent } = props;

    const dashboardUuid = dashboard.uuid;
    const layoutConfig = dashboard.layout;

    const layout = useMemo(() => resolveLayout(props.layout, layoutConfig, hasParent), [
        props.layout,
        layoutConfig,
        hasParent
    ]);

    switch (layout.type) {
        case LayoutType.GRID:
            return (
                <GridLayout dashboardUuid={dashboardUuid} layout={layout} splitSize={splitSize} />
            );
        case LayoutType.SPLIT:
            return <SplitLayout layout={layout} dashboardUuid={dashboardUuid} />;
        case LayoutType.FULL:
            return <FullLayout layout={layout} dashboardUuid={dashboardUuid} />;
        default:
            throw new Error('Unrecognized layout-type');
    }
}

export default React.memo(DashboardLayout);
