import React from 'react';
import { FieldProps, getIn } from 'formik';
import { FormControl } from 'react-bootstrap';
import EvaluatableInput from '../EvaluatableInput';

type Props = {
    placeholder?: string;
    dynamic?: boolean;
} & FieldProps;

export default function TextInput({ field, form, placeholder, dynamic }: Props) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    if (dynamic) {
        return (
            <EvaluatableInput
                autoComplete="off"
                placeholder={placeholder}
                isInvalid={!!error}
                {...field}
            />
        );
    } else {
        return (
            <FormControl
                autoComplete="off"
                placeholder={placeholder}
                isInvalid={!!error}
                {...field}
            />
        );
    }
}
