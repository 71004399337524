import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalContainer } from '@pearlchain/component-lib-ui';
import WidgetLibraryModal from '../widgetlibrary/WidgetLibraryModal';

type Props = {
    dashboardUuid: string
}

export default function HeaderItemWidgetLibrary(props: Props) {
    const [isWidgetLibraryOpen, setWidgetLibraryOpen] = useState(false); 

    return <div className="dashboard-header-item">
        <div className="dashboard-header-btn" onClick={() => setWidgetLibraryOpen(true)}>
            <FontAwesomeIcon icon="plus"/>
        </div>
        <ModalContainer>
            <WidgetLibraryModal
                dashboardUuid={props.dashboardUuid}
                visible={isWidgetLibraryOpen}
                onClose={() => setWidgetLibraryOpen(false)}/>
        </ModalContainer>
    </div>
}
