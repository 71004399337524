import React from 'react';
import { FastField } from 'formik';
import CheckboxInput from './input/CheckboxInput';

type Props = {
    name: string;
    label: string;
};

export default React.memo(function CheckboxFormField(props: Props) {
    return (
        <FastField
            name={props.name}
            label={props.label}
            component={CheckboxInput}
        />
    );
});
