import { ReportParamConfig } from "../../types/configTypes";

export function reportParamsConfigToRequest(reportParams: ReportParamConfig[] | undefined) {
    const params: { [key: string]: string } = {};
    if (reportParams == null) return params;
    for (let param of reportParams) {
        params[param.name] = param.value;
    }
    return params;
}
