import React, { useRef, useEffect } from 'react';
import Chart, {
    ChartPoint,
    ChartDataSets,
    ChartData
} from 'chart.js';

import { ActionHandler } from 'core/dashboard/types/actionHandlerTypes';
import { LineChartConfig } from '../lineChartTypes';
import { AxisType } from '../../../../types/configTypes';
import { getColorAt } from '../../../../utils/pallette';
import { toDate } from '../../../../utils/dataTypes';
import { KEY_X_AXIS, KEY_Y_AXIS, KEY_SERIES } from '../lineChartHelpers';
import { groupDataBySeries } from '../../../../utils/graph/graphHelpers';
import { axisConfigToChartOptions } from '../../../../utils/graph/chartjsHelpers';
import { resolveConditionalFormat, applyFormatters, resolveFormatValues } from 'core/dashboard/formatters/formatHelpers';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { ConditionalFmtConfig } from 'core/dashboard/types/formatConfigTypes';
import { lineChartFormatters } from '../lineChartFormatters';

type Props = {
    config: LineChartConfig;
    evaluator: Evaluator;
    data: { [key: string]: unknown }[];
    actionHandler?: ActionHandler;
};

function format(field: unknown, axisType: AxisType): number | string | Date {
    if (axisType === AxisType.TIME) {
        return toDate(field);
    } else if (typeof field === 'number') {
        return field;
    } else {
        return '' + field;
    }
}

function resolveChartData(xAxisType: AxisType, yAxisType: AxisType, seriesData: { [key: string]: unknown }[][], evaluator: Evaluator, formatConfig: ConditionalFmtConfig | undefined): ChartData {
    const datasets: ChartDataSets[] = [];

    for (let j = 0, m = seriesData.length; j < m; j++) {
        const data = seriesData[j];
        const borderColor: string = getColorAt(j);
        const dataPoints: ChartPoint[] = new Array();

        const dataset: ChartDataSets = {
            data: dataPoints,
            borderColor,
            fill: false,
            steppedLine: yAxisType === AxisType.STEPPED ? true : false
        };
        

        for (let i = 0, n = data.length; i < n; i++) {
            const datum = data[i];
            const fieldX = datum[KEY_X_AXIS];
            const fieldY = datum[KEY_Y_AXIS];
            const series = (datum[KEY_SERIES] || '') as string;

            // by default, the dataset label is set to the series key
            if (dataset.label == null) {
                dataset.label = series;
            }

            // convert to data-point
            dataPoints[i] = {
                x: format(fieldX, xAxisType),
                y: format(fieldY, yAxisType === AxisType.STEPPED ? AxisType.LINEAR : yAxisType)
            };

            // resolve the first format that matches the condition
            const formatBlock = resolveConditionalFormat(formatConfig, evaluator, datum);

            // then evaluate the dynamic properties of the format object
            const formatValues = resolveFormatValues(formatBlock, evaluator, datum);

            // apply formatters to the current dataset
            applyFormatters(lineChartFormatters, formatValues, {
                dataset,
                index: i
            });
        }

        datasets.push(dataset);
    }

    return {
        datasets
    };
}

export default function LineChartComponent(props: Props) {
    const config = props.config;
    const data = props.data;

    const elRef = useRef<HTMLCanvasElement | null>(null);
    const chartRef = useRef<Chart | null>(null);
    const seriesDataRef = useRef<{ [key: string]: unknown }[][] | null>(null);

    useEffect(() => {
        const xAxis = axisConfigToChartOptions(config.xAxis);
        const yAxis = axisConfigToChartOptions(config.yAxis);

        const seriesData = groupDataBySeries(KEY_SERIES, data);
        seriesDataRef.current = seriesData;
        const chartData = resolveChartData(config.xAxis.type, config.yAxis.type, seriesData, props.evaluator, config.format);

        const showLegend = seriesData.length > 0;

        let chart = chartRef.current;
        if (chart == null) {
            const ctx = elRef.current;
            if (ctx == null) return;

            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: chartData,
                options: {
                    legend: {
                        display: showLegend
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [xAxis],
                        yAxes: [yAxis]
                    },
                    onClick: (e, g: any) => {
                        if (!g || !g.length) return;
                        const x = g[0]._model.x;
                        const y = g[0]._model.y;
                        const datum = { x, y };

                        if (props.actionHandler && config.action) {
                            props.actionHandler(config.action, datum);
                        }
                    }
                }
            });
        } else {
            chart.data = chartData;
            chart.update();
        }
    }, [data, config]);

    return (
        <div className='canvasWrapper'>
            <canvas width="100%" height="100%" ref={elRef} />
        </div>
    );
}
