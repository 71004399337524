import React from 'react';
import { Alert } from 'react-bootstrap';
import { ServiceMessageResultResponse } from '../../../../../types/responseTypes';

type Props = {
    serviceMessage: ServiceMessageResultResponse | undefined;
};

export default function ServiceMessageResultDisplayer({ serviceMessage }: Props) {
    if (!serviceMessage) {
        return <span />;
    }

    return (
        <Alert color="error">
            <ul>
                {serviceMessage.messages.map((msg: any, index: number) => (
                    <li key={index} className={msg.type}>
                        {msg.message}
                    </li>
                ))}
            </ul>
        </Alert>
    );
}
