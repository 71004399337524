import React from 'react';
import { Modal } from 'react-bootstrap';
import WidgetLibrary from './WidgetLibrary';

type Props = {
    dashboardUuid: string,
    visible: boolean,
    onClose: () => void
}

function WidgetLibraryModal(props: Props) {
    return (
        <Modal show={props.visible} onHide={props.onClose} size="lg">
            <Modal.Header closeButton onHide={props.onClose}>
                <Modal.Title className="flex-center">Widget Library</Modal.Title>
            </Modal.Header>
            <WidgetLibrary
                dashboardUuid={props.dashboardUuid}
                onClose={props.onClose}
            />
        </Modal>
    );
}

export default React.memo(WidgetLibraryModal);
