import { PieChartConfig } from './pieChartTypes';
import { SourceMappingConfig, FieldType } from '../../sourceMapping/sourceMappingTypes';
import { reportParamsConfigToRequest } from '../../../utils/requests/requestHelpers';

export const KEY_NAME = 'name';
export const KEY_VALUE = 'value';
export const KEY_SERIES = 'series';

export function sourcesMappingFromConfig(config: PieChartConfig): SourceMappingConfig {
    // return the "advanced" config if defined
    if (config.sourceMapping) {
        return config.sourceMapping;
    }

    const params = reportParamsConfigToRequest(config.reportParams);
    return {
        sources: [{ 
            report: config.reportSource, 
            name: 'ds0', params, 
            type: config.type, 
            sqlParams: config.sqlParams, 
            orderColumn: "", 
            providersHandlers: 
            config.providersHandlers 
        }],
        series: [
            {
                series: '',
                source: 'ds0',
                mappings: [
                    {
                        from: config.groupBy,
                        to: 'name',
                        type: FieldType.GROUP_BY
                    },
                    {
                        from: config.groupBy,
                        to: 'value',
                        type: FieldType.COUNT
                    }
                ]
            }
        ]
    };
}
