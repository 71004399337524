import { Dispatch } from "redux";
import { show } from "react-notification-system-redux";
import { error } from 'react-notification-system-redux';

import { DashboardAction, DashboardActionType } from "core/dashboard/types/dashboardTypes";
import { WidgetActions } from "core/dashboard/types/widgetTypes";
import { configureWidget, removeWidget, refreshDashboard, showPage } from "core/dashboard/actions/dashboard-actions";
import { WidgetAction, WidgetActionType, beginRefresh, doneRefresh, doneRefreshError } from "core/dashboard/types/widgetStateTypes";

/**
 * Helper function, builds callbacks to form a 
 * facade over the underlying state / store implementation
 */

export function createWidgetActions(
    dashboardUuid: string,
    widgetUuid: string,
    widgetDispatch: React.Dispatch<WidgetAction>,
    dashboardDispatch: React.Dispatch<DashboardAction>,
    parentDispatch: React.Dispatch<WidgetAction> | undefined,
    storeDispatch: Dispatch
): WidgetActions {
    return {
        configure(common, config, dataSources) {
            storeDispatch(configureWidget(dashboardUuid, widgetUuid, common, config, dataSources));
        },
        
        remove() {
            storeDispatch(removeWidget(dashboardUuid, widgetUuid));
        },

        show(level: string, title: string, message: string) {
            storeDispatch(show({
                title,
                message
            }, level as any));
        },

        notifyError(title, message) {
            storeDispatch(error({ title, message }));
        },

        pushDrilldown(drilldown) {
            const dispatch = parentDispatch || widgetDispatch;
            dispatch({ type: WidgetActionType.PUSH_DRILLDOWN, drilldown });
        },

        popDrilldown(n) {
            widgetDispatch({ type: WidgetActionType.POP_DRILLDOWN, n });
        },

        setLoading(loading: boolean) {
            widgetDispatch({ type: WidgetActionType.SET_LOADING, loading });
        },

        setData(data) {
            widgetDispatch({ type: WidgetActionType.SET_DATA, data });
        },

        beginRefresh() {
            storeDispatch(beginRefresh());
        },

        doneRefresh(data) {
            storeDispatch(doneRefresh(data));
        },

        doneRefreshError(error) {
            storeDispatch(doneRefreshError(error));
        },

        setViewMode(mode) {
            widgetDispatch({ type: WidgetActionType.SET_VIEW_MODE, mode });
        },

        triggerRefresh(cacheRefresh:boolean) {
            widgetDispatch({ type: WidgetActionType.TRIGGER_REFRESH, cacheRefresh });
        },

        triggerRefreshDashboard() {
            storeDispatch(refreshDashboard(dashboardUuid));
        },

        cancelConfig() {
            widgetDispatch({ type: WidgetActionType.CANCEL_CONFIG });
        },

        setStateError(error: boolean) {
            widgetDispatch({ type: WidgetActionType.STATE_ERROR, error });
        },

        setDashboardPropertyValue(name, value) {
            dashboardDispatch({ type: DashboardActionType.SET_PROPERTY_VALUE, name, value });
        },

        showPage(pageUrl) {
            storeDispatch(showPage(pageUrl));
        }
    }
}
