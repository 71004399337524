import React from 'react';
import { Button } from 'react-bootstrap';
import { Column as ColumnReactPG } from '@pearlchain/component-lib-react-pg';

export type ReactHumanTableColumnType = {
    Header: string;
    accessor: string;
    width?: number;
    Cell: (value: any) => JSX.Element;
}

type EventType = {
    key: string;
    action: (evt: any) => void;
}[]

export function getHumanTableColumns(t: any,columns: ColumnReactPG[], data: {[key: string]: unknown}[], events: EventType): ReactHumanTableColumnType[]{
    var cellValue = "";
    return columns.map((column) => {
        if(column._key === "actions"){
            const actions = column.actions
            let buttons: any[] = []

            for (const i in actions) {
                const action = actions[i as any]
                if(!action.hidden){
                    const event = events.find(e => e.key === action.eventName);
                    buttons.push({label: action.label, event, icon: action.icon || ''})
                }
            }

            return {
                Header: column.title,
                accessor: column.title, 
                format: column.format,
                width: column.width,
                Cell: (value: any) => {
                    return (
                        <div className="h-100 d-flex align-items-center px-2">
                            {buttons.map((i, idx) => {
                                return(
                                    <div key={idx}>
                                        <Button onClick={() => i.event.action(value.row.id)}>
                                            {i.icon ? (
                                                <i className={"fa "+i.icon}></i>
                                            ): (
                                                <span>{i.label}</span>
                                            )}
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }   
            }
        }

        return {Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
            if(typeof value.value === "boolean"){
                return (
                    <div className="h-100 d-flex align-items-center p-2">
                        <span className="cell-over">{+value.value}</span>
                    </div>
                )
            }

            if(value.value === null || value.value === undefined){
                return (
                    <div className="h-100 d-flex align-items-center p-2">
                        <span className="cell-over"></span>
                    </div>
                )
            }

            return (
                <div className="h-100 d-flex align-items-center p-2">
                    <span className="cell-over">{ value.value+""}</span>
                </div>
            )
        }}
    })
}
