import React, { useMemo } from 'react';
import { Form, CloseButton } from 'react-bootstrap';
import { UserGroupItem } from './types';

type Props = {
    userGroup: string;
    userGroups: UserGroupItem[];
    onRemoved: () => void;
}

export default React.memo(function UserGroupItem(props: Props) {
    const { userGroup, userGroups } = props;

    const userGroupName = useMemo(() => {
        const ug = props.userGroups.find((ug) => ug.uuid === userGroup);
        return ug ? ug.name : '';
    }, [userGroup, userGroups]);

    return (
        <Form.Row className="px-2">
            <span className="mr-2"> { userGroupName } </span>
            <CloseButton onClick={props.onRemoved}/>
        </Form.Row>
    );
});
