import React from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { ValueType } from 'react-select/src/types';

type Option = {
    value: string; label: string
}

type Props = {
    options: Option[];
    isMulti?: boolean;
} & FieldProps;

export function createValidator(options: Option[]) {
    return (input: string | string[]) => {
        if (Array.isArray(input)) {
            if (input.some(x => !options.find(opt => opt.value === x))) {
                return 'Invalid option';
            }
        } else {
            if (options.find(opt => opt.value === input)) {
                return 'Invalid option';
            }
        }
    };
}

function optionToValue(value: ValueType<Option>) {
    if (Array.isArray(value)) {
        return value.map(v => v.value);
    } else {
        return value && (value as Option).value;
    }
}

function valueToOption(value: string | string[], options: Option[]) {
    if (Array.isArray(value)) {
        const filterOpts: Option[] = [];
        for (let v of value) {
            const option = options.find(o => o.value === v);
            if (option) filterOpts.push(option);
        }

        return filterOpts;
    } else {
        return options.find(o => o.value === value);
    }
}

export default function MultiSelectInput({ field, form, options, isMulti }: Props) {
    return (
        <Select
            options={options}
            isMulti={isMulti}
            value={valueToOption(field.value, options)}
            onChange={(optSelected: ValueType<Option>) => {
                const value = optionToValue(optSelected);
                form.setFieldValue(field.name, value);
            }}
        />
    );
}
