import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';

/**
 * Component to choose the current column type
 */

export type ColumnType = {
    sum: boolean;
    count: boolean;
    raw: boolean;
    action: boolean;
    groupBy: boolean;
};

type Props = {
    value: ColumnType;
    onChange: (v: ColumnType) => void;
};

function getTitle(value: ColumnType, t: any ): string {
    if (value.sum) {
        return t('dashboard.sum');
    } else if (value.count) {
        return t('dashboard.count');
    } else if (value.groupBy) {
        return t('dashboard.group.by');
    } else if (value.action) {
        return t('action');
    } else if (value.raw) {
        return t('dashboard.raw');
    } else {
        return 'None';
    }
}

const DEFAULT: ColumnType = {
    sum: false,
    count: false,
    raw: false,
    action: false,
    groupBy: false
};

export default function ColumnTypeDropdown(props: Props) {
    const { t } = useTranslation();

    const value = props.value;
    const title = getTitle(value, t);

    function handleChangeSelect() {
        props.onChange({ ...DEFAULT, raw: true });
    }

    function handleChangeCount() {
        props.onChange({ ...DEFAULT, count: true });
    }

    function handleChangeSum() {
        props.onChange({ ...DEFAULT, sum: true });
    }

    function handleChangeGroupBy() {
        props.onChange({ ...DEFAULT, groupBy: true });
    }

    function handleChangeAction() {
        props.onChange({ ...DEFAULT, action: true });
    }

    return (
        <DropdownButton id={undefined as any} variant="secondary" title={title}>
            <Dropdown.Item href="#" onSelect={handleChangeSelect}>
                {t('dashboard.raw')}
            </Dropdown.Item>
            <Dropdown.Item href="#" onSelect={handleChangeCount}>
                {t('dashboard.count')}
            </Dropdown.Item>
            <Dropdown.Item href="#" onSelect={handleChangeSum}>
                {t('dashboard.sum')}
            </Dropdown.Item>
            <Dropdown.Item href="#" onSelect={handleChangeGroupBy}>
                {t('dashboard.group.by')}
            </Dropdown.Item>
            <Dropdown.Item href="#" onSelect={handleChangeAction}>
                {t('action')}
            </Dropdown.Item>
        </DropdownButton>
    );
}
