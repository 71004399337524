import { ReportDefinitionResponse, ReportGraphContentType } from '../../types/responseTypes';
import { SourceMappingConfig, SourceConfig } from './sourceMappingTypes';

export const DEFAULT_SOURCE_MAPPING_CONFIG: SourceMappingConfig = {
    sources: [
        {
            name: 'ds0',
            report: '',
            params: {}
        }
    ],
    series: [
        {
            series: '',
            source: 'ds0',
            mappings: []
        }
    ]
};

/**
 * Resolves the list of source mapping "suggestions" to show in the dropdown
 */
export function getSourceFields(
    sourceId: string,
    sources: SourceConfig[],
    reportDefs: ReportDefinitionResponse[] | undefined
): string[] {
    if (!reportDefs) return [];

    const source = sources.find(source => source.name === sourceId);
    if (!source) return [];

    const reportDef = reportDefs.find(reportDef => reportDef.uuid === source.report);
    if (!reportDef) return [];

    return getReportFields(reportDef);
}

/**
 * Extracts available fields from the report definition
 */
function getReportFields(reportDef: ReportDefinitionResponse) {
    if (reportDef.contentType === ReportGraphContentType.PQL) {
        return [];
    } else {
        const sourceFields: string[] = [];
        for (let model of reportDef.models) {
            for (let field of model.fields) {
                sourceFields.push(field.name);
            }
        }
        return sourceFields;
    }
}
