import { WidgetDefinition, DataSource } from "../../../types/widgetTypes";
import img from '../../../../../img/ssource.png';
import { SingleSourceConfig } from "./singleSourceTypes";
import SingleSourceWidget from "./SingleSourceWidget";

export const SINGLE_SOURCE_WIDGET_TYPE = 'GRID_SINGLE_SOURCE';

export const SINGLE_SOURCE_WIDGET: WidgetDefinition = {
    name: 'Single-source grid',
    description: 'Shows a single report result in a grid.',
    icon: 'th-list',
    image: img,
    component: SingleSourceWidget,
    listSources: (config: SingleSourceConfig) : DataSource[] => [{
        source: config.reportSource,
        params: config.reportParams || [],
    }]
}
