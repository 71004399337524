import React from 'react';
import { FormControl } from 'react-bootstrap';
import { ErrorMessage } from 'formik';

type Props = {
    name: string;
};

export default function FormFieldErrorFeedback(props: Props) {
    return (
        <ErrorMessage
            name={props.name}
            render={msg => {
                const msgStr = typeof msg !== 'string' ? 'Error' : msg;
                return <FormControl.Feedback type="invalid">{msgStr}</FormControl.Feedback>;
            }}
        />
    );
}
