import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExportDataModalContent from './ExportDataModalContent';

type Props = {
    isShowing: boolean;
    onHide: () => void;
};

export default React.memo(function ExportDataModal(props: Props) {
    const { t } = useTranslation();
    return (
        <Modal show={props.isShowing} onHide={props.onHide}>
            <Modal.Header closeButton onHide={props.onHide}>
                {t("export.data")}
            </Modal.Header>
            <Modal.Body>
                <ExportDataModalContent/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>{t('cancel.btn')}</Button>
            </Modal.Footer>
        </Modal>
    );
});
