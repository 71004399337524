import { HumanTasksConfig, ProcessVariableConfig } from "../humanTasksTypes";
import { findHumanTasks } from "../humanTasksRequests";

export function createHumanTasksRequestHandler(config: HumanTasksConfig) {
    return () => {
        const minPriority = parseFloat(config.priority.min);
        const maxPriority = parseFloat(config.priority.max);
        const processVariables = procVarsToObject(config.processVariables);
        
        const params = {
            processVariables,
            minPriority,
            maxPriority,
            assignedToMe: config.assignedToMe,
            unassigned: config.unassigned,
            formKeys: config.formKeys,
            filter: config.filter
        };
        
        return findHumanTasks(params);
    }
}

export function procVarsToObject(procVars: ProcessVariableConfig[]) {
    const result: { [key: string]: string } = {};
    for(let procVar of procVars) {
      result[procVar.key] = procVar.value;
    }
    return result;
}
  