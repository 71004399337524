import moment = require("moment");

export function toNumber(input: unknown): number {
    if (typeof input === 'number') {
        return input;
    } else if (typeof input === 'string') {
        return parseFloat(input);
    } else {
        return NaN;
    }
}

export function toDate(input: unknown): Date {
    if (typeof input === 'number') {
        return new Date(input);
    } else if (typeof input === 'string') {
        return moment(input).toDate();
    } else {
        throw new TypeError('Data-type conversion. Could not convert ' + input + ' to date');
    }
}

export function toString(input: unknown): string {
    return input ? '' + input : '';
}
