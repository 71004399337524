import React from 'react';

import { WidgetProps } from "../../widget/widgetTypes";
import PieChartWidgetConfig from "./config/PieChartWidgetConfig";
import { PieChartConfig } from './pieChartTypes';
import PieChartWidgetView from './view/PieChartWidgetView';
import { RunReportResponse } from '../../../types/responseTypes';
import { useTrackAccessorsPropertySource } from 'core/dashboard/evaluator/propertysource/useTrackAccessorsPropertySource';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';

export const PIE_CHART_TYPE = 'PIE_CHART';

export default function(props: WidgetProps) {
    const { state, actions, propertySource } = props;

    const widget = state.widget;
    const config = widget.config as PieChartConfig | undefined;
    const common = widget.common;

    // replace with a memoized proxy, so the widget updates only when observed properties changed
    const properties = useTrackAccessorsPropertySource(config, propertySource);

    function renderView() {
        if (config == null) return renderConfig();
        if (state.hasStateError) return <></>
        const refreshCount = state.refreshCount;
        const data = state.data as RunReportResponse[];

        return <PieChartWidgetView
            actions={actions}
            refreshCount={refreshCount}
            config={config}
            data={data}
            properties={properties}
            common={common}
        />
    }

    function renderConfig() {
        return <PieChartWidgetConfig
            common={common}
            config={config}
            configure={actions.configure}
            onCancel={actions.cancelConfig}
            dataSources={widget.dataSources}/>
    }

    if (state.viewMode === WidgetViewMode.VIEW) {
        return renderView();
    } else {
        return renderConfig();
    }
}
