import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '../../types/storeTypes';
import { getDashboardWidget } from '../../utils/selector/selectors';
import { loadDashboardWidget } from '../../actions/dashboard-actions';
import LoadingSpinner from '../LoadingSpinner';
import WidgetComponent from './Widget';
import WidgetErrorBoundary from './WidgetErrorBoundary';

/**
 * Binds the widget to the store, and loads it when not available
 */

type Props = {
    dashboardUuid: string;
    widgetUuid: string;
};

export default function WidgetLoader(props: Props) {
    const { dashboardUuid, widgetUuid } = props;

    const dispatch = useDispatch();
    const widget = useSelector((state: StoreState) => getDashboardWidget(state, dashboardUuid, widgetUuid));

    useEffect(() => {
        if (!widget) {
            // load the widget and save it in the redux-store
            dispatch(loadDashboardWidget(dashboardUuid, widgetUuid));
        }
    }, [dispatch]);

    return widget ? (
        // the widget was loaded successfully
        <WidgetErrorBoundary>
            <WidgetComponent
                widget={widget}
                dashboardUuid={dashboardUuid}
                widgetUuid={widgetUuid}
            />
        </WidgetErrorBoundary>
    ) : (
        // while the widget is unavailable
        <LoadingSpinner />
    );
}
