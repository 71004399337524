import React, { useState, useEffect } from "react"
import { FormControl } from "react-bootstrap"

type Props = {
    value: string | undefined;
    onChange: (value: string) => void;
    [key: string]: unknown;
}

/**
 * Original Description: Form-Control that calls "onChange" when the focus is changed instead of every key-press
 * Currently this form control does actually call onChange on every key press, because doing otherwise results in following issue:
 * New value for property is accepted when filling in new value, then clicking outside of the text input to call the onBlur method
 * New value for property is however not accepted when filling in new value, and pressing enter, or clicking a button not linked to the text input
 * to call the onSubmit of that form (as done in DashboardPropertyValueEditor.tsx). This results in the property reverting to it's original value,
 * and to the user it seems like nothing happened
 */

function TextInput(props: Props, ref: React.Ref<any>) {
    const { value, onChange, ...more } = props; 
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const handleChange = (e: any) => {
        setInputValue(e.target.value);
        onChange(e.target.value);
    }

    const handleBlur = () => {
        onChange(inputValue);
    };
    
    return (
        <FormControl
            ref={ref}
            value={inputValue}
            onBlur={handleBlur}
            onChange={handleChange}
            {...more}
        />
    );  
}

export default React.forwardRef(TextInput) as typeof TextInput;
