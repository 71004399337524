import React from 'react';
import { FieldArray } from 'formik';
import { Button } from 'react-bootstrap';
import { SourceConfig } from '../sourceMappingTypes';
import SourceConfigComponent from './SourceConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    name: string;
    sources: SourceConfig[];
};

function createSourceConfig(i: number): SourceConfig {
    return {
        name: 'ds' + i,
        report: '',
        params: {}
    };
}

export default function SourceConfigList({ name, sources }: Props) {
    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="source-config-list">
                    <div className="config-sub-header">
                        Sources
                        <Button
                            className="header-add-btn"
                            onClick={() => arrayHelpers.push(createSourceConfig(sources.length))}
                        >
                            <FontAwesomeIcon icon="plus" />
                        </Button>
                    </div>

                    {sources.map((source, i) => (
                        <SourceConfigComponent
                            name={name + '.' + i}
                            index={i}
                            sources={sources}
                            onRemoved={i > 0 ? arrayHelpers.handleRemove(i) : undefined}
                        />
                    ))}
                </div>
            )}
        />
    );
}
