import React from 'react';
import { useToggle } from '../../../../../utils/hooks/useToggle';
import { Button } from 'react-bootstrap';

type Props = {
    description: string | null;
};

export default React.memo(function HumanTaskDetailDescription({ description }: Props) {
    const [isShowFull, toggleShowFull] = useToggle(false);
    return (
        <div className="humantask-description">
            {!isShowFull ? (
                <div className="humantask-description-summary">
                    {description ? description.substring(0, 50) : '<No Description>'}
                </div>
            ) : (
                <div
                    className="humantask-description-full"
                    dangerouslySetInnerHTML={{ __html: description || '' }}
                />
            )}
            <Button className="humantask-description-btn" onClick={toggleShowFull}>
                Detail...
            </Button>
        </div>
    );
});
