import React, { useMemo } from 'react';
import { FieldProps } from 'formik';
import { useCachedFetcher } from '@pearlchain/component-lib-common';

import { listHumanTasksFormKeys, requestId } from '../humanTasksRequests';
import MultiSelectInput from '../../../config/formik/MultiSelectInput';

export default function FormKeysInput(props: FieldProps) {
    const { data } = useCachedFetcher(listHumanTasksFormKeys, requestId.FORM_KEYS);
    const selectOpts = useMemo(() => data ? data.map((d) => ({ value: d, label: d })) : [], [data]);
    return <MultiSelectInput {...props} options={selectOpts} isMulti/>
}
