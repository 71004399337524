import { Column } from "@pearlchain/component-lib-react-pg";
import { HumanTasksConfig } from "../humanTasksTypes";


export const columns: Column[] = [
    { _key: 'actions', title: 'Actions', width: 240, hidden:true, actions: [
        {
            eventName: 'ht-approve',
            cssClass: 'btn btn-primary ht-approve fa',
            icon: "fa-check",
            hidden: true
        },
        {
            eventName: 'ht-reject',
            cssClass: 'btn btn-primary ht-reject fa',
            icon: "fa-times",
            hidden: true
        },
        {
            eventName: 'ht-claim',
            label: 'Claim',
            cssClass: 'btn btn-primary ht-claim',
            disabled: true,
            hidden: true
        },
        {
            eventName: 'ht-unclaim',
            label: 'Unclaim',
            cssClass: 'btn btn-primary ht-unclaim',
            disabled: true,
            hidden: true
        },
        {
            eventName: 'ht-assign',
            label: 'Assign',
            cssClass: 'btn btn-primary',
            hidden: true
        },
        {
            eventName: 'ht-details',
            label: 'Details',
            cssClass: 'btn btn-primary'
        }
    ]},
    { _key: 'taskId', title: 'TaskId', width: 80 },
    { _key: 'name', title: 'Name', width: 180 },
    { _key: 'description', title: 'Description', width: 300 },
    { _key: 'priority', title: 'Priority', width: 62 },
    { _key: 'assignee', title: 'Assignee', width: 120 },
    { _key: 'status', title: 'Status', width: 120 },
    { _key: 'creationDate', title: 'Creation-Date', width: 130 },
    { _key: 'dueDate', title: 'Due-Date', width: 130 },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'approvalHT', title: 'ApprovalTask', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'saveableHT', title: 'SaveableTask', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'rejectableHT', title: 'RejectableTask', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'submitBtnLabel', title: 'SubmitBtnLabel', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'approveBtnLabel', title: 'ApproveBtnLabel', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'rejectBtnLabel', title: 'RejectBtnLabel', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'saveBtnLabel', title: 'SaveBtnLabel', width:0, hidden: true  },
    { _key: 'notClaimableHT', title: 'NotClaimableTask', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'notAssignableHT', title: 'NotAssignableTask', width:0, hidden: true },
    //width 0 to hide column, as we don't want to show it, we just need it for some validation
    { _key: 'dueDateHorizon', title: 'Due-Date Horizon', width: 170 }
    
]

const columnsOrig: Column[] = JSON.parse(JSON.stringify(columns));

export function createColumnsForConfig(config: HumanTasksConfig, htSimple: any) {
    const extraColumnVars = config.extraColumnVariables;
    const standardColumnVars = config.standardColumnVariables;

    //This is a copy of columns, the reason is not inside the loop below the columns array was getting updated
    const columnsCopy: Column[] = [
        { _key: 'actions', title: 'Actions', width: 240, hidden:true, actions: [
            {
                eventName: 'ht-approve',
                cssClass: 'btn btn-primary ht-approve fa',
                icon: "fa-check",
                hidden: true
            },
            {
                eventName: 'ht-reject',
                cssClass: 'btn btn-primary ht-reject fa',
                icon: "fa-times",
                hidden: true
            },
            {
                eventName: 'ht-claim',
                label: 'Claim',
                cssClass: 'btn btn-primary ht-claim',
                disabled: true,
                hidden: true
            },
            {
                eventName: 'ht-unclaim',
                label: 'Unclaim',
                cssClass: 'btn btn-primary ht-unclaim',
                disabled: true,
                hidden: true
            },
            {
                eventName: 'ht-assign',
                label: 'Assign',
                cssClass: 'btn btn-primary',
                hidden: true
            },
            {
                eventName: 'ht-details',
                label: 'Details',
                cssClass: 'btn btn-primary'
            }
        ]},
        { _key: 'taskId', title: 'TaskId', width: 80 },
        { _key: 'name', title: 'Name', width: 180 },
        { _key: 'description', title: 'Description', width: 300 },
        { _key: 'priority', title: 'Priority', width: 62 },
        { _key: 'assignee', title: 'Assignee', width: 120 },
        { _key: 'status', title: 'Status', width: 120 },
        { _key: 'creationDate', title: 'Creation-Date', width: 130 },
        { _key: 'dueDate', title: 'Due-Date', width: 130 },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'approvalHT', title: 'ApprovalTask', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'saveableHT', title: 'SaveableTask', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'rejectableHT', title: 'RejectableTask', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'submitBtnLabel', title: 'SubmitBtnLabel', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'approveBtnLabel', title: 'ApproveBtnLabel', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'rejectBtnLabel', title: 'RejectBtnLabel', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'saveBtnLabel', title: 'SaveBtnLabel', width:0, hidden: true  },
        { _key: 'notClaimableHT', title: 'NotClaimableTask', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'notAssignableHT', title: 'NotAssignableTask', width:0, hidden: true },
        //width 0 to hide column, as we don't want to show it, we just need it for some validation
        { _key: 'dueDateHorizon', title: 'Due-Date Horizon', width: 170 }
        
    ]
    
    var columnsEnhanced;
    if(standardColumnVars === undefined) {
        columnsEnhanced = columns.slice();
    } else {
        columnsEnhanced = [];
        for(let column of columnsCopy) {
            if(standardColumnVars !== undefined) {
                let visible = true;
                standardColumnVars.forEach((columnB) => {
                    if(columnB.key === column._key) {
                        visible = columnB.visible;
                    }
                })
                if(!visible) {
                    column.width=0;
                } else {
                    if(column._key === 'actions') {
                        var width = 240;
                        if(htSimple !== undefined) {
                            if(htSimple.notClaimableHT) {
                                width -= 60;
                            }
                            if(htSimple.notAssignableHT) {
                                width -= 60;
                            }
                            column.width = width;
                        }
                    } else {
                        columnsOrig.forEach((columnOrig) => {
                            if(columnOrig._key === column._key) {
                                column.width = columnOrig.width;
                            }
                        });
                    }
                }
                columnsEnhanced.push(column);
            }
        }
    }
    if (extraColumnVars) {
        if(extraColumnVars.length > 0 ){
            for (let columnVar of extraColumnVars) {
                const key = columnVar.key;
                var width = 120;
                var multiplier = Math.ceil(key.length / 12);
                columnsEnhanced.push({
                    _key: columnVar.value,
                    title: key,
                    width: width * multiplier
                });
            }
        }
    }
    return columnsEnhanced;
}
