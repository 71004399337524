import React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { SetPropertyActionConfig } from "core/dashboard/types/actionHandlerTypes";
import { FieldProps } from "formik";

export default function SetPropertyActionInput(props: FieldProps) {
    const { field, form } = props;
    const value = field.value as SetPropertyActionConfig;
    return (
        <div className="action-config">
            <FormGroup>
                <FormLabel>Property</FormLabel>
                <FormControl value={value.property} onChange={(e: any) => {
                    form.setFieldValue(field.name, { ...value, property: e.target.value });
                }}/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Value</FormLabel>
                <FormControl value={value.value} onChange={(e: any) => {
                    form.setFieldValue(field.name, { ...value, value: e.target.value });
                }}/>
            </FormGroup>
        </div>
    );
}
