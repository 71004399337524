import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { LineChartConfig } from '../lineChartTypes';
import LineChartWidgetConfigForm from './LineChartWidgetConfigForm';
import { AxisType  } from '../../../../types/configTypes';
import { DateTimeUnit } from '../../../../types/dateTimeTypes';
import { ReportDataSource, WidgetActions } from '../../../../types/widgetTypes';

type Props = {
    common: WidgetCommonConfig;
    config: LineChartConfig | undefined;
    actions: WidgetActions;
    dataSources: ReportDataSource[];
};

export type LineChartFormValues = LineChartConfig & WidgetCommonConfig;

const DEFAULT_VALUES: LineChartConfig = {
    showLegend: false,
    xAxis: {
        label : '',
        type: AxisType.LINEAR,
        timeUnit: DateTimeUnit.DAY
    },
    yAxis: {
        label: '',
        type: AxisType.LINEAR,
        timeUnit: DateTimeUnit.DAY
    },
    sources: [{
        reportSource: '',
        xColumn: '',
        yColumn: '',
        reportParams: undefined,
        groupBy: undefined,
        type: '',
        sqlParams: {},
        orderColumn: '',
        providersHandlers: ''
    }],
    sourceMapping: undefined,
    action: undefined
};

export default function LineChartWidgetConfig(props: Props) {
    const common = props.common;
    const config = props.config;

    const initialValues = useMemo((): LineChartFormValues => {
        const configValues = config || DEFAULT_VALUES;
        return Object.assign({}, common, configValues);
    }, [config, common]);

    function onSubmit(values: LineChartFormValues) {
        const common: WidgetCommonConfig = {
            widgetName: values.widgetName,
            pollInterval: values.pollInterval,
            events: values.events
        };

        const config: LineChartConfig = {
            showLegend: values.showLegend,
            sources: values.sources,
            xAxis: values.xAxis,
            yAxis: values.yAxis,
            action: values.action,
            sourceMapping: values.sourceMapping,
            format: values.format
        };

        const dataSources: ReportDataSource[] = values.sources.map((source, index) => {
            let uuid = v4();
            if(props.dataSources){
                if(props.dataSources[index]){
                    uuid = props.dataSources[index].uniqueIdentifier
                }
            }
            return {definition: source.reportSource, type: source.type, uniqueIdentifier: uuid, params: source.sqlParams, orderColumn: source.orderColumn };
        });

        // configure the widget in the store
        props.actions.configure(common, config, dataSources);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={(formikProps) => (
                <LineChartWidgetConfigForm
                    {...formikProps}
                    isCancellable={config != null}
                    onCancel={props.actions.cancelConfig}
                />
            )}
        />
    );
}
