import React from 'react';
import { FieldProps, getIn } from 'formik';
import { FormControl } from 'react-bootstrap';

export type Option = string | { value: string; label: string };

type Props = {
    options: Option[];
} & FieldProps;

export function createValidator(options: { value: string; label: string }[]) {
    return (input: string) => {
        if (options.find(opt => opt.value === input)) {
            return 'Invalid option';
        }
    };
}

function extractValue(opt: Option) {
    return typeof opt === 'string' ? opt : opt.value;
}

function extractLabel(opt: Option) {
    return typeof opt === 'string' ? opt : opt.label;
}

export default function SelectInput({ field, form, options }: Props) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return (
        <FormControl isInvalid={!!error} as="select" {...field}>
            <option value="" disabled></option>
            {options.map((opt, key) => (
                <option key={key} value={extractValue(opt)}>
                    {extractLabel(opt)}
                </option>
            ))}
        </FormControl>
    );
}
