import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
    Row,
    Column
} from '@pearlchain/component-lib-react-pg';

import { HumanTasksConfig } from '../humanTasksTypes';
import { WidgetActions } from '../../../../types/widgetTypes';
import { createHumanTasksRequestHandler } from './humanTasksRequestHandler';
import { createColumnsForConfig } from './humanTasksGridColumns';
import HumanTaskDetailInfo from './detail/HumanTaskDetail';
import { HumanTaskOverviewResponse } from '../types/responseTypes';
import { useWidgetDataFetcher } from '../../../../utils/hooks/useWidgetDataFetcher';
import { claimTask, unclaimTask, submitTask, findHumanTasksSimple } from '../humanTasksRequests';
import AssignTaskModal from './AssignTaskModal';
import { useRequestHandler } from '../../../../utils/hooks/useRequestHandler';
import { initialWidgetErrorState } from '../../singlesource/view/SingleSourceWidgetView';
import WidgetError from 'core/dashboard/components/widget/WidgetError';
import { useTranslation } from 'react-i18next';
import { getHumanTableColumns } from 'core/dashboard/utils/react-table/ReactTableHuman';
import { SortColumn } from '../../singlesource/view/SingleSourceWidgetGrid';
import moment from 'moment';
import ReactTableGridHuman from 'core/dashboard/components/layout/ReactTableGridHuman';

type Props = {
    refreshCount: number;
    actions: WidgetActions;
    config: HumanTasksConfig;
    responseData: HumanTaskOverviewResponse[] | undefined;
    htSimple: HumanTaskOverviewResponse | undefined;
};

function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
    // only show the claim action-button if the task has no assignee
    if (property === 'ht-claim' && attribute === 'actionDisabled') {
        return !!row[5];
    }
    // only show the unclaim action-button if the task has no assignee
    if (property === 'ht-unclaim' && attribute === 'actionDisabled') {
        return !row[5];
    }
    if (property === 'ht-claim' && attribute === 'actionHidden') {
        return row[16];
    }
    // only show the unclaim action-button if the task has no assignee
    if (property === 'ht-unclaim' && attribute === 'actionHidden') {
        return row[16];
    }
    // only show the unclaim action-button if the task has no assignee
    if (property === 'ht-assign' && attribute === 'actionHidden') {
        return row[17];
    }
    if (property === 'ht-approve' && attribute === 'actionHidden') {
        //Hiding these buttons as these were not required for the spec, but we might be able to reuse this functionality in the future
        //return !row[9];
        return true;
    }
    if (property === 'ht-reject' && attribute === 'actionHidden') {
        return true;
        //return !row[9];
    }
}

type DetailModalParams = {
    task: HumanTaskOverviewResponse;
    show: boolean;
};

type AssignModalParams = {
    taskId: string;
    show: boolean;
};

export default function HumanTasksWidgetView(props: Props) {
    const { actions, config, refreshCount, responseData, htSimple } = props;
    const [detailModalParams, setDetailModalParams] = useState<DetailModalParams | undefined>(
        undefined
    );
    const [assignModalParams, setAssignModalParams] = useState<AssignModalParams | undefined>(
        undefined
    );
    const [widgetError, setWidgetError] = useState(initialWidgetErrorState);
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

    const { t } = useTranslation();

    // Setup the data-fetcher to update the backing data whenever the "refreshCount" was incremented
    const dataFetcher = useCallback(createHumanTasksRequestHandler(config), [config]);
    useWidgetDataFetcher(refreshCount, dataFetcher, actions, handleWidgetError);
    const data = useMemo(() => (responseData ? getHumanData(responseData, sortColumns) : []), [responseData, sortColumns])

    function handleWidgetError(hasError: boolean, error: string){
        setWidgetError({hasError, error})
    }

    const approveTaskHandler = useRequestHandler(submitTask, 'Approve Task');
    const handleApproveTask = useCallback((id: number) => {
        if (!data) return;
        const taskId: string = data[id].taskId;
        approveTaskHandler(taskId, {}, true).then(() => {
            actions.triggerRefresh(false);
        });
    }, [approveTaskHandler, data]);

    const rejectTaskHandler = useRequestHandler(submitTask, 'Reject Task');
    const handleRejectTask = useCallback((id: number) => {
        if (!data) return;
        const taskId: string = data[id].taskId;
        rejectTaskHandler(taskId, {}, false).then(() => {
            actions.triggerRefresh(false);
        });
    }, [rejectTaskHandler, data]);

    const claimTaskHandler = useRequestHandler(claimTask, 'Claim Task');
    const handleClaimHumanTask = useCallback((evt: any) => {
        const taskId: string = evt.row.id;
        claimTaskHandler(taskId).then(() => {
            actions.triggerRefresh(false);
            data?.map((d, i) => {
                if(d.taskId === taskId){
                    // @ts-ignore: Unreachable code error
                    window.document.getElementsByClassName("ht-unclaim")[i].disabled =  false
                    // @ts-ignore: Unreachable code error
                    window.document.getElementsByClassName("ht-claim")[i].disabled =  true
                }
            })
        });
    }, [claimTaskHandler, data]);

    const unclaimTaskHandler = useRequestHandler(unclaimTask, 'Unclaim Task');
    const handleUnclaimHumanTask = useCallback((id: number) => {
        if (!data) return;
        const taskId: string = data[id].taskId;
        unclaimTaskHandler(taskId).then(() => {
            actions.triggerRefresh(false);
            data?.map((d, i) => {
                if(d.taskId === taskId){
                    // @ts-ignore: Unreachable code error
                    window.document.getElementsByClassName("ht-unclaim")[i].disabled =  true
                    // @ts-ignore: Unreachable code error
                    window.document.getElementsByClassName("ht-claim")[i].disabled =  false
                }
            })
        });
    }, [unclaimTaskHandler, data]);

    function handleShowAssignTask(id: number) {
        if (!data) return;
        const taskId: string = data[id].taskId;
        setAssignModalParams({ show: true, taskId });
    }

    function handleHideAssignTask(submitted: boolean) {
        if (submitted) actions.triggerRefresh(false);
        setAssignModalParams(
            assignModalParams && {
                ...assignModalParams,
                show: false
            }
        );
    }

    function handleShowTaskDetail(id: number) {
        if (!data) return;
        const taskId: string = data[id].taskId;
        const task = data.find(d => d.taskId === taskId);
        if (task) setDetailModalParams({ task, show: true });
    }

    function handleHideTaskDetail(submitted: boolean) {
        if (submitted) actions.triggerRefresh(false);
        setDetailModalParams(
            detailModalParams && {
                ...detailModalParams,
                show: false
            }
        );
    }

    function getHumanData(data: Iterable<HumanTaskOverviewResponse>, sortColumns: SortColumn[]) {
        const rows : any[] = [];

        for (let item of data) {
            const itemAux : any = item

            for (const property in item.processVariables) {
                itemAux[property] = item.processVariables[property]
            }

            rows.push(itemAux)
        }

        //sorting
        if(sortColumns.length > 0){
            const key = sortColumns[0].key

            if(key === "creationDate"){
                if(sortColumns[0].direction === "ascending"){
                    rows.sort((a, b) => {
                        var date1 = moment(a[key], 'DD/MM/YYYY HH:mm').toDate()
                        var date2 = moment(b[key], 'DD/MM/YYYY HH:mm').toDate()
                        return Number(date1)-Number(date2)
                    })
                }
                else {
                    rows.sort((a, b) => {
                        var date1 = moment(a[key], 'DD/MM/YYYY HH:mm').toDate()
                        var date2 = moment(b[key], 'DD/MM/YYYY HH:mm').toDate()
                        return Number(date2)-Number(date1)
                    })
                }
            }
            else {
                if(sortColumns[0].direction === "ascending"){
                    rows.sort((a, b) => {
                        if (a[key] < b[key])
                          return -1;
                        if (a[key]> b[key])
                          return 1;
                        return 0;
                      });
                    
                }
                else {
                    rows.sort((a, b) => {
                        if (a[key] > b[key])
                          return -1;
                        if (a[key] < b[key])
                          return 1;
                        return 0;
                      });
                }
            }
        }
    
        return rows;
    }


    function getHiddenColumns(columns: Column[]) {
        const hiddenColumns: String[] = [];

        let i = 0;
        for (let item of columns) {
            if(item.width == 0){
                hiddenColumns.push(item._key)
            }
        }

        return hiddenColumns;
    }

    function handleSortColumns(cols: SortColumn[]){
        if(JSON.stringify(cols) !== JSON.stringify(sortColumns)){
            setSortColumns(cols)
        }
    }

    // Construct the grid columns from the widget config
    const columns = useMemo(() => createColumnsForConfig(config, htSimple), [config, htSimple]);
    const hiddenColumns = useMemo(() => getHiddenColumns(columns), [columns]);
    const events=[
        { key: 'ht-approve', action: handleApproveTask },
        { key: 'ht-reject', action: handleRejectTask },
        { key: 'ht-claim', action: handleClaimHumanTask },
        { key: 'ht-unclaim', action: handleUnclaimHumanTask },
        { key: 'ht-assign', action: handleShowAssignTask },
        { key: 'ht-details', action: handleShowTaskDetail }
    ]

    let columnsReactTable = getHumanTableColumns(t, columns, responseData ? responseData : [], events)

    if(!htSimple) {
        return (<div>Loading...</div>)
    } else {
        return (
            <div className="wrap-grid">
                {widgetError.hasError ? <WidgetError error={widgetError.error} /> : '' }
                <ReactTableGridHuman
                    data={data ? data : []}
                    columns={columnsReactTable}
                    hiddenColumns={hiddenColumns}
                    onSortColumnsChanged={(cols) => {
                        // update reference to the grid sort columns
                        handleSortColumns(cols);
                    }}
                />
                {detailModalParams && (
                    <HumanTaskDetailInfo {...detailModalParams} onHide={handleHideTaskDetail} />
                )}
                {assignModalParams && (
                    <AssignTaskModal {...assignModalParams} onHide={handleHideAssignTask} />
                )}
            </div>
        );
    }
}
