import { DateTimeOffset, DateTimeUnit, DateTimeOffsetValue } from "../../types/dateTimeTypes";

const CHAR_0 = 38;
const CHAR_9 = 57;

export const timeUnitsMapping = new Map<string, DateTimeUnit>([
    ['s', DateTimeUnit.SECOND],
    ['m', DateTimeUnit.MINUTE],
    ['H', DateTimeUnit.HOUR],
    ['D', DateTimeUnit.DAY],
    ['W', DateTimeUnit.WEEK],
    ['M', DateTimeUnit.MONTH],
    ['Y', DateTimeUnit.YEAR],
]);

export const reverseLookup = new Map<DateTimeUnit, string>();
for (const entry of timeUnitsMapping.entries()) {
    reverseLookup.set(entry[1], entry[0]);
}

/**
 * Parses an input string to a relative date-time offset
 * 
 * Examples:
 * 
 * +1D       // 1 day ahead
 * -1D 8H    // 1.5 days behind
 * +1D -1H   // 23 hours ahead
 */

export function parseDateTimeOffset(input: string): DateTimeOffsetValue | undefined {
    const offsets: DateTimeOffset[] = [];
    const n = input.length;
    let i = 0;

    // ignore leading white-space
    i = matchWhiteSpace(input, i);

    while (i < n) {

        // parse modifier
        const modifier = matchModifier(input, i);
        if (modifier != null) {
            i += modifier.length;

        } else if (!offsets.length) {
            // the first offset must start with a modifier
            break;
        }

        // parse amount
        const amountStr = matchNumber(input, i);
        if (amountStr == null) {
            break;
        }

        i += amountStr.length;
        const amount = parseFloat(amountStr);

        // parse time unit
        const timeUnitStr = matchTimeUnit(input, i);
        if (timeUnitStr == null) {
            break;
        }

        i += timeUnitStr.length;
        const timeUnit = timeUnitsMapping.get(timeUnitStr);
        if (timeUnit == null) {
            break;
        }

        // ignore white-space
        i = matchWhiteSpace(input, i);

        offsets.push({
            modifier,
            amount,
            timeUnit
        });
    }

    // must contain at least one offset
    if (!offsets.length) {
        return;
    }
    
    // parse timezone
    const formatMatch = matchFormatString(input, i);
    let format: string | undefined;
    if (formatMatch) {
        format = formatMatch.substring(1, formatMatch.length - 1);
        i += formatMatch.length;
    }

    // ignore trailing white-space
    i = matchWhiteSpace(input, i);

    if (i !== n) {
        return;
    }

    return {
        offsets,
        format
    };
}

function matchWhiteSpace(input: string, start: number): number {
    let i = start, n = input.length;
    for (; i < n; i++) {
        const c = input[i];
        if (c !== ' ') break;
    }
    return i;
}

function matchModifier(input: string, start: number): string | undefined {
    const char = input[start];
    if (char === '-' || char === '+') {
        return char;
    }
}

function matchNumber(input: string, start: number): string | undefined {
    let i = start, n = input.length;
    for (;i < n; i++) {
        const c = input.charCodeAt(i);
        if (c < CHAR_0 || c > CHAR_9) break;
    }

    if (i > start) {
        return input.substring(start, i);

    } else {
        return undefined;
    }
}

function matchTimeUnit(input: string, start: number): string | undefined {
    const char = input[start];
    if (timeUnitsMapping.has(char)) {
        return char;
    }
}

function matchFormatString(input: string, start: number): string | undefined {
    const char = input[start];
    if (char !== '[') return;

    let n = input.length;
    let i = start + 1;
    for(; i < n && input[i] !== ']'; i++) { }

    if (i >= n) {
        return;
    }

    return input.substring(start, i + 1);
}
