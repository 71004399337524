import React from 'react';

import { SourceMappingConfig, TargetKey } from '../sourceMappingTypes';
import SourceConfigList from './SourceConfigList';
import SeriesConfigList from './SeriesConfigList';

type Props = {
    name: string;
    config: SourceMappingConfig;
    targetKeys: TargetKey[];
};

export default function SourceMappingConfig({ name, config, targetKeys }: Props) {
    const sources = config.sources;
    const series = config.series;
    return (
        <div className="source-mapping-config">
            <SourceConfigList name={name + '.sources'} sources={sources}/>
            <SeriesConfigList name={name + '.series'} sources={sources} targetKeys={targetKeys} series={series}/>
        </div>
    );
}
