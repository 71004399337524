import { DashboardsState, Widget } from "../types/storeTypes";

export function updateWidget(state: DashboardsState, dashboardUuid: string, widgetUuid: string, fn: (w: Widget<unknown>) => void): void {
    const dashboard = state.dashboards[dashboardUuid];
    if (!dashboard) return;

    const widget = dashboard.widgets[widgetUuid];
    if (!widget) return;

    fn(widget);
}
