import { SingleSourceConfig } from '../singleSourceTypes';
import {
    GroupByTransform,
    SumTransform,
    CountTransform,
    ReportTransform,
    createGroupByTransform,
    createSumTransform,
    createCountTransform,
    createLimitTransform,
    createSortByTransform
} from '../../../../types/transformRequestTypes';
import { getColumnAlias } from './gridHelpers';
import { runReport } from '../../../../utils/requests/requestHandlers';
import { reportParamsConfigToRequest } from '../../../../utils/requests/requestHelpers';
import { RunReportResponse } from '../../../../types/responseTypes';
import { SortColumn } from './SingleSourceWidgetGrid';
import { EvaluationContext } from 'core/dashboard/utils/placeholders/placeholderTypes';
import { evaluateReportParams } from 'core/dashboard/utils/resolver/evaluateHelpers';

/**
 * Map column-config to the report-transforms to send with the request
 */

export const RESULTS_PER_PAGE = 50;

export function createSingleSourceRequestHandler(
    config: SingleSourceConfig,
    ctx: EvaluationContext,
    sortColumns: SortColumn[] | undefined,
    resultOffset: number | undefined
): Promise<RunReportResponse> {
    
    const columnConfigs = config.columns;
    const reportParams = config.reportParams && evaluateReportParams(config.reportParams, ctx);
    const params = reportParamsConfigToRequest(reportParams);
    const limit = config.limit;

    const groupByTransforms: GroupByTransform[] = [];
    const sumTransforms: SumTransform[] = [];
    const countTransforms: CountTransform[] = [];
    const rawTransforms: string[] = [];

    for (let i = 0, n = columnConfigs.length; i < n; i++) {
        const columnConfig = columnConfigs[i];
        const col = columnConfig.value;
        const alias = getColumnAlias(columnConfig, i);

        if (columnConfig.groupBy) {
            groupByTransforms.push(createGroupByTransform(col, alias));
        } else if (columnConfig.sum) {
            sumTransforms.push(createSumTransform(col, alias));
        } else if (columnConfig.count) {
            countTransforms.push(createCountTransform(alias));
        } else if (columnConfig.raw) {
            rawTransforms.push(col);
        }
    }

    const transforms: ReportTransform[] = [groupByTransforms, sumTransforms, countTransforms].flat();
    
    if (sortColumns && sortColumns.length) {
        const sc = sortColumns[0];
        transforms.push(createSortByTransform(sc.key, sc.direction));
    }

    if (limit >= 0) {
        transforms.push(createLimitTransform(limit));
    }

    return runReport({ 
        'report-uuid': config.reportSource,
        params,
        count: RESULTS_PER_PAGE,
        offset: resultOffset || 0,
        transforms
    });
}

