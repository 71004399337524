import React from 'react'
import { FieldProps, getIn } from 'formik';
import { FormControl } from 'react-bootstrap';
import { PropertyConfig } from 'core/dashboard/types/actionHandlerTypes';
import { DataSourceProvider } from 'core/dashboard/types/responseTypes';

type Props = {
    providersHandlers: DataSourceProvider[],
    handleParams: (params: PropertyConfig[] | undefined)  => void
    type: string
}

export default function DataSourcesSelectInput({ field, form, providersHandlers, type, handleParams }: Props & FieldProps) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);

    function handleOnChange(value: string | undefined){
        form.setFieldValue(field.name, value)
        const params = providersHandlers.filter(item => item.name === value)[0].params;
        handleParams(params)
    }
    
    return (
        <FormControl as="select" isInvalid={error} onChange={(e) => handleOnChange(e.currentTarget.value)} name={field.name} value={field.value}>
            <option value="">--</option>
            {providersHandlers.map((item, idx) =>  
                <option key={idx} id={idx+""} title={item.description} value={item.name}>{ item.name }</option>
            )}
        </FormControl>
    )
}
