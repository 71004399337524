import { DashboardProperty } from "core/dashboard/types/storeTypes";

export enum DialogType {
    CONFIRM = 'confirm',
    PROPERTIES = 'properties'
}

export type ConfirmDialogOpts = {
    type: DialogType.CONFIRM;
    title: string;
    text: string;
}

export type PropertiesDialogOpts = {
    type: DialogType.PROPERTIES;
    dashboardUuid: string;
}

export type PropertiesDialogResult = {
    dashboardUuid: string;
    properties: DashboardProperty[];
} | null;

export type DialogOpts = ConfirmDialogOpts | PropertiesDialogOpts;

export type DialogOptsForType<T extends DialogType> =
    T extends DialogType.CONFIRM ? ConfirmDialogOpts :
    T extends DialogType.PROPERTIES ? PropertiesDialogOpts :
    never;

export type DialogResultForType<T extends DialogType> =
    T extends DialogType.CONFIRM ? boolean :
    T extends DialogType.PROPERTIES ? PropertiesDialogResult :
    never;

export type DialogState = {
    show: boolean;
    opts: DialogOpts;
    result: unknown
};

export type DialogsState = {
    dialogs: {
        [dialogId: string]: DialogState
    };
};
