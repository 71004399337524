import { RequestBuilder } from "@pearlchain/component-lib-common";

import {
    ActionConfig,
    ActionHandlerType,
    DrilldownActionConfig,
    ShowRcpViewActionConfig,
    SetPropertyActionConfig,
    HttpRequestActionConfig,
    ActionHandler,
    ShowPageActionConfig,
    DataSourceActionConfig
} from '../types/actionHandlerTypes';
import { WidgetActions } from '../types/widgetTypes';
import { DrilldownType } from '../types/drilldownTypes';
import { Evaluator } from '../evaluator/Evaluator';
import { toString } from "../utils/dataTypes";
import { DataSourcesResponseDto } from "../types/responseTypes";

/**
 * Creates widget action-handlers. These are the actions that are performed
 * when the user interacts with a widget
 */

export function createActionHandler(actions: WidgetActions, evaluator: Evaluator): ActionHandler {
    const handleDrilldown = createDrilldownActionHandler(actions, evaluator);
    const handleShowRcpView = createShowRcpViewActionHandler(actions, evaluator);
    const handleSetProperty = createSetPropertyActionHandler(actions, evaluator);
    const handleHttpRequest = createHttpRequestActionHandler(actions, evaluator);
    const handleShowPage = createShowPageActionHandler(actions, evaluator);
    const handleDataSources = createDataSourcesActionHandler(actions, evaluator);

    return (config: ActionConfig, context: { [key: string]: unknown }) => {
        switch (config.type) {
            case ActionHandlerType.DRILLDOWN:
                handleDrilldown(config, context);
                break;
                
            case ActionHandlerType.SHOW_RCP_VIEW:
                handleShowRcpView(config, context);
                break;

            case ActionHandlerType.SET_PROPERTY:
                handleSetProperty(config, context);
                break;

            case ActionHandlerType.HTTP_REQUEST:
                handleHttpRequest(config, context);
                break;

            case ActionHandlerType.SHOW_PAGE:
                handleShowPage(config, context);
                break;

            case ActionHandlerType.DATA_SOURCE:
                handleDataSources(config, context);
                break;
        }
    };
}

function createDrilldownActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: DrilldownActionConfig, context: { [key: string]: unknown }) => {
        const properties: { [key: string]: unknown } = {};
        
        for (let prop of config.properties) {
            properties[prop.name] = evaluator.evaluate(prop.value as string, context);
        }

        actions.pushDrilldown({
            type: DrilldownType.DASHBOARD,
            dashboardUuid: config.dashboardUuid,
            properties
        });
    };
}

function createShowRcpViewActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: ShowRcpViewActionConfig, context: { [key: string]: unknown }) => {
        const viewName = config.viewName;
        const viewParams = config.viewParams;

        const fastOpenProperties: { [key: string]: any } = {};
        for (let prop of viewParams) {
            fastOpenProperties[prop.name] = evaluator.evaluate(prop.value as string, context);
        }

        const rcp: any = (window as any).rcp;
        if (rcp && rcp.openView) {
            rcp.openView(viewName, JSON.stringify(fastOpenProperties));
        }
    };
}

function createSetPropertyActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: SetPropertyActionConfig, context: { [key: string]: unknown }) => {
        const value = evaluator.evaluate(config.value, context);
        actions.setDashboardPropertyValue(config.property, value);
    };
}

function createHttpRequestActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: HttpRequestActionConfig, context: { [key: string]: unknown }) => {
        const body: { [key: string]: string } = {};
        
        for (const prop of config.params) {
            body[prop.name] = '' + evaluator.evaluate(prop.value as string, context);
        }

        const url = '' + evaluator.evaluate(config.url, context);

        new RequestBuilder()
            // request method is always POST
            .post(url)
            .setBodyJson(body)
            .fetch()
            .finally(() => {

                // and then finally refresh the widget / Dashboard
                if (config.refreshDashboard) {
                    actions.triggerRefreshDashboard();

                } else {
                    actions.triggerRefresh(false);
                }
            });
    };
}

function createShowPageActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: ShowPageActionConfig, context: { [key: string]: unknown }) => {
        let url = toString(evaluator.evaluate(config.url, context));

        if (config.params && config.params.length > 0) {
            const searchParams = new URLSearchParams();
            for (const param of config.params) {
                const paramValue = toString(evaluator.evaluate(param.value as string, context));
                searchParams.append(param.name, paramValue);
            }

            url += '?' + searchParams.toString();
        }

        window.open(url, '_blank');
    };
} 

function createDataSourcesActionHandler(actions: WidgetActions, evaluator: Evaluator) {
    return (config: DataSourceActionConfig, context: { [key: string]: unknown }) => {
        let parameters : { [key: string]: any } = {}
        for (const param of config.params) {
            const val = (param.value+"").replace('=row.','')
            //In case of boolean values
            if(typeof (context.row as any)[val] === 'boolean'){
                parameters[param.name] = (context.row as any)[val] ? "1" : "0"
            }
            else if(param.value != undefined && param.value != null){
                const paramValue = toString(evaluator.evaluate(param.value as string, context));
                parameters[param.name] = paramValue
            }
        }

        const requestBody = {
            dataSourceName: config.dataSources,
            functionName: config.providersHandlers,
            params: parameters
        }

        new RequestBuilder()
            // request method is always POST
            .post('/sec/api/datasources-execution/invoke')
            .setBodyJson(requestBody)
            .toJS()
            .then(response => {
                const res = response as DataSourcesResponseDto
                if(res.succeeded){
                    actions.show("success","Success","Your data has been processed succesfully");
                    actions.triggerRefreshDashboard();
                }
                else{
                    actions.show("error","Error",res.failReason)
                }
            })
    };
}
