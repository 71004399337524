import React from 'react';

import { WidgetProps } from '../../widget/widgetTypes';
import { BarChartConfig } from './barChartTypes';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';
import BarChartWidgetConfig from './config/BarChartWidgetConfig';
import BarChartWidgetView from './view/BarChartWidgetView';
import { RunReportResponse } from '../../../types/responseTypes';
import { useTrackAccessorsPropertySource } from 'core/dashboard/evaluator/propertysource/useTrackAccessorsPropertySource';

export const BAR_CHART_TYPE = 'BAR_CHART';

export default function BarChartWidget(props: WidgetProps) {
    const { state, actions, propertySource } = props;

    const widget = state.widget;
    const common = widget.common;
    const config = widget.config as BarChartConfig | undefined;

    // replace with a memoized proxy, so the widget updates only when observed properties changed
    const properties = useTrackAccessorsPropertySource(config, propertySource);

    function renderView() {
        if (config == null) return renderConfig();
        if (state.hasStateError) return <></>
        const data = state.data as RunReportResponse[] | undefined;

        return (
            <BarChartWidgetView
                config={config}
                refreshCount={state.refreshCount}
                actions={actions}
                data={data}
                properties={properties}
                common={common}
            />
        );
    }

    function renderConfig() {
        return <BarChartWidgetConfig common={common} config={config} actions={actions} dataSources={widget.dataSources} />;
    }

    if (state.viewMode === WidgetViewMode.VIEW) {
        return renderView();
    } else {
        return renderConfig();
    }
}
