import { WidgetDefinition, DataSource } from "../../../types/widgetTypes";
import echartsImage from '../../../../../img/echarts.png';
import PieChartWidget from "./PieChartWidget";
import { PieChartConfig } from "./pieChartTypes";

export const PIE_CHART_WIDGET_TYPE = 'PIE_CHART';

export const PIE_CHART_WIDGET: WidgetDefinition = {
    name: 'Pie-Chart',
    description: 'Displays report results in a pie-chart',
    icon: 'chart-pie',
    image: echartsImage,
    component: PieChartWidget,
    listSources: (config: PieChartConfig) : DataSource[] => [{
        source: config.reportSource,
        params: config.reportParams || [],
    }]
}
