export type TargetKey = {
    name: string;
    description: string;
    mandatory?: boolean;
};

export enum FieldType {
    RAW = 'raw',
    COUNT = 'count',
    SUM = 'sum',
    GROUP_BY = 'group-by'
}

export type FieldMappingConfig = {
    type: FieldType;
    from: string;
    to: string;
};

export type SourceConfig = {
    name: string;
    report: string;
    type?: string;
    params: { [key: string]: string };
    sqlParams: { [key: string]: any };
    orderColumn: string,
    providersHandlers: string
};

export type SeriesConfig = {
    series: string;
    source: string;
    mappings: FieldMappingConfig[];
};

export type SourceMappingConfig = {
    sources: SourceConfig[];
    series: SeriesConfig[];
};
