import { all, call, takeEvery, put, select, debounce } from "redux-saga/effects";
import { getLoginName } from "@pearlchain/component-lib-common";
import { error, success } from 'react-notification-system-redux';

import { WebComponentBriefResponse } from "../types/responseTypes";
import { Dashboard, Widget } from "../types/storeTypes";

import {
    findAvailableDashboards,
    saveWebComponent,
    deleteWebComponent,
    saveStarredWebComponents,
    registerFirebaseToken, unregisterFirebaseToken
} from '../utils/requests/requestHandlers';
import ActionType, * as Acts from "../actions/dashboard-actions";
import { loadDashboard, loadWidget } from './utils/dashboardLoader';
import { buildEmptyDashboard } from "./utils/dashboardBuilder";
import { serializeDashboard } from "../utils/serialization/dashboardSerialization";
import { getAvailableStarredWebComponents } from "../utils/selector/selectors";

export default function() {
    return all([
        takeEvery(ActionType.CREATE_DASHBOARD, handleCreateDashboard),
        takeEvery(ActionType.DELETE_DASHBOARD, handleDeleteDashboard),
        takeEvery(ActionType.FETCH_AVAILABLE_DASHBOARDS, handleFetchAvailableDashboards),
        takeEvery(ActionType.LOAD_DASHBOARD, handleLoadDashboard),
        takeEvery(ActionType.LOAD_DASHBOARD_WIDGET, handleLoadWidget),
        takeEvery(ActionType.SUBSCRIBE_FIREBASE_TOKEN, subscribeFirebaseToken),
        takeEvery(ActionType.UNSUBSCRIBE_FIREBASE_TOKEN, unsubscribeFirebaseToken),
        debounce(1000, [
            ActionType.MARK_DASHBOARD_STARRED,
            ActionType.UNMARK_DASHBOARD_STARRED,
            ActionType.MOVE_STARRED_DASHBOARD
        ], updateStarredDashboards)
    ]);
}

function *handleCreateDashboard({ name }: Acts.CreateDashboardAction) {
    const dashboard = buildEmptyDashboard(name);
    const params = serializeDashboard(dashboard);
    try {
        yield call(saveWebComponent, params);
        yield put(Acts.doneCreateDashboard(dashboard));
        yield put(success({ title: 'Created', message: 'Dashboard created successfully' }));

    } catch(e) {
        yield put(error({ title: 'Error creating dashboard', message: '' + e.message }));
    }
}

function *handleDeleteDashboard({ dashboardUuid, version }: Acts.DeleteDashboardAction) {
    try {
        yield call(deleteWebComponent, { uuid: dashboardUuid, version });
        yield put(Acts.doneDeleteDashboard(dashboardUuid));

    } catch (e) {
        put(error({ title: 'Error deleting dashboard', message: '' + e.message }));
    }
}

function *updateStarredDashboards({ view }: { view: string, type: string }) {
    const starred: WebComponentBriefResponse[] | undefined = yield select(getAvailableStarredWebComponents);
    if (!starred) return;
    const uuids = starred.map(wc => wc.uniqueIdentifier);
    try {
        yield call(saveStarredWebComponents, view, uuids);
    } catch(err) {
        console.error(err);
    }
}

function filterAvailableDashboards(available: WebComponentBriefResponse[]) {
    const starred: WebComponentBriefResponse[] = [];
    const owned: WebComponentBriefResponse[] = [];
    const shared: WebComponentBriefResponse[] = [];

    const loginName = getLoginName();
    for (const wc of available) {
        if (wc.favourite) {
            starred.push(wc);

        } else if (wc.owner === loginName) {
            owned.push(wc);

        } else {
            shared.push(wc);
        }
    }

    return { starred, owned, shared };
}

function *handleFetchAvailableDashboards({ view }: Acts.FetchAvailableDashboardsAction) {
    try {
        const allDashboards: WebComponentBriefResponse[] = yield call(findAvailableDashboards, view);
        const { starred, owned, shared } = filterAvailableDashboards(allDashboards);
        yield put(Acts.receiveAvailableDashboards(starred, owned, shared));
    } catch(e) {
        yield put(error({ title: 'Error fetching available dashboards', message: '' + e.message }));
    }
}

function *handleLoadDashboard({ dashboardUuid }: Acts.LoadDashboardAction) {
    try {
        const dashboard: Dashboard = yield call(loadDashboard, dashboardUuid);
        yield put(Acts.receiveDashboard(dashboardUuid, dashboard));
    } catch(err) {
        console.error(err);
        yield put(error({ title: 'Error loading dashboard', message: '' + err.message }));
    }
}

function *handleLoadWidget({ dashboardUuid, widgetUuid }: Acts.LoadDashboardWidgetAction) {
    try {
        const widget: Widget<unknown> = yield call(loadWidget, widgetUuid);
        yield put(Acts.receiveDashboardWidget(dashboardUuid, widget));
    } catch(err) {
        console.error(err);
        yield put(error({ title: 'Error loading dashboard', message: '' + err.message }));
    }
}

function *subscribeFirebaseToken({ token }: Acts.SubscribeFirebaseTokenAction) {
    try {
        const request = { token: token }
        yield call(registerFirebaseToken, request);
    } catch(err) {
        console.error(err);
        yield put(error({ title: 'Error registering firebase token', message: '' + err.message }));
    }
}

function *unsubscribeFirebaseToken({ }: Acts.UnsubscribeFirebaseTokenAction) {
    try {
        yield call(unregisterFirebaseToken);

    } catch(err) {
        console.error(err);
        yield put(error({ title: 'Error unregistering firebase token', message: '' + err.message }));
    }
}
