import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalStorage } from '@pearlchain/component-lib-common';

import { StoreState } from '../../types/storeTypes';
import { isDashboardModified, isDashboardReadonly, isDashboardSaving } from '../../utils/selector/selectors';
import { saveDashboardChanges } from '../../actions/dashboard-actions';

type OwnProps = {
    dashboardUuid: string
};

type Props = {
    isReadonly: boolean,
    isModified: boolean,
    isSaving: boolean,
    saveDashboardChanges: typeof saveDashboardChanges
} & OwnProps;

function DashboardSaver(props: Props) {
    const [isAutoSync, setAutoSync] = useLocalStorage('auto-sync');    
    
    const canSaveChanges = !props.isReadonly && !props.isSaving && props.isModified;
    const isAutoSyncEnabled = isAutoSync === "true";

    useEffect(() => {
        let interval:  number = 0;
            if(isAutoSyncEnabled){ 
                interval =  window.setTimeout(() => {
                    props.saveDashboardChanges(props.dashboardUuid);
                }, 5000);
            }
            return function cleanup() {
                clearTimeout(interval);
            };
        },
        [isAutoSyncEnabled]
    );

    function getClassName() {
        if(props.isSaving) {
            return ' busy';
        } else if(!canSaveChanges) {
            return ' disabled';
        } else {
            return '';
        }
    }

    function onSaved() {
        props.saveDashboardChanges(props.dashboardUuid);
    }

    return (
        <div className={"dashboard-header-item" + getClassName()}>
            <div className="dashboard-header-btn" onClick={canSaveChanges ? onSaved : undefined}>
                <FontAwesomeIcon icon="save"/>
            </div>
            <div className="dashboard-header-item-addon">
                <input id="auto-sync" type="checkbox"
                checked={isAutoSyncEnabled}
                    onChange={(e) => setAutoSync('' + e.target.checked)}/>
                <label htmlFor="auto-sync">Auto-Sync</label>
            </div>
        </div>
    );
}

export default connect(
    (state: StoreState, props: OwnProps) => {
        return { 
            isReadonly: isDashboardReadonly(state, props.dashboardUuid), 
            isModified: isDashboardModified(state, props.dashboardUuid),
            isSaving: isDashboardSaving(state, props.dashboardUuid) 
        };
    },
    { saveDashboardChanges }
)(DashboardSaver);