import React, { useCallback } from 'react';

import { WidgetProps } from '../../widget/widgetTypes';
import { HumanTasksConfig } from './humanTasksTypes';
import { WidgetViewMode } from 'core/dashboard/types/widgetStateTypes';
import HumanTasksWidgetConfig from './config/HumanTasksWidgetConfig';
import HumanTasksWidgetView from './view/HumanTasksWidgetView';
import { HumanTaskOverviewResponse } from './types/responseTypes';
import { useFetcher } from '@pearlchain/component-lib-common';
import { findHumanTasksSimple } from './humanTasksRequests';

export const HUMAN_TASKS_TYPE = 'HUMAN_TASKS';

export default function HumanTasksWidget(props: WidgetProps) {
    const { state, actions } = props;

    const widget = state.widget;
    var config = widget.config as HumanTasksConfig | undefined;

    if (state.viewMode === WidgetViewMode.VIEW && config) {
        const htSimple = useFetcher(useCallback(() => findHumanTasksSimple(config), [config]));

        const data = state.data as HumanTaskOverviewResponse[] | undefined;
        return (
            <HumanTasksWidgetView
                refreshCount={state.refreshCount}
                actions={actions}
                config={config}
                responseData={data}
                htSimple={htSimple.data}
            />
        );
    } else {
        const common = widget.common;
        return <HumanTasksWidgetConfig common={common} config={config} actions={actions} />;
    }
}
