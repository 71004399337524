import React, { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FastField } from 'formik';

import { AxisConfig, AxisType } from "../../../../../types/configTypes";
import { DateTimeUnit } from "../../../../../types/dateTimeTypes";
import FormFieldErrorFeedback from './FormFieldErrorFeedback';
import FastSelectField, { Option } from './FastSelectField';
import FormControlFieldComponent from './FormControlFieldComponent';
import CheckboxInput from './input/CheckboxInput';

type Props = {
    name: string;
    value: AxisConfig;
    axisTypes?: AxisType[];
}

const axisTypes: Option[] = [
    { label: 'Linear', value: AxisType.LINEAR },
    { label: 'Category', value: AxisType.CATEGORY },
    { label: 'Logarithmic', value: AxisType.LOGARITHMIC },
    { label: 'Time', value: AxisType.TIME },
    { label: 'Stepped Line', value: AxisType.STEPPED }
];

const timeUnits: Option[] = [
    { label: 'Second', value: DateTimeUnit.SECOND },
    { label: 'Minute', value: DateTimeUnit.MINUTE },
    { label: 'Hour', value: DateTimeUnit.HOUR },
    { label: 'Day', value: DateTimeUnit.DAY },
    { label: 'Week', value: DateTimeUnit.WEEK },
    { label: 'Month', value: DateTimeUnit.MONTH },
    { label: 'Quarter', value: DateTimeUnit.QUARTER },
    { label: 'Year', value: DateTimeUnit.YEAR }
];

function filterAxisTypeOptions(availableAxisTypes: AxisType[] | undefined) {
    return axisTypes.filter(axisType => availableAxisTypes ? availableAxisTypes.indexOf(axisType.value as AxisType) >= 0 : true)
}

export default function AxisField(props: Props) {
    const name = props.name;
    const axis = props.value;

    let i = 0;
    const columns: React.ReactElement[] = [];

    const axisTypeOptions = useMemo(() => filterAxisTypeOptions(props.axisTypes), [props.axisTypes]);

    columns.push(
        <Col key={i++}>
            <Form.Label>Label</Form.Label>
            <FastField name={name + '.label'} component={FormControlFieldComponent} />
            <FormFieldErrorFeedback name={name + '.label'}/>
        </Col>
    );

    columns.push(
        <Col key={i++}>
            <Form.Label>Type</Form.Label>
            <FastSelectField name={name + '.type'} options={axisTypeOptions} />
            <FormFieldErrorFeedback name={name + '.type'} />
        </Col>
    );

    if (axis && axis.type === AxisType.TIME) {
        columns.push(
            <Col key={i++}>
                <Form.Label>Time Unit</Form.Label>
                <FastSelectField name={name + '.timeUnit'} options={timeUnits} />
                <FormFieldErrorFeedback name={name + '.timeUnit'} />
            </Col>
        );
    }
    
    return (
        <>
            <Form.Row>
                { columns }
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Label>Stacked</Form.Label>
                    <FastField name={name + '.stacked'} component={CheckboxInput}/>
                </Col>
                <Col>
                    <Form.Label>Begin at Zero</Form.Label>
                    <FastField name={name + '.beginAtZero'} component={CheckboxInput}/>
                </Col>
            </Form.Row>
        </>
    );
}
