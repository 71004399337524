import React, { useContext, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DashboardContext } from './context';
import { setShowPropertiesPanel } from 'core/dashboard/actions/dashboard-actions';
import { isShowPropertiesPanel } from 'core/dashboard/utils/selector/selectors';
import DashboardPropertyView from './DashboardPropertyValue';
import { DashboardActionType } from 'core/dashboard/types/dashboardTypes';

export default function DashboardPropertyValues() {
    const { evaluationContext, state, dispatch, parent } = useContext(DashboardContext);
    const propertyConfigs = state.dashboard.properties;
    const [properties, setProperties] = useState(propertyConfigs)
    const propRef = useRef<{[key: string]: unknown;}>({});

    const show = useSelector(isShowPropertiesPanel);
    const reduxDispatch = useDispatch();

    useEffect(() => {
      setProperties(propertyConfigs)
    }, [propertyConfigs])

    const onHide = useCallback(() => {
        reduxDispatch(setShowPropertiesPanel(false));
    }, [reduxDispatch]);

    function handleSetProperties(name:string, value:string){
        setProperties(current =>
            current.map(obj => {
              if (obj.name === name) {
                return {...obj, val:value};
              }
      
              return obj;
            }),
        );
    }

    function handleSubmit(){
        let propertiesObject = {} as {[key: string]: unknown;}
        for (const p of properties){
            propertiesObject[p.name] = p.val
        }
        if(JSON.stringify(propertiesObject) !== JSON.stringify(propRef.current)){
            dispatch({
                type: DashboardActionType.SET_MULTIPLE_PROPERTY_VALUE,
                properties: propertiesObject
            });
        }
        propRef.current = propertiesObject
        
        
    }

    if (show && propertyConfigs.length > 0 && !parent) {
        return (
            <div className="dashboard-property-values-container">
                <FontAwesomeIcon className="dashboard-property-values-minimizer" icon="minus" onClick={onHide}/>
                {propertyConfigs.map((propConfig, i) => {
                    const propertyValue = evaluationContext.properties[propConfig.name];

                    return (
                        <DashboardPropertyView
                            key={i}
                            name={propConfig.name}
                            value={propertyValue}
                            propertyConfig={propConfig}
                            handleSetProperties={handleSetProperties}
                            handleSubmit={handleSubmit}
                        />
                    );
                })}
                
            </div>
        );
        
    } else {
        return null;
    }
}
