import React from 'react';
import { withFormik, FormikProps } from "formik";
import { PluginConfig } from "@pearlchain/component-lib-plugins-core";

import CommonConfig from "../../common/config/CommonConfig";

import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { FormOpts } from '../../../formbuilder/formBuilderTypes';
import FormBuilder from "../../../formbuilder/FormBuilder";
import { formBuilderInputs } from 'core/dashboard/components/formbuilder/formBuilderInputs';
import ConfigPanel from '../../common/config/ConfigPanel';
import WidgetConfigFooter from '../../common/WidgetConfigFooter';

export type FormValues<C> = {
    config: C;
    common: WidgetCommonConfig;
}

type OwnProps<C> = {
    values: FormValues<C>;
    config: PluginConfig<C>;
    isConfigured: boolean;
    onCancel: () => void;
    onSubmit: (values: FormValues<C>) => void;
}

const FORM_BUILDER_OPTS: FormOpts = {
    inputs: formBuilderInputs
};

function PluginWidgetConfigForm<C>(props: FormikProps<FormValues<C>> & OwnProps<C>) {
    const eventNames = props.config.eventNames;
    return (
        <form className="config-form" onSubmit={props.handleSubmit}>
            <CommonConfig fieldName="common" eventNames={eventNames}/>
            <ConfigPanel header="Plugin" defaultExpanded>
                <FormBuilder
                    baseName="config"
                    opts={FORM_BUILDER_OPTS}
                    values={props.values.config}
                    errors={props.errors}
                    config={props.config}
                />
            </ConfigPanel>
            <WidgetConfigFooter
                isFormValid={props.isValid}
                isCancellable={props.isConfigured}
                onCancel={props.onCancel}
            />
        </form>
    );
}

export default withFormik<OwnProps<any>, FormValues<any>>({
    mapPropsToValues: (props) => {
        return props.values;
    },
    handleSubmit: (values, bag) => {
        bag.props.onSubmit(values);
    }
})(PluginWidgetConfigForm);
