import { Action } from "redux";
import { produce } from 'immer';

import { DashboardsState } from "../types/storeTypes";
import { updateWidget } from "./reducerHelpers";
import ActionType, * as Acts from '../actions/dashboard-actions';
import { dashboardToWebComponentBrief } from "../utils/serialization/dashboardSerialization";

function createDashboardsState(): DashboardsState {
    return {
        dashboards: {},
        activeDashboard: undefined,
        available: undefined,
        reportDefinitions: undefined,
        showPropertiesPanel: false,
        openPage: undefined
    };
}

export default function(state: DashboardsState = createDashboardsState(), action: Action): DashboardsState {
    switch (action.type) {
        case ActionType.DONE_CREATE_DASHBOARD:
            return handleDoneCreateDashboard(state, action as Acts.DoneCreateDashboardAction);

        case ActionType.DONE_DELETE_DASHBOARD:
            return handleDoneDeleteDashboard(state, action as Acts.DoneDeleteDashboardAction);

        case ActionType.RENAME_DASHBOARD_NAME:
            return handleRenameDashboard(state, action as Acts.RenameDashboardAction);

        case ActionType.SET_ACTIVE_DASHBOARD:
            return handleSetActiveDashboard(state, action as Acts.SetActiveDashboardAction);

        case ActionType.RECEIVE_DASHBOARD:
            return handleReceiveDashboard(state, action as Acts.ReceiveDashboardAction);

        case ActionType.RECEIVE_DASHBOARD_WIDGET:
            return handleReceiveDashboardWidget(state, action as Acts.ReceiveDashboardWidgetAction);

        case ActionType.RECEIVE_AVAILABLE_DASHBOARDS:
            return handleReceiveAvailableDashboards(state, action as Acts.ReceiveAvailableDashboardsAction);

        case ActionType.SET_AVAILABLE_STARRED_DASHBOARDS:
            return handleSetAvailableStarredDashboards(state, action as Acts.SetAvailableStarredDashboardsAction);

        case ActionType.MARK_DASHBOARD_STARRED:
            return handleMarkDashboardStarred(state, action as Acts.MarkDashboardStarredAction);

        case ActionType.UNMARK_DASHBOARD_STARRED:
            return handleUnmarkDashboardStarred(state, action as Acts.UnmarkDashboardStarredAction);

        case ActionType.MOVE_STARRED_DASHBOARD:
            return handleMoveStarredDashboard(state, action as Acts.MoveStarredDashboardAction);

        case ActionType.SET_DASHBOARD_LAYOUT:
            return handleSetDashboardLayout(state, action as Acts.SetDashboardLayoutAction);

        case ActionType.BEGIN_SAVING_CHANGES:
            return handleBeginSavingChanges(state, action as Acts.BeginSaveChangesAction);

        case ActionType.ERROR_SAVING_CHANGES:
            return handleErrorSavingChanges(state, action as Acts.ErrorSavingChangesAction);

        case ActionType.DONE_SAVING_CHANGES:
            return handleDoneSavingChanges(state, action as Acts.DoneSavingChangesAction);

        case ActionType.MARK_DASHBOARD_MODIFIED:
            return handleMarkDashboardModified(state, action as Acts.MarkDashboardModifiedAction);

        case ActionType.CREATE_WIDGET:
            return handleCreateWidget(state, action as Acts.CreateWidgetAction);

        case ActionType.CONFIGURE_WIDGET:
            return handleConfigureWidget(state, action as Acts.ConfigureWidgetAction);

        case ActionType.REMOVE_WIDGET:
            return handleRemoveWidget(state, action as Acts.RemoveWidgetAction);

        case ActionType.REFRESH_DASHBOARD:
            return handleRefreshDashboard(state, action as Acts.RefreshDashboardAction);

        case ActionType.SET_DASHBOARD_PROPERTIES:
            return handleSetDashboardProperties(state, action as Acts.SetDashboardPropertiesAction);

        case ActionType.CLEAR_STATE:
            return handleClearState();

        case ActionType.SET_SHOW_PROPERTIES_PANEL:
            return handleSetDashboardPropertiesShowing(state, action as Acts.SetShowPropertiesPanelAction);

        case ActionType.SHOW_PAGE:
            return handleShowPage(state, action as Acts.ShowPageAction);

        case ActionType.HIDE_PAGE:
            return handleHidePage(state);

        case ActionType.SET_FIREBASE_TOKEN:
            return handleSetFirebaseToken(state, action as Acts.SetFirebaseTokenAction);
    }
    return state;
}

const handleDoneCreateDashboard = produce((state: DashboardsState, { dashboard }: Acts.DoneCreateDashboardAction) => {
    const uuid = dashboard.uuid;
    const webComponent = dashboardToWebComponentBrief(dashboard);
    state.dashboards[uuid] = dashboard;

    const available = state.available;
    if (!available) return;

    if (webComponent.favourite) {
        available.starred.push(webComponent);
    } else {
        available.owned.push(webComponent);
    }
});

const handleDoneDeleteDashboard = produce((state: DashboardsState, { dashboardUuid }: Acts.DoneDeleteDashboardAction) => {
    delete state.dashboards[dashboardUuid];

    const available = state.available;
    if (!available) return;

    available.starred = available.starred.filter(wc => wc.uniqueIdentifier !== dashboardUuid);
    available.owned = available.owned.filter(wc => wc.uniqueIdentifier !== dashboardUuid);

    if (state.activeDashboard === dashboardUuid) {
        state.activeDashboard = undefined;
    }
})

const handleRenameDashboard = produce((state: DashboardsState, { dashboardUuid, name }: Acts.RenameDashboardAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.name = name;
    }
});

const handleSetActiveDashboard = produce((state: DashboardsState, { dashboardUuid }: Acts.SetActiveDashboardAction) => {
    state.activeDashboard = dashboardUuid;
});

const handleReceiveDashboard = produce((state: DashboardsState, { dashboardUuid, dashboard }: Acts.ReceiveDashboardAction) => {
    state.dashboards[dashboardUuid] = dashboard;
});

const handleReceiveDashboardWidget = produce((state: DashboardsState, { dashboardUuid, widget }: Acts.ReceiveDashboardWidgetAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.widgets[widget.uuid] = widget;
    }
});

const handleReceiveAvailableDashboards = produce((state: DashboardsState, { starred, owned, shared }: Acts.ReceiveAvailableDashboardsAction) => {
    state.available = {
        starred,
        owned,
        shared
    };
});

const handleSetAvailableStarredDashboards = produce((state: DashboardsState, { starred }: Acts.SetAvailableStarredDashboardsAction) => {
    const available = state.available;
    if (available) available.starred = starred;
});

const handleMarkDashboardStarred = produce((state: DashboardsState, { dashboardUuid }: Acts.MarkDashboardStarredAction) => {
    const available = state.available;
    if (!available) return;

    const owned = available.owned;
    const shared = available.shared;
    const all = owned.concat(shared);
    const index = all.findIndex(wc => wc.uniqueIdentifier === dashboardUuid);
    if (index < 0) return;

    const wc = all[index];
    if(owned.findIndex(wc => wc.uniqueIdentifier === dashboardUuid) >= 0){
        const index = owned.findIndex(wc => wc.uniqueIdentifier === dashboardUuid)
        available.owned.splice(index, 1);
    }
    else if(shared.findIndex(wc => wc.uniqueIdentifier === dashboardUuid) >= 0){
        const index = shared.findIndex(wc => wc.uniqueIdentifier === dashboardUuid)
        available.shared.splice(index, 1);
    }
    available.starred.push({ ...wc, favourite: true });
});

const handleUnmarkDashboardStarred = produce((state: DashboardsState, { dashboardUuid }: Acts.UnmarkDashboardStarredAction) => {
    const available = state.available;
    if (!available) return;

    const starred = available.starred;
    const index = starred.findIndex(wc => wc.uniqueIdentifier === dashboardUuid);
    if (index < 0) return;

    const owner = available.owned.length > 0 ? available.owned[0].owner : "";
    const wc = starred[index];
    if(wc.owner === owner){
        available.owned.push({ ...wc, favourite: false });
    }
    else {
        available.shared.push({ ...wc, favourite: false })
    }

    available.starred.splice(index, 1);
});

const handleMoveStarredDashboard = produce((state: DashboardsState, { dashboardUuid, index }: Acts.MoveStarredDashboardAction) => {
    const available = state.available;
    if (!available) return;

    const starred = available.starred;
    const prevIndex = starred.findIndex(wc => wc.uniqueIdentifier === dashboardUuid);
    if (prevIndex < 0) return;

    const wc = starred[prevIndex];
    starred.splice(prevIndex, 1);
    starred.splice(index, 0, wc);
});

const handleSetDashboardLayout = produce((state: DashboardsState, { dashboardUuid, layout }: Acts.SetDashboardLayoutAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.layout.layout = layout;
    }
});

const handleBeginSavingChanges = produce((state: DashboardsState, { dashboardUuid }: Acts.BeginSaveChangesAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.isSaving = true;
    }
});

const handleDoneSavingChanges = produce((state: DashboardsState, { dashboardUuid, version, widgetVersions }: Acts.DoneSavingChangesAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (!dashboard) return;

    dashboard.version = version;
    dashboard.isSaving = false;
    dashboard.isModified = false;

    const widgets = dashboard.widgets;
    for (const widgetUuid in widgetVersions) {
        const widget = widgets[widgetUuid];

        if (widget) {
            widget.version = widgetVersions[widgetUuid];
        }
    }
});

const handleErrorSavingChanges = produce((state: DashboardsState, { dashboardUuid }: Acts.ErrorSavingChangesAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.isSaving = false;
    }
});

const handleMarkDashboardModified = produce((state: DashboardsState, { dashboardUuid, isModified }: Acts.MarkDashboardModifiedAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.isModified = isModified;
    }
});

const handleCreateWidget = produce((state: DashboardsState, { dashboardUuid, widget }: Acts.CreateWidgetAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (!dashboard) return;

    const widgetUuid = widget.uuid;

    // create a new layout entry
    const layoutEntries = dashboard.layout.layout;
    const maxY = layoutEntries.reduce((acc, e) => Math.max(acc, e.y), 0);
    const yPos = (maxY && maxY + 1) || 0;
    const newEntry = { x: 0, y: yPos, w: 2, h: 5, i: widgetUuid };
    layoutEntries.push(newEntry);

    // add the widget to the dashboard
    dashboard.widgets[widgetUuid] = widget;
});

const handleConfigureWidget = produce((state: DashboardsState, { dashboardUuid, widgetUuid, common, config, dataSources }: Acts.ConfigureWidgetAction) => {
    updateWidget(state, dashboardUuid, widgetUuid, (widget) => {
        widget.common = common;
        widget.config = config;
        widget.dataSources = dataSources;
    });
});

const handleRemoveWidget = produce((state: DashboardsState, { dashboardUuid, widgetUuid }: Acts.RemoveWidgetAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (!dashboard) return;

    const widgets = dashboard.widgets;
    const widget = widgets[widgetUuid];
    if (!widget) return;

    // remove widget
    delete widgets[widgetUuid];

    // remove entry from the layout config
    const layoutEntries = dashboard.layout.layout;
    const index = layoutEntries.findIndex(e => e.i === widgetUuid);
    if (index < 0) return;

    layoutEntries.splice(index, 1);
});

const handleSetDashboardProperties = produce((state: DashboardsState, { dashboardUuid, properties }: Acts.SetDashboardPropertiesAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.properties = properties;
    }
});

const handleClearState = () => createDashboardsState();

const handleSetDashboardPropertiesShowing = produce((state: DashboardsState, { show }: Acts.SetShowPropertiesPanelAction) => {
    state.showPropertiesPanel = show;
});

const handleRefreshDashboard = produce((state: DashboardsState, { dashboardUuid }: Acts.RefreshDashboardAction) => {
    const dashboard = state.dashboards[dashboardUuid];
    if (dashboard) {
        dashboard.refreshCount++;
    }
});

const handleShowPage = produce((state: DashboardsState, { pageUrl }: Acts.ShowPageAction) => {
    state.openPage = { pageUrl };
});

const handleHidePage = produce((state: DashboardsState) => {
    state.openPage = undefined;
});

const handleSetFirebaseToken = produce((state: DashboardsState, { token }: Acts.SetFirebaseTokenAction) => {
    state.firebaseToken = token;
});
