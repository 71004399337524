import produce from "immer";
import { WidgetWebComponentData } from "../../types/webComponentTypes";
import { WebComponentResponse } from "../../types/responseTypes";
import { Widget, WidgetCommonConfig } from "core/dashboard/types/storeTypes";
import { ReportDataSource } from "core/dashboard/types/widgetTypes";
import { ReportDataSourceAux } from "core/dashboard/types/requestTypes";

/** Web-Component to widget */

export function deserializeWidget(widgetUuid: string, webComponent: WebComponentResponse): Widget<unknown> {
    const data: WidgetWebComponentData = JSON.parse(webComponent.data);
    const { type, config } = data;

    const dataSources = deserializeDataSource(webComponent.dataSources as ReportDataSourceAux[])
    const configAux = editConfigDataSources(config, dataSources)

    const common: WidgetCommonConfig = {
        widgetName: data.common.widgetName,
        pollInterval: data.common.pollInterval,
        events: data.common.events ?? []
    };

    return {
        uuid: widgetUuid,
        version: webComponent.version,
        type,
        common,
        config: configAux,
        dataSources
    };
}

function deserializeDataSource(dataSources: ReportDataSourceAux[]): ReportDataSource[]{
    if(!dataSources) return []
    return dataSources.map(data => {
        return(
            {
                definition: data.definition,
                type: data.type,
                uniqueIdentifier: data.uniqueIdentifier,
                params: JSON.parse(data.params)
            }
        )
    })
}

function editConfigDataSources(config: any, dataSources: ReportDataSource[]) {
    if(!dataSources.length)
        return config

    let nextConfig;

    if(config.sources){
        nextConfig = produce(config , (draftConfig: { sources: any[]; }) => {
            draftConfig.sources.map((source: any, index: number) => {
                source.reportSource = dataSources[index].definition;
                source.type = dataSources[index].type;
            })
        })
    }
    else{
        nextConfig = produce(config , (draftConfig: { reportSource: string; type: string; }) => {
            draftConfig.reportSource = dataSources[0].definition
            draftConfig.type = dataSources[0].type
            }
        )
    }

    return nextConfig
}
