import React from 'react';
import {Form} from 'react-bootstrap'

interface FieldProps {
    value: boolean,
    label: string,
    onChange: (val: boolean) => void
}

export function PropertyCheckField(props: FieldProps) {
    return (
        <Form.Group>
            <Form.Check label={props.label} checked={props.value}
                onChange={(event: { target: any; }) => props.onChange(event.target.checked)}
            />
        </Form.Group>
    )
}
