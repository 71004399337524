import { StoreState } from "../../types/storeTypes";
import { DialogState } from "./dialogTypes";

export function getDialog(state: StoreState, dialogId: string): DialogState | undefined {
    return state.dialog.dialogs[dialogId];
}

export function getDialogResult(state: StoreState, dialogId: string): unknown {
    return getDialog(state, dialogId)?.result;
}

export function getDialogs(state: StoreState) {
    return state.dialog.dialogs;
}
