import React, { useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { SortColumn } from './SingleSourceWidgetGrid';
import { configToColumns } from './singleSourceGridColumns';
import { SingleSourceConfig } from '../singleSourceTypes';
import { ReportDataSource, WidgetActions } from '../../../../types/widgetTypes';
import { useWidgetDataFetcher } from '../../../../utils/hooks/useWidgetDataFetcher';
import { RESULTS_PER_PAGE } from './singleSourceRequestHandler';
import { RunReportResponse } from '../../../../types/responseTypes';
import { Evaluator } from 'core/dashboard/evaluator/Evaluator';
import { IPropertySource } from 'core/dashboard/evaluator/PropertySource';
import SingleSourcePager from './SingleSourcePager';
import { createActionHandler } from 'core/dashboard/actionhandler/actionHandler';
import { DEFAULT, fetchDataSourceData, fetchSingleSourceData } from 'core/dashboard/components/sourceMapping/fetchSourcesData';
import { WidgetCommonConfig } from 'core/dashboard/types/storeTypes';

/**
 * Single-source widget view
 */

type Props = {
    config: SingleSourceConfig;
    widgetData: RunReportResponse[] | undefined;
    refreshCount: number;
    actions: WidgetActions;
    properties: IPropertySource;
    dataSources: ReportDataSource[];
    common: WidgetCommonConfig;
    cacheRefreshCount: number;
};

export const initialWidgetErrorState = { hasError: false, error: "" }

export default function SingleSourceWidgetView(props: Props) {
    const { refreshCount, actions, config, widgetData, properties, common, cacheRefreshCount } = props;

    //Put data in state(data for report type)
    const [data, setData] = useState<RunReportResponse | undefined>(widgetData && undefined);
    const [loading, setLoading] = useState(true)

    const columnsConfig = config.columns;
    const evaluator = useMemo(() => new Evaluator(properties), [properties]);
    const [widgetError, setWidgetError] = useState(initialWidgetErrorState);
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
    const type = useMemo(() => props.dataSources[0].type, [props.dataSources])
    // compute columns from widget configuration
    const columns = useMemo(() => configToColumns(columnsConfig), [columnsConfig, data]);
    // create a widget action handler
    const actionHandler = useMemo(() => createActionHandler(actions, evaluator), [actions, evaluator]);
    const prevCacheRefreshCount = useRef<number>(cacheRefreshCount);

    useEffect(() => {
        if (prevCacheRefreshCount.current !== cacheRefreshCount)  {
            prevCacheRefreshCount.current = cacheRefreshCount
        }
    }, [cacheRefreshCount])

    useEffect(() => {
        if(widgetData){
            if(type === "SQL"){
                let dataAux = {data: widgetData, nextOffset: -1, totalResults: -1 }
                setData(dataAux)
            }
            else if(type === "DATASOURCE"){
                //Cast data to dataSource type
                const dataSourceData = widgetData[0] as any
                let dataAux = {data: dataSourceData.result, nextOffset: -1, totalResults: -1 }
                setData(dataAux)
            }
            else {
                setData(widgetData[0])
            }
            setLoading(false)
        }
    }, [widgetData])

    
    function handleWidgetError(hasError: boolean, error: string){
        setWidgetError({hasError, error})
    }

    function handleSortColumns(cols: SortColumn[]){
        if(JSON.stringify(cols) !== JSON.stringify(sortColumns)){
            setSortColumns(cols)
        }
    }

    if(type === "SQL"){
        const requestHandler = useCallback((offset?: number) => fetchSingleSourceData(props.dataSources, 
            evaluator, 
            offset, 
            sortColumns, 
            config.orderColumn,
            config.pagination,
            common.pollInterval,
            prevCacheRefreshCount.current !== cacheRefreshCount ? true : false), 
        [props.dataSources, evaluator, sortColumns]);

        useWidgetDataFetcher(refreshCount, requestHandler, actions, handleWidgetError);

        const nextPage = data?.data.length ? (data?.data[data.data.length -1].next_page == true) : false;
        const isDefault = (config.pagination === DEFAULT || !config.pagination ) ? true : false;

        return (
            <SingleSourcePager
                data={data ? data.data : []}
                totalResults={data ? data.data.length : 0}
                fetcher={(offset: number) => requestHandler(offset).then(x => x)}
                columns={columns}
                handleSortColumns={handleSortColumns}
                nextPage={nextPage}
                widgetError={widgetError}
                actionHandler={actionHandler}
                actions={actions}
                isDefault={isDefault}
            />
        );
    }
    else {
        const totalResults = data ? data.data.length : 0
        const requestHandler = useCallback((offset?: number) => fetchDataSourceData(config, 
            evaluator, 
            offset, 
            sortColumns), 
        [props.dataSources, evaluator, sortColumns]);

        useWidgetDataFetcher(refreshCount, requestHandler, actions, handleWidgetError);
        
        return (
            <SingleSourcePager
                data={data ? data.data : []}
                totalResults={data ? data.data.length : 0}
                fetcher={(offset: number) => requestHandler(offset).then(x => x.result)}
                columns={columns}
                handleSortColumns={handleSortColumns}
                nextPage={totalResults === RESULTS_PER_PAGE + 1 ? true : false}
                widgetError={widgetError}
                actionHandler={actionHandler}
                actions={actions}
                isDefault={true}
            />
        );
    }
}
