import React, { useState } from 'react';
import { connect } from 'react-redux';

import TitleEdit from './TitleEdit';
import { getDashboardName } from '../../utils/selector/selectors';
import { StoreState } from '../../types/storeTypes';
import { useTranslation } from 'react-i18next';

type OwnProps = {
    dashboardUuid: string
}

type StateProps = {
    dashboardName: string | undefined
}

function HeaderItemTitle({ dashboardUuid, dashboardName }: OwnProps & StateProps) {
    const [isEditing, setEditing] = useState(false);
    const { t } = useTranslation();

    function onToggleEditing() {
        setEditing(!isEditing);
    }

    return (
        <div className="dashboard-header-middle">
        { isEditing
            ? <TitleEdit onToggle={onToggleEditing} dashboardUuid={dashboardUuid}/>
            : <span onClick={onToggleEditing} className="dashboard-title">{ t(dashboardName) }</span>
        }
        </div>);
}

export default connect(
    (state: StoreState, ownProps: OwnProps): StateProps => ({
        dashboardName: ownProps.dashboardUuid && getDashboardName(state, ownProps.dashboardUuid),
    })
)(HeaderItemTitle)
