import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl } from "react-bootstrap";
import { useCachedFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from "../../../utils/requests/requestId";
import { findReportDefinitions } from "../../../utils/requests/requestHandlers";

export default function DatasourceTypeInput({ field, form }: FieldProps) {
    const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    const { data } = useCachedFetcher(findReportDefinitions, RequestId.REPORT_DEFINITIONS);

    return (
        <FormControl as="select" isInvalid={error} {...field}>
            <option value="" disabled></option>
            { data && data.map((reportDef, idx) => <option key={idx} value={reportDef.uuid}>{ reportDef.name }</option>)}
        </FormControl>
    );
}
