import {
    FormState,
    FormAction,
    FormActionType,
    ResetFormAction,
    SetFieldValueAction,
    SetFieldKeyAction,
    SetFieldTypeAction,
    RemoveFieldAction,
    SetFieldEditableAction
} from "./dashboardPropertiesReducerTypes";
import { resolveInitialValue } from "core/dashboard/properties/dashboardProperties";
import produce from "immer";
import { DashboardPropertyType } from "core/dashboard/types/dashboardPropertyTypes";

function handleReset(action: ResetFormAction) {
    return {
        properties: action.properties
    };
}

const handleSetFieldKey = produce((state: FormState, action: SetFieldKeyAction) => {
    state.properties[action.index].name = action.key;
});

const handleSetFieldType = produce((state: FormState, action: SetFieldTypeAction) => {
    const prop = state.properties[action.index];
    const value = resolveInitialValue(action.propertyType);
    prop.type = action.propertyType;
    prop.val = value;
});

const handleSetFieldValue = produce((state: FormState, action: SetFieldValueAction) => {
    state.properties[action.index].val = action.value;
});

const handleSetFieldEditable = produce((state: FormState, action: SetFieldEditableAction) => {
    state.properties[action.index].isEditable = action.editable;
});

const handleAddField = produce((state: FormState) => {
    state.properties.push({
        name: '',
        val: '',
        type: DashboardPropertyType.STRING,
        isEditable: true
    });
});

const handleRemoveField = produce((state: FormState, action: RemoveFieldAction) => {
    state.properties.splice(action.index, 1);
});

export function reducer(state: FormState, action: FormAction): FormState {
    switch (action.type) {
        case FormActionType.RESET:
            return handleReset(action);

        case FormActionType.SET_FIELD_KEY:
            return handleSetFieldKey(state, action);
            
        case FormActionType.SET_FIELD_TYPE:
            return handleSetFieldType(state, action);

        case FormActionType.SET_FIELD_VALUE:
            return handleSetFieldValue(state, action);

        case FormActionType.SET_FIELD_EDITABLE:
            return handleSetFieldEditable(state, action);

        case FormActionType.ADD_FIELD:
            return handleAddField(state);

        case FormActionType.REMOVE_FIELD:
            return handleRemoveField(state, action);

        default:
            return state;
    }
}
