import { WidgetDefinition } from "../../../types/widgetTypes";
import humanTasksImage from '../../../../../img/humtasks.png';
import HumanTasksWidget from "./HumanTasksWidget";

export const HUMAN_TASKS_WIDGET_TYPE = 'HUMAN_TASKS';

export const HUMAN_TASKS_WIDGET: WidgetDefinition = {
    name: 'Human Tasks',
    description: 'Human tasks management component',
    icon: 'users',
    image: humanTasksImage,
    component: HumanTasksWidget,
    listSources: () => []
}
