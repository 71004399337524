import React from 'react';
import { FieldProps, FastField } from "formik";
import { FormGroup, FormLabel } from 'react-bootstrap';
import Vector3DInput from './Vector3DInput';

export default function PosRot3DInput(props: FieldProps) {
    const { field } = props;
    return (
        <div className="field-group-nested">
            <FormGroup>
                <FormLabel>Position</FormLabel>
                <FastField name={field.name + '.position'} component={Vector3DInput}/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Rotation</FormLabel>
                <FastField name={field.name + '.rotation'} component={Vector3DInput}/>
            </FormGroup>
        </div>
    );
}
