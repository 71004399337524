import React, { useMemo } from 'react';
import { Formik, FieldArray, FastField } from 'formik';
import { Button, CloseButton, Col, Form } from 'react-bootstrap';

import CommonConfig from '../../common/config/CommonConfig';
import { WidgetCommonConfig } from '../../../../types/storeTypes';
import { WidgetActions } from '../../../../types/widgetTypes';
import { IframeSourceConfig } from '../iframeTypes';
import ConfigPanel from '../../common/config/ConfigPanel';
import FormControlFieldComponent from '../../common/config/formik/FormControlFieldComponent';
import {IframeParamConfig} from '../iframeTypes';
import EvaluatableFieldComponent from 'core/dashboard/components/form/formik/EvaluatableFieldComponent';

type IframeFormValues = IframeSourceConfig & WidgetCommonConfig;

type Props = {
    common: WidgetCommonConfig;
    config: IframeSourceConfig | undefined;
    actions: WidgetActions;
};

const DEFAULT_VALUES: IframeSourceConfig = {
    url: '',
    urlParams: []
};

function createEmptyColumn(): IframeParamConfig {
    return {
        name: '',
        value: '',
    };
}

export default function IframeWidgetConfig(props: Props) {
    const common = props.common;
    const config = props.config;
    
    const initialValues = useMemo((): IframeFormValues => {
        return Object.assign({}, common, DEFAULT_VALUES, config);
    }, [config, common]);

    function onFormSubmit(values: IframeFormValues) {
        const common: WidgetCommonConfig = {
            pollInterval: values.pollInterval,
            widgetName: values.widgetName,
            events: values.events
        };

        const config: IframeSourceConfig = {
            url: values.url,
            urlParams: values.urlParams
        };

        // configure the widget in the store
        props.actions.configure(common, config, []);
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            render={({ handleSubmit, values, isValid }) => (
                <form className="config-form" onSubmit={handleSubmit}>
                    <CommonConfig />
                    <ConfigPanel defaultExpanded header="Iframe">
                        <div className="p-2">
                            <Form.Group>
                                <Form.Label>Url</Form.Label>
                                <FastField name="url" component={FormControlFieldComponent} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Url Parameters</Form.Label>
                                <FieldArray
                                    name="urlParams"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.urlParams.map((column, index) => (
                                        <div key={index}>
                                            <Form.Row>
                                                <Col>
                                                    <FastField name={`urlParams[${index}].name`} placeholder="name" component={FormControlFieldComponent}/>
                                                </Col>
                                                <Col className="value-column">
                                                    <FastField name={`urlParams.${index}.value`} placeholder="value" component={EvaluatableFieldComponent}/>
                                                </Col>
                                                <Col className="flex-content">
                                                    <CloseButton onClick={() => arrayHelpers.remove(index)} />
                                                </Col>
                                            </Form.Row>
                                        </div>
                                        ))}
                                        <Button onClick={() => {
                                                const column = createEmptyColumn();
                                                arrayHelpers.push(column);
                                            }}>
                                            Add Param
                                        </Button>
                                    </div>
                                    )}
                                />
                            </Form.Group>
                        </div>
                    </ConfigPanel>
                    <div className="config-form-footer">
                        <Button variant="success" type="submit" disabled={!isValid}>
                            Save Changes
                        </Button>
                    </div>
                </form>
            )}
        />
    );
}
