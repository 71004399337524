
export enum DashboardPropertyType {
    STRING='string',
    DATE='date'
}

export type DashboardPropertyTypeConfig = {
    label: string;
    initialValue: unknown;
    resolveValue: (input: unknown) => unknown;
    asString: (input: unknown) => string;
}
