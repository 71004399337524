export enum DateTimeUnit {
    SECOND='second',
    MINUTE='minute',
    HOUR='hour',
    DAY='day',
    WEEK='week',
    MONTH='month',
    QUARTER='quarter',
    YEAR='year'
}

export type DateTimeOffset = {
    modifier: string | undefined;
    amount: number;
    timeUnit: DateTimeUnit;
}

export type DateTimeOffsetValue = {
    offsets: DateTimeOffset[];
    format: string | undefined;
}
