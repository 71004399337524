import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

type Props = {
    isCancellable: boolean;
    isFormValid: boolean;
    onCancel: () => void;
}

export default function WidgetConfigFooter({ isFormValid, isCancellable: isCancelAvailable, onCancel }: Props) {
    return (
        <div className="config-form-footer">
            <ButtonGroup>
                <Button variant="secondary" disabled={!isCancelAvailable} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={!isFormValid}>
                    Apply
                </Button>
            </ButtonGroup>
        </div>
    );
}
